import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AdditionalInfo,
  BottomBox,
  Button,
  CancelButton,
  Date,
  DetailsContainer,
  DownBtn,
  EndDate,
  Heading,
  Image,
  Input,
  InputBox,
  InputLabel,
  InputWrapper,
  Label,
  Price,
  RootContainer,
  Text,
  Title,
  TotalPrice,
  TotalText,
  UpBtn,
} from './TicketPurchase.elements';
import {
  Col,
  Row,
  Space,
} from 'antd';
import { translate } from '../../../i18n';
import { formattedDate, getFormattedDateTime, getFormattedPrice } from '../../../utils';
import { EVENT_SK, LIMITS, } from '../../../constants';

const TicketPurchase = ({
  image,
  datetime,
  endtime,
  title,
  price,
  availableTicket,
  ticketInfoIdentifier,
  entityType,
  entityIdentifier,
}) => {
  const navigate = useNavigate();
  const [noOfTicket, setNoOfTicket] = useState(1);

  const upCount = () => { noOfTicket < LIMITS.MAX_TICKET_LIMIT && noOfTicket < availableTicket && setNoOfTicket(noOfTicket + 1); }
  const downCount = () => { noOfTicket > 1 && setNoOfTicket(noOfTicket - 1); }

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (value === '' || value === undefined || value === null) {
      value = 0;
    }
    if (parseInt(value) < 0 || parseInt(value) > LIMITS.MAX_TICKET_LIMIT || parseInt(value) > availableTicket) {
      return;
    }
    setNoOfTicket(parseInt(value));
  }

  const next = () => {
    if (noOfTicket <= 0) {
      return;
    }
    navigate('/ticket-purchase/billing-information', {
      state: {
        image,
        amount: noOfTicket * price,
        ticketCount: noOfTicket,
        ticketInfoIdentifier,
        price,
        entityType,
        entityIdentifier,
        datetime,
        endtime,
        title,
      }
    });
  }

  const cancel = () => {
    navigate(-1);
  }

  const InfoBox = () => {
    return (
      <>
        {entityType === EVENT_SK &&
          <Date>
            {getFormattedDateTime(datetime)}
          </Date>
        }
        <Title>
          {title}
        </Title>
        <Price>
          {translate('ticketing.mmkPerTicket').replace('!@#$%', getFormattedPrice(price))}
        </Price>
        <EndDate>
          {`Valid Until : ${formattedDate(endtime)}`}
        </EndDate>
      </>
    );
  }

  const renderDetailsInfo = () => {
    return (
      <DetailsContainer>
        <Label>
          {translate('ticketing.orderDetails')}
        </Label>
        <Row gutter={[20, 0]}>
          <Col xs={24} sm={6} lg={6}>
            <Image alt='img' src={image} />
          </Col>
          <Col xs={24} sm={10} lg={10}>
            <InfoBox />
          </Col>
          <Col xs={12} sm={4} lg={4}>
            <InputBox>
              <InputLabel>
                {translate('ticketing.ticket')}
              </InputLabel>
              <InputWrapper>
                <Input
                  type='number'
                  value={noOfTicket}
                  addonBefore={<DownBtn onClick={downCount} />}
                  addonAfter={<UpBtn onClick={upCount} />}
                  onChange={handleInputChange}
                />
              </InputWrapper>
            </InputBox>
          </Col>
          <Col xs={12} sm={4} lg={4}>
            <TotalText>
              {translate('ticketing.total')}
            </TotalText>
            <TotalPrice>
              {`${getFormattedPrice(noOfTicket * price)} ${translate('common.mmk')}`}
            </TotalPrice>
          </Col>
        </Row>
      </DetailsContainer>
    );
  }

  return (
    <RootContainer>
      <Heading>
        {translate('ticketing.getYourTicket')}
      </Heading>
      {renderDetailsInfo()}
      <BottomBox>
        <AdditionalInfo>
          {availableTicket <= 10 && `*** 
          ${translate('ticketing.additionalInfoMessage')
              .replace('!@#$%', getFormattedPrice(availableTicket))} 
            ***`}
        </AdditionalInfo>
        <Space size='large'>
          <CancelButton onClick={cancel}>
            {translate('ticketing.cancel')}
          </CancelButton>
          <Button onClick={next}>
            {translate('ticketing.next')}
          </Button>
        </Space>
      </BottomBox>
    </RootContainer>
  );
}

export default TicketPurchase;