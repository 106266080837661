import React, { useEffect, useState } from 'react';
import { useQRCode } from 'react-qrcode';
import {
  BuyTicketButton,
  IconFavLight,
  IconShare,
  WatchButton,
} from '../../theme/styles';
import {
  Actions,
  ActionsBox,
  DetailsContainer,
  Image,
  Information,
  Poster,
  RootContainer,
  Video,
  VideoWrapper,
  DateTime,
  Title,
  SubTitle,
  Organizer,
  Icon,
  Typography,
  TextContent,
  PlaybackIcon,
  PlaybackTime,
  EventInfo,
  Text,
  Date,
  InfoWrapper,
  BuyTicketBox,
  SoldOutLabel,
  Spinner,
  VideoThumb,
  LiveIconWrapper,
  LiveText,
  LiveIcon,
} from './VideoDetails.elements';
import { translate } from '../../i18n';
import { useNavigate } from 'react-router-dom';
import { EVENT_SK, FREEBIE_SK, LOCAL_STORAGE_KEYS, VENUE_SK } from '../../constants';
import { ImageViewer, LoginPrompt } from '../../common';
import {
  SkeletonActions,
  SkeletonDetails,
  SkeletonVideo,
} from './Skeleton';
import {
  formattedDate,
  getFormattedDateTime,
  getFormattedDuration,
  getFormattedPrice,
  getFormattedTime,
  isLive,
  isLived,
  isLiveEnded,
  isNonEmptyString,
} from '../../utils';
import { api, Status } from '../../api';
import { Col, message, Row, Tooltip } from 'antd';
import PlayModal from './PlayModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const VideoDetails = ({
  loading,
  entityType,
  entityIdentifier,
  broadcastEndDateTime,
  thumb,
  price,
  datetime,
  endtime,
  title,
  playbackTime,
  organizer,
  content,
  poster,
  isSaved,
  availableTicket,
  ticketInfoIdentifier,
  privacy,
  link,
  handleFavourite,
}) => {
  const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEYS.sessionId);
  const navigate = useNavigate();
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [ticketCode, setTicketCode] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openPlayModal, setOpenPlayModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {

    // component will unmount
    return () => {
      isPlaying && stopPlaying();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (loading) {
      resetLocalState();
    }
  }, [loading]);

  useEffect(() => {
    if (content) {
      let contentElement = document.getElementById('vdc-id');
      if (contentElement) {
        contentElement.innerHTML = content;
      }
    }
  }, [content]);

  useEffect(() => {
    setOpenModal(isNonEmptyString(ticketCode));
  }, [ticketCode]);

  const stopPlaying = () => {
    api
      .stopPlayingVideo({ entityIdentifier, entityType })
      .then(() => { })
      .catch(() => { });
  }

  const playVideo = (url) => {
    setIframeUrl(url);
    setIsPlaying(true);
    setOpenPlayModal(false);
  }

  const copyLink = (e) => {
    e.preventDefault();
  }

  const isPlayEnabled = () => {
    return (entityType === VENUE_SK || (isLived(datetime) && !isLiveEnded(broadcastEndDateTime))) && !isPlaying
  }

  const handlePlayClick = () => {
    if (!isPlayEnabled()) {
      return;
    }
    setOpenPlayModal(true);
  }

  const handleBuyTicketClick = () => {
    if (apiStatus !== Status.LOADING) {
      if (availableTicket === 0 || (entityType === EVENT_SK && isLiveEnded(broadcastEndDateTime))) {
        return;
      }
      if (privacy === FREEBIE_SK) {
        buyFreeTicket();
      }
      else {
        buyPremiumTicket();
      }
    }
  }

  const buyPremiumTicket = () => {
    navigate('/ticket-purchase', {
      state: {
        thumb,
        datetime,
        endtime,
        title,
        price,
        availableTicket,
        ticketInfoIdentifier,
        entityType,
        entityIdentifier,
      }
    });
  }

  const buyFreeTicket = () => {
    setApiStatus(Status.LOADING);
    api
      .buyFreeTicket(ticketInfoIdentifier)
      .then(({ userResponse: { ticketCode } }) => {
        setTicketCode(ticketCode);
        setApiStatus(Status.SUCCESS);
      })
      .catch((error) => {
        setApiStatus(Status.ERROR);
        message.error(error.message);
      });
  }

  const resetLocalState = () => {
    setApiStatus(Status.DEFAULT);
    setTicketCode('');
    setOpenModal(false);
    setOpenPlayModal(false);
    setIframeUrl('');
    setIsPlaying(false);
  }

  const renderLiveIcon = () => {
    if (
      entityType === EVENT_SK &&
      isLive(datetime, broadcastEndDateTime) &&
      isPlaying
    ) {
      return (
        <LiveIconWrapper>
          <LiveIcon status='error' />
          <LiveText>
            LIVE
          </LiveText>
        </LiveIconWrapper>
      )
    }
    return '';
  }

  const renderVideo = () => {
    if (loading) {
      return <SkeletonVideo />
    }

    return (
      <Row>
        <Col xs={0} md={4} />
        <Col xs={24} md={16}>
          <VideoWrapper>
            {renderLiveIcon()}
            <VideoThumb
              alt='details-video'
              src={thumb}
              show={!isPlaying}
            />
            {isPlaying &&
              <Video
                src={iframeUrl}
                frameBorder="0"
                allow="autoplay; fullscreen;"
              />
            }
            <EventInfo show={entityType === EVENT_SK && !isLived(datetime)}>
              <InfoWrapper>
                <Text>
                  {translate('videoDetails.eventInfoMessage')}
                </Text>
                <Date>
                  {formattedDate(datetime)}
                </Date>
              </InfoWrapper>
            </EventInfo>
            <EventInfo show={entityType === EVENT_SK && isLiveEnded(broadcastEndDateTime)}>
              <InfoWrapper>
                <Text>
                  {translate('videoDetails.eventEndedMessage')}
                </Text>
              </InfoWrapper>
            </EventInfo>
          </VideoWrapper>
        </Col>
        <Col xs={0} md={4} />
      </Row >
    )
  };

  const renderActions = () => {
    if (loading) {
      return <SkeletonActions />
    }

    return (
      <Actions>
        <ActionsBox>
          <BuyTicketBox>
            <SoldOutLabel show={availableTicket === 0 && isLoggedIn}>
              {`*** ${translate('videoDetails.ticketSoldOutMessage')}`}
            </SoldOutLabel>
            <LoginPrompt
              text={translate('loginPrompt.toBuyThis')}
              onClick={handleBuyTicketClick}
            >
              <BuyTicketButton
                inactive={
                  (availableTicket === 0 && isLoggedIn) ||
                  apiStatus === Status.LOADING ||
                  (entityType === EVENT_SK && isLiveEnded(broadcastEndDateTime))
                }>
                {privacy === FREEBIE_SK
                  ? translate('videoDetails.buyFreeButtonLabel')
                  : translate('videoDetails.buyButtonLabel')
                    .replace('!@#$%', getFormattedPrice(price))
                }
                <Spinner show={apiStatus === Status.LOADING} />
              </BuyTicketButton>
            </LoginPrompt>
          </BuyTicketBox>
          <LoginPrompt
            text={translate('loginPrompt.toPlayThis')}
            onClick={handlePlayClick}
          >
            <WatchButton inactive={!isPlayEnabled()}>
              {translate('videoDetails.watchButtonLabel')}
            </WatchButton>
          </LoginPrompt>
          <LoginPrompt
            text={translate('loginPrompt.toLikeThis')}
            onClick={handleFavourite}
          >
            <IconFavLight
              filled={isSaved}
            />
          </LoginPrompt>

          <Tooltip
            title="Copy Link"
            color='rgba(168, 126, 226, 0.5)'
          >
            <CopyToClipboard
              text={link}
              onCopy={() => {
                message.success({
                  content: 'Link Copied',
                  style: {
                    marginTop: '64px'
                  }
                });
              }}
            >
              <IconShare onClick={copyLink} />
            </CopyToClipboard>
          </Tooltip>

        </ActionsBox>
      </Actions>
    )
  }

  const renderDetailsInfo = () => {
    if (loading) {
      return <SkeletonDetails />
    }

    return (
      <DetailsContainer>
        <Information>
          <DateTime show={entityType === EVENT_SK}>
            {getFormattedDateTime(datetime)}
          </DateTime>
          <Title>
            {title}
          </Title>
          <SubTitle>
            {translate('videoDetails.details')}
          </SubTitle>
          <PlaybackTime show={playbackTime}>
            <PlaybackIcon />
            <Typography>
              {`${translate('videoDetails.playbackTime')} - ${playbackTime}`}
            </Typography>
          </PlaybackTime>
          <Organizer>
            <Icon />
            <Typography>
              {translate('videoDetails.eventBy').replace('!@#$%', organizer)}
            </Typography>
          </Organizer>
          <TextContent id='vdc-id'>
            {content}
          </TextContent>
        </Information>
        <Poster show={poster}>
          <Image
            alt='poster img'
            src={poster}
          />
        </Poster>
      </DetailsContainer>
    )
  }

  return (
    <>
      <RootContainer>
        {renderVideo()}
        {renderActions()}
        {renderDetailsInfo()}
      </RootContainer>
      <PlayModal
        visible={openPlayModal}
        title={title}
        entityIdentifier={entityIdentifier}
        entityType={entityType}
        successCallback={playVideo}
        handleClose={() => setOpenPlayModal(false)}
      />
      <ImageViewer
        visible={openModal}
        showName={title}
        ticketCode={ticketCode}
        date={entityType === VENUE_SK
          ? formattedDate(datetime)
          : getFormattedDateTime(datetime)}
        endDate={formattedDate(endtime)}
        image={useQRCode(ticketCode)}
        handleClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default VideoDetails;