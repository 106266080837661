import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _, { isEmpty } from 'lodash';
import {
  LatestFreebieCard,
  LivestreamCard,
  PremiumCard
} from '../../common';
import {
  DISCOVER_MODE_LK,
  EVENT_SK,
  FREEBIE_LK,
  LATEST_LK,
  LIMITS,
  LIVESTREAM_LK,
  POSTER_LK,
  PREMIUM_LK,
  UNSAVE_SK,
  VENUE_SK
} from '../../constants';
import {
  ListColumn,
  ListItem,
  Spinner,
} from '../../theme/styles';
import { translate } from '../../i18n';
import {
  ListContainer,
  Loader,
  RootContainer,
  Text,
  Title,
} from './Listing.elements';
import { useSelector } from 'react-redux';
import { api, Status } from '../../api';
import { getFormattedPrice } from '../../utils';

const Listing = ({
  type,
}) => {
  const status = useSelector(({ app }) => app.status);
  const [page, setPage] = useState(1);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    status === Status.SUCCESS && fetchData();
  }, [status]);

  useEffect(() => {
    page !== 1 && type !== LATEST_LK && fetchData(page);
  }, [page]);

  useEffect(() => {
    resetState();
    fetchData();
  }, [type]);

  const fetchData = (page = 1) => {
    if (apiStatus !== Status.LOADING) {
      setApiStatus(Status.LOADING);
      const params = (type === PREMIUM_LK || type === FREEBIE_LK)
        ? {
          entityType: type.toLowerCase(),
          page
        }
        : page;

      api[
        type === LIVESTREAM_LK
          ? 'fetchLivestreamList'
          : type === LATEST_LK
            ? 'fetchLatestVideoList'
            : 'fetchVideoList'
      ](params)
        .then(({ userResponse: newList }) => {
          setApiStatus(Status.SUCCESS);
          setList(page === 1 ? newList : [...list, ...newList]);
          setHasMore(newList.length >= LIMITS.ITEMS_PER_PAGE && type !== LATEST_LK);
        })
        .catch(() => {
          setApiStatus(Status.ERROR);
        });
    }
  }

  const resetState = () => {
    setPage(1);
    setApiStatus(Status.DEFAULT);
    setHasMore(true);
    setList([]);
  }

  const loader = (
    <Loader>
      <Spinner />
    </Loader>
  );

  const Card = ({ item }) => {
    switch (type) {
      case LIVESTREAM_LK:
        return <LivestreamCard
          mode={DISCOVER_MODE_LK}
          identifier={item.entityIdentifier}
          entityType={EVENT_SK}
          image={item.thumbUrl}
          broadcastStartDateTime={item.broadcastStartDateTime}
          broadcastEndDateTime={item.broadcastEndDateTime}
          description={item.title}
          price={translate('videoDetails.buyButtonLabel').replace('!@#$%', getFormattedPrice(item.price))}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          link={item.copyLinkUrl}
        />
      case PREMIUM_LK:
        return <PremiumCard
          identifier={item.entityIdentifier}
          entityType={VENUE_SK}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          description={item.shortTitle}
          price={item.price}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          mode={DISCOVER_MODE_LK}
          link={item.copyLinkUrl}
        />
      default:
        return <LatestFreebieCard
          type={type}
          entityType={VENUE_SK}
          identifier={item.entityIdentifier}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          price={item.price}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          mode={DISCOVER_MODE_LK}
          link={item.copyLinkUrl}
        />
    }
  }

  const Rows = () => {
    return (
      <ListContainer>
        {list.map((item, key) => (
          <ListColumn key={key}>
            < ListItem >
              <Card item={item} />
            </ListItem>
          </ListColumn >
        ))}
      </ListContainer >
    );
  }

  const renderList = () => {
    return (

      <InfiniteScroll
        dataLength={list.length}
        next={() => apiStatus === Status.SUCCESS && setPage(page + 1)}
        hasMore={hasMore}
        loader={loader}
        endMessage=''
      >
        {apiStatus === Status.SUCCESS && isEmpty(list) ? <Text> {translate(`common.noContent`)} </Text> : <Rows />}
      </InfiniteScroll>

    )
  }

  return (
    <RootContainer>
      <Title>
        {translate(`common.${type === FREEBIE_LK ? 'freebie' : type.toLowerCase()}`)}
      </Title>
      {renderList()}
    </RootContainer >
  )
}

export default Listing;