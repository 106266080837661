import styled from 'styled-components';
import {
  Space as AntSpace,
} from 'antd';
import { ContainerTwoTone } from '@ant-design/icons';
import { device } from '../../theme/device';

export const Topbar = styled.div`
padding-top: 5px;
background-color: ${props => props.theme.body};
border-bottom: 1px solid ${props => props.theme.primaryColor};
position: sticky;
top: 0;
`;

export const List = styled.div`
width: 420px;
height: 80vh;
padding: 0 25px 10px 25px !important;
overflow: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 0.3em;
    height: 0.4em;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .anticon-close {
    display: none;
    float: right;
    color: ${props => props.theme.fontColor};
    font-size: 18px;

    @media ${device.mobileL} {
      display: block;
    }
  }

@media ${device.tablet} {
  padding: 0 10px 5px 10px !important;
}

@media ${device.mobileL} {
  width: 100vw;
}
`;

export const Title = styled.div`
padding-bottom: 15px !important;
font-style: normal;
font-weight: 600;
font-size: 16px;
text-align: center;
color: ${props => props.theme.fontColor};

@media ${device.laptop} {
  font-size: 15px;
}

@media ${device.mobileL} {
  font-size: 14px;
}
`;

export const ListItem = styled.div``;

export const Space = styled(AntSpace)`
gap: 20px !important;
width: 100%;
padding: 15px 0 !important;
border-top: 1px solid ${props => props.theme.primaryColor};
align-items: flex-start;

@media ${device.laptop} {
  gap: 15px !important;
}

@media ${device.mobileL} {
  gap: 8px !important;
}
`;

export const ImageWrapper = styled.div`
width: 70px;
`;

export const Image = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
aspect-ratio: 16 / 9;
`;

export const Info = styled.div``;

export const Date = styled.div`
font-style: normal;
font-weight: 400;
font-size: 14px;
color: ${props => props.theme.secondaryColor};

@media ${device.laptop} {
  font-size: 13px;
}

@media ${device.mobileL} {
  font-size: 12px;
}

@media ${device.mobileL} {
  font-size: 11px;
}
`;

export const Name = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
color: ${props => props.theme.fontColor};

@media ${device.laptop} {
  font-size: 15px;
}

@media ${device.mobileL} {
  font-size: 13px;
}

@media ${device.mobileL} {
  font-size: 12px;
}
`;


export const Time = styled.div`
padding-top: 10px !important;
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #E4E6EB;

@media ${device.laptop} {
  font-size: 13px;
}

@media ${device.mobileL} {
  font-size: 12px;
}

@media ${device.mobileL} {
  font-size: 11px;
}
`;

export const EmptyCard = styled.div`
display: ${props => props.show ? 'flex' : 'none'};
height: 90%;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
border-top: 1px solid ${props => props.theme.primaryColor};
`;

export const EmptyIcon = styled(ContainerTwoTone).attrs({
  twoToneColor: '#BEBFCF',
})`
  svg {
    width: 50px;
    height: 50px;
  }
`;

export const EmptyText = styled.div`
padding-top: 10px;
font-style: normal;
font-weight: 600;
font-size: 14px;
text-align: center;
color: ${props => props.theme.fontColor};
`;