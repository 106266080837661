import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import {
  Col,
} from 'antd';
import Ticket from '../../components/ProfileStack/TicketsTab/Ticket';
import {
  Button,
  ButtonGroup,
  ButtonSecondary,
  Container,
  ListContainer,
  Title,
  TitleBar,
  FilterOption,
  SelectedLabel,
  Text,
} from '../../components/ProfileStack/TicketsTab/TicketsTab.elements';
import { translate } from '../../i18n';
import {
  EVENT_SK,
  LIVESTREAM_LK,
  PROFILE_MODE_LK,
  PUBLISHED_SK,
  USED_SK,
  VENUE_SK,
} from '../../constants';
import { Spinner } from '../../theme';
import {
  Loader,
  RootContainer,
  FilterBar,
  EmptyText,
} from './TicketList.elements';
import {
  Dropdown,
} from '../../common';
import {
  Filter,
  FilterIcon,
  FilterLabel,
} from '../../theme/styles';
import { ContainerOutlined } from '@ant-design/icons';

const TickeList = ({
  mode = PROFILE_MODE_LK,
  list,
  item,
  filter,
  next,
  hasMore,
  filterProps,
}) => {
  const navigate = useNavigate();
  const [_list, setList] = useState([]);

  useEffect(() => {
    setList(item.ticketList);
  }, [item.ticketList]);

  const handleSeeMore = () => {
    if (mode !== PROFILE_MODE_LK) {
      return;
    }
    navigate('/list/ticket', {
      state: {
        item,
        filter,
      }
    });
  }

  const goToInvoiceDetails = () => {
    navigate('/list/invoice', {
      state: {
        entityIdentifier: item.entityIdentifier,
        entityType: item.entityType,
      }
    });
  }

  const viewShow = () => {
    if (item.status !== PUBLISHED_SK) {
      return;
    }
    navigate(`/details/${item.entityType === EVENT_SK
      ? LIVESTREAM_LK.toLowerCase()
      : item.privacy}/${btoa(item.entityIdentifier || item.eventIdentifier)
      }?entityType=${item.entityType}`);
  }

  const loader = (
    <Loader>
      <Spinner />
    </Loader>
  );

  const renderFilter = () => {
    const content = (
      <>
        {filterProps?.options.map(opt => (
          <FilterOption
            key={opt.key}
            onClick={() => filterProps?.handleFilterChange(opt)}
          >
            <Text>{opt.name}</Text>
          </FilterOption>
        ))}
      </>
    );

    return (
      <FilterBar>
        <SelectedLabel>
          {filterProps?.filter?.name}
        </SelectedLabel>
        <Dropdown
          visible={filterProps?.visible}
          placement="bottomRight"
          trigger="click"
          content={content}
          onVisibleChange={(visible) => filterProps?.setVisible(visible)}
        >
          <Filter>
            <FilterIcon />
            <FilterLabel>
              {translate('searchResult.filters')}
            </FilterLabel>
          </Filter>
        </Dropdown>
      </FilterBar>
    );
  }

  const renderTitle = ({
    title,
  }) => {
    return (
      <TitleBar>
        <Title>
          {title}
        </Title>
        <ButtonGroup>
          {item.status !== PUBLISHED_SK
            ? <ButtonSecondary
              hide={item.status === PUBLISHED_SK}
            >
              {translate('ticketing.expiredShow')}
            </ButtonSecondary>
            : <Button
              hide={item.status !== PUBLISHED_SK}
              onClick={viewShow}
            >
              {translate('ticketing.viewShow')}
            </Button>
          }
          <Button onClick={goToInvoiceDetails}>
            {translate('ticketing.invoiceDetails')}
          </Button>
        </ButtonGroup>
      </TitleBar>
    );
  }

  const renderList = list => {
    return (
      <ListContainer>
        {list.map((ticket, key) => (
          <Col
            key={key}
            xs={24}
            sm={12}
            lg={12}
            xl={8}
            xxl={6}
          >
            <Ticket
              showName={item.title}
              hasMore={key === 5 && mode === PROFILE_MODE_LK}
              image={item.thumbUrl}
              entityType={item.entityType}
              startDate={item.entityType === VENUE_SK
                ? item.publishStartDateTime
                : item.broadcastStartDateTime
              }
              endDateTime={item.publishEndDateTime}
              ticketCode={ticket.ticketCode}
              used={ticket.ticketStatus === USED_SK}
              seeMore={handleSeeMore}
              viewShow={viewShow}
            />
          </Col>
        ))}
      </ListContainer>
    );
  }

  if (mode === PROFILE_MODE_LK) {
    return (
      <Container>
        {renderTitle(item)}
        {renderList(_list)}
      </Container>
    )
  }

  return (
    <RootContainer>
      {renderFilter()}
      {renderTitle(item)}
      <InfiniteScroll
        dataLength={list.length}
        next={next}
        hasMore={hasMore}
        loader={loader}
        endMessage=''
      >
        {renderList(list)}
      </InfiniteScroll>
      {list.length === 0 && !hasMore &&
        <EmptyText>
          <ContainerOutlined /> {translate('ticketList.noTickets')}
        </EmptyText>
      }
    </RootContainer>
  );
}

export default TickeList;