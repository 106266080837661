import React from 'react';
import {
  AiOutlineGlobal,
} from 'react-icons/ai';
import {
  BURMESE_LANGUAGE_CODE_LK,
  ENGLISH_LANGUAGE_CODE_LK,
} from '../../../../constants';
import {
  changeLocale,
  getCurrentLocale,
  translate,
} from '../../../../i18n';
import Dropdown from '../../../Dropdown/Dropdown';
import {
  NavItem,
  SelectItem,
} from '../../Navbar.elements';
import {
  MenuDesktop,
  MenuMobile,
  Options,
  Switch,
} from './Language.elements';

const supportedLans = [
  {
    key: ENGLISH_LANGUAGE_CODE_LK,
    name: translate('common.english'),
  },
  {
    key: BURMESE_LANGUAGE_CODE_LK,
    name: translate('common.myanmar'),
  },
];

const Language = () => {

  const changeLanguage = (languageTag) => {
    if (getCurrentLocale() === languageTag) return;
    changeLocale(languageTag);
    window.location.reload();
  }

  const options = (
    supportedLans.map((lan, key) => {
      return (
        <Options
          key={key}
          onClick={() => changeLanguage(lan.key)}
        >
          <SelectItem>
            {lan.name}
          </SelectItem>
        </Options>
      );
    })
  );

  const DesktopMenu = () => (
    <NavItem>
      <MenuDesktop>
        <Dropdown
          placement='bottomRight'
          content={options}
        >
          <AiOutlineGlobal />
          {getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK
            ? translate('common.eng')
            : translate('common.my')}
        </Dropdown>
      </MenuDesktop>
    </NavItem>
  );

  const MobileMenu = () => (
    <MenuMobile>
      <Switch
        defaultChecked={getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK}
        checkedChildren={translate('common.eng')}
        unCheckedChildren={translate('common.my')}
        onChange={(checked) =>
          changeLanguage(checked
            ? ENGLISH_LANGUAGE_CODE_LK
            : BURMESE_LANGUAGE_CODE_LK
          )}
      />
    </MenuMobile>
  );

  return (
    <>
      <DesktopMenu />
      <MobileMenu />
    </>
  );
}

export default Language;