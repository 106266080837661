import styled from "styled-components";

export const RootContainer = styled.div`
padding: 30px 60px;
min-height: calc(100vh - 190px);

  .infinite-scroll-component {
    display: inline !important;
  }
`;

export const Loader = styled.div`
width: 100%;
padding-top: 20px;
text-align: center;
`;

export const FilterBar = styled.div`
display: ${props => props.hide ? 'none' : 'flex'};
align-items: center;
padding-bottom: 10px;
margin-bottom: 20px;
border-bottom: 1px solid #BEBFCF;
`;

export const EmptyText = styled.div`
text-align: center;
`;