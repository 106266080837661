/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import logo from '../../../assets/images/main-logo.png';
import {
    AuthGlobalIcon,
    GlobalLogo
} from '../../../theme/styles';
import {
    Container,
    Heading,
    InterestWrapper,
    SaveButton,
    Skip,
    Text,
    TextInfo
} from './Interest.elements';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../../i18n';
import { InterestCards } from '../../../common';
import { connect } from 'react-redux';
import { saveInterests } from '../../../store/actions';
import { Status } from '../../../api';

const Interest = ({
    categoryList,
    interestsStatus,
    interestsErrorMessage,
    saveInterests: _saveInterests,
}) => {
    const navigate = useNavigate();
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        if (interestsStatus === Status.SUCCESS) {
            navigate('/');
        }
        else if (interestsStatus === Status.ERROR) {

        }
    }, [interestsStatus]);

    const updateSelectedIds = (id, isChecked) => {
        setSelectedIds(prevState => {
            let _arr = _.clone(prevState);
            if (isChecked) {
                _arr.push(id);
            }
            else {
                _arr = _.remove(_arr, (item) => { return item !== id });
            }
            return _arr;
        });
    }

    const handleSave = () => {
        _saveInterests(selectedIds);
    }
    return (
        <div>
            <GlobalLogo to='/'>
                <AuthGlobalIcon src={logo} alt="" />
            </GlobalLogo>

            <Skip to='/'>
                <Text>
                    {translate('interestForm.skip')}
                </Text>
            </Skip>

            <Container>
                <InterestWrapper>
                    <Heading>
                        {translate('interestForm.title')}
                    </Heading>
                    <TextInfo>
                        {translate('interestForm.subtitle')}
                    </TextInfo>
                    <InterestCards
                        list={categoryList}
                        updateSelectedIds={updateSelectedIds}
                    />
                    <div>
                        <SaveButton onClick={handleSave}>
                            {translate('interestForm.buttonLabel')}
                        </SaveButton>
                    </div>
                </InterestWrapper>
            </Container>
        </div>
    );
}

const mapStateToProps = ({ app, auth }) => {
    const { categoryList } = app;
    const { interestsStatus, interestsErrorMessage } = auth;
    return {
        categoryList,
        interestsStatus,
        interestsErrorMessage
    }
}

export default connect(mapStateToProps, { saveInterests, })(Interest);