import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { device } from '../../theme';

export const Content = styled.div`
padding: 12px;
background: linear-gradient(93.75deg, #7851A9 2.79%, #A87EE2 97.77%);
border-radius: 6px;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
color: #fff;

@media ${device.laptop} {
  font-size: 15px;
}

@media ${device.tablet} {
  font-size: 14px;
}

@media ${device.mobileL} {
  font-size: 13px;
}
`;

export const Link = styled(RouterLink)`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
text-decoration-line: underline;
color: #FDD829;
cursor: pointer;

  :hover {
    color: #FDD829;
    text-decoration-line: underline;
  }

@media ${device.laptop} {
  font-size: 15px;
}

@media ${device.tablet} {
  font-size: 14px;
}

@media ${device.mobileL} {
  font-size: 13px;
}
`;