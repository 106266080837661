import React from 'react';
import { Navigate, useLocation, } from 'react-router-dom';
import { TicketPurchaseComponent } from '../../components';

const TicketPurchase = () => {
  const { state } = useLocation();
  const {
    thumb,
    datetime,
    endtime,
    title,
    price,
    availableTicket,
    ticketInfoIdentifier,
    entityType,
    entityIdentifier,
  } = state || {};

  if (!state) {
    return <Navigate to='/' />
  }

  return (
    <>
      <TicketPurchaseComponent
        image={thumb}
        datetime={datetime}
        endtime={endtime}
        title={title}
        price={price}
        availableTicket={availableTicket}
        ticketInfoIdentifier={ticketInfoIdentifier}
        entityType={entityType}
        entityIdentifier={entityIdentifier}
      />
    </>
  );
}

export default TicketPurchase;