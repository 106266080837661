import styled from 'styled-components';
import {
  Space,
  Image as AntImage,
  Row as AntRow,
} from 'antd';
import { GlobalButton } from '../../../theme/styles';
import { SyncOutlined } from '@ant-design/icons';
import { device } from '../../../theme';

export const RootContainer = styled.div`
  .infinite-scroll-component {
    display: inline !important;
  }
`;

export const FilterBar = styled.div`
display: ${props => props.hide ? 'none' : 'flex'};
align-items: center;
padding-bottom: 20px;
`;

export const SelectedLabel = styled.div`
flex: 1;
font-style: normal;
font-weight: 400;
font-size: 16px;
color: ${props => props.theme.fontColor};

@media ${device.laptop} {
  font-size: 15px;
}

@media ${device.tablet} {
  font-size: 14px;
}
`;

export const FilterOption = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
color: ${props => props.theme.fontColor};
`;

export const Container = styled.div`
padding-top: 20px;
padding-bottom: 35px;
border-top: 1px solid #BEBFCF;
`;

export const TitleBar = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding-bottom: 40px;

@media ${device.tablet} {
  flex-direction: column;
}
`;

export const Title = styled.div`
flex: 1;
padding-right: 50px;
padding-bottom: 0;
font-style: normal;
font-weight: 700;
font-size: 16px;
color: ${props => props.theme.fontColor};

@media ${device.tablet} {
  font-size: 14px;
  padding-right: 0;
  padding-bottom: 10px;
}

@media ${device.mobileL} {
  font-size: 13px;
}
`;

export const ButtonGroup = styled(Space)`
@media ${device.tablet} {
  align-self: flex-end;
}
`;

export const Button = styled(GlobalButton)`
display: ${props => props.hide ? 'none' : 'flex'};
min-width: 130px;
height: 30px;
align-items: center;
justify-content: center;
`;

export const ButtonSecondary = styled.div`
color: ${props => props.theme.secondaryColor};
display: ${props => props.hide ? 'none' : 'flex'};
min-width: 130px;
height: 30px;
align-items: center;
justify-content: center;
background: rgb(190 190 190 / 20%);
border-radius: 5px;
font-size: 14px;
padding: 0 7px;
`;

export const ListContainer = styled(AntRow).attrs({
  gutter: [20, 20]
})`
padding-right: 5px;
`;

export const TicketCard = styled.div`
max-width: 400px;
padding: 10px 15px;
display: grid;
grid-template-columns: 70% 30%;
position: relative;
background: #C4C4C4;
border-radius: 5px;
cursor: pointer;

@media ${device.tablet} {
  max-width: 280px;
}
`;

export const Poster = styled.div`
margin-right: 20px;
position: relative;
`;

export const Image = styled.img`
border-radius: 5px;
width: 100%;
height: 100%;
object-fit: cover;
aspect-ratio: 16 / 9;
`;

export const QRImageWrapper = styled.div`
display: inline-flex;
align-items: center;
justify-content: center;
position: relative;
text-align: center;
`;

export const QRImage = styled(AntImage)`
width: 70px;
height: auto;
object-fit: contain;
cursor: pointer;

@media ${device.tablet} {
  width: 50px;
}
`;

export const TicketCode = styled.div`
position: absolute;
bottom: 0;
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #201C21;

@media ${device.tablet} {
  font-size: 12px;
}

@media ${device.mobileL} {
  font-size: 11px;
}
`;

export const ShowInfoContainer = styled.div`
position: absolute;
width: 100%;
height: 100%;
background: linear-gradient(180deg, rgba(32, 28, 33, 0) 0%, rgba(32, 28, 33, 0.678636) 43.8%, #201C21 91.89%), url(image.png);
border-radius: 5px;
`;

export const Row = styled.div`
position: absolute;
bottom: 0;
right: 10px;
width: calc(100% - 20px);
min-height: 50%;
display: flex;
border-top: 1px solid #FFFFFF;

  >div:first-child {
    border-right: 1px solid #FFFFFF;
  }

@media ${device.tablet} {
  right: 0;
  width: 100%;
}
`;

export const Col = styled.div`
flex: 0.5;
margin-bottom: 5px;
`;

export const Head = styled.div`
padding-left: 5px;
border-bottom: 1px solid #fff;
font-style: normal;
font-weight: 700;
font-size: 12px;
color: #FFFFFF;
line-height: 2;

@media ${device.tablet} {
  font-size: 11px;
}
`;

export const Body = styled.div`
padding-top: 5px;
padding-left: 5px;
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #FFFFFF;

@media ${device.tablet} {
  font-size: 11px;
}
`;

export const Icon = styled.img`
position: absolute;
right: -5.5px;
top: 0;
width: 25%;
height: auto;
`;

export const Label = styled.div`
position: absolute;
right: -5px;
top: 0;
width: 25%;
display: flex;
align-items: center;
justify-content: center;
`;

export const SyncIcon = styled(SyncOutlined)`
color: #fff;
margin-right: 4px;

svg {
  @media ${device.tablet} {
    width: 10px;
  }
}
`;

export const Text = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
color: ${props => props.theme.fontColor};
line-height: 1.2;

@media ${device.laptop} {
  font-size: 14px;
}

@media ${device.tablet} {
  font-size: 12px;
}
`;

export const SeeMoreBox = styled.div`
position: absolute;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
background: linear-gradient(136.89deg, rgba(120, 81, 169, 0.92) 2%, rgba(250, 143, 34, 0.92) 98.91%);
z-index: 1;
cursor: pointer;
border-radius: 5px;
`;

export const SeeMoreText = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
text-decoration-line: underline;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 15px;
}

@media ${device.tablet} {
  font-size: 14px;
}

`;

export const Loader = styled.div`
text-align: center;
`;

export const EllipseWrapper = styled.div`
position: absolute;
height: 100%;
margin-left: -11.5px;
display: grid;
justify-content: center;
align-items: center;
z-index: 2;
`;

export const Ellipse = styled.div`
width: 21px;
height: 21px;
background-color: ${props => props.theme.body};
border-radius: 50%;

@media ${device.tablet} {
  width: 18px;
  height: 18px;
}
`;

export const Polygon = styled.div`
width: 15px;
height: 35px;
position: absolute;
right: calc(30% + 5px);
top: ${props => props.top && '-28px'};
bottom: ${props => props.bottom && '-28px'};
border-radius: 50%;
background-color: ${props => props.theme.body};
z-index: 2;
`;
