/* eslint-disable no-template-curly-in-string */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Form, } from 'antd';
import SignupImage from '../../../assets/images/signup.jpg';
import logo from '../../../assets/images/main-logo.png';
import GmailIcon from '../../../assets/images/gmail_icon.png'
import FacebookIcon from '../../../assets/images/facebook_icon.png';
import {
    AuthGlobalIcon,
    DividerBox,
    ErrorText,
    GlobalInputBox,
    GlobalLogo,
    GlobalPasswordBox,
    PrefixBox,
} from '../../../theme/styles';
import {
    CheckboxContainer,
    Image,
    ImageWrapper,
    InfoLink,
    SignupButton,
    SignupCheckbox,
    SignupContainer,
    SignupDivider,
    SignupEmailItem,
    SignupHeading,
    SignupInfo,
    SignupPhoneItem,
    SignupWrapper
} from './SignupForm.elements';
import {
    ToggleView,
    SocialLogin,
} from '../../../common';
import { useNavigate } from 'react-router-dom';
import { FACEBOOK_SK, GOOGLE_SK, REGISTRATION_SK } from '../../../constants';
import { translate } from '../../../i18n';
import { connect } from 'react-redux';
import {
    signup,
    resetSignupState,
} from '../../../store/actions'
import { Status } from '../../../api';
import {
    isNonEmptyString,
    isPhoneNumberValid
} from '../../../utils';
import {
    formatPhoneNumber,
    isPasswordValid,
} from '../../../utils';

const SignupForm = ({
    /**
  * Tells about the status of the signup api call,
  *
  * if status === Status.DEFAULT => api hasn't been hit yet
  * if status === Status.LOADING => api is currently being executed
  * if status === Status.SUCCESS => success response from api
  * if status === Status.ERROR   => error response from api
  *
  * @source: redux
  */
    signupStatus,

    signupErrorMessage,
    /**
   * redux action to initiate signup api request
   *
   * @source: redux
   */
    signup: _signup,
    /**
   * redux action to initiate social login api request
   *
   * @source: redux
   */
    socialLogin: _socialLogin,

    /**
   * When user hit back button, from SignupScreen,
   * reset the state associated with signup logic in redux
   *
   * @source: redux
   */
    resetSignupState: _resetSignupState,
}) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isEmail, setIsEmail] = useState(false);
    const [signupId, setSignupId] = useState('');
    const [checked, setChecked] = useState(false);
    const [required, setRequired] = useState(false);
    const [passwordValid, setPasswordValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);

    useEffect(() => {
        return () => {
            //component will unmount
            _resetSignupState();
        };
    }, []);

    useEffect(() => {
        if (signupStatus === Status.SUCCESS) {
            navigate('/otp-verify', { state: { otpType: REGISTRATION_SK, signupId } });
        }
    }, [signupStatus]);

    const onFinish = ({ userName, email, phoneNumber, password }) => {
        if (!checked) {
            setRequired(true);
            return;
        } else if (!isPasswordValid(password)) {
            setPasswordValid(false);
            return;
        }

        let _phoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : phoneNumber;
        if (phoneNumber && !isPhoneNumberValid(_phoneNumber)) {
            setPhoneValid(false);
            return
        }
        _signup(userName, email, _phoneNumber, password);
        setSignupId(isNonEmptyString(email) ? email : _phoneNumber);
    };

    const resetForm = () => {
        form.resetFields();
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = (e) => {
        setChecked(e.target.checked);
    };

    const validateMessages = {
        required: translate('requiredErrorMessage.required').replace('!@#$%', '${label}'),
        types: {
            email: translate('requiredErrorMessage.invalidEmail'),
            password: '${label} is not a valid password!',
            number: '${label} is not a valid Number!',
        },
        number: {
            range: '${label} must be at least ${max} ',
        },
    };

    const layout = {
        labelCol: { style: { display: 'none' } },
    };

    const renderLeftSide = () => (
        <ImageWrapper>
            <Image src={SignupImage} alt="" />
        </ImageWrapper>
    );

    const renderRightSide = () => (
        <>
            <SignupHeading>{translate('signupForm.title')}</SignupHeading>
            <SignupInfo>{translate('signupForm.loginLabel')}
                <InfoLink to='/login'> {translate('loginForm.title')}</InfoLink>
            </SignupInfo>
            <Form
                form={form}
                name="normal_login"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                {...layout}
            >
                <Form.Item>
                    <SocialLogin
                        provider={GOOGLE_SK}
                        image={GmailIcon}
                    />
                </Form.Item>
                <Form.Item>
                    <SocialLogin
                        provider={FACEBOOK_SK}
                        image={FacebookIcon}
                    />
                </Form.Item>
                <Form.Item>
                    <SignupDivider>{translate('loginForm.or')}</SignupDivider>
                </Form.Item>
                <ErrorText>
                    {signupErrorMessage}
                </ErrorText>
                {
                    !phoneValid && <ErrorText>
                        {translate('requiredErrorMessage.invalidPhone')}
                    </ErrorText>
                }
                {!passwordValid && <ErrorText>
                    {translate('requiredErrorMessage.passwordMinimumLengthError')}
                </ErrorText>}
                <Form.Item
                    label={translate('common.name')}
                    name={translate('requiredErrorMessage.userName')}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <GlobalInputBox placeholder={translate('common.name')} />
                </Form.Item>
                <SignupEmailItem
                    label={translate('common.email')}
                    name={translate('requiredErrorMessage.email')}
                    isEmail={isEmail}
                    rules={[
                        {
                            type: 'email',
                            required: isEmail,
                        },
                    ]}
                >
                    <GlobalInputBox
                        placeholder={translate('common.email')}
                    />
                </SignupEmailItem>
                <SignupPhoneItem
                    label={translate('common.phoneNumber')}
                    name={translate('requiredErrorMessage.phoneNumber')}
                    isEmail={isEmail}
                    rules={[
                        {
                            required: !isEmail,
                        },
                    ]}
                >
                    <GlobalInputBox
                        prefix={
                            <PrefixBox>+95
                                <DividerBox>|</DividerBox>
                            </PrefixBox>
                        }
                        type="tel"
                        inputmode="numeric"
                        pattern="\d*"
                    />
                </SignupPhoneItem>
                <Form.Item
                    label={translate('common.password')}
                    name={translate('requiredErrorMessage.password')}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <GlobalPasswordBox
                        placeholder={translate('common.password')}
                        onChange={() => setPasswordValid(true)}
                    />
                </Form.Item>
                <Form.Item>
                    <SignupCheckbox onChange={onChange} checked={checked} required={required}>
                        <CheckboxContainer>
                            {translate('signupForm.checkboxLabel')}
                        </CheckboxContainer>
                    </SignupCheckbox>
                    <SignupButton
                        htmlType="submit"
                        loading={signupStatus === Status.LOADING}
                    >
                        {signupStatus !== Status.LOADING && translate('signupForm.title')}
                    </SignupButton>
                </Form.Item>
                <Form.Item>
                    <ToggleView isEmail={isEmail} setIsEmail={setIsEmail} resetForm={resetForm} /><br /><br />
                </Form.Item>
            </Form>
        </>
    );
    return (
        <SignupWrapper>
            <GlobalLogo to='/'>
                <AuthGlobalIcon src={logo} alt="" />
            </GlobalLogo>
            <SignupContainer>
                <Col xs={24} md={10}>
                    {renderLeftSide()}
                </Col>
                <Col xs={18} md={8} offset={3}>
                    {renderRightSide()}
                </Col>
            </SignupContainer>
        </SignupWrapper>
    );
}

const mapStateToProps = ({ auth }) => {
    const {
        signupStatus,
        signupErrorMessage
    } = auth;
    return {
        signupStatus,
        signupErrorMessage
    }
}

export default connect(mapStateToProps, {
    signup,
    resetSignupState,
})(SignupForm);