import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Avatar,
  Badge as AntBadge,
  Input,
} from "antd";
import {
  AiFillBell,
} from 'react-icons/ai';
import { GlobalButton } from "../../theme/styles";
import { device, } from "../../theme";

export const Nav = styled.nav`
  background: ${({ theme }) => theme.body};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #E0E0E0;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 50px;
  padding-right: 50px;

    sup {
      margin-right: 10px;
    }

@media ${device.laptop} {
  padding-left: 30px;
  padding-right: 30px;
}  

@media ${device.mobileL} {
  padding-left: 10px;
  padding-right: 10px;
}  
`;

export const NavLogoWrapper = styled.div`
  flex: 1;
`;

export const NavLogo = styled(Link)`
display: inline-flex;
  height: 100%;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
`;

export const NavIcon = styled.img`
  width: 180px;
  height: auto;
  object-fit: cover;

@media ${device.tablet} {
  width: 120px;
  margin-left: 5px;
}

@media ${device.mobileL} {
  width: 110px;
  margin-left: 5px;
}
`;

export const MobileIcon = styled.div`
display: none;
  svg {
    width: 25px;
    height: 25px;

    @media ${device.mobileL} {
      width: 20px;
      height: 20px;
    }
  }

@media ${device.laptop} {
  display: flex;
  align-items: center;
  align-self: center;
  cursor: pointer;
  line-height: 2;
}
`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
margin-bottom: 0;

@media ${device.laptop} {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  position: absolute;
  top: 80px;
  left: ${({ click }) => (click ? 0 : "-100%")};
  opacity: 1;
  transition: all 0.5s ease;
  background: ${({ theme }) => theme.body};
} 
`;

export const NavItem = styled.li`
  border-bottom: ${({ theme, current }) => current ? `2px solid ${theme.secondaryColor}` : '2px solid transparent'};
  margin: 0 10px;

  &:hover {
    border-bottom: 2px solid ${props => props.theme.secondaryColor};
  }

  a,
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme, current }) => current ? theme.primaryColor : theme.thinFontColor};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0;
    height: 100%;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      transition: all 0.3s ease;
    }

    @media ${device.laptop} {
      text-align: center;
      padding: 1rem;
      width: 100%;
      justify-content: center;
    }

    @media ${device.laptop} {
      font-size: 14px;
    }

    @media ${device.tablet} {
      font-size: 13px;
    }

  }

  @media ${device.laptop} {
    width: 100%;
  }
`;

export const NavMenuLabel = styled.span``;

export const NavLinks = styled(Link)``;

export const NavMenuFixed = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
`;

export const NavItemIcon = styled.li`
position: relative;
margin: 0 10px;
line-height: 1rem;
cursor: pointer;
font-size: 1.5rem;

  svg {
    width: 1em;
    @media ${device.mobileL} {
      width: 0.8em;
    }
  }

@media ${device.mobileL} {
  margin: 0 5px;
}
`;

export const NavItemAvatar = styled(Avatar)`
display: ${({ show }) => show ? 'block' : 'none'};
margin: 0 10px;
border: 1px solid ${props => props.theme.primaryColor};
cursor: pointer;

@media ${device.mobileL} {
  width: 24px;
  height: 24px;
  margin: 0 5px;
}
`;

export const DiscoverLinks = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const SelectItem = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${props => props.theme.fontColor};
`;

export const SearchInput = styled(Input)`
display: ${({ show }) => show ? 'flex' : 'none'};
position: fixed;
top: 80px;
width: calc(100% - 120px);
margin: 0 60px;
height: 60px;
z-index: 1000 !important;
background: #E4E6EB;
border: 1px solid ${props => props.theme.primaryColor};
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
  
  input {
    background: #E4E6EB;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;

    @media ${device.laptop}{
      font-size: 15px;
    }

    @media ${device.mobileL}{
      font-size: 14px;
    }
  }

  .ant-input-clear-icon {
    display: flex;
    color: #000000;
  }

  .ant-input-clear-icon> svg {
    width: 24px;
    height: 24px;

    @media ${device.mobileL}{
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    z-index: 1000;
    background: #E4E6EB;
  }

@media ${device.laptop}{
  width: calc(100% - 60px);
  margin: 0 30px;
}

@media ${device.mobileL}{
  width: calc(100% - 20px);
  margin: 0 10px;
  height: 45px;
}
`;

export const ProfileMenuItem = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
color: ${props => props.theme.fontColor};

@media ${device.laptop} {
  font-size: 15px;
}

@media ${device.tablet} {
  font-size: 13px;
}
`;

export const Apperance = styled.div.attrs({
  id: 'apperance'
})`
display: flex;
padding-right: 22px !important;
font-style: normal;
font-weight: 400;
font-size: 16px;
color: ${props => props.theme.fontColor};
`;

export const Label = styled.div`
flex: 1;
margin-right: 50px;
`;

export const IconBell = styled(AiFillBell)`
color: ${props => props.theme.secondaryColor};
stroke: ${props => props.theme.altPrimaryColor};
stroke-width: 75;
`;

export const NavItemButton = styled.li`
display: ${props => props.show ? 'block' : 'none'};
margin: 0 10px;
line-height: 1rem;
cursor: pointer;

@media ${device.laptop} {
  margin: 0 5px;
}
`;

export const Button = styled(GlobalButton)`
height: 30px;
min-width: 100px;
display: flex;
align-items: center;
justify-content: center;

@media ${device.mobileL} {
  height: 25px;
  min-width: 50px;
  padding: 0 10px;
}
`;

export const Badge = styled(AntBadge)`
position: absolute;
right: 0px;
top: -4px;

  .ant-badge-status-dot {
    width: 10px;
    height: 10px;

    @media ${device.laptop} {
      width: 8px;
      height: 8px;
    }
  }

  .ant-badge-status-text{
    display: none;
  }
`;