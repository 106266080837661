import React, { useEffect, useState } from 'react';
import {
  SectionTemplate,
  LatestFreebieCard as Card,
} from '../../../common';
import { FREEBIE_LK, FREEBIE_SK, VENUE_SK } from '../../../constants';
import Skeleton from '../Skeleton/Skeleton';
import { translate } from '../../../i18n';
import { useSelector } from 'react-redux';
import { api, Status } from '../../../api';

const Freebie = ({
  mode,
}) => {
  const status = useSelector(({ app }) => app.status);
  const [loading, setLoading] = useState(true);
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    status === Status.SUCCESS &&
      fetchFreebieVideos();
  }, [status]);

  const fetchFreebieVideos = () => {
    api
      .fetchVideoList({
        entityType: FREEBIE_SK,
      })
      .then(({ userResponse }) => {
        setVideoList(userResponse);
        setLoading(false);
      });
  }


  if (loading) {
    return (
      <Skeleton
        type={FREEBIE_LK}
        title={translate('common.freebie')}
      />
    );
  }

  return (
    <SectionTemplate
      title={translate('common.freebie')}
      type={FREEBIE_LK}
    >
      {videoList.map((item, key) => (
        <Card
          key={key}
          mode={mode}
          entityType={VENUE_SK}
          identifier={item.entityIdentifier}
          type={FREEBIE_LK}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          price={item.price}
        />
      ))}
    </SectionTemplate>
  );
}

export default Freebie;