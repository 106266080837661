import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';
import {
  sessionExpired,
  apiRequestFailed,
} from './app/appActions';

const createHistory = require("history").createBrowserHistory;
export const history = createHistory();

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware,];

const store = createStore(reducers, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

// ==================================================
// Global actions, called from outside the components
// ==================================================
const sessionExpiredAction = () => {
  store.dispatch(sessionExpired);
};

const apiRequestFailedAction = () => {
  store.dispatch(apiRequestFailed);
}

// ==================================================

export default store;
export {
  sessionExpiredAction,
  apiRequestFailedAction,
};
