import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { device } from '../../theme';

export const Modal = styled(AntModal)`

 .ant-modal-content {
    width: 100%;
    height: 100%;
    background-color: rgba(32, 28, 33, 0.8) !important;;
    box-shadow: none;
  }

 .ant-modal-body{
    height: 100%;
    text-align: center;
  }

 .anticon {
    color: #fff;
  }
`;

export const ContentContainer = styled.div`
height: 100%;
display: inline-flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 20px;

@media ${device.laptop} {
  padding: 0 10px;
}
`;

export const ImageWrapper = styled.div`
padding: 40px 0;

@media ${device.laptop} {
  padding: 30px 0;
}

@media ${device.tablet} {
  padding: 20px 0;
}

@media ${device.mobileL} {
  padding: 15px 0;
}
`;

export const Image = styled.img``;

export const Title = styled.div`
font-style: normal;
font-weight: 400;
font-size: 25px;
text-align: center;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 20px;
}

@media ${device.tablet} {
  font-size: 18px;
}

@media ${device.mobileL} {
  font-size: 16px;
}
`;

export const SubTitle = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 14px;
}

@media ${device.tablet} {
  font-size: 13px;
}

@media ${device.mobileL} {
  font-size: 12px;
}
`;

export const Text = styled.div`
padding: 10px;
font-style: normal;
font-weight: 400;
font-size: 16px;
text-align: center;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 14px;
}

@media ${device.tablet} {
  font-size: 13px;
}

@media ${device.mobileL} {
  font-size: 12px;
}
`;

export const DateText = styled.div`
padding-top: 10px;
padding-bottom: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
text-align: center;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 11px;
}
`;

export const TimeText = styled.div`
padding-top: 3px;
padding-bottom: 10px;
font-style: normal;
font-weight: 400;
font-size: 12px;
text-align: center;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 11px;
}
`;

export const Divider = styled.div`
width: 40px;
border-bottom: 1px solid #FFFFFF;
`;

export const Icon = styled.div`
position: absolute;
top: 2px;
right: 56px;
display: block;
width: 56px;
height: 56px;
font-size: 16px;
font-style: normal;
line-height: 56px;
text-align: center;
text-transform: none;
text-rendering: auto;
cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }
`;