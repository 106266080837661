import React from "react";
import SocialLogin from "react-social-login";
import { SocialLoginButton } from "./SocialLogin.elements";

class SocialButton extends React.Component {
    render() {
        const { children, triggerLogin, ...props } = this.props;
        return (
            <SocialLoginButton onClick={triggerLogin} {...props}>
                {children}
            </SocialLoginButton>
        );
    }
}

export default SocialLogin(SocialButton);