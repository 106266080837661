import styled from 'styled-components';
import {
  Popover as AntPopover,
} from 'antd';
import { device } from '../../theme';

export const Popover = styled(({ className, ...props }) => (
  <AntPopover {...props} overlayClassName={className} />
))`

.ant-popover-inner-content {
    position: relative;

    padding: 10px 0;
    cursor: pointer;
    border: 1px solid ${props => props.theme.primaryColor};
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    a, >div {
      padding: ${({ noti }) => noti ? '0' : '5px 77px 5px 22px'};

      &:hover:not(#apperance) {
        background: ${({ theme, noti }) => noti ? 'transparent' : theme.primaryColor};
      }

      @media ${device.laptop}{
        padding: ${({ noti }) => noti ? '0' : '5px 50px 5px 22px'};
      }
    }
  }

  .ant-popover-inner {
    border-radius: 6px;
    background-color: ${props => props.theme.body} !important;
  }

  .ant-popover-arrow-content::before {
    background: linear-gradient(to left, #201c21 50%, #201c21 50%) no-repeat -10px -10px;
  }
`;