import React from 'react';
import InterestCard from './InterestCard';
import { InterestContainer } from './InterestCards.elements';
import { getCurrentLocale } from '../../i18n';
import { ENGLISH_LANGUAGE_CODE_LK, INTEREST_MODE_LK, PROFILE_MODE_LK } from '../../constants';

const InterestCards = ({
    mode = INTEREST_MODE_LK,
    list,
    updateSelectedIds = () => { },
}) => {
    return (
        <InterestContainer
            profile={mode === PROFILE_MODE_LK}
        >
            {list.map((item, key) => {
                return (
                    <InterestCard
                        key={key}
                        isChecked={mode === INTEREST_MODE_LK ? true : item.isSelected}
                        id={item.categoryIdentifier}
                        image1={item.colorIconImageUrl}
                        image2={item.iconImageUrl}
                        name={getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK
                            ? item.name
                            : item.nameMy
                        }
                        updateSelectedIds={updateSelectedIds}
                    />
                )
            })
            }
        </InterestContainer>
    );
}

export default InterestCards;