import React from 'react';
import { translate } from '../../i18n';
import {
    Container,
    TextContent,
    Title
} from './AboutUs.elements';

const AboutUs = () => {
    return (
        <Container>
            <Title>
                {translate('footer.aboutUs')}
            </Title>
            <TextContent>
                Showlike is an online subscription streaming service which offers a variety of live and on demand entertainment source for TV shows, talk shows, music concerts, movies, documentaries and other streaming media such as podcasts, webcasts, etc. It requires users to be a subscribing member in order to utilized the platform’s best services.<br /><br />With a reliable and fast high-speed internet connection, all types of media files are prearranged and transmitted in sequential packets of data, so that they can be streamed instantaneously between streaming apps and their compatible devices.<br /><br />In addition to broadcasting celebrity events, promotions etc., Showlike will also allow viewers to enjoy the most popular types of live streaming events such as award shows, sports, boxing matches, video games, and special one-time events with an ever-growing menu of topics.<br /><br />Aiming to give the real time experience of an actual event “as it happens” over the internet, our streaming contents will contain both live, pre-recorded and recorded whichare delivered to computers and other mobile devices via the internet and can be played back in real time.<br /><br /> Showlike, online streaming platform aims to serve as the leading professional online video and hosting solution and strives to be the best in allowing viewers to search the catalogue of online media library watching anything, at any time in their comforts of their own homes; and to create moments with family and friends while enjoying the latest TV shows, music performances, educational talk shows through and many more.
            </TextContent>
        </Container >
    );
}

export default AboutUs;