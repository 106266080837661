/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'antd';
import LoginImage from '../../../assets/images/login.jpg';
import logo from '../../../assets/images/main-logo.png';
import GmailIcon from '../../../assets/images/gmail_icon.png'
import FacebookIcon from '../../../assets/images/facebook_icon.png';
import {
    AuthGlobalIcon,
    DividerBox,
    ErrorText,
    GlobalLogo,
    GlobalPasswordBox,
    PrefixBox,
} from '../../../theme/styles';
import {
    ForgetPasswordLink,
    Image,
    ImageWrapper,
    InfoLink,
    LoginButton,
    LoginContainer,
    LoginDivider,
    LoginEmailItem,
    LoginHeading,
    LoginInfo,
    LoginInputBox,
    LoginPhoneItem,
    LoginWrapper,
} from './LoginForm.elements';
import {
    ToggleView,
    SocialLogin
} from '../../../common';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../../i18n';
import { connect } from 'react-redux';
import {
    login,
} from '../../../store/actions';
import { Status } from '../../../api';
import {
    ACTIVE_SK,
    FACEBOOK_SK,
    GOOGLE_SK,
    LOCAL_STORAGE_KEYS,
    REGISTRATION_SK
} from '../../../constants';
import { formatPhoneNumber } from '../../../utils';
import { publicIpv4 } from 'public-ip';
const LoginForm = ({
    /**
   * Tells about the status of the login api call,
   *
   * if status === Status.DEFAULT => api hasn't been hit yet
   * if status === Status.LOADING => api is currently being executed
   * if status === Status.SUCCESS => success response from api
   * if status === Status.ERROR   => error response from api
   *
   * @source: redux
   */
    loginStatus,
    loginErrorMessage,

    /**
   * redux action to initiate login api request
   *
   * @source: redux
   */
    login: _login,
    /**
   * redux action to initiate social login api request
   *
   * @source: redux
   */
    socialLogin: _socialLogin,
}) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isEmail, setIsEmail] = useState(false);
    const [ipAddress, setIpAddress] = useState('');

    useEffect(() => {
        getIpaddress();
    }, []);

    useEffect(() => {
        if (loginStatus === Status.SUCCESS) {
            const userStatus = localStorage.getItem(LOCAL_STORAGE_KEYS.userStatus);
            navigate(userStatus === ACTIVE_SK
                ? '/'
                : '/otp-verify',
                {
                    state: {
                        otpType: REGISTRATION_SK
                    }
                }
            );
        }
    }, [loginStatus]);

    const getIpaddress = async () => {
        const ip = await publicIpv4();
        setIpAddress(ip);
    };

    const onFinish = ({ email, phoneNumber, password }) => {
        let _phoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : phoneNumber;
        _login(email, _phoneNumber, password, ipAddress);
    };

    const resetForm = () => {
        form.resetFields();
    }

    const validateMessages = {
        required: translate('requiredErrorMessage.required').replace('!@#$%', '${label}'),
        types: {
            email: translate('requiredErrorMessage.invalidEmail'),
            password: '${label} is not a valid password!',
            number: '${label} is not a valid Number!',
        },
        number: {
            range: '${label} must be at least ${max} ',
        },
    };

    const layout = {
        labelCol: { style: { display: 'none' } },
    };

    const renderLeftSide = () => (
        <ImageWrapper>
            <Image src={LoginImage} alt="" />
        </ImageWrapper>
    );

    const renderRightSide = () => (
        <>
            <LoginHeading>{translate('loginForm.title')}</LoginHeading>
            <LoginInfo>
                {`${translate('loginForm.signupLabel')} `}
                <InfoLink to='/sign-up'>
                    {translate('signupForm.title')}
                </InfoLink>
            </LoginInfo>
            <Form
                form={form}
                name="normal_login"
                onFinish={onFinish}
                validateMessages={validateMessages}
                {...layout}
            >
                <Form.Item>
                    <SocialLogin
                        provider={GOOGLE_SK}
                        image={GmailIcon}
                    />
                </Form.Item>
                <Form.Item>
                    <SocialLogin
                        provider={FACEBOOK_SK}
                        image={FacebookIcon}
                    />
                </Form.Item>
                <Form.Item>
                    <LoginDivider>{translate('loginForm.or')}</LoginDivider>
                </Form.Item>
                <ErrorText>
                    {loginErrorMessage}
                </ErrorText>
                <LoginEmailItem
                    label={translate('common.email')}
                    name={translate('requiredErrorMessage.email')}
                    isEmail={isEmail}
                    rules={[
                        {
                            type: 'email',
                            required: isEmail,
                        },
                    ]}
                >
                    <LoginInputBox placeholder={translate('common.email')} />
                </LoginEmailItem>
                <LoginPhoneItem
                    label={translate('common.phoneNumber')}
                    isEmail={isEmail}
                    name={translate('requiredErrorMessage.phoneNumber')}
                    rules={[
                        {
                            required: !isEmail,
                        },
                    ]}
                >
                    <LoginInputBox
                        prefix={
                            <PrefixBox>+95
                                <DividerBox>|</DividerBox>
                            </PrefixBox>
                        }
                        type="tel"
                        inputmode="numeric"
                        pattern="\d*"
                    />
                </LoginPhoneItem>
                <Form.Item
                    label={translate('common.password')}
                    name={translate('requiredErrorMessage.password')}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <GlobalPasswordBox placeholder={translate('common.password')} />
                </Form.Item>
                <Form.Item>
                    <LoginButton htmlType="submit" loading={loginStatus === Status.LOADING} >
                        {loginStatus !== Status.LOADING && translate('loginForm.title')}
                    </LoginButton>
                    <ForgetPasswordLink to="/forget-password">
                        {translate('loginForm.forgotPassword')}
                    </ForgetPasswordLink>
                </Form.Item>
                <Form.Item>
                    <ToggleView
                        isEmail={isEmail}
                        setIsEmail={setIsEmail}
                        resetForm={resetForm}
                    /><br /><br />
                </Form.Item>
            </Form>
        </>
    );
    return (
        <LoginWrapper>
            <GlobalLogo to='/'>
                <AuthGlobalIcon src={logo} alt="" />
            </GlobalLogo>
            <LoginContainer>
                <Col xs={24} md={10}>
                    {renderLeftSide()}
                </Col>
                <Col xs={18} md={8} offset={3}>
                    {renderRightSide()}
                </Col>
            </LoginContainer>
        </LoginWrapper>
    );
}

const mapStateToProps = ({ auth }) => {
    const {
        loginStatus,
        loginErrorMessage,
    } = auth;
    return {
        loginStatus,
        loginErrorMessage,
    }
}

export default connect(mapStateToProps, {
    login,
})(LoginForm);