import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import { device } from "../../theme";
import { GlobalButton, GlobalInputBox } from "../../theme/styles";

export const Container = styled.div`
  padding: 30px 70px;
  min-height: calc(100vh - 190px);

  #normal_login .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
    background: transparent !important;
  }

  #normal_login .ant-input{
    background: transparent !important;
    color: ${props => props.theme.fontColor} !important;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  color: ${props => props.theme.fontColor};
  border-left: 5px solid ${props => props.theme.primaryColor};
  padding-left: 15px;
  margin-bottom: 30px;

  @media ${device.laptop} {
    font-size: 22px;
  }

  @media ${device.mobileL} {
    font-size: 18px;
  }

  @media ${device.mobileS} {
    font-size: 16px;
  }
`;

export const Label = styled.div`
    font-size: 16px;
    padding: 15px 0;
    color: ${props => props.theme.fontColor};

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }
  
  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const ContactInput = styled(GlobalInputBox)`
width: 100%;
 color: ${props => props.theme.fontColor} !important;

 .ant-form-item-control-input-content{
   background: transparent;
 }
 .ant-input-status-error{
   background: transparent !important;
 }
`;

export const ContactArea = styled(Input.TextArea)`
border: 1px solid #BEBFCF;
border-radius: 10px;
background: transparent;
color: ${props => props.theme.fontColor};

&:hover, :focus{
  border: 1px solid #BEBFCF;
  outline: none;
  box-shadow: none;
}
`;

export const ContactSaveButton = styled(GlobalButton)`
    margin-top: 20px;
    width: 100%;
    margin-bottom: 30px;
`;

export const Star = styled.span`
  padding-left: 5px;
  color: red;
`;

export const ContactInputBox = styled(GlobalInputBox)`
  font-size: 16px;

  &:hover, :focus{
  border: 1px solid #BEBFCF;
  outline: none;
  box-shadow: none;
}

  @media ${device.laptop}{
    font-size: 14px;
  }
  
  @media ${device.mobileL}{
    font-size: 12px;
  }

  @media ${device.mobileS}{
    font-size: 12px;
  }
`;

export const PrefixBox = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.fontColor} !important;
`;

export const DividerBox = styled.div`
  padding: 0 10px;

  @media ${device.laptop} {
    font-size: 0 8px;
  }

  @media ${device.mobileL} {
    font-size: 0 7px;
  }
`;
