import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import {
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai';
import { device } from '../../theme';

export const ReactCarousel = styled(Carousel)`
z-index: 20;

  ul>li {
    margin: 0;
  }

  ul>li>div, ul>li>a>div {
  margin-right: 20px;
  margin-left: 1px;
  border-radius: 5px;
  height: 100%;
  cursor: pointer;
  border: none;

  .ant-image{
    width: 100%;
  }

  @media ${device.laptop} {
    margin-right: 15px;
  }

}

  img {
    border-radius: 5px;
  }
`;

export const ArrowsWrapper = styled.div`
display: ${({ loading }) => loading ? 'none' : 'flex'};
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 100%;
z-index: 1;
`;

export const ArrowLeft = styled(AiOutlineLeft)`
font-size: 30px;
margin-left: 14px;
cursor: ${props => props.enable ? 'pointer' : 'auto'};;
opacity: ${props => props.enable ? 1 : 0.5};

@media ${device.laptop} {
  font-size: 25px;
  margin-left: 5px;
}

@media ${device.mobileL} {
  font-size: 20px;
  margin-left: 0px;
}
`;

export const ArrowRight = styled(AiOutlineRight)`
font-size: 30px;
margin-right: 14px;
position: absolute;
right: 0;
cursor: ${props => props.enable ? 'pointer' : 'auto'};;
opacity: ${props => props.enable ? 1 : 0.5};

@media ${device.laptop} {
  font-size: 25px;
  margin-right: 5px;
}

@media ${device.mobileL} {
  font-size: 20px;
  margin-right: 0px;
}
`;
