/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Row,
  Select,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { api, Status } from '../../../api';
import { InterestCards } from '../../../common';
import { ENGLISH_LANGUAGE_CODE_LK, PROFILE_MODE_LK } from '../../../constants';
import { getCurrentLocale, translate } from '../../../i18n';
import {
  ButtonContainer,
  CameraSection,
  Container,
  CounrtySelectBox,
  DatePickerBox,
  DisableBox,
  DividerBox,
  EditForm,
  EditImage,
  EditInputBox,
  EditLabel,
  FormItem,
  GenderInput,
  InputBox,
  LoginText,
  PrefixBox,
  ProfileImage,
  ProfileSection,
  ProfileWrapper,
  RegionLabel,
  RegionSelectBox,
  RootContainer,
  SaveButton,
  SettingRow,
  SubTitle,
  Title,
} from './EditProfile.elements';
import {
  editProfile,
  uploadProfileImage,
  resetEditProfileState,
} from '../../../store/actions';
import { cutPrefixPhoneNumber, formatPhoneNumber, isNonEmptyString, isPhoneNumberValid } from '../../../utils';
import _ from 'lodash';
import { CameraOutlined } from '@ant-design/icons';
import { ErrorText, Spinner } from '../../../theme/styles';

const moment = require('moment');

const ProfileTab = ({
  mobile,
  profileImage,
  userName,
  dateOfBirth,
  gender,
  countryName,
  stateName,
  loginId,
  email,
  mobileNumber: _mobileNumber,
  interestList,
  editProfile: _editProfile,
  uploadProfileImage: _uploadProfileImage,
  editProfileStatus,
  editProfileErrorMessage,
  uploadProfileStatus,
  /**
 * Reset state variables in profile reducer, after screen is unmounted
 * @source redux
 */
  resetEditProfileState: _resetEditProfileState,

}) => {
  const dateFormat = 'YYYY-MM-DD';
  const { Option } = Select;
  let uploadCoverInput = null;
  const status = useSelector(({ app }) => app.status);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [editName, setEditName] = useState(userName);
  const [dob, setDob] = useState('');
  const [selectedGender, setSelectedGender] = useState(gender);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [preferedCountryIdentifier, setPreferedCountryIdentifier] = useState('');
  const [showRegion, setShowRegion] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showUploadIcon, setShowUploadIcon] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [phoneValid, setPhoneValid] = useState(true);
  const [mobileNumber, setPhone] = useState(_mobileNumber);
  const [emailForm, setEmailForm] = useState(email);

  useEffect(() => {
    if (editProfileStatus === Status.SUCCESS) {
      _resetEditProfileState();
      showMessage({
        content: translate('editProfile.editProfileSuccessMessage'),
      });

    }
    else if (editProfileStatus === Status.ERROR) {
      _resetEditProfileState();
      showMessage({
        content: editProfileErrorMessage,
        type: 'error'
      });
    }
  }, [editProfileStatus]);

  useEffect(() => {
    return () => {
      _resetEditProfileState();
    }
  }, []);

  useEffect(() => {
    if (status === Status.SUCCESS) {
      fetchCountryList();
    }
  }, [status]);

  useEffect(() => {
    setEditName(userName);
    setSelectedGender(gender);
    setDob(
      isNonEmptyString(dateOfBirth)
        ? moment(dateOfBirth)
        : dateOfBirth
    );
  }, [userName, gender, dateOfBirth]);

  useEffect(() => {
    if (countryList.length > 0) {
      const index = _.findIndex(countryList, (item) => item.name === countryName);
      if (index !== -1) {
        setSelectedCountry(countryList[index].countryIdentifier);
      }
    }
  }, [countryName, countryList]);

  useEffect(() => {
    if (stateList.length > 0) {
      const index = _.findIndex(stateList, (item) => item.name === stateName);
      if (index !== -1) {
        setSelectedState(stateList[index].stateIdentifier);
      }
    }
  }, [stateName, stateList]);

  useEffect(() => {
    if (isNonEmptyString(preferedCountryIdentifier)) {
      setShowRegion(selectedCountry === preferedCountryIdentifier);
    }
  }, [selectedCountry, preferedCountryIdentifier]);

  useEffect(() => {
    if (!showRegion) {
      setSelectedState('');
    }
  }, [showRegion]);

  useEffect(() => {
    setEmailForm(email);
    setPhone(cutPrefixPhoneNumber(_mobileNumber));
  }, [email, _mobileNumber]);

  //api call for countryList
  const fetchCountryList = () => {
    api.fetchCountryList()
      .then(({ userResponse: _list }) => {
        setCountryList(_list);
        const index = _.findIndex(_list, (item) => item.name === 'Myanmar');
        const id = _list[index].countryIdentifier;
        fetchStateList(id);
        setPreferedCountryIdentifier(id);
      }
      )
  }

  //api call for state/regionList
  const fetchStateList = (countryIdentifier) => {
    api.fetchStateList({ countryIdentifier })
      .then(({ userResponse }) => {
        setStateList(userResponse);
      }
      )
  }

  const showMessage = ({ content, type = 'success' }) => {
    message[
      type === 'error'
        ? 'error'
        : 'success'
    ]({
      content,
      style: {
        marginTop: '80px'
      }
    });
  }

  const dateChange = (date, dateString) => {
    if (dateString === '') {
      return;
    }
    setDob(date);
  };

  const handleChange = (value) => {
    setSelectedGender(value);
  };

  const countryChange = (value) => {
    setSelectedCountry(value);
  }

  const stateDivisionChange = (value) => {
    setSelectedState(value);
  }

  const updateSelectedIds = (id, isChecked) => {
    setSelectedIds(prevState => {
      let _arr = _.clone(prevState);
      if (isChecked) {
        _arr.push(id);
      }
      else {
        _arr = _.remove(_arr, (item) => { return item !== id });
      }
      return _arr;
    });
  }

  const uploadProfile = () => {
    const image = uploadCoverInput.files[0];
    _uploadProfileImage(image);
  }

  const validateMessages = {
    required: translate('requiredErrorMessage.required').replace('!@#$%', '${label}'),
    types: {
      email: translate('requiredErrorMessage.invalidEmail'),
      password: '${label} is not a valid password!',
      number: '${label} is not a valid Number!',
    },
    number: {
      range: '${label} must be at least ${max} ',
    },
  };

  const onFinish = () => {
    let _dob = dob === '' ? dob : moment(dob).format(dateFormat);
    if (!isNonEmptyString(editName)) {
      setErrorMessage('Name is required!');
      return;
    }
    let _phoneNumber = mobileNumber ? formatPhoneNumber(mobileNumber) : mobileNumber;
    if (mobileNumber && !isPhoneNumberValid(_phoneNumber)) {
      setPhoneValid(false);
      return
    }
    _editProfile(
      {
        name: editName,
        email: emailForm,
        mobileNumber: _phoneNumber,
        dob: _dob,
        gender: selectedGender,
        countryIdentifier: selectedCountry,
        stateIdentifier: selectedState,
        interestIdentifiers: selectedIds.length > 0 && selectedIds,
      }
    )
  }

  const renderProfile = () => {
    return (
      <>
        <Title>
          {translate('editProfile.personalInformation')}
        </Title>
        <ProfileWrapper>
          <ProfileSection
            onMouseEnter={() => setShowUploadIcon(true)}
            onMouseLeave={() => setShowUploadIcon(false)}
          >
            <ProfileImage
              src={
                <EditImage
                  src={profileImage?.originalUrl}
                  preview={false}
                />
              }
            />
            <label htmlFor='upload-cover-input'>
              <input
                type="file"
                accept='image/*'
                id="upload-cover-input"
                name="upload-cover-input"
                ref={(ref) => { uploadCoverInput = ref; }}
                onChange={uploadProfile}
                disabled={uploadProfileStatus === Status.LOADING}
                hidden
              />

              <CameraSection showUploadIcon={showUploadIcon} >
                {uploadProfileStatus !== Status.LOADING
                  ? <CameraOutlined
                    style={{
                      color: '#fff',
                      fontSize: '32px'
                    }}
                  />
                  : <Spinner size="large" />
                }
              </CameraSection>
            </label>
          </ProfileSection>
        </ProfileWrapper>

      </>
    )
  }

  const renderEditForm = () => {
    return (
      <EditForm
        name="normal_login"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Row gutter={[0, { sm: 8, md: 10, lg: 20 }]}>
          <Col xs={24} md={6} lg={4} />
          <Col xs={24} md={14} lg={10}>
            <ErrorText>
              {editProfileErrorMessage || errorMessage}
            </ErrorText>
            {
              !phoneValid && <ErrorText>
                {translate('requiredErrorMessage.invalidPhone')}
              </ErrorText>
            }
          </Col>
          <Col xs={0} md={4} lg={10} />

          <Col xs={24} md={6} lg={4}>
            <EditLabel>
              {translate('common.name')}
            </EditLabel>
          </Col>
          <Col xs={24} md={14} lg={10}>
            <EditInputBox
              value={editName}
              onChange={(e) => {
                setEditName(e.target.value);
                setErrorMessage('');
              }
              }
            />
          </Col>
          <Col xs={0} md={4} lg={10} />

          <Col xs={24} md={6} lg={4}>
            <EditLabel>
              {translate('common.email')}
            </EditLabel>
          </Col>
          <Col xs={24} md={14} lg={10}>
            {loginId && (loginId === email)
              ? <>
                < DisableBox value={email} disabled />
                <LoginText>
                  {translate('editProfile.loginIdEmail')}
                </LoginText>
              </>
              : <FormItem
                rules={[
                  {
                    type: 'email',
                  },
                ]}
              >
                <InputBox
                  placeholder={translate('common.email')}
                  value={emailForm}
                  onChange={(e) => {
                    setEmailForm(e.target.value)
                  }
                  }
                />
              </FormItem>
            }
          </Col>
          <Col xs={0} md={4} lg={10} />

          <Col xs={24} md={6} lg={4}>
            <EditLabel>
              {translate('common.phoneNumber')}
            </EditLabel>
          </Col>
          <Col xs={24} md={14} lg={10}>
            {loginId && (loginId === _mobileNumber)
              ? <>
                < DisableBox value={_mobileNumber} disabled />
                <LoginText>
                  {translate('editProfile.loginIdPhone')}
                </LoginText>
              </>
              : <FormItem
              >
                <InputBox
                  prefix={
                    <PrefixBox>+95
                      <DividerBox>|</DividerBox>
                    </PrefixBox>
                  }
                  value={mobileNumber}
                  onChange={(e) => {
                    setPhone(e.target.value)
                    setPhoneValid(true)
                  }
                  }
                  type="tel"
                  inputmode="numeric"
                  pattern="\d*"
                />
              </FormItem>}
          </Col>
          <Col xs={0} md={4} lg={10} />

          <Col xs={24} md={6} lg={4}>
            <EditLabel>
              {translate('editProfile.dateOfBirth')}
            </EditLabel>
          </Col>
          <Col xs={24} md={14} lg={10}>
            <DatePickerBox
              placeholder={translate('editProfile.selectDate')}
              format={dateFormat}
              value={dob}
              onChange={dateChange}
            />
          </Col>
          <Col xs={0} md={4} lg={10} />

          <Col xs={24} md={6} lg={4}>
            <EditLabel>
              {translate('editProfile.gender')}
            </EditLabel>
          </Col>
          <Col xs={24} md={14} lg={10}>
            <GenderInput>
              <Select
                value={selectedGender}
                onChange={handleChange}
              >
                <Option value="male">{translate('editProfile.male')}</Option>
                <Option value="female">{translate('editProfile.female')}</Option>
              </Select>
            </GenderInput>
          </Col>
          <Col xs={0} md={4} lg={10} />
        </Row>
        {renderLocation()}
        {rendereInterest()}
        {renderButton()}
      </EditForm >
    );
  }

  const renderLocation = () => {
    return (
      <SettingRow gutter={[0, { sm: 8, md: 10, lg: 20 }]}>
        <Col xs={24} md={6} lg={4}>
          <EditLabel>
            {translate('editProfile.country')}
          </EditLabel>
        </Col>
        <Col xs={24} md={14} lg={10}>
          <CounrtySelectBox>
            <Select
              value={selectedCountry}
              onChange={countryChange}
            >
              {
                countryList.map((item, key) => {
                  return (
                    <Option value={item.countryIdentifier} key={key}>
                      {getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK
                        ? item.name
                        : item.nameMy
                      }
                    </Option>
                  );
                })
              }
            </Select>
          </CounrtySelectBox>
        </Col>
        <Col xs={0} md={4} lg={10} />

        <Col xs={24} md={6} lg={4}>
          <RegionLabel show={showRegion}>
            {translate('editProfile.stateRegion')}
          </RegionLabel>
        </Col>
        <Col xs={24} md={14} lg={10}>
          <RegionSelectBox show={showRegion}>
            <Select
              value={selectedState}
              onChange={stateDivisionChange}
            >
              {
                stateList.map((item, key) => {
                  return (
                    <Option value={item.stateIdentifier} key={key}>
                      {getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK
                        ? item.name
                        : item.nameMy
                      }
                    </Option>
                  );
                })
              }
            </Select>
          </RegionSelectBox>
        </Col>
        <Col xs={0} md={4} lg={10} />
      </SettingRow>
    );
  }

  const rendereInterest = () => {
    return (
      <Container>
        <SubTitle>
          {translate('editProfile.interest')}
        </SubTitle>
        <InterestCards
          mode={PROFILE_MODE_LK}
          list={interestList}
          updateSelectedIds={updateSelectedIds}
        />
      </Container>
    );
  }

  const renderButton = () => {
    return (
      <ButtonContainer>
        <Col xs={24} md={6} lg={4} />
        <Col xs={24} md={14} lg={10}>
          <SaveButton
            htmlType='submit'
            loading={editProfileStatus === Status.LOADING}
          >
            {editProfileStatus !== Status.LOADING && translate('common.save')}
          </SaveButton>
        </Col>
        <Col xs={0} md={4} lg={10} />
      </ButtonContainer>
    );
  }

  return (
    <RootContainer mobile={mobile}>
      {renderProfile()}
      {renderEditForm()}
    </RootContainer>
  );
}

const mapStateToProps = ({ profile }) => {
  const {
    userName,
    loginId,
    email,
    mobileNumber,
    dateOfBirth,
    gender,
    country: countryName,
    state: stateName,
    profileImage,
    interestList,
    editProfileStatus,
    editProfileErrorMessage,
    uploadProfileStatus,
  } = profile;
  return {
    userName,
    loginId,
    email,
    mobileNumber,
    dateOfBirth,
    gender,
    countryName,
    stateName,
    profileImage,
    interestList,
    editProfileStatus,
    editProfileErrorMessage,
    uploadProfileStatus,
  }
}

export default connect(mapStateToProps, {
  editProfile,
  uploadProfileImage,
  resetEditProfileState,
})(ProfileTab);