/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { api, Status } from '../../api';
import { GuestInfoSection } from '../../common';
import {
  Upcoming,
  Freebie,
  Latest,
  Livestream,
  PremiumVideo,
  VideoDetails as Details,
} from '../../components';
import { DETAILS_MODE_LK, EVENT_SK, FREEBIE_LK, LATEST_LK, LIVESTREAM_LK, LOCAL_STORAGE_KEYS, POSTER_SK, PREMIUM_LK, SAVE_SK, SERVER_ERROR_CODES, UNSAVE_SK } from '../../constants';
import { Divider } from '../../theme/styles';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const VideoDetails = () => {
  const loggedInUserIdentifier = localStorage.getItem(LOCAL_STORAGE_KEYS.userIdentifier);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { type, id } = useParams();
  const query = useQuery();
  const status = useSelector(({ app }) => app.status);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});

  useEffect(() => {
    if (status === Status.SUCCESS) {
      fetchVideoDetails();
    }
  }, [status, pathname]);

  const fetchVideoDetails = () => {
    setLoading(true);
    const entityType = query.get('entityType');
    /**
     * @Question Why api is not called from redux-saga & used directly here?
     *
     * @Explanation Not every thing needed to be controlled via redux & redux-saga,
     * this api response, is used no where except this component.
     *
     * Prefer Local state first, if the response won't be shared across components.
     */
    api
      .fetchVideoDetails({
        entityIdentifier: atob(id),
        entityType,
      })
      .then(({ userResponse: _details }) => {
        if (_details.entityType === EVENT_SK) {
          _details.dateTime = _details.broadcastStartDateTime;
          _details.duration = null;
        }
        else {
          _details.dateTime = _details.publishStartDateTime;
        }
        setState(_details);
        setLoading(false);
      })
      .catch(error => {
        if (error.name === SERVER_ERROR_CODES.invalidEventError ||
          error.name === SERVER_ERROR_CODES.invalidVenueError) {
          navigate('/404');
        }
      });
  }

  const handleFavourite = () => {
    const entityType = query.get('entityType');
    setState(prevState => {
      return {
        ...prevState,
        isSaved: prevState.isSaved === SAVE_SK ? UNSAVE_SK : SAVE_SK,
      }
    });
    if (state.isSaved === SAVE_SK) {
      api
        .saveFavourite({
          entityType,
          entityIdentifier: state.entityIdentifier,
          userIdentifier: loggedInUserIdentifier,
        })
        .then(({ userResponse: { favouriteIdentifier } }) => {
          setState(prevState => {
            return {
              ...prevState,
              favouriteIdentifier,
            }
          });
        })
        .catch(() => {

        });
    }
    else {
      api
        .deleteFavourite({
          favouriteIdentifier: state.favouriteIdentifier,
        })
        .then(() => {
          setState(prevState => {
            return {
              ...prevState,
              favouriteIdentifier: '',
            }
          });
        })
        .catch(() => {

        });
    }
  }

  const renderRelatedItems = () => {
    switch (type.toUpperCase()) {
      case LIVESTREAM_LK:
        return <Livestream mode={DETAILS_MODE_LK} />;

      case LATEST_LK:
        return <Latest mode={DETAILS_MODE_LK} />;

      case PREMIUM_LK:
        return <PremiumVideo mode={DETAILS_MODE_LK} />;

      case FREEBIE_LK:
        return <Freebie mode={DETAILS_MODE_LK} />;

      default:
        return null;
    }
  };

  return (
    <>
      <Details
        loading={loading}
        entityType={state.entityType}
        entityIdentifier={state.entityIdentifier}
        broadcastEndDateTime={state.broadcastEndDateTime}
        thumb={state.thumbUrl}
        price={state.price}
        datetime={state.dateTime}
        endtime={state.publishEndDateTime}
        title={state.title}
        playbackTime={state.duration}
        organizer={state.organizationName}
        content={state.description}
        poster={state.portraitImagePath?.originalUrl}
        isSaved={state.isSaved === UNSAVE_SK}
        availableTicket={state.availableTicket}
        ticketInfoIdentifier={state.ticketInfoIdentifier}
        privacy={state.privacy}
        link={state.copyLinkUrl}
        handleFavourite={handleFavourite}
      />
      {type !== POSTER_SK && <Divider />}
      {renderRelatedItems()}
      <Divider />
      <Upcoming />
      <GuestInfoSection />
    </>
  );
}

export default VideoDetails;