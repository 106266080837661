/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Body,
    Button,
    ButtonGroup,
    ButtonSecondary,
    Col,
    Ellipse,
    EllipseWrapper,
    Head,
    Icon,
    Image,
    Label,
    Polygon,
    Poster,
    QRImage,
    QRImageWrapper,
    Row,
    ShowInfoContainer,
    SubTitle,
    SyncIcon,
    Text,
    TicketCard,
    TicketCode,
    Title,
    TitleBar
} from './MyTicketsTab.elements';
import ticketUsedImg from '../../../assets/images/icon_ticket_used.png';
import { formattedDate, getFormattedDateTime, getFormattedTime } from '../../../utils';
import { LIMITS, PUBLISHED_SK, VENUE_SK } from '../../../constants';
import { useQRCode } from 'react-qrcode';
import { ImageViewer } from '../../../common';
import { translate } from '../../../i18n';

const Subtract = () => {
    return (
        <>
            <EllipseWrapper>
                <Ellipse />
                <Ellipse />
                <Ellipse />
                <Ellipse />
            </EllipseWrapper>
            <Polygon top />
            <Polygon bottom />
        </>
    );
}


const UsedIcon = () => {
    return (
        <>
            <Icon alt='icon' src={ticketUsedImg} />
            <Label>
                <SyncIcon />
                <Text>
                    Used
                </Text>
            </Label>
        </>
    )
}

const MyTickets = ({
    showName,
    image,
    entityType,
    startDate,
    endDateTime,
    ticketCode,
    used = false,
    hasMore = false,
    seeMore,
    viewShow = () => { },
    status,
    ticketMessage
}) => {
    const [visible, setVisible] = useState(false);
    const [readMore, setReadMore] = useState(false);
    // const [flag, setFlag] = useState();

    const LIMIT = LIMITS.DESCRIPTION_MIN_LENGTH;

    // useEffect(() => {
    //     if (showName?.length <= 23) {
    //         setFlag(true);
    //     }
    // });

    const ShowInfo = () => {
        return (
            <ShowInfoContainer>
                <Row>
                    <Col>
                        <Head>
                            Date
                        </Head>
                        <Body>
                            {formattedDate(startDate)}
                        </Body>
                    </Col>
                    <Col>
                        <Head>
                            {entityType === VENUE_SK
                                ? 'Valid Until'
                                : 'Time'
                            }
                        </Head>
                        <Body>
                            {entityType === VENUE_SK
                                ? formattedDate(endDateTime)
                                : getFormattedTime(startDate)
                            }
                        </Body>
                    </Col>
                </Row>
            </ShowInfoContainer>
        )
    }

    const renderTitle = () => {
        return (
            <>
                <TitleBar>
                    {showName?.length > LIMIT
                        ?
                        <Title>
                            {showName.slice(0, LIMIT)} ...
                        </Title>
                        : <Title>
                            {showName}
                        </Title>
                    }

                    <ButtonGroup>
                        {status !== PUBLISHED_SK
                            ? <ButtonSecondary
                                hide={status === PUBLISHED_SK}
                            >
                                {translate('ticketing.expiredShow')}
                            </ButtonSecondary>
                            : <Button
                                hide={status !== PUBLISHED_SK}
                                onClick={() => viewShow()}
                            >
                                {translate('ticketing.viewShow')}
                            </Button>
                        }
                    </ButtonGroup>
                </TitleBar>

                <SubTitle>
                    {ticketMessage}
                </SubTitle>
            </>
        );
    }

    return (
        <>
            {renderTitle()}
            <TicketCard>
                {used && <UsedIcon />}
                {/* {hasMore && <SeeMoreLink />} */}
                <Poster onClick={() => viewShow()}>
                    <ShowInfo />
                    <Image alt='pimg' src={image} />
                </Poster>
                <QRImageWrapper>
                    <QRImage
                        alt='qrimg'
                        src={useQRCode(ticketCode)}
                        preview={false}
                        onClick={() => setVisible(true)}
                    />
                    <TicketCode>
                        {ticketCode}
                    </TicketCode>
                </QRImageWrapper>
                <Subtract />
            </TicketCard>
            <ImageViewer
                visible={visible}
                showName={showName}
                ticketCode={ticketCode}
                date={entityType === VENUE_SK
                    ? formattedDate(startDate)
                    : getFormattedDateTime(startDate)}
                endDate={formattedDate(endDateTime)}
                image={useQRCode(ticketCode)}
                entityType={entityType}
                handleClose={() => setVisible(false)}
            />
        </>
    );
}

export default MyTickets;