import React from 'react';
import {
  Date,
  Image,
  ImageWrapper,
  Info,
  List,
  ListItem,
  Name,
  Space,
  Time,
  Title,
} from './NotificationList.elements';
import {
  Skeleton as AntSkeleton,
} from 'antd';
import { getPeriodicTime, getStreamingDate } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { EVENT_SK, LIVESTREAM_LK } from '../../constants';

export const Skeleton = () => {
  return (
    <>
      <AntSkeleton active paragraph={{ rows: 2 }} />
      <AntSkeleton active paragraph={{ rows: 2 }} />
      <AntSkeleton active paragraph={{ rows: 2 }} />
    </>
  );
}

const NotificationListItem = ({
  entityIdentifier,
  image,
  date,
  name,
  time,
  closeDropdown,
}) => {
  const navigate = useNavigate();

  const goToDetails = () => {
    closeDropdown();
    navigate(`/details/${LIVESTREAM_LK.toLowerCase()}/${btoa(entityIdentifier)}?entityType=${EVENT_SK}`);
  }

  return (
    <ListItem onClick={goToDetails}>
      <Space>
        <ImageWrapper>
          <Image
            alt=''
            src={image}
          />
        </ImageWrapper>
        <Info>
          <Date>
            {`streaming on ${getStreamingDate(date)}`}
          </Date>
          <Name>
            {name}
          </Name>
          <Time>
            {getPeriodicTime(time)}
          </Time>
        </Info>
      </Space>
    </ListItem>
  );
}

export default NotificationListItem;