/* eslint-disable react-hooks/exhaustive-deps */
import React,
{
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  connect,
} from 'react-redux';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  FaTimes,
  FaBars,
} from 'react-icons/fa';
import {
  AiOutlineGlobal,
  AiOutlineClose,
} from 'react-icons/ai';
import {
  FiSun,
  FiMoon,
} from 'react-icons/fi';
import {
  BiSearch,
} from 'react-icons/bi';
import {
  Badge,
  MobileIcon,
  Nav,
  NavbarContainer,
  NavIcon,
  NavLogo,
  NavMenu,
  NavItem,
  NavLinks,
  NavItemIcon,
  NavMenuFixed,
  SearchInput,
  NavLogoWrapper,
  NavItemAvatar,
  ProfileMenuItem,
  IconBell,
  Button,
  NavItemButton,
  NavMenuLabel,
} from './Navbar.elements';
import logo from '../../assets/images/main-logo.png';
import {
  translate,
} from '../../i18n';
import {
  DARK_THEME_LK,
  LIGHT_THEME_LK,
  LIVESTREAM_LK,
  LOCAL_STORAGE_KEYS,
} from '../../constants';
import { isNonEmptyString } from '../../utils';
import { Image, } from 'antd';
import { NotificationList } from '../../components';
import Dropdown from '../Dropdown/Dropdown';
import {
  changeThemeInApp,
  logout,
} from '../../store/actions';
import { changeTheme } from '../../theme';
import { Status } from '../../api';
import LoginPrompt from '../LoginPrompt/LoginPrompt';
import { Discover, Language } from './components';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Navbar = ({
  /**
   * user prefered theme
   *
   * @source redux
   */
  theme,
  profileImage,
  userName,
  changeThemeInApp: _changeThemeInApp,
  logout: _logout,
}) => {
  const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEYS.sessionId);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const searchInputRef = useRef(null);
  const [click, setClick] = useState(false);
  const [searchClicked, setSearchClicked] = useState(isNonEmptyString(query.get('keyword')));
  const [searchText, setSearchText] = useState(query.get('keyword'));
  const [profileClicked, setProfileClicked] = useState(false);
  const [openNoti, setOpenNoti] = useState(false);

  useEffect(() => {
    setSearchText(query.get('keyword'));
  }, [query.get('keyword')]);

  useEffect(() => {
    if (pathname !== '/search') {
      setSearchClicked(false);
      setSearchText('');
    }
    else if (isNonEmptyString(query.get('keyword'))) {
      setSearchClicked(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (searchClicked) {
      searchInputRef.current.focus();
    }
  }, [searchClicked]);

  const handleClick = () => setClick(!click);

  const handleChangeTheme = () => {
    const _theme = theme === LIGHT_THEME_LK ? DARK_THEME_LK : LIGHT_THEME_LK;
    changeTheme(_theme);
    _changeThemeInApp(_theme);
  }

  const handleSearchClick = () => {
    setSearchClicked(!searchClicked);
  }

  const doSearch = () => {
    if (!isNonEmptyString(searchText)) {
      navigate('/');
    }
    else if (isNonEmptyString(searchText.trim())) {
      navigate(`/search?keyword=${searchText}`);
    }
    else {
      return;
    }
  }

  const navigateTo = (path) => {
    navigate(path);
    setProfileClicked(false);
  }

  const logout = () => {
    _logout();
    setProfileClicked(false);
  }

  const notification = <NotificationList closeDropdown={() => setOpenNoti(false)} />;

  const profileMenu = (
    <>
      <ProfileMenuItem
        onClick={() =>
          navigateTo('/user-profile/mytickets')
        }
      >
        {isNonEmptyString(userName)
          ? userName
          : translate('navMenu.profile')}
      </ProfileMenuItem>
      <ProfileMenuItem
        onClick={() =>
          navigateTo('/user-profile/mytickets')
        }
      >
        {translate('userProfile.myTickets')}
      </ProfileMenuItem>
      <ProfileMenuItem
        onClick={() =>
          navigateTo('/user-profile/watchlater')
        }
      >
        {translate('userProfile.watchLater')}
      </ProfileMenuItem>
      <ProfileMenuItem
        onClick={() =>
          navigateTo('/edit-profile/settings')
        }
      >
        {translate('editProfile.settings')}
      </ProfileMenuItem>
      <ProfileMenuItem
        onClick={logout}
      >
        {translate('common.logout')}
      </ProfileMenuItem>
    </>
  );

  return (
    <>
      <Nav>
        <NavbarContainer>
          <MobileIcon onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <NavLogoWrapper>
            <NavLogo to='/'>
              <NavIcon src={logo} />
            </NavLogo>
          </NavLogoWrapper>
          <NavMenu click={click}>
            <NavItem
              current={pathname === '/'}
              onClick={handleClick}
            >
              <NavLinks to='/'>
                {translate('navMenu.home')}
              </NavLinks>
            </NavItem>
            <NavItem
              current={pathname === '/list/livestream'}
              onClick={handleClick}
            >
              <NavLinks
                to={`/list/${LIVESTREAM_LK.toLowerCase()}`}>
                {translate('navMenu.livestream')}
              </NavLinks>
            </NavItem>
            <Discover close={handleClick} />
            <NavItem
              current={pathname === '/user-profile/watchlater'}
              onClick={isLoggedIn && handleClick}
            >
              <LoginPrompt
                placement='bottom'
                text={translate('loginPrompt.toViewThis')}
                onClick={() => {
                  navigate('/user-profile/watchlater');
                  handleClick();
                }}
              >
                <NavMenuLabel>
                  {translate('navMenu.watchlater')}
                </NavMenuLabel>
              </LoginPrompt>
            </NavItem>
            <Language />
          </NavMenu>
          <NavMenuFixed>
            <NavItemIcon>
              <Badge status='success' />
              <Dropdown
                visible={openNoti}
                placement='bottomRight'
                trigger='click'
                content={notification}
                onVisibleChange={(v) => setOpenNoti(v)}
                noti
              >
                <LoginPrompt
                  placement='bottom'
                  text={translate('loginPrompt.toViewThis')}
                >
                  <IconBell />
                </LoginPrompt>
              </Dropdown>
            </NavItemIcon>
            <NavItemIcon onClick={handleChangeTheme}>
              {theme === DARK_THEME_LK
                ? <FiMoon />
                : <FiSun />
              }
            </NavItemIcon>
            <NavItemIcon>
              <BiSearch onClick={handleSearchClick} />
            </NavItemIcon>
            <NavItemButton show={!isLoggedIn}>
              <NavLinks to='/login'>
                <Button>
                  {translate('common.login')}
                </Button>
              </NavLinks>
            </NavItemButton>
            <Dropdown
              visible={profileClicked}
              placement='bottomRight'
              trigger='click'
              content={profileMenu}
              onVisibleChange={(visible) => setProfileClicked(visible)}
            >
              <NavItemAvatar
                show={isLoggedIn}
                size='medium'
                src={
                  <Image
                    preview={false}
                    src={profileImage?.originalUrl}
                  />
                }
              />
            </Dropdown>
          </NavMenuFixed>
        </NavbarContainer>
      </Nav>
      <SearchInput
        ref={searchInputRef}
        show={searchClicked}
        value={searchText}
        placeholder={translate('searchResult.searchInputPlaceholder')}
        allowClear={{ clearIcon: <AiOutlineClose /> }}
        onChange={(e) => setSearchText(e.target.value)}
        onPressEnter={doSearch}
      />
    </>
  );
}

const mapStateToProps = ({ app, profile }) => {
  const { theme, } = app;
  const {
    profileImage,
    userName,
  } = profile;
  return {
    theme,
    profileImage,
    userName,
  };
};

export default connect(mapStateToProps, {
  changeThemeInApp,
  logout,
})(Navbar);