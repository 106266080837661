import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { LoginForm } from '../../../components';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { translate } from '../../../i18n';

const Login = ({ title }) => {
  const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEYS.sessionId);

  useEffect(() => {
    document.title = ` ${translate('common.appName')} | ${title} `;
  }, []);

  if (isLoggedIn) {
    return <Navigate to='/' />;
  }

  return (
    <LoginForm />
  );
}

export default Login;