import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HeartOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import {
  Button,
  Container,
  TextOne,
  TextTwo,
  TicketIcon,
  WatchListIcon,
} from './EmptyCard.elements';
import { translate } from '../../i18n';

const EmptyCard = ({
  watchList,
}) => {
  const navigate = useNavigate();

  return (
    <Container watchList={watchList}>
      <TicketIcon watchList={watchList} />
      <WatchListIcon watchList={watchList}>
        <HeartOutlined />
        <QuestionOutlined />
      </WatchListIcon>
      <TextOne>
        {watchList
          ? translate('userProfile.noWatchListMessage')
          : translate('userProfile.noTicketMessage')}
      </TextOne>
      <TextTwo>
        {watchList
          ? translate('userProfile.noWatchListMessageTwo')
          : translate('userProfile.noTicketMessageTwo')}
      </TextTwo>
      <Button onClick={() => navigate('/')}>
        {watchList
          ? translate('userProfile.noWatchListButtonLabel')
          : translate('userProfile.noTicketButtonLabel')}
      </Button>
    </Container>
  );
}

export default EmptyCard;