import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from '../../theme';

export const InfoSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryColor};
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const TextWrapper = styled.div`
  position: absolute;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  padding-bottom: 10px;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 11px;
    padding-bottom: 0;
  }
`;

export const SubText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  padding-bottom: 10px;

  @media ${device.laptop} {
    font-size: 12px;
  }

  @media ${device.tablet} {
    font-size: 10px;
    padding-bottom: 0;
  }
`;

export const TextLink = styled(Link)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  text-decoration-line: underline;
  color: #fdd829;

  &:hover {
    color: #fdd829;
    text-decoration-line: underline;
  }

  @media ${device.laptop} {
    font-size: 12px;
  }

  @media ${device.tablet} {
    font-size: 10px;
  }
`;
