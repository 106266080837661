import { Divider } from "antd";
import styled from "styled-components";
import { device } from "../../theme";

export const ToggleContainer = styled.div`
  display: grid;
  height: 45px;
  grid-template-columns: 50% 50%;
  padding: 5px;
  border: 1px solid #bebfcf;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;

export const TogglePhone = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme, isEmail }) => !isEmail ? theme.primaryColor : '#bebfcf'} ;
  text-align: center;
  cursor: pointer;

  @media ${device.laptop}{
    font-size: 15px;
  }

  @media ${device.mobileL}{
    font-size: 14px;
  }

  @media ${device.mobileS}{
    font-size: 13px;
  }
`;

export const ToggleEamilContainer = styled.div`
display: flex;
align-items: center;
`;

export const ToggleEamil = styled.div`
font-family: "Poppins";
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
color: ${({ theme, isEmail }) => isEmail ? theme.primaryColor : '#bebfcf'} ;
text-align: center;
flex: 1;
cursor: pointer;

  @media ${device.laptop}{
    font-size: 15px;
  }

  @media ${device.mobileL}{
    font-size: 14px;
  }

  @media ${device.mobileS}{
    font-size: 13px;
  }
`;


export const ToggleDivider = styled(Divider)`
  margin: 0;
  border-left: 2px solid ${props => props.theme.primaryColor};
`;
