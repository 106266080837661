import styled from 'styled-components';
import {
  Switch as AntSwitch,
} from 'antd';
import { device } from '../../../../theme';

export const Options = styled.div``;

export const MenuDesktop = styled.div`
display: block;

@media ${device.laptop} {
  display: none;
}
`;

export const MenuMobile = styled.div`
display: none;

@media ${device.laptop} {
  display: flex;
  align-items: center;
  justify-content: center;
}
`;

export const Switch = styled(AntSwitch)`
  background-color: ${props => props.theme.primaryColor};
  
  span {
    color: ${props => props.theme.thinFontColor};
  }

  .ant-switch-handle::before {
    background-color: ${props => props.theme.altPrimaryColor};
  }
`;
