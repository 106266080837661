import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Otp } from '../../../components';
import { translate } from '../../../i18n';


const OtpVerification = ({ title }) => {
  const { state } = useLocation();

  useEffect(() => {
    document.title = `${translate('common.appName')} | ${title}`;
  }, []);

  if (!state) {
    return <Navigate to='/' />
  }

  return (
    <Otp />
  )
}

export default OtpVerification;