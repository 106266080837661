import React, { useState } from 'react';
import { useQRCode } from 'react-qrcode';
import {
  Ellipse,
  EllipseWrapper,
  Image,
  Polygon,
  Poster,
  QRImage,
  QRImageWrapper,
  ShowInfoContainer,
  Row,
  TicketCard,
  TicketCode,
  Col,
  Head,
  Body,
  Icon,
  Text,
  Label,
  SyncIcon,
  SeeMoreBox,
  SeeMoreText,
} from './TicketsTab.elements';
import ticketUsedImg from '../../../assets/images/icon_ticket_used.png';
import { translate } from '../../../i18n';
import { formattedDate, getFormattedDateTime, getFormattedTime } from '../../../utils';
import { VENUE_SK } from '../../../constants';
import { ImageViewer } from '../../../common';

const Subtract = () => {
  return (
    <>
      <EllipseWrapper>
        <Ellipse />
        <Ellipse />
        <Ellipse />
        <Ellipse />
      </EllipseWrapper>
      <Polygon top />
      <Polygon bottom />
    </>
  );
}

const UsedIcon = () => {
  return (
    <>
      <Icon alt='icon' src={ticketUsedImg} />
      <Label>
        <SyncIcon />
        <Text>
          Used
        </Text>
      </Label>
    </>
  )
}

const Ticket = ({
  showName,
  image,
  entityType,
  startDate,
  endDateTime,
  ticketCode,
  used = false,
  hasMore = false,
  seeMore,
  viewShow = () => { },
}) => {
  const [visible, setVisible] = useState(false);

  const ShowInfo = () => {
    return (
      <ShowInfoContainer>
        <Row>
          <Col>
            <Head>
              Date
            </Head>
            <Body>
              {formattedDate(startDate)}
            </Body>
          </Col>
          <Col>
            <Head>
              {entityType === VENUE_SK
                ? 'Valid Until'
                : 'Time'
              }
            </Head>
            <Body>
              {entityType === VENUE_SK
                ? formattedDate(endDateTime)
                : getFormattedTime(startDate)
              }
            </Body>
          </Col>
        </Row>
      </ShowInfoContainer>
    )
  }

  const SeeMoreLink = () => {
    return (
      <SeeMoreBox onClick={seeMore}>
        <SeeMoreText>
          {translate('ticketing.moreTickets')}
        </SeeMoreText>
      </SeeMoreBox>
    )
  }

  return (
    <>
      <TicketCard>
        {used && <UsedIcon />}
        {hasMore && <SeeMoreLink />}
        <Poster onClick={viewShow}>
          <ShowInfo />
          <Image alt='pimg' src={image} />
        </Poster>
        <QRImageWrapper>
          <QRImage
            alt='qrimg'
            src={useQRCode(ticketCode)}
            preview={false}
            onClick={() => setVisible(true)}
          />
          <TicketCode>
            {ticketCode}
          </TicketCode>
        </QRImageWrapper>
        <Subtract />
      </TicketCard>
      <ImageViewer
        visible={visible}
        showName={showName}
        ticketCode={ticketCode}
        date={entityType === VENUE_SK
          ? formattedDate(startDate)
          : getFormattedDateTime(startDate)}
        endDate={formattedDate(endDateTime)}
        image={useQRCode(ticketCode)}
        entityType={entityType}
        handleClose={() => setVisible(false)}
      />
    </>
  );
}

export default Ticket;