import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams, } from 'react-router-dom';
import { api, Status } from '../../api';
import { PaymentStatusComponent } from '../../components';
import { translate } from '../../i18n';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const PaymentStatus = ({ title }) => {
    const { status, } = useParams();
    const query = useQuery();
    const appStatus = useSelector(({ app }) => app.status);
    const [platform, setPlatform] = useState(null);
    const [apiStatus, setApiStatus] = useState(Status.DEFAULT);

    useEffect(() => {
        document.title = `${translate('common.appName')} | ${title}`;
    }, []);

    useEffect(() => {
        if (appStatus !== Status.SUCCESS) {
            return;
        }
        let merchantOrderId = query.get('merchantOrderId');
        if (merchantOrderId) {
            setApiStatus(Status.LOADING);
            api
                .checkPaymentPlatform(merchantOrderId)
                .then(({ userResponse: { platformOrigin } }) => {
                    setApiStatus(Status.SUCCESS);
                    setPlatform(platformOrigin);
                })
                .catch(() => {
                    setApiStatus(Status.ERROR);
                });
        }
    }, [appStatus, query.get('merchantOrderId')]);

    return (
        <PaymentStatusComponent
            status={status}
            platform={platform}
            loading={apiStatus !== Status.SUCCESS}
        />
    );
}

export default PaymentStatus;