import React from 'react';
import { DISCOVER_MODE_LK, HOME_MODE_LK, LATEST_LK } from '../../constants';
import { Link } from '..';
import {
  Card,
  Image,
  ActionButton,
  IconPlay,
  RootContainer,
} from './LatestFreebieCard.elements';
import CardActions from '../CardActions/CardActions';
import { formattedDate, getFormattedDuration, getFormattedPrice } from '../../utils';
import { translate } from '../../i18n';
const { Meta } = Card;

const LatestCard = ({
  entityType,
  identifier,
  image,
  title,
  duration,
  date,
  type,
  price,
  isSaved,
  favouriteIdentifier,
  link,
  expired = false,
  mode = HOME_MODE_LK,
  deleteFavouriteCallback = () => { },
}) => {
  return (
    <RootContainer>
      <Link
        to={`/details/${type.toLowerCase()}/${btoa(identifier)}?entityType=${entityType}`}
        mode={mode}
        type={type}
        prevented={expired}
      >
        <Card
          homeMode={mode === HOME_MODE_LK}
          discoverMode={mode === DISCOVER_MODE_LK}
          cover={
            <>
              <Image
                alt="latestimg"
                src={image}
              />
              <IconPlay />
              {type === LATEST_LK &&
                <ActionButton>
                  {translate('videoDetails.buyButtonLabel')
                    .replace('!@#$%', getFormattedPrice(price))}
                </ActionButton>
              }
            </>

          }
        >
          <Meta title={title} />
        </Card>
        <CardActions
          entityType={entityType}
          entityIdentifier={identifier}
          type={LATEST_LK}
          label={`${duration} | ${formattedDate(date)}`}
          mode={mode}
          isSaved={isSaved}
          favouriteIdentifier={favouriteIdentifier}
          link={link}
          deleteFavouriteCallback={deleteFavouriteCallback}
        />
      </Link>
    </RootContainer>
  );
}

export default LatestCard;