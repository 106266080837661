import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../theme";

export const FooterContainer = styled.div`
  padding: 50px;
  background: #141115;

  @media ${device.laptop}{
    padding: 50px 40px;
  }
  
  @media ${device.mobileL}{
    padding: 50px 10px;
  }
`;

export const FirstRow = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const SecondRow = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const FooterLinks = styled.div`
  flex: 1;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LinksItem = styled(Link)`
  padding: 0 30px;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  
  @media screen and (max-width: 960px) {
    padding: 0 10px;
    line-height: 2rem;
  }

  @media ${device.laptop}{
    font-size: 14px;
  }
  
  @media ${device.mobileL}{
    font-size: 13px;
  }
  
  @media ${device.mobileS}{
    font-size: 12px;
  }

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
    transition: 0.3s ease-out;
  }
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialIcon = styled.div`
  margin: ${({ sm }) => (sm ? "0 0 0 10px" : "0 10px")};
  font-size: ${({ sm }) => (sm ? "1.3rem" : "1.8rem")};
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor};
  display: flex;

  &:hover {
    margin-top: -10px;
    transition: all 0.3s;
  }
`;

export const Text = styled.div`
  flex: 1;
  padding-left: 30px;
  padding-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #bebfcf;

  @media screen and (max-width: 960px) {
    padding-left: 10px;
  }
  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 13px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const GetOnBox = styled.div`
  display: flex;
  padding-top: 20px;
  align-items: center;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fff;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 13px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const Image = styled.img`
  width: 100px;
  height: 100%;
  object-fit: cover;
  margin-left: 20px;
  cursor: pointer;
`;

export const Contacts = styled.div`
padding-top: 20px;
text-align: right;
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #fff;

@media ${device.laptop} {
    font-size: 14px;
}

@media ${device.tablet} {
    font-size: 13px;
}

@media ${device.mobileL} {
    font-size: 12px;
}
`;
