import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  SectionTemplate,
  LatestFreebieCard as Card,
} from '../../../common';
import Skeleton from '../Skeleton/Skeleton';
import { EVENT_SK, LATEST_LK, VENUE_SK } from '../../../constants';
import { translate } from '../../../i18n';
import { api, Status } from '../../../api';
import { getFormattedPrice } from '../../../utils';

const Latest = ({
  mode,
}) => {
  const status = useSelector(({ app }) => app.status);
  const [loading, setLoading] = useState(true);
  const [latests, setLatests] = useState([]);

  useEffect(() => {
    status === Status.SUCCESS &&
      fetchLatestVideoList();
  }, [status]);

  const fetchLatestVideoList = () => {
    api
      .fetchLatestVideoList()
      .then(({ userResponse: _list }) => {
        setLatests(_list);
        setLoading(false);
      });
  }

  if (loading) {
    return (
      <Skeleton
        type={LATEST_LK}
        title={translate('common.latest')}
      />
    );
  }

  return (
    <SectionTemplate
      title={translate('common.latest')}
      type={LATEST_LK}
    >
      {latests.map((item, key) => (
        <Card
          key={key}
          mode={mode}
          entityType={item.entityType}
          identifier={item.entityIdentifier}
          type={LATEST_LK}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          price={item.price}
        />
      ))}
    </SectionTemplate>
  );
}

export default Latest;