import styled from 'styled-components';
import { device } from '../../../../theme';

export const MenuDesktop = styled.div`
display: block;

@media ${device.laptop} {
  display: none;
}
`;

export const MenuMobile = styled.div`
display: none;
width: 100%;

  li {
    margin: 0 !important;
  }

@media ${device.laptop} {
  display: block;
}
`;

export const MenuMobileInner = styled.div`
display: inline-flex;
align-items: center;
justify-content: center;

  span {
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
`;

export const MenuItemLabel = styled.span``;

export const SubNavItems = styled.div`
display: ${props => props.show ? 'block' : 'none'};
width: 100%;
border-bottom: 1px solid;
background: ${props => props.theme.altPrimaryColor};

  a {
    justify-content: center;
    padding: 5px 0;
  }

  div {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #fff;

    &:hover {
      color: ${props => props.theme.secondaryColor}
    }

    @media ${device.laptop} {
      font-size: 13px;
    }

    @media ${device.tablet} {
      font-size: 12px;
    }
  }
`;