/* eslint-disable import/no-anonymous-default-export */
import { Status } from "../../api";
import {
  API_REQUEST_FAILED,
  CHANGE_THEME,
  FETCH_CATEGORY_LIST_ERROR,
  FETCH_CATEGORY_LIST_LOADING,
  FETCH_CATEGORY_LIST_SUCCESS,
  FETCH_NOTIFICATIONS_LOADING,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  INITIALIZE_APP_ERROR,
  INITIALIZE_APP_LOADING,
  INITIALIZE_APP_SUCCESS,
  SESSION_EXPIRED,
} from "../../constants/actionTypes";
import { getCurrentTheme } from "../../theme";

const currentTheme = getCurrentTheme();

const initialState = {
  /**
   * Tells about whether the api module is
   * configured or not.
   */
  status: Status.DEFAULT,
  errorMessage: '',
  /**
   * If sessionStatus === Status.DEFAULT  => User is not logged in
   * If sessionStatus === Status.SUCCESS  => User is logged in
   * If sessionStatus === Status.ERROR    => session token of logged in user has expired
   */
  sessionStatus: Status.DEFAULT,
  apiRequestStatus: Status.DEFAULT,
  categoryListStatus: Status.DEFAULT,
  categoryListErrorMessage: '',
  notificationsStatus: Status.DEFAULT,
  notificationsErrorMessage: '',
  // ================================
  categoryList: [],
  notifications: {
    notificationList: [],
    hasMore: true,
  },
  /**
   * User prefered theme, light or dark
   * default theme is dark
   */
  theme: currentTheme,
}

/**
 * App level reducer, store data that is common to entire app.
 */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INITIALIZE_APP_LOADING:
      return {
        ...state,
        status: Status.LOADING,
        errorMessage: '',
      };
    case INITIALIZE_APP_SUCCESS:
      return {
        ...state,
        status: Status.SUCCESS,
        errorMessage: '',
        sessionStatus: payload.sessionStatus,
        theme: payload.theme,
      };
    case INITIALIZE_APP_ERROR:
      return {
        ...state,
        status: Status.ERROR,
        errorMessage: payload.errorMessage,
      };
    case FETCH_CATEGORY_LIST_LOADING:
      return {
        ...state,
        categoryListStatus: Status.LOADING,
        categoryListErrorMessage: '',
      };
    case FETCH_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryListStatus: Status.SUCCESS,
        categoryList: payload.categoryList,
      };
    case FETCH_CATEGORY_LIST_ERROR:
      return {
        ...state,
        categoryListStatus: Status.ERROR,
        categoryListErrorMessage: payload.errorMessage,
      };
    case FETCH_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationsStatus: Status.LOADING,
        notificationsErrorMessage: '',
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      const {
       notifications,
      } = payload;

      return {
        ...state,
        notificationsStatus: Status.SUCCESS,
        notifications,
      };
    case FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationsStatus: Status.ERROR,
        notificationsErrorMessage: payload.errorMessage,
      };
    case CHANGE_THEME:
      return {
        ...state,
        theme: payload.theme,
      }
    case SESSION_EXPIRED:
      return {
        ...state,
        sessionStatus: Status.ERROR,
      };
    case API_REQUEST_FAILED:
      return {
        ...state,
        apiRequestStatus: Status.ERROR,
      };
    default:
      return state;
  }
}