import {
    EDIT_PROFILE_REQUEST,
    RESET_EDIT_PROFILE_STATE,
    UPLOAD_PROFILE_IMAGE_REQUEST
} from "../../constants";

export const editProfile = (
    {
        name,
        email,
        mobileNumber,
        dob,
        gender,
        countryIdentifier,
        stateIdentifier,
        interestIdentifiers
    }
) => ({
    type: EDIT_PROFILE_REQUEST,
    payload: {
        name,
        email,
        mobileNumber,
        dob,
        gender,
        countryIdentifier,
        stateIdentifier,
        interestIdentifiers
    },
});

/**
 * It Rests the editProfileStatus back to Status.DEFAULT
 */
export const resetEditProfileState = () => ({
    type: RESET_EDIT_PROFILE_STATE,
});

/**
 *  Upload profile image action
 * @param image
 * @return {{type: string, payload: {image: *}}}
 */
export const uploadProfileImage = (file, fileDirectory = 'profilePhoto', fileCategory = 'user') => ({
    type: UPLOAD_PROFILE_IMAGE_REQUEST,
    payload: {
        file,
        fileDirectory,
        fileCategory,
    },
});