import React from 'react';
import { SkeletonContainer, SkeletonCard } from './Skeleton.elements';
import { GlobalStyledSkeleton as StyledSkeleton, ListColumn, ListItem, } from '../../theme/styles';
import { Skeleton as AntSkeleton } from 'antd';

const Card = () => {
  return (
    <ListColumn>
      <ListItem>
        <SkeletonCard
          cover={
            <StyledSkeleton loading avatar active />
          }
        >
          <AntSkeleton loading active />
        </SkeletonCard>
      </ListItem>
    </ListColumn>
  );
}
const Skeleton = () => {
  return (
    <SkeletonContainer>
      <Card />
      <Card />
      <Card />
      <Card />
    </SkeletonContainer>
  );
}

export default Skeleton;