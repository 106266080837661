import styled, { css } from 'styled-components';
import { GlobalSkeletonCard } from '../../theme/styles';

const TransparentStyle = css`
background: transparent !important;

.ant-card-cover {
  aspect-ratio: 16 / 9;
}
.ant-card-body {
  padding: 0 !important;
}
`;

const DefaultStyle = css`
.ant-card-cover {
  aspect-ratio: 16 / 9;
}
`;

export const Card = styled(GlobalSkeletonCard)`
filter: ${({ theme, premium, }) => premium ? theme.boxShadow : 'none'};
${({ transparent }) => transparent ? TransparentStyle : DefaultStyle};
`;
