import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Interest as InterestSection } from '../../../components';
import { translate } from '../../../i18n';


const Interest = ({ title }) => {
  const { state } = useLocation();

  useEffect(() => {
    document.title = `${translate('common.appName')} | ${title}`;
  }, []);

  if (!state) {
    return <Navigate to='/' />;
  }

  return (
    <InterestSection />
  );
}

export default Interest;