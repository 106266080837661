/* eslint-disable import/no-anonymous-default-export */
import { Status, } from '../../api';
import {
    EDIT_PROFILE_ERROR,
    EDIT_PROFILE_LOADING,
    EDIT_PROFILE_SUCCESS,
    GET_USER_DETAILS_SUCCESS,
    RESET_EDIT_PROFILE_STATE,
    UPLOAD_PROFILE_IMAGE_ERROR,
    UPLOAD_PROFILE_IMAGE_LOADING,
    UPLOAD_PROFILE_IMAGE_SUCCESS,
} from '../../constants';

const initialState = {
    sessionId: "",
    userIdentifier: "",
    userStatus: "",
    country: "",
    dateOfBirth: "",
    gender: "",
    profileImage: null,
    state: "",
    userName: "",
    email: "",
    mobileNumber: "",
    loginId: "",
    interestList: [],
    // =========================================================
    // ======================= editProfile =====================
    // =========================================================
    editProfileStatus: Status.DEFAULT,
    editProfileErrorMessage: '',
    // =========================================================
    // ======================= uploadProfile =====================
    // =========================================================
    uploadProfileStatus: Status.DEFAULT,
    uploadErrorMessage: '',
}

/**
 * Profile reducer, store user details
 */
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                ...payload,
            }
        case EDIT_PROFILE_LOADING:
            return {
                ...state,
                editProfileStatus: Status.LOADING,
                editProfileErrorMessage: '',
            };
        case EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                editProfileStatus: Status.SUCCESS,
                editProfileErrorMessage: '',
                ...payload,
            }
        case EDIT_PROFILE_ERROR:
            return {
                ...state,
                editProfileStatus: Status.ERROR,
                editProfileErrorMessage: payload.errorMessage,
            }
        case RESET_EDIT_PROFILE_STATE:
            return {
                ...state,
                editProfileStatus: Status.DEFAULT,
                editProfileErrorMessage: '',
            };
        case UPLOAD_PROFILE_IMAGE_LOADING: {
            return {
                ...state,
                uploadProfileStatus: Status.LOADING,
                uploadErrorMessage: '',
            };
        }
        case UPLOAD_PROFILE_IMAGE_SUCCESS: {
            const { profileImage } = payload;
            return {
                ...state,
                uploadProfileStatus: Status.SUCCESS,
                uploadErrorMessage: '',
                profileImage
            };
        }
        case UPLOAD_PROFILE_IMAGE_ERROR: {
            return {
                ...state,
                uploadProfileStatus: Status.ERROR,
                uploadErrorMessage: payload.errorMessage,
            };
        }
        default:
            return state;
    }

}