import React from 'react';
import {
  Box,
  Heading,
  Icon,
  RootContainer,
  SwapArrow,
} from './PaymentConfirm.elements';
import APPLOGO from '../../../assets/images/logo.png';
import { translate } from '../../../i18n';

const PaymentConfirm = ({
  paymentImage,
}) => {

  return (
    <RootContainer>
      <Heading>
        {translate('ticketing.paymentProcessTitle')}
      </Heading>
      <Box>
        <Icon alt='payicon' src={paymentImage} />
        <SwapArrow />
        <Icon alt='applogo' src={APPLOGO} />
      </Box>
    </RootContainer>
  );
}

export default PaymentConfirm;