/* eslint-disable no-template-curly-in-string */
import { Col, Form, message, Row } from 'antd';
import React, { useState } from 'react';
import {
    ContactArea,
    ContactInput,
    ContactInputBox,
    ContactSaveButton,
    Container,
    DividerBox,
    Label,
    PrefixBox,
    Star,
    Title
} from './ContactForm.elements';
import { translate } from '../../i18n';
import { api } from '../../api';
import { ErrorText } from '../../theme/styles';
import { formatPhoneNumber, isPhoneNumberValid } from '../../utils';

const ContactForm = () => {
    const [form] = Form.useForm();
    const [showLoading, setShowLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);

    const resetForm = () => {
        form.resetFields();
    }

    const onFinish = ({
        userName,
        phoneNumber,
        email,
        message
    }) => {
        setShowLoading(true);
        let _phoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : phoneNumber;
        if (phoneNumber && !isPhoneNumberValid(_phoneNumber)) {
            setPhoneValid(false);
            setShowLoading(false);
            return
        }
        api.contact({ userName, mobileNumber: _phoneNumber, email, message })
            .then(({ userResponse }) => {
                setShowLoading(false);
                setShowError(false);
                showMessage({
                    content: userResponse.status,
                });
                resetForm()
            })
            .catch((error) => {
                setShowLoading(false);
                setShowError(true);
                setErrorMessage(error.message);
            })
    }

    const showMessage = ({ content, type = 'success' }) => {
        message[
            type === 'error'
                ? 'error'
                : 'success'
        ]({
            content,
            style: {
                marginTop: '80px'
            }
        });
    }

    const validateMessages = {
        required: translate('requiredErrorMessage.required').replace('!@#$%', '${label}'),
        types: {
            email: translate('requiredErrorMessage.invalidEmail'),
        },
    };

    const layout = {
        labelCol: { style: { display: 'none' } },
    };

    const renderContactForm = () => {
        return (
            <Form
                form={form}
                name="normal_login"
                onFinish={onFinish}
                validateMessages={validateMessages}
                {...layout}
            >
                <Row justify='center'>

                    <Col xs={0} md={9} lg={9} />
                    <Col xs={24} md={10} lg={10}>
                        {
                            showError && <ErrorText>
                                {errorMessage}
                            </ErrorText>
                        }
                        {
                            !phoneValid && <ErrorText>
                                {translate('requiredErrorMessage.invalidPhone')}
                            </ErrorText>
                        }
                    </Col>
                    <Col xs={0} md={5} lg={5} />

                    <Col xs={0} md={4} lg={5} />
                    <Col xs={24} md={5} lg={4}>
                        <Label>
                            {translate('common.name')}<Star>*</Star>
                        </Label>
                    </Col>
                    <Col xs={24} md={10} lg={10}>
                        <Form.Item
                            label={translate('common.name')}
                            name={translate('requiredErrorMessage.userName')}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <ContactInput />
                        </Form.Item>

                    </Col>
                    <Col xs={0} md={5} lg={5} />

                    <Col xs={0} md={4} lg={5} />
                    <Col xs={24} md={5} lg={4}>
                        <Label>
                            {translate('common.phoneNumber')}<Star>*</Star>
                        </Label>
                    </Col>
                    <Col xs={24} md={10} lg={10}>
                        <Form.Item
                            label={translate('common.phoneNumber')}
                            name={translate('requiredErrorMessage.phoneNumber')}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <ContactInputBox
                                prefix={
                                    <PrefixBox>+95
                                        <DividerBox>|</DividerBox>
                                    </PrefixBox>
                                }
                                type="tel"
                                inputmode="numeric"
                                pattern="\d*"
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={0} md={5} lg={5} />

                    <Col xs={0} md={4} lg={5} />
                    <Col xs={24} md={5} lg={4}>
                        <Label>
                            {translate('common.email')}<Star>*</Star>
                        </Label>
                    </Col>
                    <Col xs={24} md={10} lg={10}>
                        <Form.Item
                            label={translate('common.email')}
                            name={translate('requiredErrorMessage.email')}
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                },
                            ]}
                        >
                            <ContactInput />
                        </Form.Item>

                    </Col>
                    <Col xs={0} md={5} lg={5} />

                    <Col xs={0} md={4} lg={5} />
                    <Col xs={24} md={5} lg={4}>
                        <Label>
                            {translate('footer.yourMessage')}<Star>*</Star>
                        </Label>
                    </Col>
                    <Col xs={24} md={10} lg={10}>
                        <Form.Item
                            label={translate('footer.yourMessage')}
                            name={translate('requiredErrorMessage.message')}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <ContactArea rows={8} />
                        </Form.Item>

                    </Col>
                    <Col xs={0} md={5} lg={5} />

                    <Col xs={0} md={9} lg={9} />
                    <Col xs={24} md={10} lg={10}>
                        <ContactSaveButton
                            htmlType='submit'
                            // onClick={handleClick}
                            loading={showLoading}
                        >
                            {!showLoading && translate('common.save')}
                        </ContactSaveButton>
                    </Col>
                    <Col xs={0} md={5} lg={5} />
                </Row>

            </Form>
        );
    }

    return (
        <Container>
            <Title>
                {translate('footer.contactForm')}
            </Title>
            {renderContactForm()}
        </Container >

    );
}

export default ContactForm;