/* eslint-disable no-template-curly-in-string */
import { Col, Form, message, } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { api } from '../../../api';
import { translate } from '../../../i18n';
import { ErrorText } from '../../../theme/styles';
import { isPasswordValid } from '../../../utils';
import {
    ChangePassButton,
    ChangeSection,
    EditLabel,
    FormSection,
    PassButton,
    PasswordBox,
    OldPasswordbox,
    RootContainer,
    SettingRow,
    SettingsContainer,
    Title
} from './EditProfile.elements';

const SettingsTab = () => {
    const { pathname } = useLocation();
    const [formVisible, setFormVisible] = useState(false);
    const [passwordValid, setPasswordValid] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [form] = Form.useForm();

    useEffect(() => {
        setFormVisible(false);
    }, [pathname]);


    const onFinish = ({ currentPassword, newPassword, confirmPassword }) => {
        if (!isPasswordValid(newPassword)
            || !isPasswordValid(confirmPassword)) {
            setPasswordValid(false);
            return;
        }
        api.changePassword({ currentPassword, newPassword, confirmPassword })
            .then(({ userResponse }) => {
                showMessage({
                    content: translate('changePassword.successMessage')
                });
                form.resetFields();
                setShowError(false);
                setFormVisible(false);
            })
            .catch((error) => {
                setShowError(true);
                setErrorMessage(error.message);
            })
    };

    const showMessage = ({ content, type = 'success' }) => {
        message[
            type === 'error'
                ? 'error'
                : 'success'
        ]({
            content,
            style: {
                marginTop: '80px'
            }
        });
    }

    const validateMessages = {
        required: translate('requiredErrorMessage.required').replace('!@#$%', '${label}'),
    };

    const layout = {
        labelCol: { style: { display: 'none' } },
    };

    const onChange = () => {
        setFormVisible(true);
    }

    const renderPassword = () => {
        return (
            <SettingsContainer>
                <Title>
                    {translate('editProfile.managePassword')}
                </Title>
                <ChangeSection show={!formVisible}>
                    <SettingRow>
                        <Col xs={24} sm={5} lg={4}>
                            <EditLabel>
                                {translate('common.password')}
                            </EditLabel>
                        </Col>
                        <Col xs={24} sm={10} lg={10}>
                            <OldPasswordbox >
                                ********
                            </OldPasswordbox>
                        </Col>
                        <Col xs={24} sm={7} lg={5}>
                            <ChangePassButton onClick={onChange}>
                                {translate('common.change')}
                            </ChangePassButton>
                        </Col>
                        <Col xs={0} sm={2} lg={5} />
                    </SettingRow>
                </ChangeSection>

                <FormSection show={formVisible}>
                    <Form
                        form={form}
                        name="normal_login"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        {...layout}
                    >
                        <SettingRow gutter={[0, { xs: 0, md: 10, lg: 20 }]}>
                            <Col xs={24} md={10} lg={4} />
                            <Col xs={24} sm={10} lg={10}>
                                {
                                    !passwordValid && <ErrorText>
                                        {translate('requiredErrorMessage.passwordMinimumLengthError')}
                                    </ErrorText>
                                }
                                {
                                    showError && <ErrorText>
                                        {errorMessage}
                                    </ErrorText>
                                }
                            </Col>
                            <Col xs={0} md={4} lg={10} />

                            <Col xs={24} md={6} lg={4}>
                                <EditLabel>
                                    {translate('editProfile.currentPlaceholder')}
                                </EditLabel>
                            </Col>
                            <Col xs={24} md={14} lg={10}>
                                <Form.Item
                                    label={translate('editProfile.currentPlaceholder')}
                                    name={translate('editProfile.currentPassword')}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <PasswordBox
                                        onChange={() => setPasswordValid(true)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={0} md={4} lg={10} />

                            <Col xs={24} md={6} lg={4}>
                                <EditLabel>
                                    {translate('editProfile.newPlaceholder')}
                                </EditLabel>
                            </Col>
                            <Col xs={24} md={14} lg={10}>
                                <Form.Item
                                    label={translate('editProfile.newPlaceholder')}
                                    name={translate('editProfile.newPassword')}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <PasswordBox
                                        onChange={() => setPasswordValid(true)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={0} md={4} lg={10} />

                            <Col xs={24} md={6} lg={4}>
                                <EditLabel>
                                    {translate('editProfile.confirmPlaceholder')}
                                </EditLabel>
                            </Col>
                            <Col xs={24} md={14} lg={10}>
                                <Form.Item
                                    label={translate('editProfile.confirmPlaceholder')}
                                    name={translate('editProfile.confirmNewPassword')}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <PasswordBox
                                        onChange={() => setPasswordValid(true)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={0} md={4} lg={10} />

                            <Col xs={24} md={6} lg={4} />
                            <Col xs={24} md={14} lg={10}>
                                <Form.Item>
                                    <PassButton htmlType="submit" >
                                        {translate('common.save')}
                                    </PassButton>
                                </Form.Item>
                            </Col>
                            <Col xs={0} md={4} lg={10} />
                        </SettingRow>
                    </Form>
                </FormSection>
            </SettingsContainer >
        );
    }

    return (
        <RootContainer>
            {renderPassword()}
        </RootContainer>
    );
}

export default SettingsTab;