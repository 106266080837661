import React from 'react';
import { translate } from '../../i18n';
import {
    Container,
    Spinner,
    TextContent,
    Title
} from './TermsAndConditions.elements';

const TermsAndConditions = ({
    loading,
}) => {
    return (
        <Container>
            <Title>
                {translate('footer.termsAndConditions')}
            </Title>
            <TextContent id='tc-id'></TextContent>
            {loading && <Spinner />}
        </Container >
    );
}

export default TermsAndConditions;