/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Footer,
  Navbar,
} from '..';
import { Status } from '../../api';
import { logout } from '../../store/actions';
import SessionExpiredModal from '../SessionExpiredModal/SessionExpiredModal';
import { message } from 'antd';
import { translate } from '../../i18n';
import { FREEBIE_LK, LATEST_LK, LIVESTREAM_LK, PREMIUM_LK } from '../../constants';

const Layout = ({
  /**
* If sessionStatus === Status.DEFAULT  => User is not logged in
* If sessionStatus === Status.SUCCESS  => User is logged in
* If sessionStatus === Status.ERROR    => Current session has expired
*
* @source redux
*/
  sessionStatus,
  logoutStatus,
  title,
  component: Component,
  logout: _logout,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let pathname = title || getTitle()
    document.title = `${translate('common.appName')} | ${pathname}`;
  }, [window.location.pathname]);

  useEffect(() => {
    setVisible(sessionStatus === Status.ERROR);
  }, [sessionStatus])

  useEffect(() => {
    if (logoutStatus === Status.SUCCESS) {
      navigate('/');
      window.location.reload();
    }
    else if (logoutStatus === Status.ERROR) {
      message.error(translate('errors.logoutFailedMessage'))
    }
  }, [logoutStatus]);

  const getTitle = () => {
    const path = window.location.pathname.split('/')[2]
      .toUpperCase()
      .replaceAll('%20', ' ');
    switch (path) {
      case LIVESTREAM_LK:
        return 'Livestream';
      case LATEST_LK:
        return 'Latest';
      case PREMIUM_LK:
        return 'Premium Collection';
      case FREEBIE_LK:
        return 'Enjoy Free';
      default:
        break;
    }
  }


  const logout = () => {
    setVisible(false);
    _logout(true);
  }

  return (
    <>
      <Navbar />
      <Component />
      <Footer />
      <SessionExpiredModal
        visible={visible}
        logout={logout}
      />
    </>
  );
}

const mapStateToProps = ({ app, auth, }) => {
  const { sessionStatus } = app;
  const { logoutStatus } = auth;

  return {
    sessionStatus,
    logoutStatus,
  };
};

export default connect(mapStateToProps, {
  logout,
})(Layout);