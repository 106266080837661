import React from 'react';
import {
    Image,
    ImageWrapper,
    Title
} from './Discover.elements';


const Banner = ({
    image,
    title
}) => {
    return (
        <ImageWrapper>
            <Image src={image} alt='Image' />
            <Title>
                {title}
            </Title>

        </ImageWrapper>
    );
}

export default Banner;