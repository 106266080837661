import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { EVENT_SK, POSTER_LK, VENUE_SK } from '../../../constants';
import {
  Image,
  Skeleton,
  ImageWrapper,
  BottomBar,
  SeeAllButton,
  BottomImage,
  Wrapper,
} from './Banner.elements';
import { translate } from '../../../i18n';
import { api, Status } from '../../../api';

const Banner = () => {
  const navigate = useNavigate();
  const status = useSelector(({ app }) => app.status);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    status === Status.SUCCESS &&
      fetchBannerPoster();
  }, [status]);

  const fetchBannerPoster = () => {
    api
      .fetchBannerPoster()
      .then(({ userResponse: poster }) => {
        setState(poster);
        setLoading(false);
      });
  }

  const goToPosterList = (e) => {
    e.stopPropagation();
    navigate(`/list/poster`);
  }

  const gotToDetails = () => {
    if (!state.entityIdentifier) {
      setVisible(true);
      return;
    }
    navigate(`/details/${POSTER_LK.toLowerCase()}/${btoa(state.entityIdentifier)}?entityType=${state.entityType}`);
  }

  const renderBottomBar = () => {
    return (
      <BottomBar onClick={gotToDetails}>
        <Wrapper>
          <SeeAllButton
            onClick={goToPosterList}
          >
            {translate('common.explore')}
          </SeeAllButton>
          <BottomImage
            alt='img'
            src={state.randomPoster?.landscapeImagePath?.originalUrl}
            onClick={goToPosterList}
          />
        </Wrapper>
      </BottomBar>
    );
  }

  return (
    <>
      <ImageWrapper>
        <Skeleton loading={loading} avatar active>
          <Image
            alt='banner img'
            src={state.landscapeImagePath?.originalUrl}
            preview={{
              visible,
              mask: null,
              onVisibleChange: (v) => setVisible(v)
            }}
            onClick={gotToDetails}
          />
          {renderBottomBar()}
        </Skeleton>
      </ImageWrapper>
    </>
  );
}

export default Banner;