import React, { useState } from 'react';
import { Tabs, } from './WatchListTab.elements';
import { translate } from '../../../i18n';
import Shows from './Shows';
import Posters from './Posters';

const { TabPane } = Tabs;

const WatchListTab = () => {
  const [activeKey, setActiveKey] = useState('1');

  return (
    <Tabs
      activeKey={activeKey}
      onChange={(newActiveKey) => setActiveKey(newActiveKey)}
    >
      <TabPane
        key="1"
        tab={translate('editProfile.shows')}
      >
        <Shows active={activeKey === "1"} />
      </TabPane>
      <TabPane
        key="2"
        tab={translate('editProfile.posters')}
      >
        <Posters active={activeKey === "2"} />
      </TabPane>

    </Tabs>
  );
}

export default WatchListTab;