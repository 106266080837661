import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { api, Status } from '../../api';
import { BillPaymentComponent, Loading, } from '../../components';
import { PWA_SK } from '../../constants';

const BillPayment = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const {
        image,
        total,
        datetime,
        endtime,
        title,
        ticketCount,
        entityType,
        price,
        ticketInfoIdentifier,
        entityIdentifier,
        customerBillingInfoIdentifier,
    } = state || {};

    const status = useSelector(({ app }) => app.status);
    const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
    const [list, setList] = useState({});

    useEffect(() => {
        if (status === Status.SUCCESS) {
            fetchPaymentList();
        }
    }, [status]);

    const fetchPaymentList = () => {
        setApiStatus(Status.LOADING);
        api.fetchPaymentList({ amount: total })
            .then(({ userResponse }) => {
                setApiStatus(Status.SUCCESS);
                let _wallet = userResponse.wallet.filter(i => i.method !== PWA_SK);
                setList({
                    ...userResponse,
                    wallet: _wallet,
                });
            })
            .catch((error) => {
                setApiStatus(Status.ERROR);
            })
    }

    if (apiStatus !== Status.SUCCESS) {
        return <Loading />
    }

    return (
        <BillPaymentComponent
            image={image}
            total={total}
            payments={list}
            apiStatus={apiStatus}
            datetime={datetime}
            endtime={endtime}
            title={title}
            ticketCount={ticketCount}
            entityType={entityType}
            price={price}
            ticketInfoIdentifier={ticketInfoIdentifier}
            entityIdentifier={entityIdentifier}
            customerBillingInfoIdentifier={customerBillingInfoIdentifier}
        />
    );
}

export default BillPayment;