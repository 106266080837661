import React, { useEffect, useState } from 'react';
import {
  SectionTemplate,
  PremiumCard,
} from '../../../common';
import {
  PREMIUM_LK,
  PREMIUM_SK,
  UNSAVE_SK,
  VENUE_SK,
} from '../../../constants';
import Skeleton from '../Skeleton/Skeleton';
import { translate } from '../../../i18n';
import { useSelector } from 'react-redux';
import { api, Status } from '../../../api';
import { getFormattedPrice } from '../../../utils';

const PremiumVideo = ({
  mode,
}) => {
  const status = useSelector(({ app }) => app.status);
  const [loading, setLoading] = useState(true);
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    status === Status.SUCCESS &&
      fetchPremiumVideos();
  }, [status]);

  const fetchPremiumVideos = () => {
    api
      .fetchVideoList({
        entityType: PREMIUM_SK,
      })
      .then(({ userResponse }) => {
        setVideoList(userResponse);
        setLoading(false);
      });
  }

  if (loading) {
    return (
      <Skeleton
        type={PREMIUM_LK}
        title={translate('common.premium')}
      />
    );
  }

  return (
    <SectionTemplate
      title={translate('common.premium')}
      items={2}
      type={PREMIUM_LK}
    >
      {videoList.map((item, key) => (
        <PremiumCard
          key={key}
          mode={mode}
          entityType={VENUE_SK}
          identifier={item.entityIdentifier}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          description={item.shortTitle}
          price={item.price}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          link={item.copyLinkUrl}
        />
      ))
      }
    </SectionTemplate>
  );
}

export default PremiumVideo;