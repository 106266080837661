import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { Content, Link } from './LoginPrompt.elements';
import { translate } from '../../i18n';

const LoginPrompt = ({
  children,
  placement = 'top',
  text,
  onClick: _handleAction,
}) => {
  const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEYS.sessionId);
  const [visible, setVisible] = useState(false);

  const handleVisible = (v) => {
    if (!isLoggedIn) {
      setVisible(v);
      return;
    }
    setVisible(false);
  }

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isLoggedIn) {
      _handleAction();
    }
  }

  const content = (
    <Content>
      <Link to='/login'>
        {translate('common.login')}
      </Link>
      &nbsp;{text}
    </Content>
  );

  return (
    <Dropdown
      trigger='click'
      visible={visible}
      overlay={content}
      placement={placement}
      onVisibleChange={handleVisible}
      onClick={handleClick}
      overlayStyle={{
        minWidth: 0,
      }}
    >
      {children}
    </Dropdown>
  );
}

export default LoginPrompt;