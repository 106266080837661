import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  EmptyCard,
  EmptyIcon,
  EmptyText,
  List,
  Title,
  Topbar,
} from './NotificationList.elements';
import Item, { Skeleton } from './NotificationListItem';
import { translate } from '../../i18n';
import { fetchNotifications } from '../../store/actions';
import { Status } from '../../api';
import { CloseOutlined } from '@ant-design/icons';

const NotificationList = ({
  closeDropdown,
}) => {
  const dispatch = useDispatch();
  const { notificationList, hasMore, } = useSelector(({ app }) => app.notifications);
  const notificationsStatus = useSelector(({ app }) => app.notificationsStatus);
  const [page, setPage] = useState(1);

  useEffect(() => {
    page !== 1 && dispatch(fetchNotifications(page));
  }, [page]);

  const renderEmptyCard = () => (
    <EmptyCard show={notificationList.length === 0 && notificationsStatus !== Status.LOADING}>
      <EmptyIcon />
      <EmptyText>
        {translate('navMenu.notificationEmptyMessage')}
      </EmptyText>
    </EmptyCard>
  );

  const renderFirstLoading = () => {
    if (notificationsStatus === Status.LOADING) {
      return <Skeleton />;
    }
    return null;
  }

  const loader = <Skeleton />;

  return (
    <List id='noti-container'>
      <Topbar>
        <CloseOutlined onClick={closeDropdown} />
        <Title>
          {translate('navMenu.notifications')}
        </Title>
      </Topbar>
      {renderFirstLoading()}
      {renderEmptyCard()}
      <InfiniteScroll
        scrollableTarget='noti-container'
        dataLength={notificationList.length}
        next={() => setPage(prevState => prevState + 1)}
        hasMore={hasMore}
        loader={loader}
        endMessage=''
      >
        {notificationList.map((item, key) => (
          <Item
            key={key}
            entityIdentifier={item.entityIdentifier}
            image={item.thumbUrl}
            date={item.broadcastStartDateTime}
            name={item.title}
            time={item.createdDate}
            closeDropdown={closeDropdown}
          />
        ))}
      </InfiniteScroll>
    </List>
  );
}

export default NotificationList;