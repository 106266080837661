import styled from 'styled-components';
import { Tabs as AntTabs } from 'antd';
import { device } from '../../../theme';

export const RootContainer = styled.div`
padding: 20px 60px;
min-height: calc(100vh - 190px);

@media ${device.laptop} {
  padding: 10px 30px;
}

@media ${device.mobileL} {
  padding: 0 10px;
}
`;

export const Tabs = styled(AntTabs)`
  .ant-tabs-nav {
    &::before {
      border: none;
    }

    @media ${device.laptop} {
      margin: 0 0 16px 0;
    }

    @media ${device.mobileL} {
      margin: 0 0 10px 0;
    }
  }

  .ant-tabs-ink-bar{
    display: none;
  }

  .ant-tabs-tab-btn {
    border-bottom: 2px solid transparent;
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: ${props => props.theme.thinFontColor};

        @media ${device.laptop} {
          font-size: 14px;
        }

        @media ${device.mobileL} {
          font-size: 12px;
        }
      }
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    border-bottom: 2px solid ${props => props.theme.secondaryColor} !important;
      a {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: ${({ theme }) => theme.primaryColor};

        @media ${device.laptop} {
          font-size: 14px;
        }

        @media ${device.mobileL} {
          font-size: 12px;
        }
      }
  }
`;