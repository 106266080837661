import styled from "styled-components";
import { css } from "styled-components";
import checkIcon from "../../assets/images/icon_check.png";
import uncheckIcon from "../../assets/images/icon_uncheck.png";
import { device } from "../../theme";

const GradientBorder = css`
  background-image: linear-gradient(
      ${(props) => props.theme.primaryColor},
      ${(props) => props.theme.secondaryColor}
    ),
    linear-gradient(
      ${(props) => props.theme.primaryColor},
      ${(props) => props.theme.secondaryColor}
    );
  border-top: 3px solid ${(props) => props.theme.primaryColor};
  border-bottom: 3px solid ${(props) => props.theme.secondaryColor};
  background-size: 3px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  padding: 0 3px;
  z-index: 10;
`;

const SolidBorder = `
border: 3px solid rgba(190, 191, 207, 0.3);
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height + 10 + "px"};
  background: ${({ checked }) =>
    checked ? "rgba(190, 191, 207, 0.3)" : "transparent"};
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  ${({ checked }) => (checked ? GradientBorder : SolidBorder)}
`;

export const IconCheck = styled.img.attrs(({ checked }) => ({
  src: checked ? checkIcon : uncheckIcon,
  alt: "checkIcon",
}))`
  width: 24px !important;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  @media ${device.laptop} {
    width: 18px !important;
  }
  @media ${device.mobileL} {
    width: 16px !important;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
`;

export const Image = styled.img`
  width: 45px;
  height: 45px;

  @media ${device.laptop} {
    width: 30px;
    height: 30px;
  }
  @media ${device.mobileL} {
    width: 28px;
    height: 28px;
  }
  @media ${device.mobileS} {
    width: 25px;
    height: 25px;
  }
`;

export const Name = styled.div`
  padding-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ checked }) =>
    checked
      ? `${(props) => props.theme.fontColor}`
      : "rgba(190, 191, 207, 0.3)"};

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

// InterestCards

export const InterestContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  margin-top: ${(props) => (props.profile ? "20px" : "70px")};

  @media ${device.laptop} {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }

  @media ${device.mobileL} {
    grid-template-columns: 33% 33% 33%;
  }

  @media ${device.mobileS} {
    grid-template-columns: 50% 50%;
  }
`;
