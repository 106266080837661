import React from 'react';
import { GuestInfoSection } from '../../common';
import { PosterListingComponent } from '../../components';

const PosterListing = () => {
  return (
    <>
      <PosterListingComponent />
      <GuestInfoSection />
    </>
  );
}

export default PosterListing;