import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { api, Status } from '../../api';
import {
  TicketList as TicketListComponent,
} from '../../common';
import { LIMITS, LISTING_MODE_LK, NEW_SK, USED_SK } from '../../constants';
import { translate } from '../../i18n';

const options = [
  {
    key: 0,
    name: `${translate('ticketing.all')}`
  },
  {
    key: NEW_SK,
    name: `${translate('ticketing.new')}`
  },
  {
    key: USED_SK,
    name: `${translate('ticketing.used')}`
  },
];

const TicketList = () => {
  const { state } = useLocation();
  const {
    item,
    filter: _filter,
  } = state || {};
  const status = useSelector(({ app }) => app.status);
  const [page, setPage] = useState(1);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState(options[0]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (status === Status.SUCCESS && _filter) {
      setFilter(_filter);
      fetchTicketSaleDetailList(1, _filter.key);
    }
  }, [status, _filter]);

  useEffect(() => {
    page !== 1 && fetchTicketSaleDetailList(page, filter.key);
  }, [page]);

  useEffect(() => {
    setPage(1);
    apiStatus === Status.SUCCESS && fetchTicketSaleDetailList(1, filter.key);
  }, [filter]);

  const fetchTicketSaleDetailList = (page = 1, ticketStatus) => {
    const { entityIdentifier, entityType, } = item;
    setApiStatus(Status.LOADING);
    api
      .fetchTicketSaleDetailList({
        entityIdentifier,
        entityType,
        ticketStatus,
        page,
      })
      .then(({ userResponse: { ticketSaleDetailList } }) => {
        setApiStatus(Status.SUCCESS);
        setList(prevState => {
          return page === 1
            ? ticketSaleDetailList
            : [...prevState, ...ticketSaleDetailList]
        });
        setHasMore(ticketSaleDetailList.length >= LIMITS.ITEMS_PER_PAGE);
      })
      .catch(() => {
        setApiStatus(Status.ERROR);
      });
  }

  const handleFilterChange = (filter) => {
    setFilter(filter);
    setVisible(false);
  }

  const next = () => apiStatus === Status.SUCCESS && setPage(prevState => prevState + 1);

  if (!state) {
    return <Navigate to='/' />;
  }

  return (
    <div>
      <TicketListComponent
        mode={LISTING_MODE_LK}
        item={item}
        list={list}
        next={next}
        hasMore={hasMore}
        filterProps={{
          options,
          filter,
          visible,
          setVisible,
          handleFilterChange,
        }}
      />
    </div>
  );
}

export default TicketList;