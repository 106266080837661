import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider, } from 'react-redux';
import store from './store';
import App from './App';
import 'antd/dist/antd.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>
);