/* eslint-disable import/no-anonymous-default-export */
import { Status } from "../../api";
import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    RESET_OTP_VERIFICATION_STATE,
    RESET_SIGNUP_STATE,
    SIGNUP_ERROR,
    SIGNUP_LOADING,
    SIGNUP_SUCCESS,
    VERIFY_OTP_ERROR,
    VERIFY_OTP_LOADING,
    VERIFY_OTP_SUCCESS,
    INTERESTS_LOADING,
    INTERESTS_SUCCESS,
    INTERESTS_ERROR,
    LOGOUT_LOADING,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_FORGOT_PASSWORD_STATE,
    RESET_PASSWORD_LOADING,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_CHANGE_PASSWORD_STATE,
    RESEND_OTP_LOADING,
    RESEND_OTP_SUCCESS,
    RESEND_OTP_ERROR
} from "../../constants";

const initialState = {
    // =========================================================
    // ======================== Login ==========================
    // =========================================================
    loginStatus: Status.DEFAULT,
    loginErrorMessage: '',
    loginErrorCode: '',
    firstTimeLogin: '',
    // =========================================================
    // ==================== ForgotPassword =====================
    // =========================================================
    forgotPasswordStatus: Status.DEFAULT,
    forgotPasswordErrorMessage: '',
    // =========================================================
    // ====================== ResetPassword ====================
    // =========================================================
    resetPasswordStatus: Status.DEFAULT,
    resetPasswordErrorMessage: '',
    // =========================================================
    // =================== Signup ==============================
    // =========================================================
    signupStatus: Status.DEFAULT,
    signupErrorMessage: '',
    // =========================================================
    // =============== OtpVerification =========================
    // =========================================================
    verifyOtpStatus: Status.DEFAULT,
    verifyOtpErrorMessage: '',
    // =========================================================
    // =============== ResendOtp =========================
    // =========================================================
    resendOtpStatus: Status.DEFAULT,
    resendOtpErrorMessage: '',
    // =========================================================
    // ======================= Interests =======================
    // =========================================================
    interestsStatus: Status.DEFAULT,
    interestsErrorMessage: '',
    // =========================================================
    // ======================== Logout =========================
    // =========================================================
    logoutStatus: Status.DEFAULT,
    logoutErrorMessage: '',
};

/**
 * App level reducer, store data that is common to entire app.
 */
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LOGIN_LOADING:
            return {
                ...state,
                loginStatus: Status.LOADING,
                loginErrorMessage: '',
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loginStatus: Status.SUCCESS,
                loginErrorMessage: '',
                firstTimeLogin: payload.firstTimeLogin,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loginStatus: Status.ERROR,
                loginErrorMessage: payload.errorMessage,
                loginErrorCode: payload.errorCode,
            };
        case FORGOT_PASSWORD_LOADING:
            return {
                ...state,
                forgotPasswordStatus: Status.LOADING,
                forgotPasswordErrorMessage: '',
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordStatus: Status.SUCCESS,
                forgotPasswordErrorMessage: '',
            };
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordStatus: Status.ERROR,
                forgotPasswordErrorMessage: payload.errorMessage,
            };
        case RESET_FORGOT_PASSWORD_STATE:
            return {
                ...state,
                forgotPasswordStatus: Status.DEFAULT,
                forgetPasswordErrorMessage: '',
            };
        case RESET_PASSWORD_LOADING:
            return {
                ...state,
                resetPasswordStatus: Status.LOADING,
                resetPasswordErrorMessage: '',
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordStatus: Status.SUCCESS,
                resetPasswordErrorMessage: '',
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordStatus: Status.ERROR,
                resetPasswordErrorMessage: payload.errorMessage,
            };
        case RESET_CHANGE_PASSWORD_STATE:
            return {
                ...state,
                resetPasswordStatus: Status.DEFAULT,
                resetPasswordErrorMessage: '',
                resendOtpStatus: Status.DEFAULT,
                resendOtpErrorMessage: '',
            };
        case SIGNUP_LOADING:
            return {
                ...state,
                signupStatus: Status.LOADING,
                signupErrorMessage: "",
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                signupStatus: Status.SUCCESS,
                signupErrorMessage: "",
            };
        case SIGNUP_ERROR:
            return {
                ...state,
                signupStatus: Status.ERROR,
                signupErrorMessage: payload.errorMessage,
            };
        case RESET_SIGNUP_STATE:
            return {
                ...state,
                signupStatus: Status.DEFAULT,
                signupErrorMessage: "",
            }
        case VERIFY_OTP_LOADING:
            return {
                ...state,
                verifyOtpStatus: Status.LOADING,
                verifyOtpErrorMessage: '',
            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                verifyOtpStatus: Status.SUCCESS,
                verifyOtpErrorMessage: '',
            };
        case VERIFY_OTP_ERROR:
            return {
                ...state,
                verifyOtpStatus: Status.ERROR,
                verifyOtpErrorMessage: payload.errorMessage,
            };
        case RESET_OTP_VERIFICATION_STATE:
            return {
                ...state,
                verifyOtpStatus: Status.DEFAULT,
                verifyOtpErrorMessage: '',
            };
        case RESEND_OTP_LOADING:
            return {
                ...state,
                resendOtpStatus: Status.LOADING,
                resendOtpErrorMessage: '',
            }
        case RESEND_OTP_SUCCESS:
            return {
                ...state,
                resendOtpStatus: Status.SUCCESS,
                resendOtpErrorMessage: '',
            }
        case RESEND_OTP_ERROR:
            return {
                ...state,
                resendOtpStatus: Status.ERROR,
                resendOtpErrorMessage: payload.errorMessage,
            }

        case INTERESTS_LOADING:
            return {
                ...state,
                interestsStatus: Status.LOADING,
                interestsErrorMessage: '',
            };
        case INTERESTS_SUCCESS:
            return {
                ...state,
                interestsStatus: Status.SUCCESS,
                interestsErrorMessage: '',
            };
        case INTERESTS_ERROR:
            return {
                ...state,
                interestsStatus: Status.ERROR,
                interestsErrorMessage: payload.errorMessage,
            };
        case LOGOUT_LOADING:
            return {
                ...state,
                logoutStatus: Status.LOADING,
                logoutErrorMessage: '',
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                logoutStatus: Status.SUCCESS,
                logoutErrorMessage: '',
            };
        case LOGOUT_ERROR:
            return {
                ...state,
                logoutStatus: Status.ERROR,
                logoutError: payload.errorMessage
            };
        default:
            return state;
    }
}