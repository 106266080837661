import React from 'react';
import { Image } from 'antd';
import {
    Container,
    EditImage,
    ProfileAvatar,
    ProfileButton,
    Title
} from './Banner.elements';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../../i18n';
import { connect } from 'react-redux';

const Banner = ({
    profileImage,
    userName,
}) => {
    const navigate = useNavigate();

    const goToEditProfile = () => {
        navigate('/edit-profile/profile');
    }
    return (
        <Container>
            <ProfileAvatar
                src={
                    <EditImage
                        src={profileImage?.originalUrl}
                        preview={{
                            mask: null
                        }}
                    />}
                size={100}
                style={{ backgroundColor: '#C4C4C4' }}
            />
            <Title>
                {userName}
            </Title>

            <ProfileButton onClick={goToEditProfile}>
                {translate('editProfile.editProfileButton')}
            </ProfileButton>
        </Container>
    )
}
const mapStateToProps = ({ profile }) => {
    const {
        profileImage,
        userName,
    } = profile;
    return {
        profileImage,
        userName,
    }
}

export default connect(mapStateToProps, null)(Banner);