/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Dropdown,
  EmptyCard,
} from '../../../common';
import {
  Filter,
  FilterIcon,
  FilterLabel,
  Spinner,
} from '../../../theme/styles';
import {
  FilterBar,
  FilterOption,
  Loader,
  RootContainer,
  SelectedLabel,
  Text,
} from './TicketsTab.elements';
import { translate } from '../../../i18n';
import { api, Status } from '../../../api';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { TicketList } from '../../../common';
import { LIMITS, NEW_SK, USED_SK } from '../../../constants';

const options = [
  {
    key: 0,
    name: `${translate('ticketing.all')}`
  },
  {
    key: NEW_SK,
    name: `${translate('ticketing.new')}`
  },
  {
    key: USED_SK,
    name: `${translate('ticketing.used')}`
  },
];

const TicketsTab = () => {
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState(options[0]);
  const status = useSelector(({ app }) => app.status);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);

  useEffect(() => {
    if (status === Status.SUCCESS) {
      fetchTicketList();
    }
  }, [status]);

  useEffect(() => {
    page !== 1 && fetchTicketList(page, filter.key);
  }, [page]);

  useEffect(() => {
    setPage(1);
    !loading && fetchTicketList(1, filter.key);
  }, [filter]);

  const fetchTicketList = (page = 1, ticketStatus) => {
    setApiStatus(Status.LOADING);
    api
      .fetchTicketList(page, ticketStatus)
      .then(({ userResponse: _list }) => {
        setApiStatus(Status.SUCCESS);
        setList(page === 1 ? _list : [...list, ..._list]);
        setLoading(false);
        setHasMore(_list.length >= LIMITS.ITEMS_PER_PAGE);
      })
      .catch(() => {
        setApiStatus(Status.ERROR);
      });
  }

  const handleFilterChange = (filter) => {
    setFilter(filter);
    setVisible(false);
  }

  const next = () => apiStatus === Status.SUCCESS && setPage(prevState => prevState + 1);

  const loader = (
    <Loader>
      <Spinner />
    </Loader>
  );

  const renderFilter = () => {
    const content = (
      <>
        {options.map(opt => (
          <FilterOption
            key={opt.key}
            onClick={() => handleFilterChange(opt)}
          >
            <Text>{opt.name}</Text>
          </FilterOption>
        ))}
      </>
    );

    return (
      <FilterBar hide={list.length === 0}>
        <SelectedLabel>
          {filter.name}
        </SelectedLabel>
        <Dropdown
          visible={visible}
          placement="bottomRight"
          trigger="click"
          content={content}
          onVisibleChange={(visible) => setVisible(visible)}
        >
          <Filter>
            <FilterIcon />
            <FilterLabel>
              {translate('searchResult.filters')}
            </FilterLabel>
          </Filter>
        </Dropdown>
      </FilterBar>
    );
  }

  if (!loading && list.length === 0) {
    return <EmptyCard />;
  }

  return (
    <RootContainer>
      {renderFilter()}
      <InfiniteScroll
        dataLength={list.length}
        next={next}
        hasMore={hasMore}
        loader={loader}
        endMessage=''
      >
        {list.map((item, key) => (
          <TicketList
            key={key}
            item={item}
            filter={filter}
          />
        ))}
      </InfiniteScroll>
    </RootContainer>
  );
}

export default TicketsTab;