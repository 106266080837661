import styled from "styled-components";
import OTPInput from "otp-input-react";
import { GlobalButton } from "../../../theme/styles";
import { device } from '../../../theme';

export const OtpImageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: inline-grid;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
`;

export const OtpContainer = styled.div`
  width: 100%;
  padding: 30px 30px;
  background-color: #fff;

  @media ${device.laptopL} {
    min-height: 100%;
  } 

  @media ${device.tablet} {
    min-height: 100vh;
    padding: 65px 0;
  }

  @media ${device.mobileL} {
    min-height: 100vh;
    padding: 65px 0;
  }

  @media ${device.mobileS} {
    padding: 60px 0;
  }
`;

export const OtpHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;
  color: #201c21;
  text-align: center;
  margin-bottom: 20px;

  @media ${device.laptop} {
    font-size: 30px;
  }

  @media ${device.mobileL} {
    font-size: 25px;
  }

  @media ${device.mobileS} {
    font-size: 25px;
  }
`;

export const OtpInfo = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #bebfcf;

  @media ${device.laptop} {
    font-size: 16px;
  }
  
  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 14px;
  }
`;

export const OtpCodeInfo = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin: 20px 0;

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 14px;
  }
`;

export const OtpInputContainer = styled(OTPInput)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  color: #000;

  input {
    width: 60px !important;
    height: 50px !important;
    border: 1px solid #201c21;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 40px;

    @media screen and (min-width: 280px) {
      width: 27px !important;
      height: 50px !important;
      margin-right: 10px !important;
    }

    @media screen and (min-width: 410px) {
      width: 45px !important;
      height: 50px !important;
      margin-right: 10px !important;
    }

    @media screen and (min-width: 527px) {
      width: 60px !important;
      height: 50px !important;
      margin-right: 10px !important;
    }

    @media screen and (min-width: 992px) {
      width: 50px !important;
      height: 40px !important;
      margin-right: 9px !important;
    }

    @media screen and (min-width: 1200px) {
      width: 55px !important;
      height: 45px !important;
      margin-right: 20px !important;
    }

    @media screen and (min-width: 1366px) {
      width: 60px !important;
      height: 50px !important;
    }

    &:first-child {
      margin-left: 3px !important;
    }

    &:last-child {
      margin-right: 5px !important;
    }
  }
`;

export const ResendCode = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-top: 20px;

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 14px;
  }
`;

export const ResendCodeLink = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: underline;
  cursor: pointer;

  &:hover{
    color: ${({ theme }) => theme.primaryColor};
  }

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const OtpButton = styled(GlobalButton)`
width: 100%;
`;
