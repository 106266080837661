import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, } from 'react-router-dom';
import { Status } from '../../api';
import {
  EVENT_SK,
  FREEBIE_LK,
  LATEST_LK,
  LIVESTREAM_LK,
  POSTER_LK,
  PREMIUM_LK,
  VENUE_SK,
} from '../../constants';
import { isBase64String } from '../../utils';
import ConnectionFailed from './ConnectionFailed';
import GeneralError from './GeneralError';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const ErrorHandler = ({
  children,
}) => {
  const apiRequestStatus = useSelector(({ app }) => app.apiRequestStatus);
  const { pathname } = useLocation();
  const query = useQuery();

  useEffect(() => {
    window.addEventListener('online', () => {
      window.location.reload();
    });
  }, []);

  const isDetailsRouteValid = () => {
    const paths = pathname.split('/');
    const type = paths[2]?.toUpperCase().replaceAll('%20', ' ');
    const identifier = paths[3];
    const entityType = query.get('entityType');
    if (pathname.includes('/details')) {
      if (
        type !== LIVESTREAM_LK &&
        type !== LATEST_LK &&
        type !== PREMIUM_LK &&
        type !== FREEBIE_LK &&
        type !== POSTER_LK
      ) {
        return false;
      }
      else if (!isBase64String(identifier)) {
        return false;
      }
      else if (
        !entityType ||
        (entityType !== EVENT_SK &&
          entityType !== VENUE_SK)
      ) {
        return false;
      }
    }
    return true;
  }

  if (!isDetailsRouteValid()) {
    return <Navigate to='/404' />;
  }

  if (!navigator.onLine) {
    return <ConnectionFailed />;
  }

  if (apiRequestStatus === Status.ERROR) {
    return <GeneralError />;
  }

  return children;
};

export default ErrorHandler;