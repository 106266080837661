/**
 * Returns true if phonenumber is valid, else false
 *
 * @param {string} phonetxt - phone number entered by user
 */
export function isPhoneNumberValid(phoneNumber) {
    return phoneNumber.length === 13
}

/**
 * Returns true if password is valid, else false
 * current check: Password shouldn't be empty and atleast 8 character
 *
 * @param {string} password - password entered by user
 */
export function isPasswordValid(password) {
    // At least one number, one uppercase letter, one lowercase letter and one special symbol.
    const passwordregix = /^(?=.*[0-9])(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()-_+={}[|\/:;'"<>,.?]).*$/;

    return passwordregix.test(password);
}

export function formatPhoneNumber(phoneNumber) {
    if (phoneNumber.startsWith('09')) {
        return phoneNumber.replace('09', '+959');
    }
    else if (phoneNumber.startsWith("959")) {
        return phoneNumber.replace('959', '+959');

    }
    else if (phoneNumber.startsWith('9')) {
        if (phoneNumber.length === 9) {
            return phoneNumber.replace('9', '+9599')
        } else {
            return phoneNumber.replace('9', '+959');
        }
    }
    else if (!phoneNumber.startsWith('+959')) {
        return '+959' + phoneNumber;

    }
    return phoneNumber;
}

export function cutPrefixPhoneNumber(phoneNumber) {
    const _phoneNumber = phoneNumber.slice(3);
    return _phoneNumber;
}

/**
 * Returns true if phonenumber is valid, else false
 *
 * @param {string} phonetxt - phone number entered by user
 */
export function isPhoneNumberPattern(phonetxt) {
    // var phoneno = /^\d{10}$/;    // To restrict for min 10 entries
    // const phoneno = /^.[0-9\\+]{0,20}$/;
    const phoneno = /^\+[1-9]\d{1,14}$/; // https://www.twilio.com/docs/glossary/what-e164

    return phonetxt.match(phoneno);
}