import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import { device, GlobalButton, GlobalInputBox } from "../../../theme";
import {
    Col,
    Input as AntInput,
    Radio as AntRadio,
    Space as AntSpace,
    Modal as AntModal,
} from "antd";
import { Spinner as GlobalSpinner } from "../../../theme/styles";

export const RootContainer = styled.div`
  padding: 40px 60px;
  min-height: calc(100vh - 190px);

  @media ${device.laptop} {
    padding: 30px;
  }

  @media ${device.mobileL} {
    padding: 20px 10px;
  }

  @media ${device.mobileS} {
    padding: 20px 10px;
  }
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: ${({ theme }) => theme.fontColor};
  text-align: center;
  padding-bottom: 50px;

  @media ${device.laptop} {
    font-size: 22px;
  }

  @media ${device.mobileL} {
    font-size: 19px;
  }

  @media ${device.mobileS} {
    font-size: 16px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-size: 16px;
  color: #fff;
  padding: 10px 0px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 15px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

export const DetailsContainer = styled.div`
  padding-top: 20px;
`;

export const InputBox = styled(GlobalInputBox)`
  color: ${(props) => props.theme.fontColor} !important;
  margin-bottom: 15px !important;
  text-align: ${(props) => (props.left ? "left" : "right")};
`;

export const EditLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.fontColor} !important;
  padding: 15px 0;

  @media ${device.tablet} {
    font-size: 15px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    padding: 10px;
  }

  @media ${device.mobileM} {
    font-size: 13px;
  }
`;

export const R = styled.span`
  padding-left: 5px;
  color: red;
`;

export const PhoneBox = styled.div`
   margin-bottom: 15px !important;
`;

export const BillTextArea = styled(TextArea)`
border: 1px solid #BEBFCF;
border-radius: 10px;
background: transparent;
margin-bottom: 15px !important;
color: ${props => props.theme.fontColor};

&:hover, :focus{
    border: 1px solid #BEBFCF;
}
`;

export const SpinnerContainer = styled.div`
min-height: calc(100vh - 190px);
`;

export const Spinner = styled(GlobalSpinner).attrs({
    size: "middle",
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Loader = styled.div`
text-align: center;
`;

export const Input = styled(AntInput)`
  height: 54px;
  background: transparent;
  border: 1px solid #bebfcf;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: ${(props) => props.theme.fontColor};
  text-align: ${(props) => (props.left ? "left" : "right")};
  margin-bottom: 15px !important;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }

  &:focus,
  :hover {
    border: 1px solid #bebfcf;
    box-shadow: none;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileL}{
    min-height: 0px;
  }
`;

export const Image = styled.img`
  width: 30px;
  height: auto;
  padding-left: 5px;
  object-fit: contain;

  @media screen and (max-width: 552px) {

  }
`;

export const ImageWrapper = styled.div`
  text-align: center;
  padding-top: 15px;
`;

export const ThumbImage = styled.img`
  width: 50%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  border-radius: 5px;

  @media ${device.mobileL} {
    padding: 0 5%;
  }
`;

export const Text = styled.div`
  min-width: 210px;
  color:  ${props => props.theme.lightFontColor};
  padding-right: 80px;
  font-size: 14px;
  text-align: left;
`;

export const Radio = styled(AntRadio)`
  display: flex;
  flex-direction: row-reverse;

  .ant-radio-inner {
    background: transparent;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.primaryColor} !important ;
    background: transparent;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${({ theme }) => theme.primaryColor};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;

export const Space = styled(AntSpace)`
  border: 1px solid #BEBFCF;
  border-radius: 5px;
  min-width: 250px;
  min-height: 40px;
  margin-bottom: 10px;
`;

export const ButtonSpace = styled(AntSpace)`
  border-radius: 5px;
  min-width: 250px;
  min-height: 40px;
  margin-bottom: 20px;
`;


export const Box = styled.div`
  width: 100%;
  min-height: 200px;
  background: #141115;
  border-radius: 5px;
`;

export const LeftCol = styled(Col)`
  text-align: center;
`;

export const InfoSection = styled.div`
  padding: 0 0 15px 10px;
`;

export const Date = styled.div`
padding-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.secondaryColor};

  @media ${device.laptop} {
    font-size: 13px;
  }
`;

export const EndDate = styled(Date)`
  font-size: 16px;
  color: #fff;

  @media ${device.laptop} {
    font-size: 15px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

export const Data = styled.div`
  padding-top: 15px;
  font-size: 16px;
  display: flex;
  color: #fff;

  @media ${device.laptop} {
    font-size: 15px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

export const Label = styled.div``;

export const LabelData = styled.div`
  font-weight: bold;
  padding-left: 10px;
`;

export const BottomBox = styled.div`
  padding-top: 30px;
  text-align: right;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const Button = styled(GlobalButton)`
  min-width: 175px;
  border: 1px solid #ffffff;

  @media ${device.laptop} {
    min-width: 150px;
  }

  @media ${device.mobileL} {
    min-width: 140px;
    height: 40px !important;
  }

  @media ${device.mobileS} {
    min-width: 120px;
  }

  &:hover {
    border: 1px solid #ffffff;
  }
`;

export const CancelButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.fontColor};
  color: ${(props) => props.theme.fontColor};
  background: transparent;

  &:hover {
    background: linear-gradient(
      94.11deg,
      ${(props) => props.theme.primaryColor} 0.54%,
      ${(props) => props.theme.primaryColor} 98.6%
    );
  }
`;

export const Container = styled.div`
display: flex;
flex-direction: row;
min-height: 600px;

  >div {
    flex: 0.5;
  }

@media ${device.tablet} {
  flex-direction: column-reverse;
}
`;

export const LeftColumn = styled.div`
  @media ${device.tablet} {
    padding-top: 20px;
}
`;

export const RightColumn = styled.div``;

export const SubTitle = styled(Title)`
color: ${props => props.theme.fontColor};
`;

export const WarningContainer = styled.div`
text-align: center;
`;

export const WarningTitle = styled.div`
padding-top: 30px;
color: ${props => props.theme.secondaryColor};
text-transform: uppercase;
font-style: normal;
font-weight: 700;
font-size: 18px;
text-align: center;

@media ${device.laptop} {
  font-size: 16px;
}

@media ${device.tablet} {
  font-size: 15px;
}

@media ${device.mobileL} {
  font-size: 14px;
}
`;

export const WarningInfo = styled.div`
text-align: center;
color: ${props => props.theme.secondaryColor};
padding: 20px 0;
`;

export const WarningButton = styled(GlobalButton)`
width: 80px;
margin-top: 20px;
`;

export const Modal = styled(AntModal)`
  .ant-modal-content {
    background: #201C21;
    border: 1px solid #7851A9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  svg {
    color: #fff;
  }
`;