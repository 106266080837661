import React from 'react';
import {
  Image,
  ImageWrapper,
  InfoSection,
  SubText,
  Text,
  TextLink,
  TextSpan,
  TextWrapper,
} from './GuestInfoSection.elements';
import backgroundImg from '../../assets/images/guest-info-bg.png';
import { translate } from '../../i18n';
import { LOCAL_STORAGE_KEYS } from '../../constants';

const GuestInfoSection = () => {
  const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEYS.sessionId);

  if (isLoggedIn) {
    return null;
  }

  return (
    <InfoSection>
      <ImageWrapper>
        <TextWrapper>
          <Text>
            Would you like to proceed?
          </Text>
          <SubText>
            <TextLink to='sign-up'>Sign up</TextLink> or <TextLink to='/login'>Log in</TextLink> to your account to view more videos
          </SubText>
          <SubText>
            Lorem ipsum dolor sit amet.
          </SubText>
        </TextWrapper>
        <Image src={backgroundImg} alt='Image' />

      </ImageWrapper >
    </InfoSection >
  );
}

export default GuestInfoSection;