import React from 'react';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../i18n';
import Carousel from '../Carousel/Carousel';
import {
  Container,
  SeeAllButton,
  Title,
} from './SectionTemplate.elements';

const SectionTemplate = ({
  filled,
  title,
  items,
  children,
  explore,
  loading,
  type,
}) => {
  const navigate = useNavigate();

  const goToListing = () => {
    navigate(`/list/${type.toLowerCase()}`);
  }
  return (
    <Container filled={filled}>
      <Title>
        {title}
        <SeeAllButton
          explore={explore}
          loading={loading}
          onClick={goToListing}
        >
          {translate('common.seeAll')}
        </SeeAllButton>
      </Title>
      <Carousel items={items} loading={loading}>
        {children}
      </Carousel>
    </Container>
  );
}

export default SectionTemplate;