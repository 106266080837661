import { Space } from 'antd';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { device } from '../../../theme';

export const RootContainer = styled.div`
padding: 40px 60px;
min-height: calc(100vh - 190px);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media ${device.laptop} {
  padding: 40px 30px;
}

@media ${device.tablet} {
  padding: 30px 20px;
}
`;

export const Heading = styled.div`
font-style: normal;
font-weight: 700;
font-size: 25px;
color: ${({ theme }) => theme.fontColor};
text-align: center;

@media ${device.laptop} {
  font-size: 20px;
}

@media ${device.tablet} {
  font-size: 15px;
}
`;

export const Icon = styled.img`
width: 60px;
height: auto;
object-fit: contain;

@media ${device.laptop} {
  width: 50px;
}

@media ${device.tablet} {
  width: 40px;
}
`;

export const Box = styled(Space).attrs({
  size: 50,
})`
margin-top: 50px;
width: 100%;
justify-content: center;
`;

export const SwapArrow = styled.div`
width: 40px;
height: 25px;
display:grid;

  &:before,
  &:after {
    content: "";
    grid-area: 1/1;
    background: currentColor;
    clip-path: polygon(0 10px,calc(100% - 15px) 10px,calc(100% - 15px) 0,100% 50%,calc(100% - 15px) 100%,calc(100% - 15px) calc(100% - 10px),0 calc(100% - 10px));
    animation: a4 .4s infinite alternate;
  }

  &:after {
    --s:-1;
  }
  @keyframes a4 {
    0%  {transform: scale(var(--s,1)) translate(12px,-6px) translate(4px)}
    100%{transform: scale(var(--s,1)) translate(12px,-6px) translate(-4px)}
  }

@media ${device.laptop} {
  width: 30px;
  height: 15px;
}
`;