import React from 'react';
import { Skeleton as AntSkeleton } from 'antd';
import {
  Card,
} from './SkeletonCard.elements';
import { GlobalStyledSkeleton as StyledSkeleton, } from '../../theme/styles';
import { FREEBIE_LK, LATEST_LK, LIVESTREAM_LK, PREMIUM_LK } from '../../constants';

const SkeletonCard = ({ type, }) => {
  const rows = () => {
    switch (type) {
      case PREMIUM_LK:
        return 5;
      case LIVESTREAM_LK:
        return 3;
      default:
        return 1;
    }
  }
  return (
    <Card
      premium={type === PREMIUM_LK}
      transparent={type === LATEST_LK || type === FREEBIE_LK}
      cover={
        <StyledSkeleton loading avatar active />
      }
    >
      <AntSkeleton loading active paragraph={{ rows: rows() }} />
    </Card>
  );
}

export default SkeletonCard;