import styled from "styled-components";
import {
  Skeleton as AntSkeleton,
  Modal as AntModal,
  Badge,
} from "antd";
import orginizerIcon from "../../assets/images/icon_orginizer.png";
import playbackTimeIcon from "../../assets/images/icon_playbacktime.png";
import {
  ErrorText,
  GlobalButton,
  GlobalInputBox,
  Spinner as GlobalSpinner,
} from "../../theme/styles";
import { Link as RLink } from "react-router-dom";
import { device } from "../../theme";

export const RootContainer = styled.div`
  padding: 0 60px 60px 60px;

@media ${device.laptop} {
padding: 0 30px 60px 30px;
}

@media ${device.mobileL} {
padding: 0 10px 60px 10px;
}
`;

export const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 50px;

  @media ${device.laptop} {
    margin-top: 50px;
} 

 @media ${device.mobileL} {
  margin-top: 30px;
}
`;

export const VideoThumb = styled.img`
display: ${props => props.show ? 'block' : 'none'};
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  cursor: pointer;
`;

export const Video = styled.iframe`
width: 100%;
aspect-ratio: 16 / 9;

  html {
    width: 100% !important;
    overflow: hidden !important;
  }
`;

export const Actions = styled.div`
  padding: 35px 0;

@media ${device.laptop} {
  padding: 25px 0;
}

@media ${device.mobileL} {
  padding: 15px 0;
}
`;

export const ActionsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
position: relative;

  div,
  svg {
    margin-left: 20px;

    @media screen and (max-width: 460px) {
      margin-left: 10px;
    }
  }
`;

export const BuyTicketBox = styled.div`
margin-left: 0 !important;

  div {
    margin-left: 0 !important;
  }
`;

export const SoldOutLabel = styled.div`
display: ${props => props.show ? 'block' : 'none'};
position: absolute;
top: -25px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 2;
color: #EA4435;

@media ${device.mobileL} {
  top: -17px;
  font-size: 10px;
}
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;

@media ${device.tablet} {
  flex-direction: column;
}
`;

export const Information = styled.div`
  padding-right: 20px;
  flex: 7;
`;

export const Poster = styled.div`
display: ${props => props.show ? 'block' : 'none'};
flex: 3;

@media ${device.tablet} {
  padding: 20px 20% 0 20%;
}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 3 / 4;
`;

export const DateTime = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.secondaryColor};

@media ${device.laptop} {
  font-size: 20px;
}

@media ${device.tablet} {
  font-size: 16px;
}

@media ${device.mobileL} {
  font-size: 12px;
}
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: ${({ theme }) => theme.fontColor};

@media ${device.laptop} {
  font-size: 25px;
}

@media ${device.tablet} {
  font-size: 20px;
}

@media ${device.mobileL} {
  font-size: 15px;
}
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.fontColor};
  padding-top: 15px;
  padding-bottom: 20px;

@media ${device.laptop} {
  font-size: 16px;
}

@media ${device.tablet} {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media ${device.mobileL} {
  font-size: 12px;
}
`;

export const Organizer = styled.div`
  padding-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const Typography = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};

@media ${device.laptop} {
  font-size: 14px;
}

@media ${device.tablet} {
  font-size: 12px;
}

`;

export const TextContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.fontColor};
  word-break: break-all;

@media ${device.laptop} {
  font-size: 13px;
}

@media ${device.tablet} {
  font-size: 12px;
}
`;

export const Icon = styled.img.attrs({
  src: orginizerIcon,
  alt: "picon",
})`
  width: 18px;
  margin-right: 15px;
`;

export const PlaybackTime = styled.div`
  padding-bottom: 5px;
  display: ${props => props.show ? 'flex' : 'none'};
  align-items: center;
`;

export const PlaybackIcon = styled.img.attrs({
  src: playbackTimeIcon,
  alt: "picon",
})`
  width: 16px;
  margin-right: 16px;
`;

export const EventInfo = styled.div`
display: ${props => props.show ? 'block' : 'none'};
position: absolute;
top: 0;
width: 100%;
height: 100%;
text-align: center;
background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
/* backdrop-filter: blur(10px); */
`;

export const InfoWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100%;
`;

export const Text = styled.div`
font-style: normal;
font-weight: 400;
font-size: 25px;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 20px;
}

@media ${device.tablet} {
  font-size: 15px;
}

@media ${device.mobileL} {
  font-size: 13px;
}
`;

export const Date = styled.div`
font-style: normal;
font-weight: 500;
font-size: 70px;
text-align: center;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 50px;
}

@media ${device.tablet} {
  font-size: 30px;
}

@media ${device.mobileL} {
  font-size: 20px;
}
`;

export const VideoSkeleton = styled(AntSkeleton)`
 span {
  width: 100% !important;
  padding-top: 56.25% !important;  /* 16:9 Aspect Ratio */
  border-radius: 0 !important;
 }
 .ant-skeleton-header {
  padding: 0 !important;
 }
 .ant-skeleton-content {
   display: none !important;
 }
`;

export const Spinner = styled(GlobalSpinner)`
display: ${props => props.show ? 'block' : 'none'};
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`;

export const Modal = styled(AntModal)`
  .ant-modal-content {
    background: #201C21;
    border: 1px solid #7851A9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  svg {
    color: #fff;
  }
`;

export const ModalTitle = styled.div`
padding-top: 30px;
font-style: normal;
font-weight: 700;
font-size: 18px;
text-align: center;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 16px;
}

@media ${device.tablet} {
  font-size: 15px;
}

@media ${device.mobileL} {
  font-size: 14px;
}
`;

export const ModalForm = styled.div`
padding: 30px 80px;

@media ${device.laptop} {
  padding: 30px 50px;
}

@media ${device.mobileL} {
  padding: 30px 0px;
}

`;

export const Input = styled(GlobalInputBox)`
color: #fff;
`;

export const ModalButton = styled(GlobalButton)`
width: 100%;
margin-top: 20px;
`;

export const Error = styled(ErrorText)`
text-align: center;
font-style: normal;
font-weight: normal;
font-size: 14px;

@media ${device.laptop} {
  font-size: 13px;
}

@media ${device.tablet} {
  font-size: 12px;
}

@media ${device.mobileL} {
  font-size: 11px;
}
`;

export const LinkWrapper = styled.div`
width: 100%;
text-align: right;
`;

export const Link = styled(RLink)`
display: inline;
font-style: normal;
font-weight: normal;
font-size: 14px;
text-decoration: underline;
color: #fff;
cursor: pointer;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }

@media ${device.laptop} {
  font-size: 13px;
}

@media ${device.tablet} {
  font-size: 12px;
}
`;

export const WarningContainer = styled.div`
text-align: center;
`;

export const WarningTitle = styled(ModalTitle)`
color: ${props => props.theme.secondaryColor};
text-transform: uppercase;
`;

export const WarningInfo = styled.div`
text-align: center;
color: ${props => props.theme.secondaryColor};
padding: 20px 0;
`;

export const WarningButton = styled(ModalButton)`
width: 80px;
`;

export const LiveIconWrapper = styled.div`
position: absolute;
top: 5%;
left: 5%;

@media ${device.laptop} {
  top: 5%;
}

@media ${device.tablet} {
  top: 7%;
}

@media ${device.mobileL} {
  top: 8%;
}
`;

export const LiveText = styled.span`
color: red;
font-size: 15px;
font-weight: bold;
letter-spacing: 1.5px;
padding-left: 10px;

@media ${device.laptop} {
  font-size: 13px;
}

@media ${device.tablet} {
  font-size: 12px;
}

@media ${device.mobileL} {
  font-size: 11px;
}
`;

export const LiveIcon = styled(Badge)`
  .ant-badge-status-dot {
    width: 15px !important;
    height: 15px !important;
  }
  .ant-badge-status-text{
    display: none;
  }
`;