import {
  Avatar,
  Image
} from "antd";
import styled from "styled-components";
import { device } from "../../../theme";
import { GlobalButton } from "../../../theme/styles";

export const Container = styled.div`
  width: 100%;
  height: 169px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(
      92.16deg,
      ${(props) => props.theme.primaryColor} 1.07%,
      ${(props) => props.theme.primaryColor} 27.39%,
      #fdd829 77.29%,
      ${(props) => props.theme.secondaryColor} 98.91%
    );

  @media ${device.laptop} {
    height: 169px;
  }

  @media ${device.mobileL} {
    height: 100%;
  }
`;

export const ProfileAvatar = styled(Avatar)`
  cursor: pointer;

  div {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${device.mobileL} {
    margin-top: 10px;
  }
`;

export const EditImage = styled(Image)`
   width: 100%;
   height: 100%;
   object-fit: contain;
`;

export const Title = styled.div`
  padding-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #201c21;
  text-align: center;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 13px;
    padding-bottom: 20px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const ProfileButton = styled(GlobalButton)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  min-width: 135px;
  font-size: 16px;

  @media ${device.laptop} {
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 14px;
  }

  @media ${device.mobileL} {
    position: relative;
    bottom: 10px;
    right: 0px;
    min-width: 100px;
    height: 40px !important;
    font-size: 12px;
  }
`;
