import React, { useEffect, useState } from 'react';
import { useQRCode } from 'react-qrcode';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../../i18n';
import {
    Button,
    Image,
    ImageWrapper,
    RootContainer, SubTitle, Text, Title,
} from './PaymentQR.elements';

const PaymentQR = ({
    qrCode,
    expiry,
    paymentName,
}) => {
    let timer = null;
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    }

    const saveImage = () => {
        let imgSrc = document.getElementById('payment-qr-img').src;
        let a = document.createElement('a');
        a.href = imgSrc;
        a.download = `${translate('common.appName')}-${paymentName}-QR.png`;
        a.click();
    }

    return (
        <RootContainer>
            <Title>
                {translate('ticketing.qrPageTitle').replace('!@#$%', paymentName)}
            </Title>
            <SubTitle>
                {translate('ticketing.qrPageSubTitle')}
            </SubTitle>
            <ImageWrapper>
                <Image
                    id='payment-qr-img'
                    alt='qrimg'
                    src={useQRCode(qrCode)}
                />
            </ImageWrapper>
            <Text>
                {translate('ticketing.qrExpiryMessage').replace('!@#$%', expiry)}
            </Text>
            <Text onClick={saveImage}>
                <u>{translate('ticketing.saveToGallery')}</u>
            </Text>
            <Button onClick={handleClick}>
                {translate('ticketing.done')}
            </Button>
        </RootContainer>
    );
};

export default PaymentQR;