import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
    PaymentQRComponent,
} from '../../components';

const PaymentQRPage = () => {
    const { state } = useLocation();
    const {
        qrCode,
        expiry,
        paymentName,
    } = state || {};

    if (!state) {
        return <Navigate to='/' />;
    }

    return (
        <PaymentQRComponent
            qrCode={qrCode}
            expiry={expiry}
            paymentName={paymentName}
        />
    );
};

export default PaymentQRPage;