import React from 'react';
import {
  Table,
  TCell,
  TRow,
} from './InvoicesList.elements';
import { translate } from '../../i18n';
import { FREE_SK, NEW_SK } from '../../constants';
import { getFormattedPrice } from '../../utils';

const Invoice = ({
  price,
  totalAmount,
  netAmount,
  processingFee,
  ticketList,
  paymentType,
  processingFeePercent,
}) => {
  return (
    <Table>
      <TRow>
        <TCell left>
          {translate('ticketing.ticketCode')}
        </TCell>
        <TCell center>
          {translate('ticketing.status')}
        </TCell>
        <TCell right>
          {translate('ticketing.amount')}
        </TCell>
      </TRow>
      <TRow>
        {ticketList.map(ticket => (
          <>
            <TCell>
              {ticket.ticketCode}
            </TCell>
            <TCell center>
              {ticket.ticketStatus === NEW_SK
                ? translate('ticketing.new')
                : translate('ticketing.used')}
            </TCell>
            <TCell right>
              {`${getFormattedPrice(price)} ${translate('common.mmk')}`}
            </TCell>
          </>
        ))}
      </TRow>
      <TRow>
        <TCell>{translate('ticketing.totalAmount')}</TCell>
        <TCell />
        <TCell right>
          {`${getFormattedPrice(totalAmount)} ${translate('common.mmk')}`}
        </TCell>
      </TRow>
    </Table>
  );
}

export default Invoice;