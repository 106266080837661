import React, { useState } from 'react';
import { Col, message, Row } from 'antd';
import {
    BottomBox,
    Box,
    Button,
    ButtonSpace,
    CancelButton,
    Data,
    Date,
    EndDate,
    Heading,
    Image,
    InfoSection,
    Label,
    LabelData,
    LeftCol,
    Radio,
    RadioContainer,
    RootContainer,
    Container,
    Space,
    Text,
    ThumbImage,
    Title,
    LeftColumn,
    RightColumn,
    ImageWrapper,
    Spinner,
    SpinnerContainer,
    SubTitle,
    WarningContainer,
    WarningTitle,
    WarningInfo,
    WarningButton,
    Modal,
} from './BillPayment.elements';
import {
    EVENT_SK,
    SERVER_ERROR_CODES,
} from '../../../constants';
import { formattedDate, getFormattedDateTime, getFormattedPrice } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { api, Status } from '../../../api';
import { translate } from '../../../i18n';

export const Loading = () => {
    return (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>

    );
}

const BillPayment = ({
    image,
    total,
    payments,
    apiStatus,
    datetime,
    endtime,
    title,
    ticketCount,
    entityType,
    price,
    ticketInfoIdentifier,
    entityIdentifier,
    customerBillingInfoIdentifier,
}) => {
    const navigate = useNavigate();
    const [selectedPayment, setSelectedPayment] = useState(payments[0]);
    const [billpaymentApiStatus, setBillPaymentApiStatus] = useState(Status.DEFAULT);
    const [visible, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handlePaymentChange = (e) => {
        setSelectedPayment(e.target.value);
    }

    const checkIsButtonDisabled = () => {
        return !selectedPayment
    }

    const confirm = () => {
        if (billpaymentApiStatus === Status.LOADING) {
            return;
        }
        setBillPaymentApiStatus(Status.LOADING);
        api
            .confirmPayment({
                customerBillingInfoIdentifier,
                ticketInfoIdentifier,
                paymentIdentifier: selectedPayment.paymentIdentifier,
                price,
                ticketCount,
                amount: selectedPayment.amount,
                processingFeeAmount: selectedPayment.processingFee,
                totalAmount: selectedPayment.total,
            })
            .then(({ userResponse }) => {
                setBillPaymentApiStatus(Status.SUCCESS);
                handleSuccess(userResponse);
            })
            .catch((error) => {
                setBillPaymentApiStatus(Status.ERROR);
                if (error.name !== SERVER_ERROR_CODES.invalidPaymentUser) {
                    message.error(error.message);
                    return;
                }
                setVisible(true);
                setErrorMessage(error.message);
            });
    }

    const handleSuccess = (userResponse) => {
        const { redirectUrl, qrCode, } = userResponse;
        if (qrCode) {
            navigate('/ticket-purchase/bill-payment/qr', {
                state: {
                    qrCode,
                    expiry: selectedPayment.expiry,
                    paymentName: selectedPayment.type,
                }
            });
        }
        else if (redirectUrl) {
            redirectToExternalUrl(redirectUrl);
        }
        else {
            navigate('/ticket-purchase/bill-payment/pin', {
                state: {
                    paymentName: selectedPayment.type,
                }
            });
        }
    }

    const redirectToExternalUrl = (url) => {
        if (!url) {
            return;
        }
        window.location.href = url;
    }

    const renderLeft = () => {
        return <Radio.Group
            value={selectedPayment}
            onChange={handlePaymentChange}
        >
            {apiStatus === Status.SUCCESS && <RadioContainer>
                {payments?.wallet?.map((item, key) => (
                    <Space>
                        <Image
                            alt='icon'
                            src={item.imageUrl}
                        />
                        <Radio
                            key={key}
                            value={item}
                        >
                            <Text>{item.name}</Text>
                        </Radio>
                    </Space>
                ))}
                {apiStatus === Status.SUCCESS &&
                    <SubTitle>
                        {translate('ticketing.moreOptions')}
                    </SubTitle>}

                {payments?.card?.map((item, key) => (
                    <Space>
                        <Image
                            alt='icon'
                            src={item.imageUrl}
                        />
                        <Radio
                            key={key}
                            value={item}
                        >
                            <Text>{item.type}</Text>
                        </Radio>
                    </Space>
                ))}
            </RadioContainer>}
        </Radio.Group>
    }

    const renderRight = () => {
        return <Box>
            <ImageWrapper>
                <ThumbImage alt='img' src={image} />
            </ImageWrapper>
            <InfoSection>
                <Title>
                    {title}
                </Title>
                {entityType === EVENT_SK &&
                    <Date>
                        {getFormattedDateTime(datetime)}
                    </Date>
                }
                <EndDate>
                    {`Valid Until : ${formattedDate(endtime)}`}
                </EndDate>
                <Data>
                    {`${translate('ticketing.totalTickets')} : ${ticketCount}`}
                </Data>
                <Data>
                    <Label>{translate('ticketing.total')} :</Label>
                    <LabelData>{` ${getFormattedPrice(total)} MMK`}</LabelData>
                </Data>
            </InfoSection>
        </Box>
    }

    const cancel = () => {
        navigate(-1);
    }

    const Alert = () => {
        return (
            <Modal
                visible={visible}
                centered
                footer={false}
                onCancel={() => setVisible(false)}
                closable={false}
            >
                <WarningContainer>
                    <WarningTitle>
                        Sorry!
                    </WarningTitle>
                    <WarningInfo>
                        {errorMessage}
                    </WarningInfo>
                    <WarningButton onClick={() => setVisible(false)}>
                        OK
                    </WarningButton>
                </WarningContainer>
            </Modal>
        );
    };

    return (
        <>
            <RootContainer>
                <Heading>
                    {translate('ticketing.selectPayment')}
                </Heading>

                <Container>
                    <LeftColumn>
                        <Row justify='center'>
                            <Col xs={0} md={6} />
                            <LeftCol xs={24} md={12} >{renderLeft()}</LeftCol>
                            <Col xs={0} md={6} />
                        </Row>
                    </LeftColumn>
                    <RightColumn>
                        <Row>
                            <Col xs={0} lg={3} />
                            <Col xs={24} lg={18}>{renderRight()}</Col>
                            <Col xs={0} lg={3} />
                        </Row>
                    </RightColumn>
                </Container>

                <BottomBox>
                    <ButtonSpace size='large'>
                        <CancelButton onClick={cancel}>
                            {translate('ticketing.back')}
                        </CancelButton>
                        <Button
                            onClick={confirm}
                            disabled={checkIsButtonDisabled()}
                            loading={billpaymentApiStatus === Status.LOADING}
                        >
                            {translate('ticketing.confirmPayment')}
                        </Button>
                    </ButtonSpace>
                </BottomBox>

            </RootContainer>
            <Alert />
        </>
    );
}

export default BillPayment;