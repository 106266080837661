import {
  DARK_THEME_LK,
  LOCAL_STORAGE_KEYS,
} from "../constants";

/**
 * Change app theme
 *
 * @param {string} theme - one of "dark"|"light"
 */
export function changeTheme(theme) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.userPreferedTheme, theme);
}

/**
 * Reset theme to dark
 */
export function resetTheme() {
  const theme = DARK_THEME_LK;
  localStorage.setItem(LOCAL_STORAGE_KEYS.userPreferedTheme, theme);
}

/**
 *  get current user prefered theme
 * @return { dark|light}
 */
export function getCurrentTheme() {
  let theme = localStorage.getItem(LOCAL_STORAGE_KEYS.userPreferedTheme);
  if (theme === null || theme === undefined) {
    theme = DARK_THEME_LK;
  }
  return theme;
}