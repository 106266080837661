/**
 * Maps server error code
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  noErrorCodeFromServer: 910381,
  internalServerError: '1010',
  invalidVenueError: '1065',
  invalidEventError: '1066',
  concurrentVideoStreamsError: '1162',
  sessionExpired: '1000',
  invalidPaymentUser: '1207',
};
