import React, { useState } from 'react';
import { translate } from '../../i18n';
import { ToggleContainer, ToggleDivider, ToggleEamil, ToggleEamilContainer, TogglePhone } from './ToggleView.elements';

const ToggleView = ({
    isEmail,
    setIsEmail,
    resetForm = () => { }
}) => {
    const onChange = (email) => {
        setIsEmail(email);
        resetForm();
    }
    return (
        <ToggleContainer>
            <TogglePhone onClick={() => onChange(false)} isEmail={isEmail}>{translate('common.phoneNumber')}</TogglePhone>
            <ToggleEamilContainer>
                <ToggleDivider type="vertical" />
                <ToggleEamil onClick={() => onChange(true)} isEmail={isEmail}>{translate('common.email')}</ToggleEamil>
            </ToggleEamilContainer>
        </ToggleContainer >
    );
}

export default ToggleView;