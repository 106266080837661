import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api, Status } from '../../api';
import { PrivacyPolicyComponent } from '../../components';
import { ENGLISH_LANGUAGE_CODE_LK } from '../../constants';
import { getCurrentLocale } from '../../i18n';

const PrivacyPolicy = () => {
    const status = useSelector(({ app }) => app.status);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (status === Status.SUCCESS) {
            api.fetchPrivacyPolicy()
                .then(({ userResponse: { privacyPolicy, privacyPolicyMy } }) => {
                    let contentElement = document.getElementById('pp-id');
                    if (contentElement) {
                        contentElement.innerHTML =
                            getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK
                                ? privacyPolicy
                                : privacyPolicyMy
                    }
                    setLoading(false);
                }
                )
                .catch((error) => {

                })
        }
    }, [status]);
    return (
        <PrivacyPolicyComponent loading={loading} />
    );
}

export default PrivacyPolicy;