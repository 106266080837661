import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { translate } from '../../../i18n';
import MyTicketsTab from '../MyTicketsTab/MyTicketsTab';
import TicketsTab from '../TicketsTab/TicketsTab';
import WatchListTab from '../WatchListTab/WatchListTab';
import {
  RootContainer,
  Tabs,
} from './MainProfile.elements';
const { TabPane } = Tabs;

const Profile = () => {
  const { pathname } = useLocation();
  const [activeKey, setActiveKey] = useState();

  useEffect(() => {
    let key;
    if (pathname === '/user-profile/mytickets') {
      key = '0'
    }
    else if (pathname === '/user-profile/tickets') {
      key = '1'
    }
    else {
      key = '2'
    }
    setActiveKey(key)
  }, [pathname]);

  return (
    <RootContainer>
      <Tabs centered activeKey={activeKey}>
        <TabPane
          tab={
            <Link to='/user-profile/mytickets'>
              {translate('userProfile.myTickets')}
            </Link>
          }
          key='0'
        >
          <MyTicketsTab />
        </TabPane>
        <TabPane
          tab={
            <Link to='/user-profile/tickets'>
              {translate('userProfile.purchasedTickets')}
            </Link>
          }
          key='1'
        >
          <TicketsTab />
        </TabPane>
        <TabPane
          tab={
            <Link to='/user-profile/watchlater'>
              {translate('userProfile.watchLater')}
            </Link>
          }
          key='2'
        >
          <WatchListTab />
        </TabPane>
      </Tabs>
    </RootContainer>
  );
}

export default Profile;