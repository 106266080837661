import styled from 'styled-components';
import { device } from '../../theme';
import { Spinner as GlobalSpinner, } from "../../theme/styles";

export const Container = styled.div`
  padding: 30px 70px;
  min-height: calc(100vh - 190px);

  @media ${device.laptop} {
    padding: 30px 70px;
  }

  @media ${device.mobileL} {
    padding: 30px;
  }

  @media ${device.mobileS} {
    padding: 30px;
  }
`;
export const Spinner = styled(GlobalSpinner).attrs({
  size: 'large'
})`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  color: ${props => props.theme.fontColor};
  border-left: 5px solid ${props => props.theme.primaryColor};
  padding-left: 15px;
  margin-bottom: 15px;

  @media ${device.laptop} {
    font-size: 22px;
  }

  @media ${device.mobileL} {
    font-size: 18px;
  }

  @media ${device.mobileS} {
    font-size: 16px;
  }
`;

export const TextContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.fontColor};

  @media ${device.laptop} {
    font-size: 13px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;