import styled from "styled-components";
import {
  device,
  ListContainer as ListContainerGlobal,
} from "../../theme";

export const RootContainer = styled.div`
  min-height: calc(100vh - 190px);
`;

export const ListContainer = styled(ListContainerGlobal)`
padding-top: 30px;

@media ${device.mobileL} {
padding-top: 10px;
}
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
  height: auto;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 5 / 1;
  object-fit: cover;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 75px;
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

@media ${device.laptop} {
  font-size: 30px;
}

@media ${device.mobileL} {
  font-size: 20px;
}
`;

export const Text = styled.div`
  color: ${(props) => props.theme.fontColor};
  font-size: 28px;
  justify-content: center;
  text-align: center;
  padding-top: 30px;

  @media ${device.laptop} {
    font-size: 25px;
  }

  @media ${device.mobileL} {
    font-size: 20px;
  }
`;

export const Loader = styled.div`
  text-align: center;
`;