import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import ShowExpiredModal from '../ShowExpiredModal/ShowExpiredModal';

const Link = ({
    to,
    mode,
    children,
    prevented = false,
    ...state
}) => {
    const [visible, setVisible] = useState(false);

    const handleClick = e => {
        if (!prevented) {
            return;
        }
        e.preventDefault();
        setVisible(true);
    }

    return (
        <>
            <ReactLink
                to={to}
                state={state}
                onClick={handleClick}
            >
                {children}
            </ReactLink>
            <ShowExpiredModal
                visible={visible}
                handleClose={() => setVisible(false)}
            />
        </>
    );
}

export default Link;