/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, message, Row } from 'antd';
import OtpImage from '../../../assets/images/otpbg.jpg';
import logo from '../../../assets/images/main-logo.png';
import {
    Image,
    OtpButton,
    OtpCodeInfo,
    OtpContainer,
    OtpHeading,
    OtpImageWrapper,
    OtpInfo,
    OtpInputContainer,
    ResendCode,
    ResendCodeLink,
} from './Otp.elements';
import {
    AuthGlobalIcon,
    ErrorText,
    GlobalLogo,
    Spinner
} from '../../../theme/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { REGISTRATION_SK, } from '../../../constants';
import { translate } from '../../../i18n';
import { connect } from 'react-redux';
import {
    verifyOtp,
    resendOtp,
    resetOtpVerificationState,
} from '../../../store/actions';
import { Status } from '../../../api';
import { publicIpv4 } from 'public-ip';

const Otp = ({
    /**
   * Tells about the status of the verify otp api call,
   *
   * if verifyOtpStatus === Status.DEFAULT => api hasn't been hit yet
   * if verifyOtpStatus === Status.LOADING => api is currently being executed
   * if verifyOtpStatus === Status.SUCCESS => success response from api
   * if verifyOtpStatus === Status.ERROR   => error response from api
   *
   * @source: redux
   */
    verifyOtpStatus,

    verifyOtpErrorMessage,

    /**
   * redux action to make verify otp api request
   *
   * @source: redux
   */
    verifyOtp: _verifyOtp,

    /**
   * Tells about the status of the resend otp api call
   *
   * @source: redux
   */
    resendOtp: _resendOtp,

    /**
   * When user hit back button, from OtpVerificationScreen,
   * reset the state associated with it, in redux
   *
   * @source: redux
   */
    resetOtpVerificationState: _resetOtpVerificationState,
    resendOtpStatus,
    resendOtpErrorMessage,
}) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { otpType, signupId } = state || {};
    const [OTP, setOTP] = useState("");
    const [ipAddress, setIpAddress] = useState('');

    useEffect(() => {
        getIpaddress();
    }, []);

    useEffect(() => {
        return () => {
            // componentWillUnmount
            _resetOtpVerificationState();
        };
    }, []);

    useEffect(() => {
        if (verifyOtpStatus === Status.SUCCESS) {
            navigate(otpType === REGISTRATION_SK
                ? '/interest'
                : '/change-password', { state: { otp: OTP } })
        }
    }, [verifyOtpStatus]);

    useEffect(() => {
        if (resendOtpStatus === Status.SUCCESS) {
            message.success({
                content: 'Please Check resend OTP code',
            });
        }
    }, [resendOtpStatus]);

    const getIpaddress = async () => {
        const ip = await publicIpv4();
        setIpAddress(ip);
    };

    const verify = () => {
        _verifyOtp(OTP, otpType, ipAddress);
    }

    const handleResendOtp = () => {
        _resendOtp(otpType);
    }

    return (
        <>
            <GlobalLogo to='/'>
                <AuthGlobalIcon src={logo} alt="" />
            </GlobalLogo>
            <OtpImageWrapper>
                <Image src={OtpImage} alt="" />

                <Row>
                    <Col xs={0} md={7} lg={12} />
                    <Col xs={24} md={16} lg={10}>
                        <OtpContainer>
                            <OtpHeading>{translate('otpVerification.title')} </OtpHeading>

                            <OtpInfo>
                                {translate('otpVerification.subtitle').replace('!@#$%', signupId)}
                            </OtpInfo>

                            <OtpCodeInfo>
                                {translate('otpVerification.inputLabel')}
                            </OtpCodeInfo>

                            <ErrorText>
                                {verifyOtpStatus === Status.ERROR && verifyOtpErrorMessage}
                            </ErrorText>
                            <ErrorText>
                                {resendOtpStatus === Status.ERROR && resendOtpErrorMessage}
                            </ErrorText>

                            <OtpInputContainer
                                value={OTP}
                                onChange={setOTP}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                            />

                            <OtpButton htmlType="submit" onClick={verify} loading={verifyOtpStatus === Status.LOADING}>
                                {verifyOtpStatus !== Status.LOADING && translate('otpVerification.buttonLabel')}
                            </OtpButton>

                            <ResendCode>
                                {translate('otpVerification.notReceivedLabel')} &nbsp;
                                <ResendCodeLink onClick={handleResendOtp}                                >
                                    {resendOtpStatus !== Status.LOADING
                                        ? translate('otpVerification.resendCode')
                                        : <Spinner size="small" />
                                    }
                                </ResendCodeLink>
                            </ResendCode>

                        </OtpContainer>
                    </Col>
                    <Col xs={0} md={1} lg={2} />
                </Row>

            </OtpImageWrapper>
        </>

    );
}

const mapStateToProps = ({ auth }) => {
    const {
        verifyOtpStatus,
        verifyOtpErrorMessage,
        resendOtpStatus,
        resendOtpErrorMessage
    } = auth;

    return {
        verifyOtpStatus,
        verifyOtpErrorMessage,
        resendOtpStatus,
        resendOtpErrorMessage
    }
}

export default connect(mapStateToProps, {
    verifyOtp,
    resendOtp,
    resetOtpVerificationState,
})(Otp);