import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PaymentConfirmComponent } from '../../components';

const PaymentConfirm = () => {
  const { state } = useLocation();
  const { paymentImage } = state || {};

  if (!state) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <PaymentConfirmComponent
        paymentImage={paymentImage}
      />
    </>
  );
}

export default PaymentConfirm;