/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { api, Status } from '../../api';
import {
  BillingInfoComponent,
} from '../../components';

const BillingInfo = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    image,
    amount,
    datetime,
    endtime,
    title,
    ticketCount,
    price,
    ticketInfoIdentifier,
    entityType,
    entityIdentifier,
  } = state || {};

  const status = useSelector(({ app }) => app.status);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [list, setList] = useState({});

  useEffect(() => {
    if (status === Status.SUCCESS) {
      fetchCustomerBillingInfos();
    }
  }, [status]);

  const fetchCustomerBillingInfos = () => {
    setApiStatus(Status.LOADING);
    api
      .fetchCustomerBillingInfos()
      .then(({ userResponse }) => {
        setApiStatus(Status.SUCCESS);
        setList(userResponse);
      })
      .catch(() => {
        setApiStatus(Status.ERROR);
      });
  }

  if (!state) {
    return <Navigate to='/' />
  }

  return (
    <BillingInfoComponent
      image={image}
      apiStatus={apiStatus}
      billingInfoList={list}
      total={amount}
      datetime={datetime}
      endtime={endtime}
      title={title}
      ticketCount={ticketCount}
      entityType={entityType}
      price={price}
      ticketInfoIdentifier={ticketInfoIdentifier}
      entityIdentifier={entityIdentifier}
    />
  );
}

export default BillingInfo;