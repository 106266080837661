import React from 'react';
import { Popover } from './Dropdown.elements';

const Dropdown = ({
  placement = 'bottomRight',
  trigger = 'hover',
  content,
  children,
  ...rest
}) => {
  return (
    <Popover
      placement={placement}
      trigger={trigger}
      content={content}
      {...rest}
    >
      {children}
    </Popover>
  );
}

export default Dropdown;