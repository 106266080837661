import styled from 'styled-components';
import { device } from '../../theme';

export const Container = styled.div`
padding: 0 40px 50px 60px;
position: relative;
background-color: ${({ theme, filled }) => filled ? theme.primaryColor : 'transparent'};

@media ${device.laptop} {
  padding: 0 20px 30px 35px;
}

@media ${device.mobileL} {
  padding: 0 5px 30px 20px;
}
`;

export const Title = styled.div`
padding: 20px 0;
font-style: normal;
font-weight: 700;
font-size: 18px;
color: ${({ theme }) => theme.fontColor};

@media ${device.laptop} {
  padding: 10px 0;
  font-size: 16px;
}

@media ${device.mobileL} {
  padding: 10px 0;
  font-size: 14px;
}
`;

export const SeeAllButton = styled.div`
display: ${({ explore, loading }) => explore || loading ? 'none' : 'block'};
float: right;
margin-right: 20px;
font-style: normal;
font-weight: 400;
font-size: 16px;
color: ${({ theme }) => theme.fontColor};
cursor: pointer;

@media ${device.laptop} {
  margin-right: 15px;
  font-size: 14px;
}

@media ${device.mobileL} {
  margin-right: 15px;
  font-size: 12px;
}
`;