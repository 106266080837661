import styled from "styled-components";
import { Input as AntInput } from "antd";
import { GlobalButton } from "../../../theme/styles";
import { device } from "../../../theme";

export const RootContainer = styled.div`
  padding: 40px 60px;
  min-height: calc(100vh - 190px);

  @media ${device.laptop} {
    padding: 30px 20px;
  }

  @media ${device.mobileL} {
    padding: 20px;
  }

  @media ${device.mobileS} {
    padding: 20px 10px;
  }
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: ${({ theme }) => theme.fontColor};
  text-align: center;

  @media ${device.laptop} {
    font-size: 22px;
  }

  @media ${device.mobileL} {
    font-size: 19px;
  }

  @media ${device.mobileS} {
    font-size: 16px;
  }
`;

export const DetailsContainer = styled.div`
  padding-top: 20px;
`;

export const Label = styled.div`
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
    padding-bottom: 5px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  border-radius: 5px;

  @media ${device.mobileL} {
    padding: 0 5%;
  }
`;

export const Date = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.secondaryColor};
  line-height: 1;

  @media ${device.laptop} {
    font-size: 13px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const EndDate = styled(Date)`
  padding-top: 15px;
  color: ${(props) => props.theme.fontColor};

  @media ${device.mobileL} {
    padding-bottom: 15px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  padding: 9px 0;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const Price = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  line-height: 30px;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  line-height: 1;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }

  span,
  input {
    text-align: center;
    background: transparent !important;
    border: none;
  }
`;

export const InputLabel = styled.div``;

export const InputWrapper = styled.div`
  width: 120px;
  max-width: 120px;
  margin-top: 15px;
  border: 1px solid #bebfcf !important;
  box-sizing: border-box;
  border-radius: 5px;

  @media ${device.laptop} {
    width: 120px;
  }

  @media ${device.mobileL} {
    width: 100px;
  }

  @media ${device.mobileS} {
    width: 100px;
  }
`;

export const Text = styled.div`
  color: #ff0000;
  font-size: 13px;
  padding-top: 15px;
  line-height: normal;

  @media ${device.mobileL} {
      font-size: 12px;
      padding-top: 10px;
    }
`;

export const Input = styled(AntInput)`
  input {
    padding: 0;
    margin-left: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.fontColor};
    text-align: center;

    @media ${device.laptop} {
      font-size: 14px;
      margin-left: 0px;
    }

    @media ${device.mobileL} {
      font-size: 12px;
    }
  }
`;

const StepButton = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  cursor: pointer;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const UpBtn = styled(StepButton)`
  &::after {
    content: "+";
  }
`;

export const DownBtn = styled(StepButton)`
  &::after {
    content: "-";
  }
`;

export const TotalText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  line-height: 1;
  text-align: center;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const TotalPrice = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  line-height: 60px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 50px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const BottomBox = styled.div`
  padding-top: 30px;
  display: flex;
  align-items: center;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const AdditionalInfo = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.fontColor};

  @media ${device.laptop} {
    font-size: 13px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
    padding-bottom: 20px;
  }
`;

export const Button = styled(GlobalButton)`
  min-width: 175px;
  border: 1px solid #ffffff;

  @media ${device.laptop} {
    min-width: 150px;
  }

  @media ${device.mobileL} {
    min-width: 140px;
    height: 40px !important;
  }

  @media ${device.mobileS} {
    min-width: 120px;
  }

  &:hover {
    border: 1px solid #ffffff;
  }
`;

export const CancelButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.fontColor};
  color: ${(props) => props.theme.fontColor};
  background: transparent;

  &:hover {
    background: linear-gradient(
      94.11deg,
      ${(props) => props.theme.primaryColor} 0.54%,
      ${(props) => props.theme.primaryColor} 98.6%
    );
  }
`;
