/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getBuildConfig } from '../../config/buildConfig';
import { ACTIVE_SK, GOOGLE_SK, LOCAL_STORAGE_KEYS, REGISTRATION_SK } from '../../constants';
import { translate } from '../../i18n';
import { SocialContainer, SocialImg } from '../../theme/styles';
import {
    socialLogin,
    login,
} from '../../store/actions';
import SocialButton from './SocialButton';
import {
    ImgSection,
    LabelSection
} from './SocialLogin.elements';
import { useNavigate } from 'react-router-dom';
import { Status } from '../../api';
import { publicIpv4 } from 'public-ip';

const GOOGLE_CLIENT_ID = getBuildConfig().googleClientId;
const FACEBOOK_APP_ID = getBuildConfig().facebookAppId;
const SocialLogin = ({
    image,
    provider,
    socialLogin: _socialLogin,
    login: _login,
    /**
  * Tells about the status of the login api call,
  *
  * if status === Status.DEFAULT => api hasn't been hit yet
  * if status === Status.LOADING => api is currently being executed
  * if status === Status.SUCCESS => success response from api
  * if status === Status.ERROR   => error response from api
  *
  * @source: redux
  */
    loginStatus,
    loginErrorMessage,
    firstTimeLogin
}) => {

    const navigate = useNavigate();
    const [ipAddress, setIpAddress] = useState('');

    useEffect(() => {
        getIpaddress();
    }, []);

    useEffect(() => {
        if (loginStatus === Status.SUCCESS) {
            navigate(firstTimeLogin
                ? '/interest'
                : '/', { state: { firstTimeLogin } }
            );
        }
    }, [loginStatus, firstTimeLogin]);

    const getIpaddress = async () => {
        const ip = await publicIpv4();
        setIpAddress(ip);
    };

    const handleLoginSuccess = (user) => {
        const { idToken, accessToken } = user._token || {};
        _socialLogin(provider, provider === GOOGLE_SK ? idToken : accessToken, ipAddress);
    };

    const handleSocialLoginFailure = (err) => {
        console.error(err);
    };
    return (
        <SocialButton
            provider={provider}
            appId={provider === GOOGLE_SK
                ? GOOGLE_CLIENT_ID
                : FACEBOOK_APP_ID
            }
            onLoginSuccess={handleLoginSuccess}
            onLoginFailure={handleSocialLoginFailure}
        >
            <SocialContainer>
                <ImgSection>
                    <SocialImg src={image} alt='' />
                </ImgSection>
                <LabelSection>
                    {provider === GOOGLE_SK
                        ? translate('loginForm.gmailButtonLabel')
                        : translate('loginForm.facebookButtonLabel')
                    }
                </LabelSection>

            </SocialContainer>
        </SocialButton>
    );
}

const mapStateToProps = ({ auth }) => {
    const {
        loginStatus,
        loginErrorMessage,
        firstTimeLogin,
    } = auth;
    return {
        loginStatus,
        loginErrorMessage,
        firstTimeLogin
    }
}

export default connect(mapStateToProps, {
    login,
    socialLogin,
})(SocialLogin);