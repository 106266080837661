import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Container,
    Image,
    SubTitle,
    Title,
} from './PaymentStatus.elements';
import { translate } from '../../../i18n';
import successImg from '../../../assets/images/img_payment_success.png';
import failedImg from '../../../assets/images/img_payment_failed.png';
import { Spinner } from '../../../theme';
import { ANDROID_SK, IOS_SK, PORTAL_SK } from '../../../constants';

const PaymentStatus = ({
    status,
    platform,
    loading,
}) => {
    const navigate = useNavigate();

    const successInfo = {
        image: successImg,
        title: translate('ticketing.paymentSuccessTitle'),
        subTitle: translate('ticketing.paymentSuccessSubTitle'),
        buttonLabel: translate('ticketing.paymentSuccessButtonLabel'),
    };

    const failedInfo = {
        image: failedImg,
        title: translate('ticketing.paymentFailedTitle'),
        subTitle: translate('ticketing.paymentFailedSubTitle'),
        buttonLabel: translate('ticketing.paymentFailedButtonLabel'),
    };

    const info = status === 'success' ? successInfo : failedInfo;

    const handleButtonClick = () => {
        if (!platform) {
            return;
        }
        if (platform === PORTAL_SK) {
            status === 'success'
                ? navigate('/user-profile/tickets')
                : navigate('/');
        }
        else if (platform === IOS_SK || platform === ANDROID_SK) {
            // send message to native app
            // https://github.com/react-native-webview/react-native-webview/blob/master/docs/Guide.md#communicating-between-js-and-native
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(status);
            }
        }
    }

    return (
        <Container>
            <Image alt='img' src={info.image} />
            <Title>
                {info.title}
            </Title>
            <SubTitle>
                {info.subTitle}
            </SubTitle>
            <Button onClick={handleButtonClick}>
                {loading
                    ? <Spinner />
                    : info.buttonLabel
                }
            </Button>
        </Container>
    );
}

export default PaymentStatus;