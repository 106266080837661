const PRIMARY_COLOR = '#A87EE2';
const SECONDARY_COLOR = '#FA8F22';
const ALT_PRIMARY_COLOR = '#7851A9';
const BLACK = '#201C21';
const WHITE = '#fff';
const THIN_COLOR = '#201C21';
const GRAY_COLOR = '#BEBFCF';

const lightTheme = {
  primaryColor: PRIMARY_COLOR,
  secondaryColor: SECONDARY_COLOR,
  altPrimaryColor: ALT_PRIMARY_COLOR,
  body: WHITE,
  fontColor: BLACK,
  thinFontColor: THIN_COLOR,
  grayFontColor: GRAY_COLOR,
  lightFontColor: BLACK,
  grayBoxColor: 'rgba(54,48,55,0.5)',
  boxShadow: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  dividerColor: '#BEBFCF',
}

export default lightTheme;