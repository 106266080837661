import styled from "styled-components";
import { GlobalSkeletonCard, ListContainer } from "../../theme/styles";

export const SkeletonContainer = styled(ListContainer)`
margin-top: -40px;
`;

export const SkeletonCard = styled(GlobalSkeletonCard)`
border-radius: 5px;
filter: ${({ theme }) => theme.boxShadow};

.ant-card-cover {
  aspect-ratio: 16 / 9;
}
`;
