const LOCAL_STORAGE_KEYS = {
  /**
   * session assigned after a successful login
   */
  sessionId: 'sessionId',
  /**
  * device id assigned after a successful login
  */
  deviceId: 'deviceId',
  /**
  * device name assigned after a successful login
  */
  deviceName: 'deviceName',
  /**
   * Unique id that distinguishes user
   */
  userIdentifier: 'userIdentifier',
  loginType: 'loginType',
  userName: 'userName',
  userStatus: 'userStatus',
  /**
   * User preferred theme, default theme is dark
   */
  userPreferedTheme: 'userPreferedTheme',
  /**
   * User preferred language, default lan is en
   */
  userPreferedLangage: 'userPreferedLangage',
};

export default LOCAL_STORAGE_KEYS;
