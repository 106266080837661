import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { GuestInfoSection } from '../../common';
import {
  BannerDiscover as Banner,
  List,
} from '../../components';
import { api, Status } from '../../api';
import { EVENT_SK, LIMITS } from '../../constants';

const Discover = () => {
  const { pathname, state, } = useLocation();
  const { categoryIdentifier, bannerImageUrl } = state || {};
  const { categoryName } = useParams();
  const status = useSelector(({ app }) => app.status);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (status === Status.SUCCESS) {
      resetState();
      fetchData();
    }
  }, [status, pathname]);

  useEffect(() => {
    page !== 1 && fetchData(page);
  }, [page]);

  const fetchData = (page = 1) => {
    setApiStatus(Status.LOADING);
    api
      .fetchVideoList({
        categoryIdentifier,
        page
      })
      .then(({ userResponse: newList }) => {
        setApiStatus(Status.SUCCESS);
        let _list = page === 1 ? newList : [...list, ...newList];
        setList(_list);
        setHasMore(newList.length >= LIMITS.ITEMS_PER_PAGE);
      })
      .catch(() => {
        setApiStatus(Status.ERROR);
      });
  }

  const resetState = () => {
    setPage(1);
    setList([]);
    setHasMore(true);
  }

  if (!state) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <Banner
        image={bannerImageUrl}
        title={categoryName}
      />
      <List
        list={list}
        hasMore={hasMore}
        next={() => apiStatus === Status.SUCCESS && setPage(prevState => prevState + 1)}
        status={apiStatus}
      />
      <GuestInfoSection />
    </>
  );
}

export default Discover;