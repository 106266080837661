import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  LatestFreebieCard,
  LivestreamCard,
  PremiumCard,
  Skeleton,
} from '../../common';
import { translate } from '../../i18n';
import {
  FilterIcon,
  ListColumn,
  ListItem,
  FilterLabel as Label,
  Filter,
} from '../../theme/styles';
import {
  TopSection,
  Title,
  Filters,
  ListContainer,
  EndMessage,
  Select,
  Wrapper,
  FilterLabel,
  Text,
  RootContainer,
} from './SearchResult.elements';
import {
  FREEBIE_LK,
  DISCOVER_MODE_LK,
  TODAY_SK,
  THIS_WEEK_SK,
  THIS_MONTH_SK,
  THIS_YEAR_SK,
  PREMIUM_SK,
  FREEBIE_SK,
  EVENT_SK,
  UNSAVE_SK,
  NEWEST_SK,
  POPULARITY_SK,
  LIVE_SK,
} from '../../constants';
import {
  Col,
  Row,
} from 'antd';
import { getFormattedPrice } from '../../utils';
const { Option } = Select;

const timeframeOptions = [
  {
    key: TODAY_SK,
    name: translate('searchResult.today'),
  },
  {
    key: THIS_WEEK_SK,
    name: translate('searchResult.thisWeek'),
  },
  {
    key: THIS_MONTH_SK,
    name: translate('searchResult.thisMonth'),
  },
  {
    key: THIS_YEAR_SK,
    name: translate('searchResult.thisYear'),
  },
];

const typeOptions = [
  {
    key: PREMIUM_SK,
    name: translate('searchResult.premium'),
  },
  {
    key: LIVE_SK,
    name: translate('searchResult.livestream'),
  },
  {
    key: FREEBIE_SK,
    name: translate('searchResult.freebie'),
  },
];

const sortByOptions = [
  {
    key: NEWEST_SK,
    name: translate('searchResult.newest'),
  },
  {
    key: POPULARITY_SK,
    name: translate('searchResult.popularity'),
  },
];

const FilterItem = ({
  value,
  label,
  placeholder,
  options,
  handleChange,
}) => {
  return (
    <Col
      xs={12}
      md={8}
    >
      <FilterLabel>{label}</FilterLabel>
      <Select
        value={value}
        dropdownClassName='search-result-filter-dropdown'
        placeholder={placeholder}
        onChange={(value) => handleChange(value)}
      >
        <Option value={0}>
          <Text>{placeholder}</Text>
        </Option>
        {options.map(option => (
          <Option
            key={option.key}
            value={option.key}
          >
            {option.name}
          </Option>
        ))}
      </Select>
    </Col>
  );
}

const SearchResult = ({
  noResult,
  list,
  hasMore,
  timeFrame,
  type,
  sortBy,
  next,
  setTimeFrame,
  setType,
  setSortBy,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const loader = <Skeleton />;

  const endMessage = (
    <EndMessage>
      {translate('searchResult.noMoreResults')}
    </EndMessage>
  );

  const renderTopSection = () => {
    return (
      <TopSection>
        <Wrapper>
          <Title>
            {`${translate('searchResult.allResults')} (${list.length})`}
          </Title>
          <Filter
            hide={noResult}
            onClick={() => setShowFilters(!showFilters)}>
            <FilterIcon active={showFilters} />
            <Label>
              {translate('searchResult.filters')}
            </Label>
          </Filter>
        </Wrapper>
        <Filters showFilters={showFilters && !noResult}>
          <Row gutter={[20, 0]}>
            <FilterItem
              value={timeFrame}
              label={translate('searchResult.timeFrame')}
              placeholder={translate('searchResult.selectTimeFrame')}
              options={timeframeOptions}
              handleChange={setTimeFrame}
            />
            <FilterItem
              value={type}
              label={translate('searchResult.type')}
              placeholder={translate('searchResult.selectType')}
              options={typeOptions}
              handleChange={setType}
            />
            <FilterItem
              value={sortBy}
              label={translate('searchResult.sortBy')}
              placeholder={translate('searchResult.selectSortBy')}
              options={sortByOptions}
              handleChange={setSortBy}
            />
          </Row>
        </Filters>
      </TopSection>
    );
  }

  const renderItem = (item) => {
    switch (item.type) {
      case EVENT_SK:
        return <LivestreamCard
          mode={DISCOVER_MODE_LK}
          identifier={item.entityIdentifier}
          entityType={item.entityType}
          image={item.thumbUrl}
          broadcastStartDateTime={item.broadcastStartDateTime}
          broadcastEndDateTime={item.broadcastEndDateTime}
          description={item.title}
          price={translate('videoDetails.buyButtonLabel').replace('!@#$%', getFormattedPrice(item.price))}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          link={item.copyLinkUrl}
        />
      case PREMIUM_SK:
        return <PremiumCard
          identifier={item.entityIdentifier}
          entityType={item.entityType}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          description={item.shortTitle}
          price={item.price}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          mode={DISCOVER_MODE_LK}
          link={item.copyLinkUrl}
        />
      case FREEBIE_SK:
        return <LatestFreebieCard
          type={FREEBIE_LK}
          entityType={item.entityType}
          identifier={item.entityIdentifier}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          price={item.price}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          mode={DISCOVER_MODE_LK}
          link={item.copyLinkUrl}
        />
      default:
        break;
    }
  }

  return (
    <RootContainer>
      {renderTopSection()}
      <InfiniteScroll
        dataLength={list.length}
        next={next}
        hasMore={hasMore}
        loader={loader}
        endMessage={endMessage}
      >
        <ListContainer>
          {list.map((item, key) => (
            <ListColumn key={key}>
              <ListItem>
                {renderItem(item)}
              </ListItem>
            </ListColumn>
          ))}
        </ListContainer>
      </InfiniteScroll>
    </RootContainer>
  );
}

export default SearchResult;