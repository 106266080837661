import styled, { css } from 'styled-components';
import { LATEST_LK } from '../../constants';
import { device } from '../../theme';
import { IconFavGray, IconShare } from '../../theme/styles';

const DefaultStyle = css`
font-style: normal;
font-weight: ${({ type }) => type === LATEST_LK ? 'normal' : '700'};
font-size: ${({ type }) => type === LATEST_LK ? '16px' : '18px'};
color: ${(props) => props.theme.secondaryColor};

@media ${device.laptop} {
  font-size: ${({ type }) => type === LATEST_LK ? '14px' : '14px'};
}

@media ${device.tablet} {
  font-size: ${({ type }) => type === LATEST_LK ? '10px' : '12px'};
}

@media ${device.mobileM} {
  font-size: ${({ type }) => type === LATEST_LK ? '9px' : '9px'};
}
`;

const TransparentStyle = css`
font-style: normal;
font-weight: 400;
font-size: 16px;
color: ${({ theme }) => theme.grayFontColor};

@media ${device.laptop} {
  font-size: 14px;
}

@media ${device.tablet} {
  font-size: 10px;
}

@media ${device.mobileL}{
  font-size: 9px;
}
`;

export const ActionOne = styled.div`
flex: 1;
${({ transparent }) => transparent ? TransparentStyle : DefaultStyle}
height: 10px;
`;

export const ActionTwo = styled(IconFavGray)`
margin-right: 10px;

@media ${device.tablet} {
  margin-right: 5px;
}
`;

export const ActionThree = styled(IconShare)``;

export const Actions = styled.div`
display: flex;
flex-direction: row;
align-items: center;
position: absolute;
bottom: 0;
padding: ${props => props.defaultMode ? '20px 15px 20px 0' : '20px 15px 30px 15px'};
width: 100%;

  ${ActionTwo}, ${ActionThree} {
    display: ${({ defaultMode }) => defaultMode ? 'none' : 'block'};
  }

@media only screen and (max-width: 1024px) and (min-width: 768px){
  padding: ${props => props.defaultMode ? '20px 15px 30px 0' : '20px 5px 20px 5px'};
} 

@media ${device.tablet} {
  flex-direction: column;
  align-items: flex-start;
  padding: ${props => props.defaultMode ? '25px 15px 20px 0' : '20px 5px 20px 5px'};
}
`;

export const ActionsIcon = styled.div`
display: flex;
align-items: center;
justify-content: center;

@media ${device.tablet} {
  align-self: flex-end;
}
`;