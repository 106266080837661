import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { api, Status } from '../../api';
import { GuestInfoSection } from '../../common';
import {
  SearchResultList,
} from '../../components';
import { EVENT_SK, LIMITS, } from '../../constants';
import { isNonEmptyString } from '../../utils';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const SearchResult = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const status = useSelector(({ app }) => app.status);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [hasMore, setHasMore] = useState(true);
  const [timeFrame, setTimeFrame] = useState(0);
  const [type, setType] = useState(0);
  const [sortBy, setSortBy] = useState(0);
  const [noResult, setNoResult] = useState(true);

  useEffect(() => {
    if (!isNonEmptyString(query.get('keyword'))) {
      navigate('/');
      return;
    }
    if (status === Status.SUCCESS) {
      resetState();
      search(0, 0, 0);
    }
  }, [query.get('keyword'), status]);

  useEffect(() => {
    page !== 1 && search(timeFrame, type, sortBy, page);
  }, [page]);

  useEffect(() => {
    if (apiStatus === Status.SUCCESS) {
      setPage(1);
      search(timeFrame, type, sortBy);
    }
  }, [timeFrame, type, sortBy]);

  useEffect(() => {
    if (list.length > 0) {
      setNoResult(false);
    }
    else if (page === 1 && !timeFrame && !type && !sortBy) {
      setNoResult(true);
    }
  }, [list]);

  const search = (timeFrame, type, sortBy, page = 1) => {
    setApiStatus(Status.LOADING);
    const keyword = query.get('keyword');
    api
      .fetchSearchList({
        keyword,
        page,
        timeFrame,
        type,
        sortBy,
      })
      .then(({ userResponse: newList }) => {
        setApiStatus(Status.SUCCESS);
        newList.map(item => {
          if (item.entityType === EVENT_SK) {
            item.type = item.entityType;
          }
          else {
            item.type = item.privacy;
          }
        });
        const _list = page === 1 ? [...newList] : [...list, ...newList];
        setList(_list);
        setHasMore(newList.length >= LIMITS.ITEMS_PER_PAGE);
      })
      .catch(() => {
        setApiStatus(Status.ERROR);
      });
  }

  const resetState = () => {
    setList([]);
    setHasMore(true);
    setPage(1);
    setTimeFrame(0);
    setType(0);
    setSortBy(0);
    setNoResult(true);
  }

  return (
    <>
      <SearchResultList
        noResult={noResult}
        list={list}
        hasMore={hasMore}
        timeFrame={timeFrame}
        type={type}
        sortBy={sortBy}
        next={() => setPage(prevState => prevState + 1)}
        setTimeFrame={setTimeFrame}
        setType={setType}
        setSortBy={setSortBy}
      />
      <GuestInfoSection />
    </>
  );
}

export default SearchResult;