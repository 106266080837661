import styled from 'styled-components';
import { device } from '../../../theme';
import { GlobalButton } from '../../../theme/styles';

export const Container = styled.div`
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const Image = styled.img`
width: 200px;
height: auto;
object-fit: contain;
aspect-ratio: 1 / 1;

@media ${device.laptop} {
  width: 150px;
}

@media ${device.tablet} {
  width: 100px;
}
`;

export const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 30px;
text-align: center;
color: ${props => props.theme.fontColor};

@media ${device.laptop} {
  font-size: 25px;
}

@media ${device.tablet} {
  font-size: 20px;
}
`;

export const SubTitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 18px;
text-align: center;
color: ${props => props.theme.fontColor};
padding-bottom: 25px;

@media ${device.laptop} {
  font-size: 16px;
}

@media ${device.tablet} {
  font-size: 14px;
}
`;

export const Button = styled(GlobalButton)`
min-width: 400px;

@media ${device.tablet} {
  min-width: 90%;
}
`;