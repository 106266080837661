export const SAVE_SK = 'save';
export const UNSAVE_SK = 'unsave';
export const FACEBOOK_SK = 'facebook';
export const GOOGLE_SK = 'google';
export const PUBLISHED_SK = 'published';
//============================================================================
//======================= Server Entity Types ================================
//============================================================================
export const POSTER_SK = 'poster';
export const VENUE_SK = 'venue';
export const EVENT_SK = 'event';
export const VIDEO_SK = 'video';
//============================================================================
//======================= Types of media items ===============================
//============================================================================
export const LIVESTREAM_LK = 'LIVESTREAM';
export const LATEST_LK = 'LATEST';
export const PREMIUM_LK = 'PREMIUM';
export const FREEBIE_LK = 'FREE TO ENJOY';
export const POSTER_LK = 'POSTER';
//============================================================================
//======================= Types of pages =====================================
//============================================================================
export const HOME_MODE_LK = 'HOME';
export const DETAILS_MODE_LK = 'DETAILS';
export const DISCOVER_MODE_LK = 'DISCOVER';
export const SEARCH_MODE_LK = 'SEARCH';
export const LISTING_MODE_LK = `LISTING`;
export const PROFILE_MODE_LK = 'PROFILE';
export const INTEREST_MODE_LK = 'INTEREST';

//============================================================================
//======================= Types of filters ===================================
//============================================================================
export const TODAY_SK = 'today';
export const THIS_WEEK_SK = 'thisWeek';
export const THIS_MONTH_SK = 'thisMonth';
export const THIS_YEAR_SK = 'thisYear';
export const FREEBIE_SK = 'free to enjoy';
export const PREMIUM_SK = 'premium';
export const LIVE_SK = 'live';
export const NEWEST_SK = 'newest';
export const POPULARITY_SK = 'popularity';
//============================================================================
//======================= Types of Payment ===================================
//============================================================================
export const KBZPAY_SK = 'kbzpay';
export const CBPAY_SK = 'cbpay';
export const UNKNOWN_SK = 'unknownpay';
export const UNKNOWN1_SK = 'unknown1pay';
export const WAVEPAY_SK = 'wavepay';
export const OK$_SK = 'okdollar';
//============================================================================
//======================= Language constants =================================
//============================================================================
export const ENGLISH_LANGUAGE_CODE_LK = 'en';
export const BURMESE_LANGUAGE_CODE_LK = 'my';
//============================================================================
//======================= Theme constants =================================
//============================================================================
export const DARK_THEME_LK = 'dark';
export const LIGHT_THEME_LK = 'light';
//============================================================================
//======================= Authentication =====================================
//============================================================================
export const REGISTRATION_SK = 'registration';
export const RESET_PASSWORD_SK = 'passwordReset';
//============================================================================
//=========================== userStatus =====================================
//============================================================================
export const ACTIVE_SK = 'active';
export const INACTIVE_SK = 'inactive';
//============================================================================
//=========================== Ticket Status ==================================
//============================================================================
export const USED_SK = 'used';
export const NEW_SK = 'new';
export const SHOW_SK = 'show';
export const EXPIRED_SK = 'expired';
//============================================================================
//=============================== Payment Type ===============================
//============================================================================
export const FREE_SK = 'FREE';
export const QR_SK = 'QR';
export const PIN_SK = 'PIN';
export const PWA_SK = 'PWA';
export const OTP_SK = 'OTP';
//============================================================================
//=============================== Platform Origin ============================
//============================================================================
export const PORTAL_SK = 'portal';
export const IOS_SK = 'ios';
export const ANDROID_SK = 'android';