import styled from "styled-components";
import { device } from '../../../theme';
import {
  Image as AntImage,
  Skeleton as AntSkeleton,
} from "antd";

export const BottomBar = styled.div`
  display: none;
  width: 100%;
  height: 215px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(32, 28, 33, 0) 0%,
    rgba(32, 28, 33, 0.6275) 39.96%,
    #201c21 89.3%
  );

@media ${device.laptop} {
  display: block;
}
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
  position: relative;
  &:hover {
    ${BottomBar} {
      display: block;
    }
  }

  .ant-image {
    width: 100%;
  }
`;

export const Image = styled(AntImage)`
  width: 100%;
  aspect-ratio: 72 / 25;
  object-fit: cover;
`;

export const SeeAllButton = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  padding-right: 15px;

@media ${device.laptop} {
  font-size: 12px;
  padding-right: 5px;
}
`;

export const BottomImage = styled.img`
  width: 160px;
  height: auto;
  object-fit: cover;

@media ${device.laptop} {
  width: 100px;
}

@media ${device.tablet} {
  width: 80px;
}
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

@media ${device.laptop} {
  bottom: 10px;
}

@media ${device.mobileL} {
  right: 10px;
  bottom: 5px;
}
`;

export const Skeleton = styled(AntSkeleton)`
  span {
    width: 100% !important;
    padding-top: 34.72% !important; /* 72:25 Aspect Ratio */
    border-radius: 0 !important;
  }
  .ant-skeleton-header {
    padding: 0 !important;
  }
  .ant-skeleton-content {
    display: none !important;
  }
`;
