import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { GuestInfoSection } from '../../common';
import { Listing as ListingComponent } from '../../components/';
import { FREEBIE_LK, LATEST_LK, LIVESTREAM_LK, PREMIUM_LK } from '../../constants';

const Listing = () => {
  const { type } = useParams();

  if (
    type.toUpperCase() !== LIVESTREAM_LK &&
    type.toUpperCase() !== LATEST_LK &&
    type.toUpperCase() !== PREMIUM_LK &&
    type.toUpperCase() !== FREEBIE_LK
  ) {
    return <Navigate to='/404' />;
  }

  return (
    <>
      <ListingComponent type={type.toUpperCase()} />
      <GuestInfoSection />
    </>
  )
}

export default Listing;