import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Image,
  ImageWrapper,
  Info,
  RootContainer,
  Text,
} from './PaymentPIN.elements';
import image from '../../../assets/images/img_payment_info.png';
import { translate } from '../../../i18n';

const PaymentPIN = ({
  paymentName
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  }

  return (
    <RootContainer>
      <ImageWrapper>
        <Image alt='img' src={image} />
      </ImageWrapper>
      <Info>
        <Text>
          {translate('ticketing.paymentInfoOne').replaceAll('!@#$%', paymentName)}
        </Text>
        <Text>
          {translate('ticketing.paymentInfoTwo')}
        </Text>
        <Text>
          {translate('ticketing.paymentInfoThree').replace('!@#$%', translate('common.appName'))}
        </Text>
      </Info>
      <Button onClick={handleClick}>
        {translate('ticketing.done')}
      </Button>
    </RootContainer>
  );
}

export default PaymentPIN;