import React from 'react';
import {
  Button,
  GeneralText,
  GeneralImage as Image,
  RootContainer,
} from './ErrorHandler.elements';
import { translate } from '../../i18n';
import Img from '../../assets/images/img_payment_failed.png';

const GeneralError = () => {
  const retry = () => {
    window.location.reload();
  }

  return (
    <RootContainer>
      <Image src={Img} />
      <GeneralText>
        {translate('generalError.text')}
      </GeneralText>
      <Button onClick={retry}>
        {translate('generalError.buttonLabel')}
      </Button>
    </RootContainer>
  );
}

export default GeneralError;