import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, GlobalStyles } from './theme';
import {
  ChangePasswordPage,
  DiscoverPage,
  ForgetPasswordPage,
  HomePage,
  InterestPage,
  ListingPage,
  LoginPage,
  OtpVerificationPage,
  SearchResultPage,
  SignupPage,
  VideoDetailsPage,
  UserProfilePage,
  NotFoundPage,
  TicketPurchasePage,
  BillPaymentPage,
  PaymentConfirmPage,
  EditProfilePage,
  PosterListingPage,
  ContactPage,
  TermsAndConditonsPage,
  AboutUsPage,
  PrivacyPolicyPage,
  PaymentStatusPage,
  InvoicesListPage,
  TicketListPage,
  BillingInfoPage,
  PaymentQRPage,
  PaymentPINPage,
} from './pages';
import { Layout } from './common';
import { ScrollToTop } from './utils';
import { initializeApp } from './store/actions';
import { LIGHT_THEME_LK } from './constants';
import { ErrorHandler } from './components';

const App = ({ initializeApp: _initializeApp }) => {
  const theme = useSelector(({ app }) => app.theme);

  useEffect(() => {
    /**
     * Responsible for setting up one time configuration
     * needed by app
     */
    _initializeApp();
  }, []);

  return (
    <ThemeProvider theme={theme === LIGHT_THEME_LK ? lightTheme : darkTheme}>
      <Router>
        <ScrollToTop />
        <GlobalStyles />
        <ErrorHandler>
          <Routes>
            <Route
              exact
              path="/"
              element={<Layout component={HomePage} title="Home" />}
            />
            <Route
              exact
              path="/details/:type/:id"
              element={
                <Layout component={VideoDetailsPage} title="Show Details" />
              }
            />
            <Route
              exact
              path="/discover/:categoryName"
              element={<Layout component={DiscoverPage} title="Discover" />}
            />
            <Route
              exact
              path="/list/:type"
              element={<Layout component={ListingPage} title="List" />}
            />
            <Route
              exact
              path="/list/poster"
              element={<Layout component={PosterListingPage} title="List" />}
            />
            <Route
              exact
              path="/list/invoice"
              element={<Layout component={InvoicesListPage} title="List" />}
            />
            <Route
              exact
              path="/list/ticket"
              element={<Layout component={TicketListPage} title="List" />}
            />
            <Route
              exact
              path="/search"
              element={<Layout component={SearchResultPage} title="Search" />}
            />
            <Route
              exact
              path="/contact-us"
              element={<Layout component={ContactPage} title="Contact Us" />}
            />
            <Route
              exact
              path="/about-us"
              element={<Layout component={AboutUsPage} title="About Us" />}
            />
            <Route
              exact
              path="/privacy-policy"
              element={
                <Layout component={PrivacyPolicyPage} title="Privacy Policy" />
              }
            />
            <Route
              exact
              path="/terms-conditions"
              element={
                <Layout
                  component={TermsAndConditonsPage}
                  title="Terms Conditons"
                />
              }
            />
            <Route
              exact
              path="/user-profile/mytickets"
              element={
                <Layout component={UserProfilePage} title="My Tickets" />
              }
            />
            <Route
              exact
              path="/user-profile/tickets"
              element={
                <Layout component={UserProfilePage} title="Purchased Tickets" />
              }
            />
            <Route
              exact
              path="/user-profile/watchlater"
              element={
                <Layout component={UserProfilePage} title="Watch Later" />
              }
            />
            <Route
              exact
              path="/ticket-purchase"
              element={
                <Layout
                  component={TicketPurchasePage}
                  title="Ticket Purchase"
                />
              }
            />
            <Route
              exact
              path="/ticket-purchase/billing-information"
              element={
                <Layout component={BillingInfoPage} title="Ticket Purchase" />
              }
            />
            <Route
              exact
              path="/ticket-purchase/bill-payment"
              element={
                <Layout component={BillPaymentPage} title="Ticket Purchase" />
              }
            />
            <Route
              exact
              path="/ticket-purchase/bill-payment/confirm"
              element={
                <Layout
                  component={PaymentConfirmPage}
                  title="Ticket Purchase"
                />
              }
            />
            <Route
              exact
              path="/ticket-purchase/bill-payment/:status"
              element={<PaymentStatusPage title="Ticket Purchase" />}
            />
            <Route
              exact
              path="/ticket-purchase/bill-payment/qr"
              element={
                <Layout component={PaymentQRPage} title="Ticket Purchase" />
              }
            />
            <Route
              exact
              path="/ticket-purchase/bill-payment/pin"
              element={
                <Layout component={PaymentPINPage} title="Ticket Purchase" />
              }
            />
            <Route
              exact
              path="/edit-profile/profile"
              element={
                <Layout component={EditProfilePage} title="Edit Profile" />
              }
            />
            <Route
              exact
              path="/edit-profile/settings"
              element={
                <Layout component={EditProfilePage} title="Edit Profile" />
              }
            />
            <Route exact path="/login" element={<LoginPage title="Login" />} />
            <Route
              exact
              path="/sign-up"
              element={<SignupPage title="Sign Up" />}
            />
            <Route
              exact
              path="/forget-password"
              element={<ForgetPasswordPage title="Forget Password" />}
            />
            <Route
              exact
              path="/change-password"
              element={<ChangePasswordPage title="Change Password" />}
            />
            <Route
              exact
              path="/otp-verify"
              element={<OtpVerificationPage title="Otp Verify" />}
            />
            <Route
              exact
              path="/interest"
              element={<InterestPage title="Interest" />}
            />
            <Route
              exact
              path="/404"
              element={<Layout component={NotFoundPage} title="Not Found" />}
            />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </ErrorHandler>
      </Router>
    </ThemeProvider>
  );
};

export default connect(null, {
  initializeApp,
})(App);
