import styled from "styled-components";
import { device, GlobalButton } from "../../../theme";

export const RootContainer = styled.div`
min-height: calc(100vh - 190px);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 60px 25%;

@media ${device.tablet} {
  padding: 60px 5%;
}
`;

export const ImageWrapper = styled.div``;

export const Image = styled.img`
width: auto;
height: 200px;
`;

export const Info = styled.ol`
width: 100%;
padding: 30px 18%;
text-align: left;

@media ${device.tablet} {
  padding: 20px 5%;
}
`;

export const Text = styled.li`
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: normal;
color: ${props => props.theme.fontColor};
padding: 10px;
`;

export const Button = styled(GlobalButton)`
width: 100%;
max-width: 400px;
`;