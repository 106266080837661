import React from 'react';
import { translate } from '../../i18n';
import {
    Container,
    Modal,
    Title,
    Text,
    Button,
} from './ShowExpiredModal.elements';

const ShowExpiredModal = ({
    visible,
    handleClose,
}) => {
    return (
        <Modal
            visible={visible}
            footer={false}
            centered
            destroyOnClose
            onCancel={handleClose}
        >
            <Container>
                <Title>
                    {translate('userProfile.showExpiredTitle')}
                </Title>
                <Text>
                    {translate('userProfile.showExpiredMessage')}
                </Text>
                <Button onClick={handleClose}>
                    {translate('common.ok')}
                </Button>
            </Container>
        </Modal>
    );
}

export default ShowExpiredModal;