import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api, Status } from '../../api';
import {
  TermsAndConditionsComponent
} from '../../components';
import { ENGLISH_LANGUAGE_CODE_LK } from '../../constants';
import { getCurrentLocale } from '../../i18n';


const TermsAndConditons = () => {
  const status = useSelector(({ app }) => app.status);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (status === Status.SUCCESS) {
      api.fetchPrivacyPolicy()
        .then(({ userResponse: { termsAndConditions, termsAndConditionsMy } }) => {
          let contentElement = document.getElementById('tc-id');
          if (contentElement) {
            contentElement.innerHTML =
              getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK
                ? termsAndConditions
                : termsAndConditionsMy
          }
          setLoading(false);
        }
        )
        .catch((error) => {

        })
    }
  }, [status]);

  return (
    <TermsAndConditionsComponent loading={loading} />
  );
}

export default TermsAndConditons;