import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  SectionTemplate,
  LivestreamCard,
} from '../../../common';
import {
  DETAILS_MODE_LK,
  EVENT_SK,
  LIVESTREAM_LK,
  UNSAVE_SK,
} from '../../../constants';
import Skeleton from '../Skeleton/Skeleton';
import { translate } from '../../../i18n';
import { api, Status } from '../../../api';
import EmptyImg from '../../../assets/images/img_mock_livestream.png';
import {
  EmptyCardContainer,
  EmptyImage,
  Text,
} from './Livestream.elements';
import { getFormattedPrice } from '../../../utils';

const EmptyCard = () => {
  return (
    <EmptyCardContainer>
      <EmptyImage src={EmptyImg} alt='eimg' />
      <Text>Coming Soon</Text>
    </EmptyCardContainer>
  );
}

const Livestream = ({
  mode,
}) => {
  const status = useSelector(({ app }) => app.status);
  const [loading, setLoading] = useState(true);
  const [livestreamList, setLivestreamList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    status === Status.SUCCESS &&
      fetchLivestreamList();
  }, [status]);

  const fetchLivestreamList = () => {
    api
      .fetchLivestreamList()
      .then(({ userResponse: _list }) => {
        setTotalCount(_list.length);
        // if list is less than 3, show empty design cards
        while (_list.length < 3) {
          _list.push(null);
        }
        setLivestreamList(_list);
        setLoading(false);
      });
  }

  if (loading) {
    return (
      <Skeleton
        type={LIVESTREAM_LK}
        title={translate('common.livestream')}
        mode={mode}
      />
    );
  }

  return (
    <SectionTemplate
      filled={mode !== DETAILS_MODE_LK}
      title={translate('common.livestream')}
      items={3}
      type={LIVESTREAM_LK}
      loading={totalCount < 3}
    >
      {livestreamList.map((item, key) => (
        !item
          ? <EmptyCard key={key} />
          : <LivestreamCard
            key={key}
            mode={mode}
            identifier={item.entityIdentifier}
            entityType={EVENT_SK}
            image={item.thumbUrl}
            broadcastStartDateTime={item.broadcastStartDateTime}
            broadcastEndDateTime={item.broadcastEndDateTime}
            description={item.title}
            price={translate('videoDetails.buyButtonLabel').replace('!@#$%', getFormattedPrice(item.price))}
            isSaved={item.isSaved === UNSAVE_SK}
            favouriteIdentifier={item.favouriteIdentifier}
            link={item.copyLinkUrl}
          />
      ))}
    </SectionTemplate>
  );
}

export default Livestream;