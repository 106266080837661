import styled from 'styled-components';
import {
  Skeleton as AntSkeleton,
} from 'antd';
import { device } from '../../../theme';

export const Skeleton = styled(AntSkeleton)`

 span {
   width: 100% !important; 
   height: 100% !important;
   border-radius: 5px !important;
   padding-top: 133.33% !important;
 }

 .ant-skeleton-content {
   display: none !important;
 }
`;