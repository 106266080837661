import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  LatestFreebieCard,
  PremiumCard
} from '../../common';
import { DISCOVER_MODE_LK, EVENT_SK, FREEBIE_LK, FREEBIE_SK, LATEST_LK, PREMIUM_SK, UNSAVE_SK, VENUE_SK } from '../../constants';
import {
  ListColumn,
  ListItem,
  Spinner
} from '../../theme/styles';
import { ListContainer, Loader, RootContainer, Text } from './Discover.elements';
import { translate } from '../../i18n';
import { isEmpty } from 'lodash';
import { Status } from '../../api';

const List = ({
  list,
  hasMore,
  next,
  status
}) => {

  const loader = (
    <Loader>
      <Spinner />
    </Loader>
  );

  const renderItem = (item) => {
    switch (item.privacy) {
      case PREMIUM_SK:
        return <PremiumCard
          identifier={item.entityIdentifier}
          entityType={VENUE_SK}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          description={item.shortTitle}
          price={item.price}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          mode={DISCOVER_MODE_LK}
          link={item.copyLinkUrl}
        />
      case FREEBIE_SK:
        return <LatestFreebieCard
          type={FREEBIE_LK}
          entityType={VENUE_SK}
          identifier={item.entityIdentifier}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          price={item.price}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          mode={DISCOVER_MODE_LK}
          link={item.copyLinkUrl}
        />
      default:
        break;
    }
  }

  return (
    <RootContainer>
      {status === Status.SUCCESS && isEmpty(list) ? <Text> {translate(`common.noContent`)}</Text> : <InfiniteScroll
        dataLength={list.length}
        next={next}
        hasMore={hasMore}
        loader={loader}
        endMessage=''
      >
        <ListContainer>
          {list.map((item, key) => (
            <ListColumn key={key}>
              <ListItem>
                {renderItem(item)}
              </ListItem>
            </ListColumn>
          ))}
        </ListContainer>
      </InfiniteScroll>}
    </RootContainer>

  );
}

export default List;
