const PRIMARY_COLOR = '#A87EE2';
const SECONDARY_COLOR = '#FA8F22';
const ALT_PRIMARY_COLOR = '#7851A9';
const BLACK = '#201C21';
const WHITE = '#fff';
const THIN_COLOR = 'rgba(255, 255, 255, 0.7)';
const GRAY_COLOR = '#E4E6EB';


const darkTheme = {
    primaryColor: PRIMARY_COLOR,
    secondaryColor: SECONDARY_COLOR,
    altPrimaryColor: ALT_PRIMARY_COLOR,
    body: BLACK,
    fontColor: WHITE,
    thinFontColor: THIN_COLOR,
    grayFontColor: GRAY_COLOR,
    lightFontColor: '#BEBFCF',
    grayBoxColor: 'rgb(54 48 55)',
    boxShadow: 'none',
    dividerColor: 'linear-gradient(to right, rgba(228, 230, 235, 1),rgba(228, 230, 235, 0.7), rgba(228, 230, 235, 1),rgba(228, 230, 235, 0.5)) no-repeat',
}

export default darkTheme