import { Col, Form, Row } from "antd";
import styled from "styled-components";
import { device } from "../../../theme";
import { GlobalButton } from "../../../theme/styles";

export const Container = styled(Row)`
  background-color: #fff;
  min-height: 100vh;
  color: #000;
`;

export const ChangePassWrapper = styled(Col)`
  margin-top: 100px;
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};

  @media ${device.laptop} {
    font-size: 40px;
  }
  
  @media ${device.mobileL} {
    font-size: 25px;
  }

  @media ${device.mobileS} {
    font-size: 20px;
  }
`;

export const FormRow = styled(Row)``;

export const FormContainer = styled(Form)`
  padding: 30px 20px;
`;

export const ChangePassButton = styled(GlobalButton)`
width: 100%;
`;