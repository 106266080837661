/**
 * i18n stands for internationalization
 */
import i18n from 'i18n-js';
import { LOCAL_STORAGE_KEYS } from '../constants';

const en = require('./locales/en');
const my = require('./locales/my');

// Should the app fallback to English if user locale doesn't exists
i18n.fallbacks = true;

// Define the supported translation
i18n.translations = {
  en,
  my,
};

let languageTag = 'my';
/**
 * Get preferred language from AsyncStorage
 */
const getSavedPreference = async () => {
  try {
    const language = localStorage.getItem(
      LOCAL_STORAGE_KEYS.userPreferedLangage,
    );
    // if (isNonEmptyString(language)) {
    languageTag = language;
    // }
  } catch (e) {
  }

  i18n.locale = languageTag;
};

getSavedPreference();
