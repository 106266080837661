/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Col,
  message,
  Row,
} from 'antd';
import { api, Status } from '../../../api';
import {
  EmptyCard,
  LatestFreebieCard,
  LivestreamCard,
  PremiumCard,
  Skeleton,
} from '../../../common';
import {
  DISCOVER_MODE_LK,
  EVENT_SK,
  FREEBIE_LK,
  FREEBIE_SK,
  LIMITS,
  PREMIUM_SK,
  PUBLISHED_SK,
  UNSAVE_SK,
  VIDEO_SK,
} from '../../../constants';
import { getFormattedPrice } from '../../../utils';
import _ from 'lodash';
import { translate } from '../../../i18n';
import { ListContainer, Loader, } from './WatchListTab.elements';
import { ListColumn, ListItem, Spinner } from '../../../theme/styles';

const Shows = ({
  active,
}) => {
  const status = useSelector(({ app }) => app.status);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [hasMore, setHasMore] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    !active && resetState();
  }, [active]);

  useEffect(() => {
    status === Status.SUCCESS && active && fetchShowsList();
  }, [status, active]);

  useEffect(() => {
    page !== 1 && fetchShowsList(page);
  }, [page]);

  useEffect(() => {
    setIsEmpty(list.length === 0);
    setList(prevState => {
      return _.filter(prevState, item => !item.isDeleted);
    });
  }, [list]);

  const fetchShowsList = (page = 1) => {
    setApiStatus(Status.LOADING);
    api
      .fetchFavouriteList({
        entityType: VIDEO_SK,
        page,
      })
      .then(({ userResponse: newList }) => {
        setApiStatus(Status.SUCCESS);
        newList.map(item => {
          item.type = item.entityType === EVENT_SK ? item.entityType : item.privacy;
        });
        setList([...list, ...newList]);
        setHasMore(newList.length >= LIMITS.ITEMS_PER_PAGE);
        setIsEmpty(page === 1 && newList.length === 0);
      })
      .catch(() => {
        setApiStatus(Status.ERROR);
      });
  }

  const deleteFavouriteCallback = (favouriteIdentifier) => {
    showMessage();
    let _list = _.clone(list);
    _list.map(item => {
      if (item.favouriteIdentifier === favouriteIdentifier) {
        item.isDeleted = true;
      }
    })
    setList(_list);
  }

  const showMessage = () => {
    message.success({
      content: translate('userProfile.unsavedSuccessMessage'),
    });
  }

  const resetState = () => {
    setPage(1);
    setList([]);
    setApiStatus(Status.DEFAULT);
    setHasMore(true);
    setIsEmpty(false);
  }

  const loader = (
    <Loader>
      <Spinner />
    </Loader>
  );

  const endMessage = (
    isEmpty && <EmptyCard watchList />
  );

  const next = () => {
    if (apiStatus !== Status.DEFAULT &&
      apiStatus !== Status.LOADING
    ) {
      setPage(prevState => prevState + 1);
    }
  }

  const renderItem = (item) => {
    if (item.isDeleted) {
      return null;
    }
    switch (item.type) {
      case EVENT_SK:
        return <LivestreamCard
          mode={DISCOVER_MODE_LK}
          identifier={item.entityIdentifier}
          entityType={item.entityType}
          image={item.thumbUrl}
          broadcastStartDateTime={item.broadcastStartDateTime}
          broadcastEndDateTime={item.broadcastEndDateTime}
          description={item.title}
          price={translate('videoDetails.buyButtonLabel').replace('!@#$%', getFormattedPrice(item.price))}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          link={item.copyLinkUrl}
          expired={item.status !== PUBLISHED_SK}
          deleteFavouriteCallback={deleteFavouriteCallback}
        />
      case PREMIUM_SK:
        return <PremiumCard
          identifier={item.entityIdentifier}
          entityType={item.entityType}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          description={item.shortTitle}
          price={item.price}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          mode={DISCOVER_MODE_LK}
          link={item.copyLinkUrl}
          expired={item.status !== PUBLISHED_SK}
          deleteFavouriteCallback={deleteFavouriteCallback}
        />
      case FREEBIE_SK:
        return <LatestFreebieCard
          type={FREEBIE_LK}
          entityType={item.entityType}
          identifier={item.entityIdentifier}
          image={item.thumbUrl}
          title={item.title}
          duration={item.duration}
          date={item.publishStartDateTime}
          price={item.price}
          isSaved={item.isSaved === UNSAVE_SK}
          favouriteIdentifier={item.favouriteIdentifier}
          mode={DISCOVER_MODE_LK}
          link={item.copyLinkUrl}
          expired={item.status !== PUBLISHED_SK}
          deleteFavouriteCallback={deleteFavouriteCallback}
        />
      default:
        break;
    }
  }

  return (
    <InfiniteScroll
      dataLength={list.length}
      next={next}
      hasMore={hasMore}
      loader={loader}
      endMessage={endMessage}
    >
      <ListContainer>
        {list.map((item, key) => (
          <ListColumn key={key}>
            <ListItem>
              {renderItem(item)}
            </ListItem>
          </ListColumn>
        ))}
      </ListContainer>
    </InfiniteScroll>
  );
}

export default Shows;