/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
    Container,
    IconCheck,
    Image,
    Name,
    Wrapper
} from './InterestCards.elements';

const InterestCard = ({
    isChecked,
    id,
    image1,
    image2,
    altname,
    name,
    updateSelectedIds
}) => {
    let ref = useRef(null);
    const [height, setHeight] = useState(0);
    const [checked, setChecked] = useState(isChecked);

    useEffect(() => {
        setHeight(ref.current.clientWidth);
    }, []);

    useEffect(() => {
        updateSelectedIds(id, checked);
    }, [checked]);

    const onChange = () => {
        setChecked(!checked);
    };
    return (
        <Container ref={ref} onClick={onChange} checked={checked} height={height}>
            <IconCheck checked={checked} />
            <Wrapper>
                <Image src={checked ? image1 : image2} alt={altname} />
                <Name checked={checked}>
                    {name}
                </Name>

            </Wrapper>

        </Container>
    );
}

export default InterestCard;