import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
    PaymentPINComponent,
} from '../../components';

const PaymentPIN = () => {
    const { state } = useLocation();
    const {
        paymentName,
    } = state || {};

    if (!state) {
        return <Navigate to='/' />;
    }

    return (
        <PaymentPINComponent
            paymentName={paymentName}
        />
    );
}

export default PaymentPIN;