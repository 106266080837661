import { Spin } from "antd";
import styled from "styled-components";
import { device } from "../../theme";
import { ListContainer as ListContainerGlobal } from "../../theme/styles";

export const RootContainer = styled.div`
  min-height: calc(100vh - 190px);
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: ${(props) => props.theme.fontColor};
  margin-left: 60px;
  padding-top: 20px;

  @media ${device.laptop} {
    margin-left: 30px;
    font-size: 25px;
    padding-top: 10px;
  }

  @media ${device.mobileL} {
    margin-left: 10px;
    font-size: 20px;
  }
`;

export const ListContainer = styled(ListContainerGlobal)`
  padding-top: 20px !important;

  @media ${device.laptop} {
    padding-top: 10px !important;
  }

  @media ${device.mobileL} {
    padding-top: 0 !important;
  }
`;

export const Loader = styled.div`
  text-align: center;
`;

export const Text = styled.div`
  color: ${(props) => props.theme.fontColor};
  font-size: 28px;
  justify-content: center;
  text-align: center;

  @media ${device.laptop} {
    font-size: 25px;
  }

  @media ${device.mobileL} {
    font-size: 20px;
  }
`;
