/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Col,
    Form
} from 'antd';
import logo from '../../../assets/images/main-logo.png';
import {
    AuthGlobalIcon,
    ErrorText,
    GlobalLogo,
    GlobalPasswordBox
} from '../../../theme/styles';
import {
    ChangePassButton,
    ChangePassWrapper,
    Container,
    FormContainer,
    FormRow,
    Heading,
} from './ChangePassword.elements';
import { useLocation, useNavigate } from 'react-router-dom';
import { translate } from '../../../i18n';
import { connect } from 'react-redux';
import {
    resetPassword,
    resetChangePasswordState,
} from '../../../store/actions';
import { Status } from '../../../api';
import { isPasswordValid } from '../../../utils';

const ChangePassword = ({
    resetPassword: _resetPassword,
    resetChangePasswordState: _resetChangePasswordState,
    resetPasswordStatus,
    resetPasswordErrorMessage,
}) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { otp } = state || {};
    const [passwordValid, setPasswordValid] = useState(true);

    useEffect(() => {
        //component will unmount
        return () => {
            _resetChangePasswordState();
        }
    }, []);

    useEffect(() => {
        if (resetPasswordStatus === Status.SUCCESS) {
            navigate('/login');
        }
    }, [resetPasswordStatus]);

    const onFinish = ({ password, confirmPassword }) => {
        if (!isPasswordValid(password)) {
            setPasswordValid(false);
            return;
        }
        _resetPassword(otp, password, confirmPassword);
    };

    return (
        <div>
            <GlobalLogo to='/'>
                <AuthGlobalIcon src={logo} alt="" />
            </GlobalLogo>
            <Container>
                <Col xs={0} md={1} lg={4} />
                <ChangePassWrapper xs={24} md={22} lg={16}>
                    <Heading>
                        {translate('changePassword.title')}
                    </Heading>

                    <FormRow>
                        <Col xs={0} md={3} lg={4} />
                        <Col xs={24} md={18} lg={16} >
                            <FormContainer
                                name="normal_login"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                            >
                                <ErrorText>
                                    {resetPasswordErrorMessage}
                                </ErrorText>

                                {!passwordValid && <ErrorText>
                                    {translate('requiredErrorMessage.passwordMinimumLengthError')}
                                </ErrorText>}

                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: `${translate('changePassword.inputLabelOne')}`,
                                        },
                                    ]}
                                >
                                    <GlobalPasswordBox
                                        placeholder={translate('changePassword.inputLabelOne')}
                                        onChange={() => setPasswordValid(true)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="confirmPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: `${translate('changePassword.inputLabelTwo')}`,
                                        },
                                    ]}
                                >
                                    <GlobalPasswordBox placeholder={translate('changePassword.inputLabelTwo')} />
                                </Form.Item>

                                <Form.Item>
                                    <ChangePassButton htmlType="submit" loading={resetPasswordStatus === Status.LOADING} >
                                        {resetPasswordStatus !== Status.LOADING && translate('changePassword.buttonLabel')}
                                    </ChangePassButton>
                                </Form.Item>
                            </FormContainer>
                        </Col>
                        <Col xs={0} md={3} lg={4} />
                    </FormRow>

                </ChangePassWrapper>
                <Col xs={0} md={1} lg={4} />
            </Container>
        </div>

    );
}

const mapStateToProps = ({ auth }) => {
    const {
        resetPasswordStatus,
        resetPasswordErrorMessage,
    } = auth;

    return {
        resetPasswordStatus,
        resetPasswordErrorMessage,
    }
}

export default connect(mapStateToProps, {
    resetPassword,
    resetChangePasswordState,
})(ChangePassword);