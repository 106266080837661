import { Divider, Form, Input, Row } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { GlobalButton, GlobalInputBox } from "../../../theme/styles";
import { device } from "../../../theme/device";

export const LoginWrapper = styled.div`
  position: relative;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 200px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const LoginHeading = styled.div`
  margin: 11px 0;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  color: ${({ theme }) => theme.primaryColor};

  @media ${device.laptop} {
    font-size: 40px;
  }

  @media ${device.mobileL} {
    font-size: 25px;
  }

  @media ${device.mobileS} {
    font-size: 20px;
  }
`;

export const LoginInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 5px;

  @media ${device.laptop} {
    font-size: 14px;
  }
  
  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const InfoLink = styled(Link)`
  font-style: normal;
  font-weight: 400;
  color: ${props => props.theme.secondaryColor};

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;

export const LoginDivider = styled(Divider)`
  margin: 0 !important;
  color: ${({ theme }) => theme.primaryColor} !important;
  border-top-color: ${({ theme }) => theme.primaryColor} !important;
`;

export const LoginContainer = styled(Row)`
  background-color: #fff;
  min-height: 100vh;
`;

export const ForgetPasswordLink = styled(Link)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  float: right;

  &:hover {
    color: #000000;
  }

  @media ${device.laptop}{
    font-size: 14px;
  }
  
  @media ${device.mobileL}{
    font-size: 12px;
  }

  @media ${device.mobileS}{
    font-size: 12px;
  }
`;

export const PhoneLogin = styled.div`
  display: flex;
  height: 45px;
  padding: 5px;
  border: 1px solid #bebfcf;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-start;
`;

export const CountryCodeBox = styled(Input.Group)`
  padding-right: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #201c21;
  width: 0 !important;

  .ant-select-selector {
    border: none !important;
  }

  @media ${device.laptop}{
    font-size: 14px;
  }
  
  @media ${device.mobileL}{
    font-size: 12px;
  }
  
  @media ${device.mobileS}{
    font-size: 12px;
  }
`;

export const PhoneNumberLogin = styled.div`
  display: flex;
  align-items: center;
`;

export const PhoneDivider = styled(Divider)`
  margin: 0;
  border-left: 1px solid #bebfcf;
`;

export const PhoneInput = styled(Input)`
  padding-left: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #201c21;

  @media ${device.laptop}{
    font-size: 14px;
  }

  @media ${device.mobileL}{
    font-size: 12px;
  }

  @media ${device.mobileS}{
    font-size: 12px;
  }
`;

export const LoginEmailItem = styled(Form.Item)`
  display: ${({ isEmail }) => (isEmail ? "block" : "none")};
`;

export const LoginPhoneItem = styled(Form.Item)`
  display: ${({ isEmail }) => (!isEmail ? "block" : "none")};
`;

export const LoginInputBox = styled(GlobalInputBox)`
  font-size: 16px;

  @media ${device.laptop}{
    font-size: 14px;
  }
  
  @media ${device.mobileL}{
    font-size: 12px;
  }

  @media ${device.mobileS}{
    font-size: 12px;
  }
`;

export const LoginPhoneBox = styled.div`
`;

export const LoginButton = styled(GlobalButton)`
width: 100%;
`;

