import styled, { css } from "styled-components";
import { Card as AntCard } from "antd";
import { IconPlay as GlobalIcon } from "../../theme/styles";
import { device } from "../../theme";

const cardHoverEffect = css`
background-image: linear-gradient(
        ${(props) => props.theme.primaryColor},
        ${(props) => props.theme.secondaryColor}
      ),
      linear-gradient(
        ${(props) => props.theme.primaryColor},
        ${(props) => props.theme.secondaryColor}
      );
    border-top: 2px solid ${(props) => props.theme.primaryColor};
    border-bottom: 3px solid ${(props) => props.theme.secondaryColor};
    background-size: 3px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
    padding: 0 3px;

    .ant-card-cover img {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
`;

export const Card = styled(AntCard)`
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  filter: ${({ theme }) => theme.boxShadow};

  &:hover {
    ${cardHoverEffect}
  }

  .ant-card-cover {
    aspect-ratio: 16 / 9;
    position: relative;
    margin: 0 !important;

    img {
      border-radius: 5px;
    }
  }

  .ant-card-body {
    padding: 18px 15px;
    position: relative;

    @media ${device.laptop} {
      padding: 10px;
    }

    @media ${device.tablet} {
      padding: 10px 5px;
    }
  }

  .ant-card-meta {
    padding-bottom: 60px;
  }

  .ant-card-meta-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-top: 10px;
    display: ${({ discoverMode }) => (discoverMode ? "none" : "block")};
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    @media ${device.laptop} {
      -webkit-line-clamp: 3;
      font-size: 14px;
    }

    @media ${device.tablet} {
      -webkit-line-clamp: 2;
      font-size: 13px;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const IconPlay = styled(GlobalIcon)`
  display: block;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

@media ${device.laptop} {
  font-size: 16px;
}

@media ${device.tablet} {
  font-size: 13px;
}
`;

export const Date = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #bebfcf;

@media ${device.laptop} {
  font-size: 14px;
}

@media ${device.tablet} {
  font-size: 10px;
}
`;

export const RootContainer = styled.div`
position: relative;
height: 100%;

  &:hover {
    ${Card} {
      ${cardHoverEffect}
    }
  }
`;
