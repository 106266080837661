import React from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  DetailsContainer,
  Heading,
  RootContainer,
  ShowTitle,
  Subtitle,
  Title,
  Collapse,
  Header,
  TextWrapper,
  Text,
  Loader,
  Spinner,
} from './InvoicesList.elements';
import {
  Col,
  Row,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Invoice from './Invoice';
import { translate } from '../../i18n';
import { getformattedDate } from '../../utils';
const { Panel } = Collapse;

const InvoicesList = ({
  details,
  list,
  hasMore,
  next,
}) => {
  const navigate = useNavigate();

  const goToTickets = () => {
    navigate('/user-profile/tickets');
  }

  const loader = (
    <Loader>
      <Spinner />
    </Loader>
  );

  const renderHeading = () => {
    return (
      <Heading>
        <Title onClick={goToTickets}>
          {translate('ticketing.myTickets')}
        </Title>
        <Subtitle>
          {` > ${translate('ticketing.invoiceDetails')}`}
        </Subtitle>
      </Heading>
    );
  }

  const CollapseHeader = ({
    invoiceDate,
    invoiceNo,
    paymentType,
  }) => {
    return (
      <Header>
        <TextWrapper>
          <Text>
            {translate('ticketing.invoiceDate')}
          </Text>
          <Text>
            {translate('ticketing.invoiceNo')}
          </Text>
          <Text>
            {translate('ticketing.paymentType')}
          </Text>
        </TextWrapper>
        <TextWrapper>
          <Text>
            {`: ${invoiceDate}`}
          </Text>
          <Text>
            {`: ${invoiceNo}`}
          </Text>
          <Text>
            {`: ${paymentType}`}
          </Text>
        </TextWrapper>
      </Header>
    );
  }

  const CollapseContent = ({
    price,
    item,
  }) => {
    return (
      <Invoice
        price={price}
        totalAmount={item.totalAmount}
        netAmount={item.amount}
        processingFee={item.processingFee}
        ticketList={item.ticketList}
        paymentType={item.paymentType}
        processingFeePercent={item.processingFeePercent}
      />
    );
  }

  const renderList = () => {
    return (
      <InfiniteScroll
        dataLength={list.length}
        next={next}
        hasMore={hasMore}
        loader={loader}
        endMessage=''
      >
        {list.map((item, key) => (
          <Collapse
            key={key}
            expandIconPosition='right'
            expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
          >
            <Panel
              header={
                <CollapseHeader
                  invoiceDate={getformattedDate(item.invoiceDate)}
                  invoiceNo={item.invoiceNo}
                  paymentType={item.paymentType}
                />
              }
              key={key}
            >
              <CollapseContent
                price={details.price}
                item={item}
              />
            </Panel>
          </Collapse>
        ))}
      </InfiniteScroll>
    );
  }

  return (
    <RootContainer>
      {renderHeading()}
      <Row>
        <Col xs={0} md={3} lg={2} />
        <Col xs={24} md={18} lg={20}>
          <DetailsContainer>
            <ShowTitle>
              {details.title}
            </ShowTitle>
            {renderList()}
          </DetailsContainer>
        </Col>
        <Col xs={0} md={3} lg={2} />
      </Row>
    </RootContainer>
  );
}

export default InvoicesList;