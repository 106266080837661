import styled from 'styled-components';
import { device } from '../../../theme';

export const EmptyCardContainer = styled.div`
cursor: auto !important;
`;

export const EmptyImage = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 5px;
`;

export const Text = styled.div`
width: 100%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-style: normal;
font-weight: 700;
font-size: 20px;
color: #FFFFFF;
text-align: center;
padding-right: 15px;

@media ${device.laptop} {
  font-size: 14px;
}

@media ${device.tablet} {
  font-size: 12px;
}
`;