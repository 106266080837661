import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { GlobalButton } from '../../theme/styles';
import { device } from '../../theme/device';

export const Modal = styled(AntModal)`
  .ant-modal-content {
    border-radius: 5px !important;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-close {
    display: none !important;
  }
`;

export const Container = styled.div`
padding: 30px 0;
background: ${props => props.theme.body};
border: 1px solid #7851A9;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
text-align: center;
`;

export const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 27px;
text-align: center;
text-transform: uppercase;
color: ${props => props.theme.secondaryColor};

@media ${device.tablet} {
  font-size: 22px;
}

@media ${device.mobileL} {
  font-size: 19px;
}
`;

export const Text = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
text-align: center;
color: ${props => props.theme.fontColor};
padding-top: 10px;
padding-bottom: 20px;

@media ${device.tablet} {
  font-size: 14px;
}

@media ${device.mobileL} {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
`;

export const Button = styled(GlobalButton)`
min-width: 100px;
text-transform: uppercase;

@media ${device.mobileL} {
  min-width: 80px;
  height: 35px;
}
`;