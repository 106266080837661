import React from 'react';
import {
  Button,
  Image,
  RootContainer,
  SubText,
  Text,
} from './ErrorHandler.elements';
import { translate } from '../../i18n';
import Img from '../../assets/images/image_network_failed.png';

const ConnectionFailed = () => {
  const retry = () => {
    window.location.reload();
  }

  return (
    <RootContainer>
      <Image src={Img} />
      <Text>
        {translate('connectionFailed.text')}
      </Text>
      <SubText>
        {translate('connectionFailed.subText')}
      </SubText>
      <Button onClick={retry}>
        {translate('connectionFailed.buttonLabel')}
      </Button>
    </RootContainer>
  );
}

export default ConnectionFailed;