import styled from "styled-components";
import { Image as AntImage } from "antd";
import { IconFavLight } from "../../theme/styles";
import { device } from "../../theme";
import { Modal as AntModal } from "antd";

export const Bottom = styled.div`
  display: none;
  position: absolute;
  bottom: -1px;
  width: calc(100% - 5px);
  padding-right: 10px;
  padding-top: 60px;
  text-align: right;
  background: linear-gradient(
    180deg,
    rgba(32, 28, 33, 0) 8.5%,
    rgba(32, 28, 33, 0.5802) 53.87%,
    #201c21 100%
  );

  @media ${device.laptop} {
    display: block;
    bottom: 0;
    width: 100%;
    padding-right: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const FavIcon = styled(IconFavLight)``;

export const Image = styled(AntImage)`
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 4;
  object-fit: cover;
  border-radius: 5px !important;

  &:hover {
    border-radius: 0 !important;
  }
`;

export const Item = styled.div`
  cursor: pointer;
  height: 100%;
  aspect-ratio: 3 / 4;
  position: relative;

  &:hover {
    background-image: linear-gradient(
        ${(props) => props.theme.primaryColor},
        ${(props) => props.theme.secondaryColor}
      ),
      linear-gradient(
        ${(props) => props.theme.primaryColor},
        ${(props) => props.theme.secondaryColor}
      );
    border-top: 3px solid ${(props) => props.theme.primaryColor};
    border-bottom: 3px solid ${(props) => props.theme.secondaryColor};
    background-size: 3px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
    padding: 0 3px;

    ${Image} {
      border-radius: 0 !important;
    }

    ${Bottom} {
      display: block;
    }

    @media ${device.laptop} {
      border: none;
      background-image: none;
      padding: 0;

      ${Image} {
        border-radius: 5px !important;
      }
    }
  }

  .ant-image {
    height: 100%;
  }
`;

export const Modal = styled(AntModal)`
  overflow: hidden;
  .ant-modal-content {
    width: 100%;
    height: 100%;
    background-color: rgba(32, 28, 33, 0.8) !important;
    box-shadow: none;
  }

  .ant-modal-body {
    height: 100%;
    text-align: center;
    padding: 0 !important;
  }

  .anticon {
    color: #fff;
  }
`;

export const ContentContainer = styled.div`
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Icon = styled.div`
  position: absolute;
  top: 2px;
  right: 56px;
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DownloadImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 3 / 4;
  object-fit: cover;
`;
