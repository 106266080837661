import 'moment/locale/my';
import 'moment/locale/en-in';

const moment = require('moment');
const momenttz = require('moment-timezone');

/**
 * @returns object containing ISO 8601 and RFC2822 formatted date
 */
export function getDate() {
  const currentDate = new Date(Date.now());
  let iso8601Date = currentDate.toISOString();

  iso8601Date = `${iso8601Date.split('.')[0]}+00:00`;

  const rfc2822Date = currentDate.toUTCString().replace('GMT', '+0000');

  return {
    iso8601Date,
    rfc2822Date,
  };
}

/**
 * Use this method to show date in application
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 * @param {bool} isUTC - if incoming date in UTC and we want to show in local time
 *
 * @return {string} formatted  date in format "MMMM DD, YYYY"
 */
export function formattedDate(date, isUTC = true) {
  const myDate = isUTC ? moment.utc(date).local() : moment(date);

  if (myDate.isValid()) {
    return myDate.format(
      `MMMM D, YYYY`,
    );
  }

  return '';
}

/**
 *
 * @param time in HH:mm:ss utc time    ex 01:00:33
 * @return {string} duration
 */
export function getFormattedDuration(time) {
  let t = moment(time, 'HH:mm:ss');
  let hrs = t.diff(moment().startOf('day'), 'hours');
  let mins = t.format('mm');
  return `${hrs}.${mins} hr`;
}

/**
 * Use this method to show date in application
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 *
 * @return {string} formatted  date
 */
export function getFormattedDateTime(date) {
  let currentTimezone = momenttz.tz.guess();
  let format = 'dddd, MMMM D, YYYY [at] h:mm A [UTC]Z';
  return moment.utc(date).tz(currentTimezone, true).format(format);
}

/**
 * Use this method to show time in application
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 *
 * @return {string} formatted time
 */
export function getFormattedTime(date) {
  let currentTimezone = momenttz.tz.guess();
  let format = 'h:mm A [UTC]Z';
  return moment.utc(date).tz(currentTimezone, true).format(format);
}

/**
 * Use this method to show date in application
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 * @param {bool} isUTC - if incoming date in UTC and we want to show in local time
 *
 * @return {string} formatted  date in format "D.M.YYYY"
 */
export function getformattedDate(date, isUTC = true) {
  const myDate = isUTC ? moment.utc(date).local() : moment(date);

  if (myDate.isValid()) {
    return myDate.format(
      `D.M.YYYY`,
    );
  }

  return '';
}

/**
 * Use this method to show streaming date in notification
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 * @param {bool} isUTC - if incoming date in UTC and we want to show in local time
 *
 * @return {string} formatted  date in format "MMMM DD, YYYY at 00:00 AM"
 */
export function getStreamingDate(date, isUTC = true) {
  const myDate = isUTC ? moment.utc(date).local() : moment(date);

  if (myDate.isValid()) {
    return `${formattedDate(myDate)} ${myDate.format('[at] h:mm A')}`;
  }

  return '';
}

/**
 * @Param date : ISO 8601 or RFC 2822 format
 *
 * @return {string} :getPeriodicTime time for notifications like 1 min ago, 1 month ago etc
 */
export function getPeriodicTime(date) {
  // current date time
  const now = moment();

  // post created date time converted  UTC to locale timezone
  const myDate = moment.utc(date).local();

  if (now.diff(myDate, 'minutes', true) < 1) {
    // if post is 15 min or less than show recent                    : recent
    return 'recent';
  }
  if (now.diff(myDate, 'days', true) < 1) {
    // today post but not recent, time diff is less than 1 day      : X minute ago or y hours ago
    return myDate.from(now);
  }
  if (now.diff(myDate, 'days', true) < 2) {
    // yesterday                                                    : yesterday at HH:mm
    return `Yesterday`;
  }
  if (now.diff(myDate, 'weeks', true) < 1) {
    // more than yesterday day, with in week                        : X day ago
    return myDate.from(now);
  }
  if (now.year() === myDate.year()) {
    // posted date is in current                                    : mmmm dd
    return myDate.format(
      `MMMM DD`,
    );
  }
  // posted date is not in current                                : mmmm dd, YYYY
  return myDate
    .format(
      `MMMM DD, YYYY`,
    );
}

/**
 * Use this method to check event is lived or not started
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 *
 * @return {boolean} false if current date is not reached at given datetime
 */
export function isLived(datetime) {
  let myDate = moment.utc(datetime).startOf('day');
  return moment().diff(myDate) >= 0;
}

/**
 * Use this method to check event is ended or not
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 *
 * @return {boolean} false if current date is not reached at given datetime
 */
export function isLiveEnded(datetime) {
  let myDate = moment.utc(datetime);
  return moment().isAfter(myDate) || moment().isSame(myDate);
}

/**
 * @param {String} dates - utc date
 * @return true if current date is between start date and end date
 */
export function isLive(startDateTime, endDateTime) {
  let currentDateTime = moment();
  return (
    currentDateTime >= moment.utc(startDateTime) &&
    currentDateTime <= moment.utc(endDateTime)
  );
}