import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import { device, GlobalButton, GlobalInputBox } from "../../../theme";
import { Input as AntInput } from "antd";
import { Spinner as GlobalSpinner } from "../../../theme/styles";

export const RootContainer = styled.div`
  padding: 40px 60px;
  min-height: calc(100vh - 190px);

  .ant-input{
    background: transparent !important;
    color: ${props => props.theme.fontColor} !important;
    font-size: 15px;
  }

  @media ${device.laptop} {
    padding: 30px;
  }

  @media ${device.mobileL} {
    padding: 20px 10px;
  }

  @media ${device.mobileS} {
    padding: 20px 10px;
  }
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: ${({ theme }) => theme.fontColor};
  text-align: center;

  @media ${device.laptop} {
    font-size: 22px;
  }

  @media ${device.mobileL} {
    font-size: 19px;
  }

  @media ${device.mobileS} {
    font-size: 16px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.fontColor};
  padding: 20px 0;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

export const DetailsContainer = styled.div`
  padding-top: 20px;
`;

export const InputBox = styled(GlobalInputBox)`
  color: ${(props) => props.theme.fontColor} !important;
  margin-bottom: 15px !important;
  /* text-align: ${(props) => (props.left ? "left" : "right")}; */
`;

export const EditLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.fontColor} !important;
  padding: 15px 0;

  @media ${device.tablet} {
    font-size: 15px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    padding: 10px;
  }

  @media ${device.mobileM} {
    font-size: 13px;
  }
`;

export const R = styled.span`
  padding-left: 5px;
  color: red;
`;

export const PhoneBox = styled.div`
   margin-bottom: 15px !important;
`;

export const BillTextArea = styled(TextArea)`
border: 1px solid #BEBFCF;
border-radius: 10px;
background: transparent;
margin-bottom: 15px !important;
color: ${props => props.theme.fontColor};
font-size: 16px !important;

&:hover, :focus{
    border: 1px solid #BEBFCF;
}
`;

export const Spinner = styled(GlobalSpinner).attrs({
  size: "middle",
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Loader = styled.div`
text-align: center;
`;

export const Input = styled(AntInput)`
  height: 54px;
  background: transparent;
  border: 1px solid #bebfcf;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: ${(props) => props.theme.fontColor};
  text-align: ${(props) => (props.left ? "left" : "right")};
  margin-bottom: 15px !important;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }

  &:focus,
  :hover {
    border: 1px solid #bebfcf;
    box-shadow: none;
  }
`;

export const BottomBox = styled.div`
  padding-top: 30px;
  text-align: right;

  @media ${device.mobileL} {
    text-align: center;
  }

  @media ${device.mobileS} {
    text-align: center;
  }
`;

export const Button = styled(GlobalButton)`
  min-width: 175px;
  border: 1px solid #ffffff;

  @media ${device.laptop} {
    min-width: 150px;
  }

  @media ${device.mobileL} {
    min-width: 140px;
    height: 40px !important;
  }

  @media ${device.mobileS} {
    min-width: 120px;
  }

  &:hover {
    border: 1px solid #ffffff;
  }
`;

export const CancelButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.fontColor};
  color: ${(props) => props.theme.fontColor};
  background: transparent;

  &:hover {
    background: linear-gradient(
      94.11deg,
      ${(props) => props.theme.primaryColor} 0.54%,
      ${(props) => props.theme.primaryColor} 98.6%
    );
  }
`;

export const PrefixBox = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.fontColor} !important;
`;

export const DividerBox = styled.div`
  padding: 0 10px;

  @media ${device.laptop} {
    font-size: 0 8px;
  }

  @media ${device.mobileL} {
    font-size: 0 7px;
  }
`;