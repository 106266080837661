import React from 'react';
import html2canvas from 'html2canvas';
import {
  ContentContainer,
  DateText,
  Divider,
  Icon,
  Image,
  ImageWrapper,
  Modal,
  SubTitle,
  Text,
  TimeText,
  Title,
} from './ImageViewer.elements';
import { DownloadOutlined } from '@ant-design/icons';
import { translate } from '../../i18n';
import { VENUE_SK } from '../../constants';

const ImageViewer = ({
  visible,
  showName,
  ticketCode,
  date,
  endDate,
  image,
  entityType,
  handleClose,
}) => {

  const download = () => {
    let element = document.getElementById(`ticket-qr-image-${ticketCode}`);
    element.style.backgroundColor = 'black';
    html2canvas(element).then((canvas) => {
      var a = document.createElement('a');
      // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
      a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
      a.download = `${translate('common.appName')}_${ticketCode}_${showName}.png`;
      a.click();
      element.style.backgroundColor = 'transparent';
    })
  }

  return (
    <Modal
      viewer
      id='ticket-qr-viewer'
      visible={visible}
      centered
      footer={false}
      maskClosable={false}
      width='100vw'
      height='100vh'
      onCancel={handleClose}
    >
      <Icon onClick={download}>
        <DownloadOutlined />
      </Icon>
      <ContentContainer id={`ticket-qr-image-${ticketCode}`}>
        <Title>
          Align the QR code within the frame to scan
        </Title>
        <ImageWrapper>
          <Image
            alt='img'
            src={image}
          />
        </ImageWrapper>
        <SubTitle>
          {showName}
        </SubTitle>
        <Text>
          Ticket code : {ticketCode}
        </Text>
        <Divider />
        <DateText>
          {`Date : ${date}`}
        </DateText>
        <TimeText>
          {`Valid Until : ${endDate}`}
        </TimeText>
        <Divider />
      </ContentContainer>
    </Modal>
  );
}

export default ImageViewer;