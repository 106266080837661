import { message, Tooltip } from 'antd';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { api } from '../../api';
import {
  DISCOVER_MODE_LK,
  LATEST_LK,
  LOCAL_STORAGE_KEYS,
} from '../../constants';
import { translate } from '../../i18n';
import { getFormattedPrice } from '../../utils';
import LoginPrompt from '../LoginPrompt/LoginPrompt';
import {
  ActionOne,
  Actions,
  ActionsIcon,
  ActionThree,
  ActionTwo,
} from './CardActions.elements';

const CardActions = ({
  entityType,
  entityIdentifier,
  type,
  label,
  isSaved,
  favouriteIdentifier,
  mode,
  link,
  deleteFavouriteCallback = () => { },
}) => {
  const loggedInUserIdentifier = localStorage.getItem(LOCAL_STORAGE_KEYS.userIdentifier);
  const [_isSaved, setIsSaved] = useState(isSaved);
  const [_favouriteIdentifier, setFavouriteIdentifier] = useState(favouriteIdentifier);

  const copyLink = (e) => {
    e.preventDefault();
  }

  const handleFavourite = (e) => {
    setIsSaved(prevState => {
      return !prevState;
    });
    if (_isSaved) {
      api
        .deleteFavourite({
          favouriteIdentifier: _favouriteIdentifier,
        })
        .then(() => {
          deleteFavouriteCallback(_favouriteIdentifier);
        })
        .catch(() => { });
    }
    else {
      api
        .saveFavourite({
          entityIdentifier,
          entityType,
          userIdentifier: loggedInUserIdentifier,
        })
        .then(({ userResponse: { favouriteIdentifier } }) => {
          setFavouriteIdentifier(favouriteIdentifier);
        })
        .catch(() => { });
    }
  }

  return (
    <Actions
      defaultMode={
        type === LATEST_LK &&
        mode !== DISCOVER_MODE_LK
      }
    >
      <ActionOne
        transparent={
          type === LATEST_LK &&
          mode !== DISCOVER_MODE_LK
        }
        type={type}
      >
        {label}
        {/* {translate('videoDetails.buyButtonLabel').replace('!@#$%', getFormattedPrice(label))} */}
      </ActionOne>
      <ActionsIcon>
        <LoginPrompt
          text={translate('loginPrompt.toLikeThis')}
          onClick={handleFavourite}
        >
          <ActionTwo filled={_isSaved} />
        </LoginPrompt>
        <Tooltip
          title="Copy Link"
          color='rgba(32, 28, 33, 0.8)'
        >
          <CopyToClipboard
            text={link}
            onCopy={() => {
              message.success({
                content: 'Link Copied',
                style: {
                  marginTop: '64px'
                }
              });
            }}
          >
            <ActionThree onClick={copyLink} />
          </CopyToClipboard>
        </Tooltip>
      </ActionsIcon>
    </Actions>
  );
}

export default CardActions;