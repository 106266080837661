import styled, { createGlobalStyle, css } from "styled-components";
import { AiFillPlayCircle, AiFillHeart } from "react-icons/ai";
import { Skeleton, Card, Button, Input, Row, Col, Spin } from "antd";
import { Link } from "react-router-dom";
import { device } from "./device";
import { ShareAltOutlined, SlidersTwoTone } from "@ant-design/icons";

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}

body {
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.fontColor};
}

h1, h2, h3, h4, h5, h6 {
  color: ${({ theme }) => theme.fontColor};
}

.ql-size-large, .ql-size-huge {
    font-size: 1.5em;
}

.ant-popover, .ant-drop-down {
  position: fixed;
}

.ant-image-preview-mask {
  background-color: rgba(32, 28, 33, 0.8);
}

.search-result-filter-dropdown {
  background: ${({ theme }) => theme.body};
  border: 1px solid ${(props) => props.theme.primaryColor};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

    .ant-select-item {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      color: ${({ theme }) => theme.thinFontColor};

      @media ${device.laptop} {
        font-size: 14px;
      }

      @media ${device.mobileL} {
        font-size: 12px;
      }
    }

    .ant-select-item-option-selected {
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }
    
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: rgba(120, 81, 169, 0.5);
    }
}
`;

export const Divider = styled.div`
  margin: 0 60px;
  padding-bottom: 1px;
  background: ${({ theme }) => theme.dividerColor};
  background-size: 100% 1px;

@media ${device.laptop} {
  margin: 0 35px;
}

@media ${device.mobileL} {
  margin: 0 20px;
}
`;

export const GlobalSkeletonCard = styled(Card)`
  background-color: #fff;
  border: none;
`;

export const GlobalStyledSkeleton = styled(Skeleton)`
  height: 100%;

  span {
    width: 100% !important;
    height: 100% !important;
    border-radius: 5px !important;
  }

  .ant-skeleton-header {
    padding: 0 !important;
    border-radius: 5px !important;
  }

  .ant-skeleton-content {
    display: none !important;
  }
`;

export const IconPlay = styled(AiFillPlayCircle)`
  display: none;
  color: ${(props) => props.theme.secondaryColor};
  font-size: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  @media ${device.tablet} {
    font-size: 26px;
  }
`;

export const IconShare = styled(ShareAltOutlined)`
  svg {
    color: #a87ee2;
    font-size: 24px;
    cursor: pointer;

    @media ${device.tablet} {
      font-size: 19px;
    }
  }
`;

export const IconFavLight = styled(AiFillHeart)`
  font-size: 25px;
  color: ${(props) => (props.filled ? props.theme.secondaryColor : "#e4e6eb")};
  stroke: ${(props) => props.theme.primaryColor};
  stroke-width: 70;
  cursor: pointer;

  @media ${device.tablet} {
    font-size: 20px;
  }
`;

export const IconFavGray = styled(AiFillHeart)`
  font-size: 25px;
  color: ${(props) => (props.filled ? props.theme.secondaryColor : "#bebfcf")};
  stroke: ${({ theme }) => theme.primaryColor};
  stroke-width: 70;
  cursor: pointer;

  @media ${device.tablet} {
    font-size: 20px;
  }
`;

export const BuyTicketButton = styled.div`
  position: relative;
  min-width: 175px;
  padding: 10px 20px;
  background: ${(props) =>
    props.inactive
      ? "#BEBFCF"
      : `linear-gradient(95.27deg,${props.theme.altPrimaryColor} 0%,${props.theme.secondaryColor} 100%)`};
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background: ${(props) =>
    props.inactive
      ? "#BEBFCF"
      : `linear-gradient(0deg,rgba(255, 255, 255, 0.2),rgba(255, 255, 255, 0.2)),linear-gradient(95.27deg,${props.theme.primaryColor} 0%,${props.theme.secondaryColor} 100%)`};
  }

@media ${device.laptop} {
  min-width: 150px;
  padding: 10px 15px;
  font-size: 16px;
}

@media screen and (max-width: 460px) {
  min-width: 140px;
  padding: 10px 15px;
  font-size: 15px;
}

@media ${device.mobileL} {
  min-width: 120px;
  padding: 10px 10px;
  font-size: 13px;
}

@media screen and (max-width: 330px) {
  min-width: 120px;
  padding: 10px 10px;
  font-size: 13px;
}
`;

export const WatchButton = styled.div`
  min-width: 175px;
  padding: 10px 20px;
  background: ${({ theme, inactive }) =>
    inactive ? "#BEBFCF" : theme.primaryColor};
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;

@media ${device.laptop} {
  min-width: 150px;
  padding: 10px 15px;
  font-size: 16px;
}

@media screen and (max-width: 460px) {
  min-width: 140px;
  padding: 10px 15px;
  font-size: 15px;
}

@media ${device.mobileL} {
  min-width: 120px;
  padding: 10px 10px;
  font-size: 13px;
}

@media screen and (max-width: 330px) {
  min-width: 110px;
  padding: 10px 10px;
  font-size: 13px;
}
`;

const inputStyle = css`
  height: 54px;
  background: transparent !important;
  border: 1px solid #bebfcf;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #000;

  &:focus,
  :hover {
    border: 1px solid #bebfcf !important;
    box-shadow: none !important;
  }

  @media ${device.laptop} {
    font-size: 15px;
  }
  
  @media ${device.mobileL} {
    font-size: 13px;
  }
`;

export const GlobalInputBox = styled(Input)`
  ${inputStyle}
`;

export const GlobalPasswordBox = styled(Input.Password)`
  ${inputStyle}
`;

export const GlobalButton = styled(Button)`
  height: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(
    90.58deg,
    ${(props) => props.theme.altPrimaryColor},
    ${(props) => props.theme.primaryColor}
  );
  border: 1px solid #201c21;
  box-sizing: border-box;
  border-radius: 5px;

  &:hover {
    color: #fff;
    border: 1px solid #201c21;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      linear-gradient(
        90.58deg,
        ${(props) => props.theme.altPrimaryColor},
        ${(props) => props.theme.primaryColor}
      );
  }

  &:focus {
    color: #fff;
    border: 1px solid #201c21;
    background: linear-gradient(
      90.58deg,
      ${(props) => props.theme.altPrimaryColor},
      ${(props) => props.theme.primaryColor}
    );
  }

  @media ${device.laptop} {
    font-size: 14px;
  }
  
  @media ${device.mobileL} {
    font-size: 13px;
  }

  @media ${device.mobileS} {
    font-size: 11px;
  }
`;

export const GlobalLogo = styled(Link)`
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const GlobalIcon = styled.img`
  position: absolute;
  width: 180px;
  height: 80px;
  top: 2px;
  left: 50px;
  object-fit: cover;
  z-index: 1000;

  @media screen and (max-width: 399px) {
    width: 100px;
  }

  @media screen and (max-width: 499px) {
    width: 110px;
  }
`;

export const AuthGlobalIcon = styled.img`
  position: absolute;
  width: 180px;
  height: auto;
  top: 10px;
  left: 50px;
  margin-left: -10px;
  object-fit: cover;
  z-index: 1000;

  @media ${device.laptop} {
    width: 160px;
  }

  @media ${device.mobileL} {
    width: 130px;
  }

  @media ${device.mobileS} {
    width: 130px;
  }
`;

export const ListContainer = styled(Row).attrs({
  gutter: [
    0,
    { xs: 10, md: 20 }],
})`
  background: ${({ theme }) => theme.body};
  padding: 20px 40px 30px 60px;

@media ${device.laptop} {
  padding: 20px 10px 30px 30px;
}

@media ${device.mobileL} {
  padding: 20px 3px 30px 10px;
}
`;

export const ListColumn = styled(Col).attrs({
  xs: 12,
  sm: 8,
  md: 8,
  lg: 6,
})``;

export const ListItem = styled.div`
width: calc(100% - 20px);
height: 100%;

@media ${device.mobileL} {
  width: calc(100% - 10px);
}
`;

export const Filter = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: center;
  cursor: pointer;
`;

export const FilterIcon = styled(SlidersTwoTone).attrs(({ theme, active }) => ({
  twoToneColor: active ? theme.secondaryColor : theme.primaryColor,
}))`
  svg {
    width: 24px;
    height: 24px;

    @media ${device.laptop} {
      width: 20px;
      height: 20px;
    }
  }
`;

export const FilterLabel = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.thinFontColor};

@media ${device.laptop} {
  font-size: 18px;
}

@media ${device.mobileL} {
  font-size: 16px;
}
`;

export const ErrorText = styled.div`
  color: #ff0000;
  padding-bottom: 10px;
`;

export const Spinner = styled(Spin)`
  i {
    background-color: ${(props) => props.theme.secondaryColor};
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SocialImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const PrefixBox = styled.div`
  display: flex;
  flex-direction: row;
  color: #000 !important;
`;

export const DividerBox = styled.div`
  padding: 0 10px;

  @media ${device.laptop} {
    font-size: 0 8px;
  }

  @media ${device.mobileL} {
    font-size: 0 7px;
  }
`;

export default GlobalStyle;
