/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PosterCard } from '../../common';
import {
  Loader,
  PosterColumn,
  PosterContainer,
  PosterItem,
  RootContainer,
  Title,
} from './PosterListing.elements';
import { translate } from '../../i18n';
import { api, Status } from '../../api';
import { LIMITS, LISTING_MODE_LK, UNSAVE_SK, } from '../../constants';
import { Spinner } from '../../theme/styles';

const PosterListing = ({
  showName,
}) => {
  const status = useSelector(({ app }) => app.status);
  const [page, setPage] = useState(1);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    status === Status.SUCCESS && fetchData();
  }, [status]);

  useEffect(() => {
    page !== 1 && fetchData(page);
  }, [page]);

  const fetchData = (page = 1) => {
    if (apiStatus !== Status.LOADING) {
      setApiStatus(Status.LOADING);
      api
        .fetchPosterLinkList(page)
        .then(({ userResponse: newList }) => {
          setApiStatus(Status.SUCCESS);
          setList([...list, ...newList]);
          setHasMore(newList.length >= LIMITS.ITEMS_PER_PAGE);
        })
        .catch(() => {
          setApiStatus(Status.ERROR);
        });
    }
  }

  const loader = (
    <Loader>
      <Spinner />
    </Loader>
  );

  const renderList = () => {
    return (
      <InfiniteScroll
        dataLength={list.length}
        next={() => apiStatus === Status.SUCCESS && setPage(page + 1)}
        hasMore={hasMore}
        loader={loader}
        endMessage=''
      >
        <PosterContainer>
          {list.map((item, key) => (
            <PosterColumn
              key={key}
              xs={12}
              sm={6}
              md={6}
              lg={4}
            >
              <PosterItem>
                <PosterCard
                  mode={LISTING_MODE_LK}
                  entityType={item.entityType}
                  entityIdentifier={item.entityIdentifier}
                  posterIdentifier={item.posterIdentifier}
                  isSaved={item.isSaved === UNSAVE_SK}
                  favouriteIdentifier={item.favouriteIdentifier}
                  image={item.portraitImagePath.originalUrl}
                />
              </PosterItem>
            </PosterColumn>
          ))}
        </PosterContainer>
      </InfiniteScroll>
    );
  }

  return (
    <RootContainer>
      <Title>
        {translate('common.explore')}
      </Title>
      {renderList()}
    </RootContainer>
  );
}

export default PosterListing;