/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Form, } from 'antd';
import logo from '../../../assets/images/main-logo.png';
import {
    Container,
    ForgetButton,
    ForgetEmailItem,
    ForgetInputBox,
    ForgetPhoneItem,
    ForgetWrapper,
    FormContainer,
    FormRow,
    Heading,
    TextInfo
} from './ForgetPassword.elements';
import {
    forgotPassword,
    resetForgetPasswordState,
} from '../../../store/actions';
import {
    AuthGlobalIcon,
    DividerBox,
    ErrorText,
    GlobalLogo,
    PrefixBox
} from '../../../theme/styles';
import { ToggleView } from '../../../common';
import { useNavigate } from 'react-router-dom';
import { RESET_PASSWORD_SK } from '../../../constants';
import { translate } from '../../../i18n';
import { connect } from 'react-redux';
import { formatPhoneNumber, isNonEmptyString } from '../../../utils';
import { Status } from '../../../api';


const ForgetPassword = ({
    forgotPassword: _forgotPassword,
    resetForgetPasswordState: _resetForgetPasswordState,
    forgotPasswordStatus,
    forgotPasswordErrorMessage
}) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isEmail, setIsEmail] = useState(false);
    const [signupId, setSignupId] = useState('');

    useEffect(() => {
        return () => {
            //component will unmount
            _resetForgetPasswordState();
        };
    }, []);

    useEffect(() => {
        if (forgotPasswordStatus === Status.SUCCESS) {
            navigate('/otp-verify', { state: { otpType: RESET_PASSWORD_SK, signupId } })
        }
    }, [forgotPasswordStatus]);

    const onFinish = ({ email, phoneNumber }) => {
        let _phoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : phoneNumber;
        _forgotPassword(email, _phoneNumber);
        setSignupId(isNonEmptyString(email) ? email : _phoneNumber);
    };

    const resetForm = () => {
        form.resetFields();
    }

    const validateMessages = {
        required: translate('requiredErrorMessage.required').replace('!@#$%', '${label}'),
        types: {
            email: translate('requiredErrorMessage.invalidEmail'),
        },
    };

    const layout = {
        labelCol: { style: { display: 'none' } },
    };

    return (
        <div>
            <GlobalLogo to='/'>
                <AuthGlobalIcon src={logo} alt="" />
            </GlobalLogo>
            <Container>
                <Col xs={0} md={1} lg={4} />
                <ForgetWrapper xs={24} md={22} lg={16}>
                    <Heading>
                        {translate('forgotPasswordForm.title')}
                    </Heading>
                    <TextInfo>
                        {translate('forgotPasswordForm.subtitle')}
                    </TextInfo>

                    <FormRow>
                        <Col xs={0} md={3} lg={4} />
                        <Col xs={24} md={18} lg={16} >
                            <FormContainer
                                form={form}
                                name="normal_login"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                {...layout}
                            >
                                <ErrorText>
                                    {forgotPasswordErrorMessage}
                                </ErrorText>

                                <ForgetEmailItem
                                    label={translate('common.email')}
                                    name="email"
                                    isEmail={isEmail}
                                    rules={[
                                        {
                                            type: 'email',
                                            required: isEmail,
                                        },
                                    ]}
                                >
                                    <ForgetInputBox placeholder={translate('common.email')} />
                                </ForgetEmailItem>

                                <ForgetPhoneItem
                                    label={translate('common.phoneNumber')}
                                    name="phoneNumber"
                                    isEmail={isEmail}
                                    rules={[
                                        {
                                            required: !isEmail,
                                        },
                                    ]}
                                >
                                    <ForgetInputBox
                                        prefix={
                                            <PrefixBox>+95
                                                <DividerBox>|</DividerBox>
                                            </PrefixBox>
                                        }
                                        type="tel"
                                        inputmode="numeric"
                                        pattern="\d*"
                                    />
                                </ForgetPhoneItem>

                                <Form.Item>
                                    <ForgetButton htmlType="submit" loading={forgotPasswordStatus === Status.LOADING}>
                                        {forgotPasswordStatus !== Status.LOADING && translate('forgotPasswordForm.buttonLabel')}
                                    </ForgetButton>
                                </Form.Item>
                                <Form.Item>
                                    <ToggleView isEmail={isEmail} setIsEmail={setIsEmail} resetForm={resetForm} />
                                </Form.Item>
                            </FormContainer>
                        </Col>
                        <Col xs={0} md={3} lg={4} />
                    </FormRow>

                </ForgetWrapper>
                <Col xs={0} md={1} lg={4} />
            </Container>
        </div>

    );
}

const mapStateToProps = ({ auth }) => {
    const {
        forgotPasswordStatus,
        forgotPasswordErrorMessage,
    } = auth;
    return {
        forgotPasswordStatus,
        forgotPasswordErrorMessage,
    }
}

export default connect(mapStateToProps, {
    forgotPassword,
    resetForgetPasswordState,
})(ForgetPassword);