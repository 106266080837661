import styled from "styled-components";
import { Link } from "react-router-dom";
import { GlobalButton } from "../../../theme/styles";
import { device } from "../../../theme";

export const Container = styled.div`
  background: ${(props) => props.theme.body};
  min-height: 100vh;
  color: ${(props) => props.theme.fontColor};
  padding: 0 40px 0 60px;

  @media ${device.laptop} {
    padding: 0 30px 0 50px;
  }
  
  @media ${device.mobileL} {
    padding: 0 20px 0 40px;
  }

  @media ${device.mobileS} {
    padding: 0 10px 0 10px;
  }
`;

export const InterestWrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: right;
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 75px;
  text-align: center;
  padding-top: 20px;

  @media ${device.laptop} {
    font-size: 40px;
  }
  
  @media ${device.mobileL} {
    font-size: 25px;
  }
  
  @media ${device.mobileS} {
    font-size: 20px;
  }
`;

export const TextInfo = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const Skip = styled(Link)`
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.theme.fontColor};
`;

export const Text = styled.div`
  position: absolute;
  top: 38px;
  right: 60px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  z-index: 10;

  @media ${device.mobileS} {
    top: 26px;
    right: 60px;
  }
`;

export const SaveButton = styled(GlobalButton)`
  margin-top: 30px;
  margin-right: 20px;
  width: 175px;

  @media ${device.laptop} {
    margin-right: 20px;
  }

  @media ${device.tablet} {
    margin-right: 100px;
  }

  @media ${device.mobileL} {
    margin-right: 20px;
  }

  @media ${device.mobileS} {
    margin-right: 44px;
  }
`;
