import styled from 'styled-components';
import { device } from '../../theme/device';
import { GlobalButton } from '../../theme/styles';

export const RootContainer = styled.div`
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const Image = styled.img`
width: 290px;
height: auto;
object-fit: contain;
aspect-ratio: 908 / 777;

@media ${device.laptop} {
  width: 220px;
}

@media ${device.tablet} {
  width: 200px;
}
`;

export const GeneralImage = styled(Image)`
width: 240px;
aspect-ratio: 1 / 1;

@media ${device.laptop} {
  width: 200px;
}

@media ${device.tablet} {
  width: 140px;
}
`;

export const Text = styled.div`
width: 100%;
padding-top: 20px;
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 45px;
text-align: center;
color: ${({ theme }) => theme.fontColor};

@media ${device.laptop} {
  font-size: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

@media ${device.tablet} {
  font-size: 20px;
}
`;

export const SubText = styled.div`
padding-bottom: 20px;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;
text-align: center;
color: #FFFFFF;

@media ${device.laptop} {
  font-size: 16px;
}

@media ${device.tablet} {
  font-size: 14px;
}
`;

export const GeneralText = styled(Text)`
padding-bottom: 20px;
`;

export const Button = styled(GlobalButton)`
min-width: 175px;
border: 1px solid #FFFFFF;

  &:focus, :hover {
    border: 1px solid #FFFFFF;
  }
`;