import React from "react";
import {
  Col,
  Row,
  Skeleton,
} from "antd";
import {
  Actions,
  ActionsBox,
  DetailsContainer,
  Information,
  Poster,
  VideoDiv,
  VideoSkeleton,
  VideoWrapper,
} from "./VideoDetails.elements";

export const SkeletonVideo = () => {
  return (
    <Row>
      <Col xs={0} md={4} />
      <Col xs={24} md={16}>
        <VideoWrapper>
          <VideoSkeleton loading avatar active />
        </VideoWrapper>
      </Col>
      <Col xs={0} md={4} />
    </Row>
  );
}

export const SkeletonActions = () => {
  return (
    <Actions>
      <ActionsBox>
        <Skeleton.Button size='large' active />
        <Skeleton.Button size='large' active />
        <Skeleton.Avatar size='small' active />
        <Skeleton.Avatar size='small' active />
      </ActionsBox>
    </Actions>
  );
}


export const SkeletonDetails = () => {
  return (
    <DetailsContainer>
      <Information>
        <Skeleton active paragraph={{ rows: 7 }} />
      </Information>
      <Poster show />
    </DetailsContainer>
  )
}