import styled from "styled-components";
import { device, GlobalButton } from "../../../theme";

export const RootContainer = styled.div`
min-height: calc(100vh - 190px);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 60px 25%;

@media ${device.tablet} {
  padding: 60px 5%;
}
`;

export const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: normal;
text-align: center;
color: ${props => props.theme.fontColor};
padding-bottom: 30px;

@media ${device.tablet} {
  font-size: 16px;
}
`;

export const SubTitle = styled.div`
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: normal;
color: ${props => props.theme.fontColor};
padding-bottom: 20px;

@media ${device.tablet} {
  font-size: 14px;
}
`;

export const ImageWrapper = styled.div`
padding-bottom: 20px;
`;

export const Image = styled.img`
border: 1px solid;
`;

export const Text = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: normal;
color: ${props => props.red ? 'red' : props.theme.fontColor};
padding-bottom: 20px;
cursor: pointer;

@media ${device.tablet} {
  font-size: 14px;
}
`;

export const Button = styled(GlobalButton)`
width: 100%;
max-width: 400px;
`;