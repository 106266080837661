/* eslint-disable react-hooks/exhaustive-deps */
import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { api, Status } from '../../../api';
import { Dropdown, EmptyCard } from '../../../common';
import {
    LIMITS,
    EXPIRED_SK,
    SHOW_SK,
    VENUE_SK,
    USED_SK,
    PROFILE_MODE_LK,
    PUBLISHED_SK,
    EVENT_SK,
    LIVESTREAM_LK
} from '../../../constants';
import { translate } from '../../../i18n';
import {
    Filter,
    FilterIcon,
    FilterLabel,
    Spinner,
} from '../../../theme/styles';
import MyTickets from './MyTickets';
import {
    FilterBar,
    FilterOption,
    ListContainer,
    Loader,
    RootContainer,
    SelectedLabel,
    Text
} from './MyTicketsTab.elements';

const options = [
    {
        key: 0,
        name: `${translate('ticketing.all')}`
    },
    {
        key: SHOW_SK,
        name: `${translate('ticketing.show')}`
    },
    {
        key: EXPIRED_SK,
        name: `${translate('ticketing.expired')}`
    },
];

const MyTicketsTab = () => {
    const mode = PROFILE_MODE_LK;
    const [visible, setVisible] = useState(false);
    const [filter, setFilter] = useState(options[0]);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
    const status = useSelector(({ app }) => app.status);
    const navigate = useNavigate();

    useEffect(() => {
        if (status === Status.SUCCESS) {
            fetchUsedTicketHistory();
        }
    }, [status]);

    useEffect(() => {
        page !== 1 && fetchUsedTicketHistory(page, filter.key);
    }, [page]);

    useEffect(() => {
        setPage(1);
        !loading && fetchUsedTicketHistory(1, filter.key);
    }, [filter]);

    const fetchUsedTicketHistory = (page = 1, showStatus) => {
        setApiStatus(Status.LOADING);
        api
            .fetchUsedTicketHistory(page, showStatus)
            .then(({ userResponse: _list }) => {
                setApiStatus(Status.SUCCESS);
                setList(page === 1 ? _list : [...list, ..._list]);
                setLoading(false);
                setHasMore(_list.length >= LIMITS.ITEMS_PER_PAGE);
            })
            .catch(() => {
                setApiStatus(Status.ERROR);
            });
    }

    const handleFilterChange = (filter) => {
        setFilter(filter);
        setVisible(false);
    }

    const next = () => apiStatus === Status.SUCCESS && setPage(prevState => prevState + 1);

    const loader = (
        <Loader>
            <Spinner />
        </Loader>
    );

    const renderFilter = () => {
        const content = (
            <>
                {options.map(opt => (
                    <FilterOption
                        key={opt.key}
                        onClick={() => handleFilterChange(opt)}
                    >
                        <Text>{opt.name}</Text>
                    </FilterOption>
                ))}
            </>
        );

        return (
            <FilterBar hide={list.length === 0}>
                <SelectedLabel>
                    {filter.name}
                </SelectedLabel>
                <Dropdown
                    visible={visible}
                    placement="bottomRight"
                    trigger="click"
                    content={content}
                    onVisibleChange={(visible) => setVisible(visible)}
                >
                    <Filter>
                        <FilterIcon />
                        <FilterLabel>
                            {translate('searchResult.filters')}
                        </FilterLabel>
                    </Filter>
                </Dropdown>
            </FilterBar>
        );
    }

    if (!loading && list.length === 0) {
        return <EmptyCard />;
    }

    const handleSeeMore = () => {
        if (mode !== PROFILE_MODE_LK) {
            return;
        }
        navigate('/list/ticket', {
            state: {
                list,
                filter,
            }
        });
    }

    const viewShow = (ticket) => {
        if (ticket.status !== PUBLISHED_SK) {
            return;
        }
        navigate(`/details/${ticket.entityType === EVENT_SK
            ? LIVESTREAM_LK.toLowerCase()
            : ticket.privacy}/${btoa(ticket.entityIdentifier || ticket.eventIdentifier)
            }?entityType=${ticket.entityType}`);
    }

    return (
        <RootContainer>
            {renderFilter()}
            <InfiniteScroll
                dataLength={list.length}
                next={next}
                hasMore={hasMore}
                loader={loader}
                endMessage=''
            >
                <ListContainer>
                    {list.map((ticket, key) => (
                        <Col
                            key={key}
                            xs={24}
                            sm={12}
                            lg={12}
                            xl={8}
                            xxl={6}
                        >
                            <MyTickets
                                showName={ticket?.title}
                                // hasMore={key === 5 && mode === PROFILE_MODE_LK}
                                image={ticket?.thumbUrl}
                                entityType={ticket?.entityType}
                                startDate={ticket?.entityType === VENUE_SK
                                    ? ticket.publishStartDateTime
                                    : ticket.broadcastStartDateTime
                                }
                                endDateTime={ticket?.publishEndDateTime}
                                ticketCode={ticket.ticketList.ticketCode}
                                used={ticket.ticketList.ticketStatus === USED_SK}
                                status={ticket?.status}
                                ticketMessage={ticket.ticketList.ticketMessage}
                                seeMore={handleSeeMore}
                                viewShow={() => viewShow(ticket)}
                            />
                        </Col>
                    ))}
                </ListContainer>
            </InfiniteScroll>
        </RootContainer>
    )
}

export default MyTicketsTab;