import React from 'react';
import {
  ContentContainer,
  DownloadImage,
  Icon,
  ImageWrapper,
  Modal,
} from './PosterCard.elements';
import { DownloadOutlined } from '@ant-design/icons';
import { translate } from '../../i18n';
import { getAccessKey, getBuildConfig } from '../../config/buildConfig';
const { GetObjectCommand, S3Client } = require('@aws-sdk/client-s3');

const ImageViewer = ({
  visible,
  imageUrl,
  handleClose,
}) => {

  const buildConfig = getBuildConfig();
  const AccessKey = getAccessKey();
  const getObject = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const client = new S3Client({
          region: buildConfig.awsRegion,
          credentials: {
            accessKeyId: AccessKey,
            secretAccessKey: buildConfig.awsSecretAccessKey,
          },
        });

        const getObjectCommand = new GetObjectCommand({
          Bucket: buildConfig.awsBucket,
          Key: imageUrl.split('.com/')[1],
        });

        const response = await client.send(getObjectCommand);
        resolve(response);

      } catch (err) {
        // Handle the error or throw
        return reject(err)
      }
    })
  }


  const download = () => {
    getObject()
      .then(response => {
        const reader = response.Body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      // Create a new response out of the stream
      .then(stream => new Response(stream))
      // Create an object URL for the response
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      // Update image
      .then(url => {
        var a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = url;
        a.download = `${translate('common.appName')}_Poster.png`;
        a.click();
      })
      .catch(err => console.error(err));
  }
  return (
    <Modal
      viewer
      visible={visible}
      centered
      footer={false}
      maskClosable={false}
      width='100vw'
      height='100vh'
      onCancel={handleClose}
    >
      <Icon onClick={download}>
        <DownloadOutlined />
      </Icon>
      <ContentContainer id='ticket-qr-image'>
        <ImageWrapper>
          <DownloadImage
            alt='img'
            src={imageUrl}
          />
        </ImageWrapper>
      </ContentContainer>
    </Modal>
  );
}

export default ImageViewer;