import { takeLatest, put, call, select, } from 'redux-saga/effects';
import {
  FETCH_CATEGORY_LIST_ERROR,
  FETCH_CATEGORY_LIST_LOADING,
  FETCH_CATEGORY_LIST_REQUEST,
  FETCH_CATEGORY_LIST_SUCCESS,
  INITIALIZE_APP_ERROR,
  INITIALIZE_APP_LOADING,
  INITIALIZE_APP_REQUEST,
  INITIALIZE_APP_SUCCESS,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_LOADING,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_LOADING,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
} from "../../constants/actionTypes";
import {
  sessionExpiredAction,
  apiRequestFailedAction,
} from '..';
import {
    LIMITS,
  LOCAL_STORAGE_KEYS,
} from '../../constants';
import {
  api,
  Status,
} from '../../api';
import { getCurrentTheme } from '../../theme';
import { browserNameDetect, getUniqueDeviceId } from '../../utils/uniqueDeviceId';

// worker saga: Initialize necessary things before app starts
function* initializeApp() {
  try {
    yield put({ type: INITIALIZE_APP_LOADING });
    const { deviceId, deviceName } = getDeviceInfo();
    let sessionId = localStorage.getItem(LOCAL_STORAGE_KEYS.sessionId);
    if (sessionId === null || sessionId === undefined) {
      sessionId = '';
    }
    api.init({
      deviceId,
      deviceName,
      sessionId,
      sessionExpiredAction,
      apiRequestFailedAction,
    });
    // get user prefered theme, default theme is dark
    let theme = getCurrentTheme();
    yield put({
      type: INITIALIZE_APP_SUCCESS,
      payload: {
        sessionStatus: sessionId !== '' ? Status.SUCCESS : Status.DEFAULT,
        theme,
      },
    });
    yield put({
      type: FETCH_CATEGORY_LIST_REQUEST,
    });
    if (sessionId) {
      const userIdentifier = localStorage.getItem(LOCAL_STORAGE_KEYS.userIdentifier);
      // Dispatch action to hit get user details API
      yield put({
        type: GET_USER_DETAILS_REQUEST,
        payload: {
          userIdentifier,
        },
      });
      // Dispatch action to hit fetch notification list API
      yield put({
        type: FETCH_NOTIFICATIONS_REQUEST,
        payload: {
          page: 1,
        },
      });
    }

  } catch (error) {
    yield put({
      type: INITIALIZE_APP_ERROR,
      payload: { errorMessage: error.message },
    });
  }
}

// worker saga: Calls the categories API.
function* fetchCategoryList() {
  try {
    yield put({ type: FETCH_CATEGORY_LIST_LOADING });
    // api call
    const response = yield call({ context: api, fn: api.fetchCategoryList });
    // parse the data from response
    const {
      userResponse
    } = response;
    // send parsed data into action payload
    yield put({
      type: FETCH_CATEGORY_LIST_SUCCESS,
      payload: {
        categoryList: userResponse,
      },
    });

  } catch (error) {
    yield put({
      type: FETCH_CATEGORY_LIST_ERROR,
      payload: { errorMessage: error.message },
    });
  }
}

//worker saga: Calls the notifications API.
function* fetchNotifications({ payload: { page } }) {
  try {
    yield put({ type: FETCH_NOTIFICATIONS_LOADING });

    const { notifications: { notificationList: _list }}=yield select(getDataFromStore);
    // api call
    const response = yield call(
      { context: api, fn: api.fetchNotifications },
      { page },
    );
    // parse the data from response
    const {
      userResponse: {
        notificationList,
      }
    } = response;

    const notifications = {
        notificationList: [ ..._list, ...notificationList],
        hasMore: notificationList.length >= LIMITS.ITEMS_PER_PAGE,
    }

    // send parsed data into action payload
    yield put({
      type: FETCH_NOTIFICATIONS_SUCCESS,
      payload: {
        notifications,
      },
    });

  } catch (error) {
    yield put({
      type: FETCH_NOTIFICATIONS_ERROR,
      payload: { errorMessage: error.message },
    });
  }
}

//worker saga: Calls the getUserDeatils API.
function* getUserDetails() {
  try {
    yield put({ type: GET_USER_DETAILS_LOADING });
    //api call
    const response = yield call({ context: api, fn: api.fetchUserDetails });
    // parse the data from response
    const {
      userResponse
    } = response;
    // send parsed data into action payload
    yield put({
      type: GET_USER_DETAILS_SUCCESS,
      payload: userResponse,
    });
  } catch (error) {
    yield put({
      type: GET_USER_DETAILS_ERROR,
      payload: { errorMessage: error.message },
    });
  }
}

function getDeviceInfo() {

const deviceId = localStorage.getItem(LOCAL_STORAGE_KEYS.deviceId) || getUniqueDeviceId();
  const deviceName = localStorage.getItem(LOCAL_STORAGE_KEYS.deviceName) || browserNameDetect();
  return {
    deviceId,
    deviceName,
  };
}

/**
 * Fetch data from redux store
 *
 * NOTE: Don't overfill this for all data acess from redux store, create new
 * accessor if requirement is different or gets exhaustive
 *
 * @return {Object} return user identifier
 */
 const getDataFromStore = ({ app }) => {
    const { notifications } = app;
    return {
      notifications,
    };
  };

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(INITIALIZE_APP_REQUEST, initializeApp);
  yield takeLatest(FETCH_CATEGORY_LIST_REQUEST, fetchCategoryList);
  yield takeLatest(FETCH_NOTIFICATIONS_REQUEST, fetchNotifications);
  yield takeLatest(GET_USER_DETAILS_REQUEST, getUserDetails);
}