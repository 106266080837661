import React from 'react';
import { Skeleton as StyledSkeleton } from './Upcoming.elements';
import { SectionTemplate } from '../../../common';
import { translate } from '../../../i18n';

const Skeleton = () => {
  return (
    <SectionTemplate
      loading='true'
      title={translate('home.upcoming')}
    >
      <StyledSkeleton loading avatar active />
      <StyledSkeleton loading avatar active />
      <StyledSkeleton loading avatar active />
      <StyledSkeleton loading avatar active />
    </SectionTemplate>
  );
}

export default Skeleton;