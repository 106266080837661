import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';
import {
  HOME_MODE_LK,
  LISTING_MODE_LK,
  LOCAL_STORAGE_KEYS,
  POSTER_LK,
  POSTER_SK,
} from '../../constants';
import { translate } from '../../i18n';
import LoginPrompt from '../LoginPrompt/LoginPrompt';
import ImageViewer from './ImageViewer';
import {
  Bottom,
  FavIcon,
  Image,
  Item,
} from './PosterCard.elements';

const PosterCard = ({
  entityType,
  entityIdentifier,
  posterIdentifier,
  isSaved,
  favouriteIdentifier,
  image,
  mode = HOME_MODE_LK,
  deleteFavouriteCallback = () => { },
}) => {
  const navigate = useNavigate();
  const loggedInUserIdentifier = localStorage.getItem(LOCAL_STORAGE_KEYS.userIdentifier);
  const [_isSaved, setIsSaved] = useState(isSaved);
  const [_favouriteIdentifier, setFavouriteIdentifier] = useState(favouriteIdentifier);
  const [visible, setVisible] = useState(false);

  const handleFavourite = () => {
    setIsSaved(prevState => {
      return !prevState;
    });
    if (_isSaved) {
      api
        .deleteFavourite({
          favouriteIdentifier: _favouriteIdentifier,
        })
        .then(() => {
          deleteFavouriteCallback(_favouriteIdentifier);
        })
        .catch(() => { });
    }
    else {
      api
        .saveFavourite({
          entityIdentifier: posterIdentifier,
          entityType: POSTER_SK,
          userIdentifier: loggedInUserIdentifier,
        })
        .then(({ userResponse: { favouriteIdentifier } }) => {
          setFavouriteIdentifier(favouriteIdentifier);
        })
        .catch(() => { });
    }
  }

  const handleClick = () => {
    if (mode !== LISTING_MODE_LK) {
      setVisible(true);
      return;
    }
    navigate(`/details/${POSTER_LK.toLowerCase()
      }/${btoa(entityIdentifier)
      }?entityType=${entityType}`);
  }

  const handleBottomClick = () => {
    if (mode === LISTING_MODE_LK) {
      handleClick();
    } else {
      setVisible(true);
    }
  }

  return (
    <>
      <Item
        homeMode={mode === HOME_MODE_LK}
      >
        <Image
          preview={false}
          src={image}
          onClick={handleClick}
        />
        <Bottom onClick={handleBottomClick}>
          <LoginPrompt
            text={translate('loginPrompt.toLikeThis')}
            onClick={handleFavourite}
          >
            <FavIcon filled={_isSaved} />
          </LoginPrompt>
        </Bottom>
      </Item>
      <ImageViewer
        visible={visible}
        imageUrl={image}
        handleClose={() => setVisible(false)}
      />
    </>
  );
}

export default PosterCard;