import { takeLatest, put, call } from 'redux-saga/effects';
import { api } from '../../api';
import { EDIT_PROFILE_ERROR, EDIT_PROFILE_LOADING, EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, UPLOAD_PROFILE_IMAGE_ERROR, UPLOAD_PROFILE_IMAGE_LOADING, UPLOAD_PROFILE_IMAGE_REQUEST, UPLOAD_PROFILE_IMAGE_SUCCESS } from "../../constants";

/**
 * worker saga: Calls the Put edit profile API.
 */
function* editProfile({ payload: {
    name,
    email,
    mobileNumber,
    dob,
    gender,
    countryIdentifier,
    stateIdentifier,
    interestIdentifiers
} }) {
    try {
        yield put({ type: EDIT_PROFILE_LOADING });
        // api call
        const response = yield call(
            { context: api, fn: api.editProfile },
            { name, email, mobileNumber, dob, gender, countryIdentifier, stateIdentifier, interestIdentifiers },
        );
        // parse the data from response
        const {
            userResponse
        } = response;
        yield put({
            type: EDIT_PROFILE_SUCCESS,
            payload: userResponse,
        });

    } catch (error) {
        yield put({
            type: EDIT_PROFILE_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

/**
 * worker saga: Calls upload profile image API.
 */
function* uploadProfileImage({ payload: { file, fileDirectory, fileCategory, } }) {
    try {
        yield put({ type: UPLOAD_PROFILE_IMAGE_LOADING });
        // api call
        const response = yield call(
            { context: api, fn: api.uploadProfile },
            { file, fileDirectory, fileCategory }
        );
        // parse the data from response
        const {
            userResponse: {
                profileImage,
            }
        } = response;

        // send parsed data into action payload
        yield put({
            type: UPLOAD_PROFILE_IMAGE_SUCCESS,
            payload: {
                profileImage,
            },
        });

    } catch (error) {
        yield put({
            type: UPLOAD_PROFILE_IMAGE_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}


// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
    yield takeLatest(EDIT_PROFILE_REQUEST, editProfile);
    yield takeLatest(UPLOAD_PROFILE_IMAGE_REQUEST, uploadProfileImage);
}