import i18n from 'i18n-js';
import { LOCAL_STORAGE_KEYS } from '../constants';

/**
 * Translates text.
 *
 * @param key The i18n key.
 */
export function translate(key, options) {
  return key ? i18n.t(key, options) : null;
}

/**
 * Change app language
 *
 * @param {string} languageTag - one of "en"|"my"
 */
export function changeLocale(languageTag) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.userPreferedLangage, languageTag);
  i18n.locale = languageTag;
}

/**
 * Reset Locale to english
 */
export function resetLocale() {
  const languageTag = 'en';
  localStorage.setItem(LOCAL_STORAGE_KEYS.userPreferedLangage, languageTag);
  i18n.locale = languageTag;
}

/**
 *  get current used locale
 * @return { en|my}
 */
export function getCurrentLocale() {
  return i18n.currentLocale();
}
