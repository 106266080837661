// ============================================================
// ======================= App level ==========================
// ============================================================
export const INITIALIZE_APP_REQUEST = 'INITIALIZE_APP_REQUEST';
export const INITIALIZE_APP_LOADING = 'INITIALIZE_APP_LOADING';
export const INITIALIZE_APP_SUCCESS = 'INITIALIZE_APP_SUCCESS';
export const INITIALIZE_APP_ERROR = 'INITIALIZE_APP_ERROR';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_LOADING = 'LOGOUT_LOADING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const API_REQUEST_FAILED = 'API_REQUEST_FAILED';
export const CHANGE_THEME = 'CHANGE_THEME';
// ============================================================
// =========================== Login ==========================
// ============================================================
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const SOCIAL_LOGIN_REQUEST = 'SOCIAL_LOGIN_REQUEST';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';
// ============================================================
// ========================== Signup ==========================
// ============================================================
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_LOADING = 'SIGNUP_LOADING';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const RESET_SIGNUP_STATE = 'RESET_SIGNUP_STATE';
// ============================================================
// =================== OtpVerificationScreen ==================
// ============================================================
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_LOADING = 'VERIFY_OTP_LOADING';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';
export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_LOADING = 'RESEND_OTP_LOADING';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_ERROR = 'RESEND_OTP_ERROR';
export const RESET_OTP_VERIFICATION_STATE = 'RESET_OTP_VERIFICATION_STATE';
// ============================================================
// ===================== ForgotPassword =======================
// ============================================================
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_FORGOT_PASSWORD_STATE = 'RESET_FORGOT_PASSWORD_STATE';
// ============================================================
// ===================== ResetPassword ========================
// ============================================================
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_CHANGE_PASSWORD_STATE = 'RESET_CHANGE_PASSWORD_STATE';
// ============================================================
// ======================= Category List ======================
// ============================================================
export const FETCH_CATEGORY_LIST_REQUEST = 'FETCH_CATEGORY_LIST_REQUEST';
export const FETCH_CATEGORY_LIST_LOADING = 'FETCH_CATEGORY_LIST_LOADING';
export const FETCH_CATEGORY_LIST_SUCCESS = 'FETCH_CATEGORY_LIST_SUCCESS';
export const FETCH_CATEGORY_LIST_ERROR = 'FETCH_CATEGORY_LIST_ERROR';
// ============================================================
// ================= notifications ============================
// ============================================================
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_LOADING = 'FETCH_NOTIFICATIONS_LOADING';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';
// =============================================================
// ======================= interestIdentifiers =================
// =============================================================
export const INTERESTS_REQUEST = 'INTERESTS_REQUEST';
export const INTERESTS_LOADING = 'INTERESTS_LOADING';
export const INTERESTS_SUCCESS = 'INTERESTS_SUCCESS';
export const INTERESTS_ERROR = 'INTERESTS_ERROR';
// ============================================================
// ================== ProfileScreen ===========================
// ============================================================
export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_LOADING = 'GET_USER_DETAILS_LOADING';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';
// =============================================================
// ========================== editProfile ======================
// =============================================================
export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_LOADING = 'EDIT_PROFILE_LOADING';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_ERROR = 'EDIT_PROFILE_ERROR';
export const RESET_EDIT_PROFILE_STATE = 'RESET_EDIT_PROFILE_STATE';
// ============================================================
// ================= uploadProfile ============================
// ============================================================
export const UPLOAD_PROFILE_IMAGE_REQUEST = 'UPLOAD_PROFILE_IMAGE_REQUEST';
export const UPLOAD_PROFILE_IMAGE_LOADING = 'UPLOAD_PROFILE_IMAGE_LOADING';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_ERROR = 'UPLOAD_PROFILE_IMAGE_ERROR';
