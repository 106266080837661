import React from 'react';
import { translate } from '../../i18n';
import { Container, Spinner, TextContent, Title } from './PrivacyPolicy.elements';

const PrivacyPolicy = ({
    loading,
}) => {
    return (
        <Container>
            <Title>
                {translate('footer.privacyPolicy')}
            </Title>
            <TextContent id='pp-id'></TextContent>
            {loading && <Spinner />}
        </Container >
    );
}

export default PrivacyPolicy;