import { Col, Row, Spin } from 'antd';
import styled from 'styled-components';
import { device } from '../../theme';
import { ListContainer as ListContainerGlobal } from "../../theme/styles";

export const RootContainer = styled.div`
min-height: calc(100vh - 190px);
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: ${props => props.theme.fontColor};
  margin-left: 60px;
  padding-top: 20px;

@media ${device.laptop} {
  margin-left: 30px;
  font-size: 25px;
  padding-top: 10px;
}

@media ${device.mobileL} {
  margin-left: 10px;
  font-size: 20px;
}
`;


export const PosterContainer = styled(ListContainerGlobal)`
    padding-top: 20px !important;
  
@media ${device.laptop} {
  padding-top: 10px !important;
}

@media ${device.mobileL} {
  padding-top: 0 !important;
}
`;

export const PosterColumn = styled(Col)``;

export const PosterItem = styled.div`
width: calc(100% - 20px);
height: 100%;

@media ${device.mobileL} {
  width: calc(100% - 10px);
}
`;

export const Loader = styled.div`
text-align: center;
`;
