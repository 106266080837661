import React, { useEffect } from 'react';
import {
    Col,
    Form,
    message,
    Row,
    Space,
} from 'antd';
import { useState } from 'react';
import { api, Status } from '../../../api';
import { translate } from '../../../i18n';
import { cutPrefixPhoneNumber, formatPhoneNumber, getFormattedPrice, isPhoneNumberValid } from '../../../utils';
import {
    BillTextArea,
    BottomBox,
    Button,
    CancelButton,
    DetailsContainer,
    DividerBox,
    EditLabel,
    Heading,
    Input,
    InputBox,
    PrefixBox,
    R,
    RootContainer,
    Spinner,
    Title
} from './BillingInfo.elements';
import { useNavigate } from 'react-router-dom';

export const Loading = () => {
    return (
        <RootContainer>
            <Spinner />
        </RootContainer>
    );
}

const BillingInfo = ({
    image,
    apiStatus,
    billingInfoList,
    total,
    datetime,
    endtime,
    title,
    ticketCount,
    entityType,
    price,
    ticketInfoIdentifier,
    entityIdentifier,
}) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [billingApiStatus, setBillingApiStatus] = useState(Status.DEFAULT);
    const [state, setState] = useState({
        name: '',
        mobileNumber: '',
        email: '',
        billAddress: '',
        billCity: '',
    });

    useEffect(() => {
        if (billingInfoList.customerPhone) {
            setState({
                name: billingInfoList.customerName,
                mobileNumber: cutPrefixPhoneNumber(billingInfoList.customerPhone),
                email: billingInfoList.customerEmail,
                billAddress: billingInfoList.billAddress,
                billCity: billingInfoList.billCity,
            })
        }

    }, [billingInfoList]);

    const renderHeader = () => {
        return (
            <>
                <Heading>
                    {translate('ticketing.checkOutForm')}
                </Heading>
                <Row>
                    <Col xs={0} md={3} lg={5} />
                    <Col xs={24} md={6} lg={6}>
                        <Title>
                            {translate('ticketing.billingInformation')}
                        </Title>
                    </Col>
                </Row>
            </>
        );
    }

    const showMessage = ({ content, type = 'success' }) => {
        message[
            type === 'error'
                ? 'error'
                : 'success'
        ]({
            content,
            style: {
                marginTop: '80px'
            }
        });
    }

    const handleSubmit = () => {
        let _mobileNumber = formatPhoneNumber(state.mobileNumber);
        if (!isPhoneNumberValid(_mobileNumber)) {
            showMessage({
                content: 'Phone number is not valid',
                type: 'error'
            });
            return;
        }
        setBillingApiStatus(Status.LOADING);
        api.saveCustomerBillingInfos({
            customerName: state.name,
            customerEmail: state.email,
            customerPhone: _mobileNumber,
            billCity: state.billCity,
            billAddress: state.billAddress,
        })
            .then(({ userResponse: { customerBillingInfoIdentifier } }) => {
                setBillingApiStatus(Status.SUCCESS);
                navigate('/ticket-purchase/bill-payment', {
                    state: {
                        image,
                        total,
                        datetime,
                        endtime,
                        title,
                        ticketCount,
                        entityType,
                        price,
                        ticketInfoIdentifier,
                        entityIdentifier,
                        customerBillingInfoIdentifier
                    }
                })
            })
            .catch((error) => {
                setBillingApiStatus(Status.ERROR);
                showMessage({
                    content: error.message,
                    type: 'error'
                });
            })
    }

    const checkIsButtonDisabled = () => {
        return !state.name || !state.mobileNumber || !state.email || !state.billAddress || !state.billCity
    }

    const cancel = () => {
        navigate(-1);
    }

    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }
    const renderPaymentForm = () => {
        return (
            <DetailsContainer>
                <Form
                    form={form}
                    name="create_payment"
                    onSubmit={handleSubmit}
                    scrollToFirstError
                >
                    <Row>
                        <Col xs={0} md={3} lg={5} />
                        <Col xs={24} md={6} lg={4}>
                            <EditLabel>
                                {translate('common.name')}<R>*</R>
                            </EditLabel>
                        </Col>
                        <Col xs={24} md={12} lg={10}>
                            <Input
                                name='name'
                                value={state.name}
                                onChange={handleChange}
                                left
                            />
                        </Col>
                        <Col xs={0} md={3} lg={5} />

                        <Col xs={0} md={3} lg={5} />
                        <Col xs={24} md={6} lg={4}>
                            <EditLabel>
                                {translate('common.phoneNumber')}<R>*</R>
                            </EditLabel>
                        </Col>
                        <Col xs={24} md={12} lg={10}>
                            <InputBox
                                prefix={
                                    <PrefixBox>+95
                                        <DividerBox>|</DividerBox>
                                    </PrefixBox>
                                }
                                name='mobileNumber'
                                value={state.mobileNumber}
                                onChange={handleChange}
                                type="tel"
                                inputmode="numeric"
                                pattern="\d*"
                            />
                        </Col>
                        <Col xs={0} md={3} lg={5} />

                        <Col xs={0} md={3} lg={5} />
                        <Col xs={24} md={6} lg={4}>
                            <EditLabel>
                                {translate('common.email')}<R>*</R>
                            </EditLabel>
                        </Col>
                        <Col xs={24} md={12} lg={10}>
                            <InputBox
                                name='email'
                                value={state.email}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={0} md={3} lg={5} />

                        <Col xs={0} md={3} lg={5} />
                        <Col xs={24} md={6} lg={4}>
                            <EditLabel>
                                {translate('ticketing.address')}<R>*</R>
                            </EditLabel>
                        </Col>
                        <Col xs={24} md={12} lg={10}>
                            <BillTextArea
                                name='billAddress'
                                value={state.billAddress}
                                onChange={handleChange}
                                right
                                rows={4} />
                        </Col>
                        <Col xs={0} md={3} lg={5} />

                        <Col xs={0} md={3} lg={5} />
                        <Col xs={24} md={6} lg={4}>
                            <EditLabel>
                                {translate('ticketing.city')}<R>*</R>
                            </EditLabel>
                        </Col>
                        <Col xs={24} md={12} lg={10}>
                            <InputBox
                                name='billCity'
                                value={state.billCity}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={0} md={3} lg={5} />

                        <Col xs={0} md={3} lg={5} />
                        <Col xs={24} md={6} lg={4}>
                            <EditLabel>
                                {translate('ticketing.total')}
                            </EditLabel>
                        </Col>
                        <Col xs={24} md={12} lg={10}>
                            <Input
                                value={`${getFormattedPrice(total)} MMK`}
                            />
                        </Col>
                        <Col xs={0} md={3} lg={5} />
                    </Row>
                    <BottomBox>
                        <Space size='large'>
                            <CancelButton onClick={cancel}>
                                {translate('ticketing.back')}
                            </CancelButton>
                            <Button
                                onClick={handleSubmit}
                                disabled={checkIsButtonDisabled()}
                                loading={billingApiStatus === Status.LOADING}
                            >
                                {translate('ticketing.next')}
                            </Button>
                        </Space>
                    </BottomBox>
                </Form>
            </DetailsContainer>
        );
    }
    return (
        <RootContainer>
            {renderHeader()}
            {apiStatus === Status.LOADING && <Loading />}
            {apiStatus === Status.SUCCESS && renderPaymentForm()}
        </RootContainer>
    );
}

export default BillingInfo;