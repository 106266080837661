import styled from "styled-components";
import { Spin, Tabs as AntTabs } from "antd";
import {
  device,
  ListContainer as ListContainerGlobal,
} from "../../../theme";

export const Tabs = styled(AntTabs)`
  .ant-tabs-tab:first-child {
    margin: 0;

    div {
      padding-right: 15px;
      border-bottom: 0 !important;
      border-right: 2px solid ${props => props.theme.secondaryColor} !important;
    }
  }

  .ant-tabs-tab:nth-child(2) {
    margin: 0;

    div {
      padding-left: 15px;
      border-bottom: 0 !important;
    }
  }
  .ant-tabs-nav {
    &::before {
      border: none;
    }
  }

  .ant-tabs-tab-btn {
    color: rgba(190, 191, 207, 0.8);
    border: none !important;
    line-height: 0.8;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: rgba(190, 191, 207, 0.8);

      @media ${device.laptop} {
        font-size: 14px;
      }

      @media ${device.mobileL} {
        font-size: 12px;
      }
      
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props => props.theme.fontColor};
    text-decoration: underline;
    border: none !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 0.8;

      @media ${device.laptop} {
        font-size: 14px;
      }

      @media ${device.mobileL} {
        font-size: 12px;
      }
  }

  .infinite-scroll-component {
    display: inline !important;
  }

  .ant-tabs-content-holder{
    border-top: 1px solid #BEBFCF;
    padding-top: 25px;
  }
`;

export const VerticalBox = styled.div`
  border-left: 2px solid orange;
`;

export const Loader = styled.div`
text-align: center;
`;

export const ListContainer = styled(ListContainerGlobal)`
padding: 0 !important;
padding-bottom: 40px !important;
`;