import React from 'react';
import {
  CardActions,
  Link,
} from '../../common';
import {
  Card,
  Image,
  LiveIcon,
  LiveIconWrapper,
  LiveText,
  RootContainer,
} from './LivestreamCard.elements';
import { DISCOVER_MODE_LK, HOME_MODE_LK, LIVESTREAM_LK } from '../../constants';
import { getFormattedDateTime, isLive } from '../../utils';
const { Meta } = Card;

const LivestreamCard = ({
  identifier,
  entityType,
  image,
  broadcastStartDateTime,
  broadcastEndDateTime,
  description,
  price,
  isSaved,
  favouriteIdentifier,
  link,
  expired = false,
  mode = HOME_MODE_LK,
  deleteFavouriteCallback = () => { },
}) => {

  const renderLiveIcon = () => {
    if (isLive(broadcastStartDateTime, broadcastEndDateTime)) {
      return (
        <LiveIconWrapper>
          <LiveIcon status='error' />
          <LiveText>
            LIVE
          </LiveText>
        </LiveIconWrapper>
      )
    }
    return '';
  }

  return (
    <RootContainer>
      <Link
        to={`/details/${LIVESTREAM_LK.toLowerCase()}/${btoa(identifier)}?entityType=${entityType}`}
        mode={mode}
        type={LIVESTREAM_LK}
        prevented={expired}
      >
        <Card
          homeMode={mode === HOME_MODE_LK}
          discoverMode={mode === DISCOVER_MODE_LK}
          cover={
            <>
              {renderLiveIcon()}
              <Image
                alt="livestreamimg"
                src={image}
              />
            </>
          }
        >
          <Meta title={getFormattedDateTime(broadcastStartDateTime)} description={description} />
        </Card>
        <CardActions
          entityType={entityType}
          entityIdentifier={identifier}
          type={LIVESTREAM_LK}
          label={price}
          mode={mode}
          isSaved={isSaved}
          favouriteIdentifier={favouriteIdentifier}
          link={link}
          deleteFavouriteCallback={deleteFavouriteCallback}
        />
      </Link>
    </RootContainer>
  );
}

export default LivestreamCard;