import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { translate } from '../../../../i18n';
import Dropdown from '../../../Dropdown/Dropdown';
import {
  DiscoverLinks,
  NavItem,
  SelectItem,
} from '../../Navbar.elements';
import {
  MenuDesktop,
  MenuItemLabel,
  MenuMobile,
  MenuMobileInner,
  SubNavItems,
} from './Discover.elements';

const Discover = ({
  close = () => { },
}) => {
  const { pathname } = useLocation();
  const categoryList = useSelector(({ app }) => app.categoryList);
  const [discoverClicked, setDiscoverClicked] = useState(false);
  const [click, setClick] = useState(false);

  const content = (
    categoryList.map((item, key) => {
      return (
        <DiscoverLinks
          key={key}
          to={`/discover/${item.name}`}
          state={{
            categoryIdentifier: item.categoryIdentifier,
            bannerImageUrl: item.bannerImageUrl,
          }}
          onClick={() => {
            setDiscoverClicked(false);
            close();
            setClick(false);
          }}
        >
          <SelectItem>
            {item.name}
          </SelectItem>
        </DiscoverLinks>
      );
    })
  );

  const Menu = () => (
    <MenuDesktop>
      <NavItem current={pathname.includes('/discover')}>
        <Dropdown
          visible={discoverClicked}
          placement="bottomRight"
          content={content}
          onVisibleChange={(v) => setDiscoverClicked(v)}
        >
          {translate('navMenu.discover')}
        </Dropdown>
      </NavItem>
    </MenuDesktop>
  );

  const MobileMenu = () => (
    <>
      <MenuMobile>
        <NavItem
          current={pathname.includes('/discover')}
          onClick={() => setClick(!click)}
        >
          <MenuMobileInner>
            <MenuItemLabel>
              {translate('navMenu.discover')}
            </MenuItemLabel>
            {click ? <UpOutlined /> : <DownOutlined />}
          </MenuMobileInner>
        </NavItem>
      </MenuMobile>
      <SubNavItems show={click}>
        {content}
      </SubNavItems>
    </>
  )

  return (
    <>
      <Menu />
      <MobileMenu />
    </>
  );
};

export default Discover;