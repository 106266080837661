/* eslint-disable no-unused-vars */
import { getContentMD5 } from "./helpers/headerUtils";
import Request from "./Request";

class Api {
  constructor() {
    this.request = new Request();
  }

  /**
   * @param {Object} options                - contain extra configuration for request object
   * @param {string} deviceId               - device id
   * @param {string} deviceName             - device name
   * @param {string} sessionId              - session id if user is logged in
   * @param {function} sessionExpiredAction - A redux action which will be dispatch if session is expired
   */
  init({ deviceId, deviceName, sessionId, sessionExpiredAction, apiRequestFailedAction, }) {
    this.request.init({
      deviceId,
      deviceName,
      sessionId,
      sessionExpiredAction,
      apiRequestFailedAction,
    });
  }

  // ================================ APIs =================================
  login({ email, phoneNumber, password, userRole, ipAddress }) {
    const userRequest = { userRole, ipAddress };
    userRequest.password = getContentMD5(password);
    userRequest.ipAddress = ipAddress;

    if (email) {
      userRequest.email = email;
    } else {
      // in api, the key is 'mobileNumber'
      userRequest.mobileNumber = phoneNumber;
    }

    return this.request.post('/v01/login', null, { userRequest });
  }

  logout() {
    return this.request.post('/v01/logout', null, null);
  }

  signup({ userName, email, phoneNumber, password, userRole }) {
    const userRequest = { userRole, userName };
    userRequest.password = getContentMD5(password);
    if (email) {
      userRequest.email = email;
    } else {
      // in api, the key is 'mobileNumber'
      userRequest.mobileNumber = phoneNumber;
    }
    return this.request.post('/v01/registration', null, { userRequest });
  }

  verifyOtp({ otp, userIdentifier, otpType, ipAddress, }) {
    const userRequest = {
      otp,
      otpType,
      userIdentifier,
      ipAddress
    };
    return this.request.post('/v01/verifyOtp', null, { userRequest });
  }

  resendOtp({ otpType, userIdentifier }) {
    const userRequest = {
      otpType,
      userIdentifier,
    }
    return this.request.post('/v01/resendOtp', null, { userRequest });
  }

  saveInterests({ interestIdentifiers }) {
    const userRequest = {
      interestIdentifiers,
    }
    return this.request.put('/v01/editProfile', null, { userRequest });
  }

  forgotPassword({ email, phoneNumber }) {
    const userRequest = {};
    if (email) {
      userRequest.email = email;
    } else {
      // in api, the key is 'mobileNumber'
      userRequest.mobileNumber = phoneNumber;
    }

    return this.request.post('/v01/forgotPassword', null, { userRequest, });
  }

  resetPassword({ otp, password, confirmPassword, userIdentifier }) {
    const userRequest = { otp, userIdentifier };

    userRequest.password = getContentMD5(password);
    userRequest.confirmPassword = getContentMD5(confirmPassword);

    return this.request.put('/v01/resetPassword', null, { userRequest });
  }

  changePassword({ currentPassword, newPassword, confirmPassword }) {
    const userRequest = { currentPassword, newPassword, confirmPassword };

    userRequest.currentPassword = getContentMD5(currentPassword);
    userRequest.newPassword = getContentMD5(newPassword);
    userRequest.confirmPassword = getContentMD5(confirmPassword);

    return this.request.put('/v01/changePassword', null, { userRequest });
  }

  socialLogin({
    loginType,
    token,
    ipAddress,
  }) {
    const userRequest = {
      loginType,
      token,
      ipAddress
    };

    return this.request.post('/v01/socialLogin', null, { userRequest, });
  }

  fetchCategoryList() {
    return this.request.get('/v01/fetchCategoryList', null, null);
  }

  fetchNotifications({ page }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    return this.request.get('/v01/fetchNotificationList', { userRequest }, null);
  }

  fetchUserDetails() {
    return this.request.get('/v01/fetchUserDetails', null, null);
  }

  fetchBannerPoster() {
    return this.request.get('/v01/fetchBannerPoster', null, null);
  }

  fetchUpcomingPosters() {
    return this.request.get('/v01/fetchPosterUnlinkList', null, null);
  }

  fetchLivestreamList(page = 1) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    return this.request.get('/v01/fetchLiveStreamList', { userRequest }, null);
  }

  fetchLatestVideoList() {
    return this.request.get('/v01/fetchLatestVideoList', null, null);
  }

  fetchVideoList({ entityType, categoryIdentifier, page = 1 }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (entityType) {
      userRequest.entityType = entityType;
    }
    if (categoryIdentifier) {
      userRequest.categoryIdentifier = categoryIdentifier;
    }
    return this.request.get('/v01/fetchVideoList', { userRequest }, null);
  }

  fetchVideoDetails({ entityIdentifier, entityType }) {
    const userRequest = {
      entityIdentifier,
      entityType,
    };
    return this.request.get('/v01/getVideoDetails', { userRequest }, null);
  }

  saveFavourite({ entityIdentifier, entityType, userIdentifier }) {
    const userRequest = {
      entityIdentifier,
      entityType,
      userIdentifier,
    };
    return this.request.post('/v01/saveFavourite', null, { userRequest });
  }

  deleteFavourite({ favouriteIdentifier }) {
    const userRequest = {
      favouriteIdentifier,
    };
    return this.request.delete('/v01/deleteFavourite', null, { userRequest });
  }

  fetchFavouriteList({ entityType, page }) {
    const userRequest = {
      entityType,
      pagination: {
        page,
      },
    };
    return this.request.get('/v01/fetchFavouriteList', { userRequest }, null);
  }

  fetchPaymentList({ amount }) {
    const userRequest = {
      amount,
    };
    return this.request.get('/v01/fetchPaymentList', { userRequest }, null);
  }

  confirmPayment({
    customerBillingInfoIdentifier,
    ticketInfoIdentifier,
    paymentIdentifier,
    price,
    ticketCount,
    amount,
    processingFeeAmount,
    totalAmount,
  }) {
    const userRequest = {
      customerBillingInfoIdentifier,
      ticketInfoIdentifier,
      paymentIdentifier,
      price,
      ticketCount,
      amount,
      processingFeeAmount,
      totalAmount,
    };
    return this.request.post('/v01/buyTicket', null, { userRequest });
  }

  buyFreeTicket(ticketInfoIdentifier) {
    const userRequest = {
      ticketInfoIdentifier,
    };
    return this.request.post('/v01/buyFreeTicket', null, { userRequest });
  }

  fetchCustomerBillingInfos() {
    return this.request.get('/v01/fetchCustomerBillingInfos', null, null);
  }

  saveCustomerBillingInfos({ customerName, customerEmail, customerPhone, billCity, billAddress }) {
    const userRequest = {
      customerName,
      customerEmail,
      customerPhone,
      billCity,
      billAddress
    };
    return this.request.post('/v01/saveCustomerBillingInfos', null, { userRequest });
  }

  fetchCountryList() {
    return this.request.get('/v01/fetchCountryList', null, null);
  }

  fetchStateList({ countryIdentifier }) {
    const userRequest = {
      countryIdentifier,
    }
    return this.request.get('/v01/fetchStateList', { userRequest }, null);
  }

  editProfile({ name, email, mobileNumber, dob, gender, countryIdentifier, stateIdentifier, interestIdentifiers }) {
    const userRequest = {
      bio: {
        name,
        email,
        mobileNumber,
        dob,
        gender,
        countryIdentifier,
        stateIdentifier,
      },
    };
    if (interestIdentifiers) {
      userRequest.interestIdentifiers = interestIdentifiers
    }
    return this.request.put('/v01/editProfile', null, { userRequest });
  }

  uploadProfile({ fileDirectory, file, fileCategory }) {
    const userRequest = {
      fileDirectory,
      file,
      fileCategory,
    };
    const data = new FormData();
    data.append('userRequest[fileDirectory]', fileDirectory);
    data.append('userRequest[file]', file);
    data.append('userRequest[fileCategory]', fileCategory);

    return this.request.post('/v01/s3FileUpload', null, data, true);
  }

  fetchTicketList(page, ticketStatus) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (ticketStatus) {
      userRequest.ticketStatus = ticketStatus;
    }
    return this.request.get('/v01/fetchTicketList', { userRequest }, null);
  }

  fetchUsedTicketHistory(page, showStatus) {
    const userRequest = {
      pagination: {
        page,
      }
    };
    if (showStatus) {
      userRequest.showStatus = showStatus;
    }
    return this.request.get('/v01/fetchUsedTicketHistory', { userRequest }, null);
  }

  fetchPosterLinkList(page = 1) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    return this.request.get('/v01/fetchPosterLinkList', { userRequest }, null);
  }

  fetchSearchList({ keyword, timeFrame, type, sortBy, page = 1 }) {
    const userRequest = {
      keyword,
      pagination: {
        page,
      },
    };
    if (timeFrame || type || sortBy) {
      userRequest.filter = {
        timeFrame: timeFrame || '',
        type: type || '',
        sortBy: sortBy || '',
      };
    }
    return this.request.get('/v01/fetchSearchList', { userRequest }, null);
  }

  fetchInvoiceList({ entityIdentifier, entityType, page }) {
    const userRequest = {
      entityIdentifier,
      entityType,
      pagination: {
        page,
      },
    };
    return this.request.get('/v01/fetchInvoiceList', { userRequest }, null);
  }

  verifyTicketCode({ entityIdentifier, entityType, ticketCode }) {
    const userRequest = {
      entityIdentifier,
      entityType,
      ticketCode,
    };
    return this.request.post('/v02/verifyTicketCode', null, { userRequest });
  }

  contact({ userName, email, mobileNumber, message }) {
    const userRequest = {
      userName,
      email,
      mobileNumber,
      message
    }
    return this.request.post('/v01/contact', null, { userRequest });
  }

  fetchPrivacyPolicy() {
    return this.request.get('/v01/fetchPrivacyPolicy', null, null);
  }

  stopPlayingVideo({ entityIdentifier, entityType }) {
    const userRequest = {
      entityIdentifier,
      entityType,
    };
    return this.request.put('/v01/stopPlayingVideo', null, { userRequest });
  }

  fetchTicketSaleDetailList({ entityIdentifier, entityType, ticketStatus, page }) {
    const userRequest = {
      entityIdentifier,
      entityType,
      pagination: {
        page,
      },
    };
    if (ticketStatus) {
      userRequest.ticketStatus = ticketStatus;
    }
    return this.request.get('/v01/fetchTicketSaleDetailList', { userRequest }, null);
  }

  checkPaymentPlatform(orderId) {
    const userRequest = {
      orderId,
    };
    return this.request.get('/v01/checkPaymentPlatform', { userRequest }, null);
  }

  /**
  * After a successful login, save device info into
  * request object, which will be send in Headers
  */
  setDeviceInfo({ deviceId, deviceName }) {
    this.request.setDeviceInfo({ deviceId, deviceName });
  }

  /**
  * After a successful login, save session id into
  * request object, which will be send in Headers
  */
  setSessionId(sessionId) {
    this.request.setSessionId(sessionId);
  }
}

const api = new Api();

export default api;