import React from 'react';
import {
  Button,
  Container,
  Modal,
  Text,
  Title,
} from './SessionExpiredModal.elements';
import { translate } from '../../i18n';

const SessionExpiredModal = ({
  visible,
  logout,
}) => {
  return (
    <Modal
      visible={visible}
      footer={false}
      centered
    >
      <Container>
        <Title>
          {translate('errors.sessionExpiredTitle')}
        </Title>
        <Text>
          {translate('errors.sessionExpiredMessage')}
        </Text>
        <Button onClick={logout}>
          {translate('common.ok')}
        </Button>
      </Container>
    </Modal>
  );
}

export default SessionExpiredModal;