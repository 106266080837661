import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PosterCard, SectionTemplate, } from '../../../common';
import Skeleton from './Skeleton';
import { translate } from '../../../i18n';
import { EVENT_SK, POSTER_LK, UNSAVE_SK, VENUE_SK } from '../../../constants';
import { api, Status } from '../../../api';

const Explore = ({
  showName,
}) => {
  const status = useSelector(({ app }) => app.status);
  const [loading, setLoading] = useState(true);
  const [posters, setPosters] = useState([]);

  useEffect(() => {
    status === Status.SUCCESS &&
      fetchUpcomingPosters();
  }, [status]);

  const fetchUpcomingPosters = () => {
    api
      .fetchUpcomingPosters()
      .then(({ userResponse }) => {
        setPosters(userResponse);
        setLoading(false);
      })
      .catch(() => { });
  }

  if (loading) {
    return <Skeleton />;
  }

  return (
    <SectionTemplate
      explore
      title={translate('home.upcoming')}
      type={POSTER_LK}
    >
      {posters.map((poster, key) => (
        <PosterCard
          key={key}
          entityType={poster.entityType}
          posterIdentifier={poster.entityIdentifier}
          image={poster.portraitImagePath.originalUrl}
          isSaved={poster.isSaved === UNSAVE_SK}
          favouriteIdentifier={poster.favouriteIdentifier}
        />
      ))}
    </SectionTemplate>
  );
}

export default Explore;