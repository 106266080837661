import {
  API_REQUEST_FAILED,
  CHANGE_THEME,
  INITIALIZE_APP_REQUEST,
  SESSION_EXPIRED,
  FETCH_NOTIFICATIONS_REQUEST,
} from "../../constants/actionTypes";

export const initializeApp = () => ({
  type: INITIALIZE_APP_REQUEST,
});

export const changeThemeInApp = theme => ({
  type: CHANGE_THEME,
  payload: {
    theme,
  }
});

export const sessionExpired = {
  type: SESSION_EXPIRED,
};

export const apiRequestFailed = {
  type: API_REQUEST_FAILED,
};

export const fetchNotifications = page => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
  payload: {
    page,
  }
});