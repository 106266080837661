import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import {
  ArrowsWrapper,
  ArrowLeft,
  ArrowRight,
  ReactCarousel,
} from './Carousel.elements';

const Carousel = ({
  children,
  items = 4,
  loading,
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2560 },
      items: items,
    },
    desktop: {
      breakpoint: { max: 2560, min: 1440 },
      items: items,
    },
    laptop: {
      breakpoint: { max: 1440, min: 768 },
      items: items,
    },
    tablet: {
      breakpoint: { max: 768, min: 375 },
      items: items === 2 ? items : 3,
    },
    mobile: {
      breakpoint: { max: 375, min: 0 },
      items: 2
    }
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: {
      currentSlide,
      slidesToShow,
      totalItems,
    } } = rest;
    return (
      <ArrowsWrapper loading={loading}>
        <ArrowLeft enable={currentSlide !== 0} onClick={() => previous()} />
        <ArrowRight enable={(totalItems - currentSlide) !== slidesToShow} onClick={() => next()} />
      </ArrowsWrapper>
    );
  };

  return (
    <ReactCarousel
      ssr={true}
      arrows={false}
      autoPlay={false}
      shouldResetAutoplay={false}
      responsive={responsive}
      renderButtonGroupOutside={true}
      customButtonGroup={<ButtonGroup />}
    >
      {children}
    </ReactCarousel>
  );
}

export default Carousel;