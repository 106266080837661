import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ChangePassword as ChangePasswordForm } from '../../../components';
import { translate } from '../../../i18n';

const ChangePassword = ({ title }) => {
  const { state } = useLocation();

  useEffect(() => {
    document.title = `${translate('common.appName')} | ${title}`;
  }, []);

  if (!state) {
    return <Navigate to='/' />
  }

  return (
    <ChangePasswordForm />
  );
}

export default ChangePassword;