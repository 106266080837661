import {
    FORGOT_PASSWORD_REQUEST,
    INTERESTS_REQUEST,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    RESEND_OTP_REQUEST,
    RESET_CHANGE_PASSWORD_STATE,
    RESET_FORGOT_PASSWORD_STATE,
    RESET_OTP_VERIFICATION_STATE,
    RESET_PASSWORD_REQUEST,
    RESET_SIGNUP_STATE,
    SIGNUP_REQUEST,
    SOCIAL_LOGIN_REQUEST,
    VERIFY_OTP_REQUEST,

} from "../../constants";

/**
 * Initiate login api request. Only one value is needed
 * between email or phoneNumber, not both.
 *
 * @param {string} email       - Email with which user want to login
 * @param {string} phoneNumber - Phone number with which user want to login
 * @param {string} password    - Password of the account
 * @param {string} userRole    - User type for the account, by default will be `member`
 */
export const login = (email, phoneNumber, password, ipAddress, userRole = 'member') => ({
    type: LOGIN_REQUEST,
    payload: {
        email,
        phoneNumber,
        password,
        ipAddress,
        userRole,
    },
});

export const socialLogin = (
    loginType,
    token,
    ipAddress
) => ({
    type: SOCIAL_LOGIN_REQUEST,
    payload: {
        loginType,
        token,
        ipAddress
    },
});


/**
 * Reset state associated with signup logic
 */
export const resetSignupState = () => ({
    type: RESET_SIGNUP_STATE,
});

/**
 * Initiate signup api request. Only one value is needed
 * between email or phoneNumber, not both.
 *
 * @param {string} name        - Name with which user want to signup
 * @param {string} email       - Email with which user want to signup
 * @param {string} phoneNumber - Phone number with which user want to signup
 * @param {string} password    - Password of the account
 * @param {string} userRole    - User type for the new account, by default will be `member`
 */
export const signup = (userName, email, phoneNumber, password, userRole = 'member') => ({
    type: SIGNUP_REQUEST,
    payload: {
        userName,
        email,
        phoneNumber,
        password,
        userRole,
    },
});

/**
 * Reset state associated with Otp Verification screen
 */
export const resetOtpVerificationState = () => ({
    type: RESET_OTP_VERIFICATION_STATE,
});

/**
 * Initiate verify otp api request.
 *
 * @param {string} otp - otp send to phone for cross-verify
 */
export const verifyOtp = (otp, otpType, ipAddress) => ({
    type: VERIFY_OTP_REQUEST,
    payload: {
        otp,
        otpType,
        ipAddress
    },
});

/**
 * Initiate new otp api request. New otp can be requested
 * for registration process or password reset process.
 * Value of otyType can be `registration` or `resetPassword`
 *
 * @param {string} otpType - Tells whether the action is dispatch for registeration or password reset
 */
export const resendOtp = otpType => ({
    type: RESEND_OTP_REQUEST,
    payload: {
        otpType,
    },
});

export const saveInterests = (interestIdentifiers) => ({
    type: INTERESTS_REQUEST,
    payload: {
        interestIdentifiers,
    }
});

export const forgotPassword = (email, phoneNumber) => ({
    type: FORGOT_PASSWORD_REQUEST,
    payload: {
        email,
        phoneNumber
    }
});

/**
 * Reset state associated with Forget Password screen
 */
export const resetForgetPasswordState = () => ({
    type: RESET_FORGOT_PASSWORD_STATE,
});

/**
 * Initiate reset password api request.
 *
 * @param {string} otp             - Otp sent to the email/phone number
 * @param {string} password        - New password for the account
 * @param {string} confirmPassword - Confirm password for the account
 */
export const resetPassword = (otp, password, confirmPassword,) => ({
    type: RESET_PASSWORD_REQUEST,
    payload: {
        otp,
        password,
        confirmPassword,
    },
});

/**
 * Reset state associated with Reset Password screen
 */
export const resetChangePasswordState = () => ({
    type: RESET_CHANGE_PASSWORD_STATE,
});

/**
 * Action dispatch to logout user, and enter guest mode.
 */
export const logout = (isSessionExpired = false) => ({
    type: LOGOUT_REQUEST,
    payload: {
        isSessionExpired,
    },
});