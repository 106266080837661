import styled from 'styled-components';
import {
  ListContainer as ListContainerGlobal,
} from '../../theme/styles';
import {
  Select as AntSelect,
} from 'antd';
import { device } from '../../theme';

export const RootContainer = styled.div`
min-height: calc(100vh - 190px);
`;

export const TopSection = styled.div`
padding: 80px 60px 0 60px;

@media ${device.laptop} {
  padding: 70px 30px 0 30px;
}

@media ${device.mobileL} {
  padding: 60px 10px 0 10px;
}
`;

export const Wrapper = styled.div`
display: flex;
`;

export const Title = styled.div`
flex: 1;
font-style: normal;
font-weight: 500;
font-size: 20px;
color: ${({ theme }) => theme.fontColor};

@media ${device.laptop} {
  font-size: 18px;
}

@media ${device.mobileL} {
  font-size: 16px;
}
`;

export const ListContainer = styled(ListContainerGlobal)`
padding-top: 20px !important;

@media ${device.laptop} {
  padding-top: 10px !important;
}
`;

export const EndMessage = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
text-align: center;
color: ${({ theme }) => theme.fontColor};
padding-bottom: 30px;

@media ${device.laptop} {
  font-size: 14px;
}

@media ${device.mobileL} {
  font-size: 12px;
}
`;

export const Filters = styled.div`
display: ${({ showFilters }) => showFilters ? 'block' : 'none'};
`;

export const FilterLabel = styled.div`
padding-top: 15px;
padding-bottom: 10px;
font-style: normal;
font-weight: 300;
font-size: 16px;
color: ${({ theme }) => theme.fontColor};

@media ${device.laptop} {
  font-size: 14px;
}

@media ${device.mobileL} {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
`;

export const Select = styled(AntSelect)`
width: 100%;
margin-bottom: 10px;

  .ant-select-selection-placeholder, .ant-select-selection-item {
    line-height: 50px !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: ${({ theme }) => theme.thinFontColor};

    @media ${device.laptop} {
      font-size: 14px;
    }

    @media ${device.mobileL} {
      font-size: 12px;
    }
  }

  .ant-select-selector {
    height: 50px !important;
    background: transparent !important;
    border: 1px solid ${props => props.theme.primaryColor} !important;
    box-sizing: border-box !important;
    filter: drop-shadow(0px 0px 4px rgba(120, 81, 169, 0.5)) !important;
    border-radius: 5px !important;
  }

  .ant-select-arrow svg {
    width: 20px;
    height: 20px;
    color: #BEBFCF;

    @media ${device.laptop} {
      width: 15px;
      height: 15px;
    }
  }

`;

export const Text = styled.span`
font-weight: 700;
`;
