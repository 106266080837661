import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { api, Status } from '../../api';
import { SERVER_ERROR_CODES } from '../../constants';
import { translate } from '../../i18n';
import { isNonEmptyString } from '../../utils';
import {
  Error,
  Input,
  Link,
  LinkWrapper,
  Modal,
  ModalButton,
  ModalForm,
  ModalTitle,
  WarningButton,
  WarningContainer,
  WarningInfo,
  WarningTitle,
} from './VideoDetails.elements';

const PlayModal = ({
  visible,
  title,
  entityIdentifier,
  entityType,
  /**
   * Function, which display the actual video content to user
   * since ticket code verification has been succeeded
   */
  successCallback,
  handleClose,
}) => {
  const [ticketCode, setTicketCode] = useState('');
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSpecialError, setIsSpecialError] = useState(false);

  useEffect(() => {
    if (!visible) {
      setTicketCode('');
      setApiStatus(Status.DEFAULT);
      setErrorMessage('');
      setIsSpecialError(false);
    }
  }, [visible]);

  const verifyTicketCode = () => {
    if (!isNonEmptyString(ticketCode)) {
      return;
    }

    setApiStatus(Status.LOADING);
    api
      .verifyTicketCode({
        entityIdentifier,
        entityType,
        ticketCode,
      })
      .then(({ userResponse: { iframe } }) => {
        setApiStatus(Status.SUCCESS);
        successCallback(iframe);
        message.success(translate('videoDetails.verifyTicketSuccessMessage'));
      })
      .catch((error) => {
        setApiStatus(Status.ERROR);
        setErrorMessage(error.message);
        setIsSpecialError(error.name === SERVER_ERROR_CODES.concurrentVideoStreamsError);
      });
  }

  const handleInputChange = (e) => {
    setTicketCode(e.target.value);
    setErrorMessage('');
  }

  const renderForm = () => {
    return (
      <>
        <ModalTitle>
          {title}
        </ModalTitle>
        <ModalForm>
          <Error>
            {errorMessage}
          </Error>
          <Input
            value={ticketCode}
            placeholder={translate('videoDetails.ticketCodeInputPlaceholder')}
            onChange={handleInputChange}
          />
          <ModalButton
            loading={apiStatus === Status.LOADING}
            onClick={verifyTicketCode}
          >
            {apiStatus !== Status.LOADING && translate('videoDetails.watchButtonLabel')}
          </ModalButton>
        </ModalForm>
        <LinkWrapper>
          <Link to='/user-profile/tickets'>
            {translate('videoDetails.goToMyTickets')}
          </Link>
        </LinkWrapper>
      </>
    )
  }

  const renderWarningInfo = () => {
    return (
      <WarningContainer>
        <WarningTitle>
          Sorry!
        </WarningTitle>
        <WarningInfo>
          {errorMessage}
        </WarningInfo>
        <WarningButton onClick={handleClose}>
          OK
        </WarningButton>
      </WarningContainer>
    );
  }

  return (
    <Modal
      visible={visible}
      centered
      footer={false}
      onCancel={apiStatus !== Status.LOADING && handleClose}
      closable={!isSpecialError}
    >
      {!isSpecialError && renderForm()}
      {isSpecialError && renderWarningInfo()}
    </Modal>
  );
};

export default PlayModal;