import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  MainContentComponent,
  ProfileBanner as Banner,
} from '../../components';
import { LOCAL_STORAGE_KEYS } from '../../constants';

const UserProfile = () => {
  const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEYS.sessionId);

  if (!isLoggedIn) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <Banner />
      <MainContentComponent />
    </>
  );
}

export default UserProfile;