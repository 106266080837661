import styled, { css } from "styled-components";
import {
  Avatar,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tabs as AntTabs,
  Image
} from "antd";
import {
  GlobalButton,
  GlobalInputBox,
  GlobalPasswordBox,
} from "../../../theme/styles";
import { Radio as AntRadio } from "antd";
import { device } from "../../../theme";

const { TabPane } = AntTabs;

const Tabs = styled(AntTabs)`
  .ant-tabs-tab-btn {
    a {
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
      font-size: 18px;
      color: ${(props) => props.theme.fontColor};
      padding-right: 20px;

      @media ${device.laptop} {
        font-size: 16px;
      }

      @media ${device.mobileL} {
        font-size: 14px;
      }

      @media ${device.mobileM} {
        font-size: 13px;
      }
    }
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    a {
      color: ${({ theme }) => theme.primaryColor} !important;
    }
  }

  .ant-tabs-tab {
    border-bottom: 1px solid ${(props) => props.theme.fontColor};
    padding: 0px !important;
    margin: 0 60px 0 0 !important;

    @media ${device.tablet}{
      border-bottom: 0px;
    }
  }

  .ant-tabs-nav-list{
    @media ${device.tablet}{
      justify-content: center;
      align-items: center;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
`;

export const DesktopTabs = styled(Tabs).attrs({
  tabPosition: "left",
})`
  display: flex;

  .ant-tabs-nav-list {
    @media ${device.tablet} {
      flex-direction: row !important;
    }
  }

  .ant-tabs-content-holder{
    @media ${device.tablet} {
      border-left: 0px !important;
    }
  }

  @media ${device.tablet} {
    flex-direction: column !important;
  }
`;

export const SubTab = styled(TabPane)`
  padding-left: 0 !important;
`;

export const RootContainer = styled.div`
  padding: 20px 0 60px 60px;
  /* min-height: calc(80vh - 210px); */
  min-height: calc(100vh - 260px);

  .ant-input{
    background: transparent !important;
    color: ${props => props.theme.fontColor} !important;
    font-size: 15px;
  }

  @media ${device.tablet} {
    padding: 20px 40px;
  }

  @media ${device.mobileL} {
    padding: 20px;
  }

  @media ${device.mobileM} {
    padding: 10px;
  }
`;

export const Title = styled.div`
  padding-top: 0;
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  color: ${({ theme }) => theme.fontColor};

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    padding-bottom: 10px;
  }

  @media ${device.mobileM} {
    font-size: 14px;
  }
`;

export const ProfileWrapper = styled.div`
  @media ${device.mobileL} {
    text-align: center;
  }
`;

export const ProfileSection = styled.div`
  display: inline-block;
  position: relative;
  margin: 20px 0;
`;

export const ProfileImage = styled(Avatar).attrs({
  size: 100,
  style: {
    backgroundColor: "#C4C4C4",
  },
})`
  div {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const EditImage = styled(Image)`
   width: 100%;
   height: 100%;
   object-fit: contain;
`;

export const CameraSection = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50px;
  display: ${({ showUploadIcon }) => (showUploadIcon ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  cursor: pointer;
`;

export const EditForm = styled(Form)`
  margin-bottom: 30px;
`;

export const SettingsContainer = styled.div`
  margin-bottom: 30px;
`;

export const EditLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.fontColor} !important;
  padding: 15px 0;

  @media ${device.tablet} {
    font-size: 15px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    padding: 10px;
  }

  @media ${device.mobileM} {
    font-size: 13px;
  }
`;

export const RegionLabel = styled(EditLabel)`
  display: ${(props) => (props.show ? "block" : "none")};
`;

export const EditInputBox = styled(GlobalInputBox)`
  color: ${(props) => props.theme.fontColor} !important;
`;

export const DisableBox = styled(GlobalInputBox)`
  border: 1px solid #bebfcf !important;
  cursor: default !important;

  &:disabled {
    background-color: transparent !important;
    color: rgba(190, 191, 207, 0.7);
  }

  &:hover {
    border: 1px solid #bebfcf !important;
  }
`;

export const LoginText = styled.div`
  float: right;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #ea4435;
`;

export const DatePickerBox = styled(DatePicker)`
  width: 100%;
  height: 50px;
  border: 1px solid #bebfcf;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  .ant-picker-input > input {
    color: ${(props) => props.theme.fontColor} !important;
  }

  .anticon {
    color: ${(props) => props.theme.fontColor};
  }

  &:focus,
  :hover {
    border: 1px solid #bebfcf !important;
    box-shadow: none !important;
  }

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const GenderInput = styled(Input.Group)`
  .ant-select {
    width: 100% !important;
  }
  .ant-select-selector {
    border: 1px solid #bebfcf !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    height: 50px !important;
    background-color: transparent !important;
  }

  .ant-select-selection-item {
    color: ${(props) => props.theme.fontColor} !important;
    line-height: 48px !important;
  }

  .anticon {
    color: ${(props) => props.theme.fontColor} !important;
  }
`;

export const GenderSelect = styled(Select).attrs((props) => ({
  dropdownStyle: {
    backgroundColor: props.theme.body,
    border: "1px solid #bebfcf",
    borderRadius: "5px",
  },
}))``;

export const Container = styled.div`
  padding-right: 40px;

  @media ${device.mobileL} {
    padding: 0px;
  }
`;

export const SaveButton = styled(GlobalButton)`
  width: 100%;
`;

export const ButtonContainer = styled(Row)`
  padding-top: 50px;
`;

export const SettingPasswordBox = styled(GlobalPasswordBox)`
  border: 1px solid rgba(190, 191, 207, 0.5) !important;
  cursor: default !important;
  color: ${(props) => props.theme.fontColor};

  &:disabled {
    background-color: transparent !important;
    color: rgba(190, 191, 207, 0.7);
  }

  &:hover {
    border: 1px solid rgba(190, 191, 207, 0.5) !important;
  }

  input {
    background: transparent;
    color: ${(props) => props.theme.fontColor};
  }
  .anticon {
    color: ${(props) => props.theme.fontColor} !important;
  }
`;

export const ChangePassButton = styled(GlobalButton)`
  margin-left: 30px;
  width: 100%;

  @media screen and (max-width: 575px) {
    margin-top: 20px;
    margin-left: 0px;
  }
`;

export const CounrtySelectBox = styled(Input.Group)`
  .ant-select {
    width: 100% !important;
  }
  .ant-select-selector {
    border: 1px solid #bebfcf !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    height: 50px !important;
    background-color: transparent !important;
  }

  .ant-select-selection-item {
    color: ${(props) => props.theme.fontColor} !important;
    line-height: 48px !important;
  }

  .anticon {
    color: ${(props) => props.theme.fontColor} !important;
  }
`;

export const RegionSelectBox = styled(CounrtySelectBox)`
  display: ${(props) => (props.show ? "block" : "none")};
`;

export const SettingRow = styled(Row)`
  padding-top: 20px;

  @media ${device.mobileL} {
    padding-top: 0px;
  }
`;

export const SubText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: ${(props) => props.theme.fontColor};
  padding: 30px 0 20px 0;

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const ThemeSelect = styled.div`
  display: flex;
  flex: 1;
`;

export const Label = styled.div`
  font-size: 16px;
  padding: 15px 0;
  color: ${(props) => props.theme.fontColor};

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const OldPasswordbox = styled.div`
  border: 1px solid #bebfcf;
  border-radius: 10px;
  height: 54px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: ${(props) => props.theme.fontColor};

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const PasswordBox = styled(GlobalPasswordBox)`
  .ant-input {
    background: transparent;
    color: ${(props) => props.theme.fontColor};
  }
  .anticon {
    color: ${(props) => props.theme.fontColor};

    &:hover {
      color: ${(props) => props.theme.fontColor};
    }
  }
`;

export const PassButton = styled(GlobalButton)`
  width: 100%;
`;

export const RadioLabel = styled.div`
  color: ${(props) => props.theme.fontColor} !important;
  margin-bottom: 10px;
`;

export const Radio = styled(AntRadio)`
  margin-right: 20px;

  .ant-radio-inner {
    background: transparent;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.primaryColor} !important ;
    background: transparent;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${({ theme }) => theme.primaryColor};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;

export const SubTitle = styled.div`
  padding-top: 0;
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  margin-top: 50px;
  color: ${({ theme }) => theme.fontColor};

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    margin-top: 20px;
  }

  @media ${device.mobileS} {
    font-size: 13px;
  }
`;

export const FormSection = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const ChangeSection = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const PhoneBox = styled.div``;

export const InputBox = styled(GlobalInputBox)`
  color: ${({ theme }) => theme.fontColor};
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`;

export const PrefixBox = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.fontColor} !important;
`;

export const DividerBox = styled.div`
  padding: 0 10px;

  @media ${device.laptop} {
    font-size: 0 8px;
  }

  @media ${device.mobileL} {
    font-size: 0 7px;
  }
`;
