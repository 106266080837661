import React from 'react';
import {
  AiOutlineTwitter,
  AiFillYoutube,
} from 'react-icons/ai';
import {
  FaFacebook,
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterLinks,
  LinksItem,
  Socials,
  Text,
  SocialIcon,
  GetOnBox,
  Image,
  Label,
  SecondRow,
  FirstRow,
  Contacts,
} from './Footer.elements';
import appleStoreImg from '../../assets/images/apple-store.png';
import googlePlayImg from '../../assets/images/google-play.png';
import { translate } from '../../i18n';
import { PhoneOutlined } from '@ant-design/icons';

const Footer = () => {
  return (
    <FooterContainer>
      <FirstRow>
        <FooterLinks>
          <LinksItem to='/contact-us'>
            {translate('footer.contactUs')}
          </LinksItem>
          <LinksItem to='/about-us'>
            {translate('footer.aboutUs')}
          </LinksItem>
          <LinksItem to='/terms-conditions'>
            {translate('footer.termsAndConditions')}
          </LinksItem>
          <LinksItem to='/privacy-policy'>
            {translate('footer.privacyPolicy')}
          </LinksItem>
        </FooterLinks>
        <Socials>
          <SocialIcon>
            <AiOutlineTwitter />
          </SocialIcon>
          <SocialIcon>
            <AiFillYoutube />
          </SocialIcon>
          <SocialIcon sm>
            <FaFacebook />
          </SocialIcon>
        </Socials>
      </FirstRow>
      <Contacts>
        <PhoneOutlined /> {translate('footer.contactPhoneNumbers')}
      </Contacts>
      <SecondRow>
        <Text>
          {translate('common.appName')} &copy; {new Date().getFullYear()}. {translate('footer.copyRightMessage')}
        </Text>
        <GetOnBox>
          <Label>{translate('footer.getOn')}</Label>
          <Image src={appleStoreImg} alt='Apple Store Image' />
          <Image src={googlePlayImg} alt='Google Play Image' />
        </GetOnBox>
      </SecondRow>
    </FooterContainer>
  );
}

export default Footer;