import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Image,
  RootContainer,
  Text,
} from './NotFound.elements';
import { translate } from '../../i18n';
import Img from '../../assets/images/img_404.png';

const NotFound = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  }

  return (
    <RootContainer>
      <Image src={Img} />
      <Text>
        {translate('notFound.text')}
      </Text>
      <Button onClick={goToHome}>
        {translate('notFound.buttonLabel')}
      </Button>
    </RootContainer>
  );
}

export default NotFound;