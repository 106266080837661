import { Checkbox, Divider, Form, Input, Row } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../../theme";
import { GlobalButton, GlobalInputBox } from "../../../theme/styles";

export const SignupWrapper = styled.div`
  position: relative;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 200px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SignupHeading = styled.div`
  margin: 13px 0;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  color: ${({ theme }) => theme.primaryColor};

  @media ${device.laptop} {
    font-size: 40px;
  }
  
  @media ${device.mobileL} {
    font-size: 25px;
  }

  @media ${device.mobileS} {
    font-size: 20px;
  }
`;

export const SignupInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 5px;

  @media ${device.laptop} {
    font-size: 14px;
  }
  
  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const InfoLink = styled(Link)`
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.theme.secondaryColor};

  &:hover {
    color: ${(props) => props.theme.secondaryColor};
  }
`;

export const SignupDivider = styled(Divider)`
  margin: 0 !important;
  color: ${({ theme }) => theme.primaryColor} !important;
  border-top-color: ${({ theme }) => theme.primaryColor} !important;
`;

export const SignupContainer = styled(Row)`
  background-color: #fff;
  min-height: 100vh;

  #normal_login_phoneNumber .ant-input{
    background: transparent !important;
  }
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 10px;
  font-size: 16px;

  @media ${device.laptop} {
    font-size: 14px;
  }
  
  @media ${device.mobileL} {
    font-size: 12px;
  }
  
  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const CheckboxSubInfoLink = styled(Link)`
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.primaryColor};
`;

export const InputStyled = styled(Input)`
  border: 1px solid #bebfcf;
  box-sizing: border-box;
  border-radius: 10px;
`;

export const InputPasswordStyled = styled(Input.Password)`
  border: 1px solid #bebfcf;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 10px rgba(106, 100, 173, 0.1));
  border-radius: 10px;
`;

export const PhoneLogin = styled.div`
  display: flex;
  height: 45px;
  padding: 5px;
  border: 1px solid #bebfcf;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-start;
`;

export const CountryCodeBox = styled(Input.Group)`
  padding-right: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #201c21;
  width: 0 !important;

  .ant-select-selector {
    border: none !important;
  }

  @media ${device.laptop} {
    font-size: 14px;
  }
  
  @media ${device.mobileL} {
    font-size: 12px;
  }
  
  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const PhoneNumberLogin = styled.div`
  display: flex;
  align-items: center;
`;

export const PhoneDivider = styled(Divider)`
  margin: 0;
  border-left: 1px solid #bebfcf;
`;

export const PhoneInput = styled(Input)`
  padding-left: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #201c21;

  @media ${device.laptop} {
    font-size: 14px;
  }
  
  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;
export const SignupEmailItem = styled(Form.Item)`
  display: ${({ isEmail }) => (isEmail ? "block" : "none")};
`;

export const SignupPhoneItem = styled(Form.Item)`
  display: ${({ isEmail }) => (!isEmail ? "block" : "none")};
`;

export const SignupPhoneBox = styled.div`
`;

export const SignupButton = styled(GlobalButton)`
  width: 100%;
`;

export const SignupCheckbox = styled(Checkbox)`
.ant-checkbox-inner{
  border: ${({ required }) => (!required ? "1px solid #d9d9d9" : "1px solid red")};

  &:hover{
  border: ${({ required }) => (!required ? "1px solid #d9d9d9" : "1px solid red")};
}
}
`;
