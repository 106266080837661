/* eslint-disable no-unused-vars */
import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../api/api";
import {
  FETCH_NOTIFICATIONS_REQUEST,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  GET_USER_DETAILS_REQUEST,
  INTERESTS_ERROR,
  INTERESTS_LOADING,
  INTERESTS_REQUEST,
  INTERESTS_SUCCESS,
  LOCAL_STORAGE_KEYS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_LOADING,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESEND_OTP_ERROR,
  RESEND_OTP_LOADING,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_LOADING,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SOCIAL_LOGIN_REQUEST,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_LOADING,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS
} from "../../constants";
import { resetLocale } from "../../i18n";
import { device, resetChangeTheme, resetTheme } from "../../theme";

/**
 * worker saga: Calls the login API with given credentials,
 * on sucess, receives user identifier & session id
 *
 * TODO: Send correct ip address in api body
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.email       - email of the user
 * @param {string} action.payload.phoneNumber - phone number of the user
 * @param {string} action.payload.password    - password for the account
 * @param {string} action.payload.userRole    - by default `memeber`
 */
function* login({ payload: { email, phoneNumber, password, userRole, ipAddress } }) {
  try {
    yield put({ type: LOGIN_LOADING });
    // const ipAddress = '1.1.1.1'; // <===== temp solution
    // api call
    const response = yield call(
      { context: api, fn: api.login },
      { email, phoneNumber, password, userRole, ipAddress },
    );

    // parse the data from response
    const {
      userResponse: {
        sessionId,
        deviceId,
        deviceName,
        userIdentifier,
        userStatus,
        country,
        dateOfBirth,
        gender,
        loginId,
        profileImage,
        state,
        userName,
      },
    } = response;

    if (sessionId) {
      //update sessionId and device info in Request
      api.setSessionId(sessionId);
      api.setDeviceInfo({ deviceId, deviceName });
      localStorage.setItem(LOCAL_STORAGE_KEYS.sessionId, sessionId);
      localStorage.setItem(LOCAL_STORAGE_KEYS.deviceId, deviceId);
      localStorage.setItem(LOCAL_STORAGE_KEYS.deviceName, deviceName);
    }
    yield put({
      type: LOGIN_SUCCESS,
      payload: {
        sessionId,
        userIdentifier,
        userStatus,
        country,
        dateOfBirth,
        gender,
        loginId,
        profileImage,
        state,
        userName,
      },
    });
    // Save userIdentifier and userStatus in Local storage
    localStorage.setItem(LOCAL_STORAGE_KEYS.userIdentifier, userIdentifier);
    localStorage.setItem(LOCAL_STORAGE_KEYS.userStatus, userStatus);
    // Dispatch action to hit getUserDetails api
    yield put({
      type: GET_USER_DETAILS_REQUEST,
      payload: { userIdentifier },
    });
    // Dispatch action to hit fetch notification list API
    yield put({
      type: FETCH_NOTIFICATIONS_REQUEST,
      payload: {
        page: 1,
      },
    });
  }
  catch (error) {
    yield put({
      type: LOGIN_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* socialLogin({
  payload: { loginType, token, ipAddress },
}) {
  try {
    yield put({ type: LOGIN_LOADING });
    // const ipAddress = '1.1.1.1'; // <===== temp solution
    // api call
    const response = yield call(
      { context: api, fn: api.socialLogin },
      {
        loginType,
        token,
        ipAddress,
      },
    );
    // parse the data from response
    const {
      userResponse: {
        sessionId,
        deviceId,
        deviceName,
        userIdentifier,
        userStatus,
        country,
        dateOfBirth,
        gender,
        loginId,
        profileImage,
        state,
        userName,
        firstTimeLogin,
      },
    } = response;

    if (sessionId) {
      //update sessionId and device info in Request
      api.setSessionId(sessionId);
      api.setDeviceInfo({ deviceId, deviceName });
      localStorage.setItem(LOCAL_STORAGE_KEYS.sessionId, sessionId);
      localStorage.setItem(LOCAL_STORAGE_KEYS.deviceId, deviceId);
      localStorage.setItem(LOCAL_STORAGE_KEYS.deviceName, deviceName);
    }
    yield put({
      type: LOGIN_SUCCESS,
      payload: {
        sessionId,
        userIdentifier,
        userStatus,
        country,
        dateOfBirth,
        gender,
        loginId,
        profileImage,
        state,
        userName,
        firstTimeLogin
      },
    });
    // Save userIdentifier and userStatus in Local storage
    localStorage.setItem(LOCAL_STORAGE_KEYS.userIdentifier, userIdentifier);
    localStorage.setItem(LOCAL_STORAGE_KEYS.userStatus, userStatus);
    // Dispatch action to hit getUserDetails api
    yield put({
      type: GET_USER_DETAILS_REQUEST,
      payload: { userIdentifier },
    });
    // Dispatch action to hit fetch notification list API
    yield put({
      type: FETCH_NOTIFICATIONS_REQUEST,
      payload: {
        page: 1,
      },
    });

  } catch (error) {
    yield put({
      type: LOGIN_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the signup API with given credentials,
 * on sucess, receives user identifier & otp expiry time
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.name        - name of the user
 * @param {string} action.payload.email       - email of the user
 * @param {string} action.payload.phoneNumber - phone number of the user
 * @param {string} action.payload.password    - password for the account
 * @param {string} action.payload.userRole    - by default `memeber`
 */

function* signup({ payload: { userName, email, phoneNumber, password, userRole } }) {
  try {
    yield put({ type: SIGNUP_LOADING });

    //api call
    const response = yield call(
      { context: api, fn: api.signup },
      { userName, email, phoneNumber, password, userRole },
    );

    // parse the data from response
    const {
      userResponse: { userIdentifier, },
    } = response;

    // send parsed data into action payload
    yield put({
      type: SIGNUP_SUCCESS,
    });
    // Save userIdentifier and userStatus in Local storage
    localStorage.setItem(LOCAL_STORAGE_KEYS.userIdentifier, userIdentifier);
    localStorage.setItem(LOCAL_STORAGE_KEYS.userStatus, 'inactive');
  }
  catch (error) {
    yield put({
      type: SIGNUP_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the verify otp API, with given otp.
 * on success, session id is stored in "app" reducer, and rest of the
 * data is stored in "profile" reducer
 *
 * TODO: Send ip address in api body
 *
 * @param {Object} action             - action object dispatched by user
 * @param {string} action.payload.otp - otp entered by the user
 */
function* verifyOtp({ payload: { otp, otpType, ipAddress } }) {
  try {
    yield put({ type: VERIFY_OTP_LOADING });
    const userIdentifier = localStorage.getItem(LOCAL_STORAGE_KEYS.userIdentifier);
    // const ipAddress = '1.1.1.1'; // <===== temp solution

    // api call
    const response = yield call(
      { context: api, fn: api.verifyOtp },
      { otp, otpType, userIdentifier, ipAddress }
    );

    // parse the data from response
    const {
      userResponse: {
        sessionId,
        deviceId,
        deviceName,
        userStatus,
      },
    } = response;

    if (sessionId) {
      //update sessionId in Request
      api.setSessionId(sessionId);
      api.setDeviceInfo({ deviceId, deviceName });
    }
    // send parsed data into action payload
    yield put({
      type: VERIFY_OTP_SUCCESS,
    });

    if (sessionId) {
      // Save sessionId and userStatus in Local storage
      localStorage.setItem(LOCAL_STORAGE_KEYS.sessionId, sessionId);
      localStorage.setItem(LOCAL_STORAGE_KEYS.deviceId, deviceId);
      localStorage.setItem(LOCAL_STORAGE_KEYS.deviceName, deviceName);
      localStorage.setItem(LOCAL_STORAGE_KEYS.userStatus, userStatus);
      // Dispatch action to hit getUserDetails api
      yield put({
        type: GET_USER_DETAILS_REQUEST,
        payload: { userIdentifier },
      });
      // Dispatch action to hit fetch notification list API
      yield put({
        type: FETCH_NOTIFICATIONS_REQUEST,
        payload: {
          page: 1,
        },
      });
    }

  }
  catch (error) {
    yield put({
      type: VERIFY_OTP_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the resend otp API.
 *
 * @param {Object} action                 - action object dispatched by user
 * @param {string} action.payload.otpType - whether to request otp for registeration or password reset
 */
function* resendOtp({ payload: { otpType } }) {
  try {
    yield put({ type: RESEND_OTP_LOADING });

    //get userIdenfifier from localStorage
    const userIdentifier = localStorage.getItem(LOCAL_STORAGE_KEYS.userIdentifier);
    // make an api call
    const response = yield call(
      { context: api, fn: api.resendOtp },
      { otpType, userIdentifier },
    );

    //  send parsed data into action payload
    yield put({
      type: RESEND_OTP_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: RESEND_OTP_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the forget password API.
 *
 * @param {Object} action                     - action object dispatched by user
 * @param {string} action.payload.email       - account email whose password forgotten
 * @param {string} action.payload.phoneNumber - account phone number whose password forgotten
 */
function* forgotPassword({ payload: { email, phoneNumber } }) {
  try {
    yield put({ type: FORGOT_PASSWORD_LOADING });

    // make an api call
    const response = yield call(
      { context: api, fn: api.forgotPassword },
      { email, phoneNumber },
    );

    // parse the data from response
    const {
      userResponse: { userIdentifier, },
    } = response;

    yield put({ type: FORGOT_PASSWORD_SUCCESS });

    // Save userIdentifier in Local storage
    localStorage.setItem(LOCAL_STORAGE_KEYS.userIdentifier, userIdentifier);

  } catch (error) {
    yield put({
      type: FORGOT_PASSWORD_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the reset password API.
 *
 * @param {Object} action                         - action object dispatched by user
 * @param {string} action.payload.otp             - otp sent to the email/phone number
 * @param {string} action.payload.password        - new password for the account
 * @param {string} action.payload.confirmPassword - confirm password for the account
 */
function* resetPassword({ payload: { otp, password, confirmPassword } }) {
  try {
    yield put({ type: RESET_PASSWORD_LOADING });
    //get userIdenfifier from localStorage
    const userIdentifier = localStorage.getItem(LOCAL_STORAGE_KEYS.userIdentifier);

    // make an api call
    yield call(
      { context: api, fn: api.resetPassword },
      { userIdentifier, otp, password, confirmPassword },
    );

    // send success action
    yield put({
      type: RESET_PASSWORD_SUCCESS,
    });

  } catch (error) {
    yield put({
      type: RESET_PASSWORD_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* saveInterests({ payload: { interestIdentifiers } }) {
  try {
    yield put({ type: INTERESTS_LOADING });

    // make an api call
    const response = yield call(
      { context: api, fn: api.saveInterests },
      { interestIdentifiers },
    );

    // send parsed data into action payload
    yield put({
      type: INTERESTS_SUCCESS,
    });

    yield put({
      type: GET_USER_DETAILS_REQUEST,
    })

  } catch (error) {
    yield put({
      type: INTERESTS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
* worker saga: Remove session id from Async storage
*/
function* logout({ payload: { isSessionExpired } }) {
  try {
    yield put({ type: LOGOUT_LOADING });

    if (!isSessionExpired) {
      // api call
      yield call({ context: api, fn: api.logout });
    }

    api.setSessionId('');
    api.setDeviceInfo({ deviceId: '', deviceName: '' });

    yield put({
      type: LOGOUT_SUCCESS,
    });

    // Remove sessionId, userIdentifier, userStatus, language and theme in Local storage
    localStorage.removeItem(LOCAL_STORAGE_KEYS.sessionId);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.deviceId);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.deviceName);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.userIdentifier);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.userStatus);

  } catch (error) {
    yield put({
      type: LOGOUT_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}


/**
 * Fetch data from redux store
 *
 * NOTE: Don't overfill this for all data acess from redux store, create new
 * accessor if requirement is different or gets exhaustive
 *
 * @return {Object} return user identifier
 */
const getDataFromStore = ({ auth }) => {
  const { userIdentifier } = auth;
  return {
    userIdentifier,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(LOGIN_REQUEST, login);
  yield takeLatest(SOCIAL_LOGIN_REQUEST, socialLogin);
  yield takeLatest(SIGNUP_REQUEST, signup);
  yield takeLatest(VERIFY_OTP_REQUEST, verifyOtp);
  yield takeLatest(RESEND_OTP_REQUEST, resendOtp);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(INTERESTS_REQUEST, saveInterests);
  yield takeLatest(LOGOUT_REQUEST, logout);
}