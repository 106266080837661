import styled, { css } from "styled-components";
import { Card as AntCard } from "antd";
import { IconPlay as GlobalIconPlay } from "../../theme/styles";
import { device } from "../../theme";

const GradientBorder = css`
  background-image: linear-gradient(
      ${(props) => props.theme.primaryColor},
      ${(props) => props.theme.secondaryColor}
    ),
    linear-gradient(${(props) => props.theme.primaryColor}, ${(props) => props.theme.secondaryColor});
  border-top: 2px solid ${props => props.theme.primaryColor};
  border-bottom: 3px solid ${(props) => props.theme.secondaryColor};
  background-size: 3px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  padding: 0 3px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;

export const ActionButton = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  right: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.secondaryColor};
  text-align: right;

  @media ${device.laptop} {
    font-size: 12px;
  }
`;

export const IconPlay = styled(GlobalIconPlay)``;

const cardHoverEffect = css`
${({ discoverMode }) => (discoverMode ? GradientBorder : "")};

${Image} {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  filter: ${({ discoverMode }) =>
    discoverMode
      ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
      : "blur(2px)"};
}
${IconPlay}, ${ActionButton} {
  display: block;
}
`;

export const Card = styled(AntCard)`
  height: 100%;
  border-radius: 5px;
  border: none;
  background: ${({ discoverMode }) =>
    discoverMode ? "#fff" : "transparent !important"};
  filter: ${({ theme, discoverMode }) =>
    discoverMode ? theme.boxShadow : "none"};

  &:hover {
    ${cardHoverEffect}
  }

  .ant-card-cover {
    position: relative;
    aspect-ratio: 16 / 9;
    margin: 0 !important;

    img {
      border-radius: 5px;
    }
  }

  .ant-card-body {
    padding: ${({ discoverMode }) =>
    discoverMode ? "18px 15px" : "15px 0 0 0"};
  }

  .ant-card-meta-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: ${({ theme, discoverMode }) =>
    discoverMode ? "#201C21" : theme.fontColor};
    padding-bottom: ${({ discoverMode }) => (discoverMode ? "60px" : "50px")};
    white-space: normal !important;
    text-overflow: unset !important;

    @media ${device.laptop} {
      font-size: 16px;
    }

    @media ${device.tablet} {
      font-size: 13px;
      padding-bottom: ${({ discoverMode }) => (discoverMode ? "60px" : "40px")};
    }
    @media ${device.mobileL} {
      font-size: 10px;
      padding-bottom: ${({ discoverMode }) => (discoverMode ? "60px" : "60px")};
    }
  }

@media ${device.laptop} {
  ${Image} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      filter: ${({ discoverMode }) =>
    discoverMode
      ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
      : "blur(1px)"};
    }
  ${IconPlay}, ${ActionButton} {
    display: block;
  }
}
`;

export const RootContainer = styled.div`
position: relative;
height: 100%;

  &:hover {
    ${Card} {
      ${cardHoverEffect}
    }
  }
`;