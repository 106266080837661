/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import {
  InvoicesListComponent,
} from '../../components';
import { api, Status, } from '../../api';
import { LIMITS } from '../../constants';

const InvoicesList = () => {
  const { state } = useLocation();
  const { entityIdentifier, entityType } = state || {};
  const status = useSelector(({ app }) => app.status);
  const [page, setPage] = useState(1);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [details, setDetails] = useState({});
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (status === Status.SUCCESS) {
      fetchInvoiceList();
    }
  }, [status]);

  useEffect(() => {
    page !== 1 && fetchInvoiceList(page);
  }, [page]);

  const fetchInvoiceList = (page = 1) => {
    setApiStatus(Status.LOADING);
    api
      .fetchInvoiceList({
        entityIdentifier,
        entityType,
        page,
      })
      .then(({ userResponse }) => {
        setApiStatus(Status.SUCCESS);
        const { invoiceList, ...details } = userResponse;
        setDetails({ ...details });
        setList([...list, ...invoiceList]);
        setHasMore(invoiceList.length >= LIMITS.ITEMS_PER_PAGE);
      })
      .catch(() => {
        setApiStatus(Status.ERROR);
      });
  }

  const next = () => {
    if (apiStatus === Status.SUCCESS) {
      setPage(page + 1);
    }
  }

  if (!state) {
    return <Navigate to='/' />;
  }

  return (
    <InvoicesListComponent
      details={details}
      list={list}
      hasMore={hasMore}
      next={next}
    />
  );
}

export default InvoicesList;