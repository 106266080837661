import React from 'react';
import {
  Banner,
  Upcoming,
  Freebie,
  Latest,
  Livestream,
  PremiumVideo,
} from '../../components';
import { Divider } from '../../theme/styles';
import { GuestInfoSection } from '../../common';

const Home = () => {
  return (
    <>
      <Banner />
      <Upcoming />
      <Livestream />
      <Latest />
      <Divider />
      <PremiumVideo />
      <Divider />
      <Freebie />
      <GuestInfoSection />
    </>
  );
}

export default Home;