import React from 'react';
import { DISCOVER_MODE_LK, HOME_MODE_LK, PREMIUM_LK } from '../../constants';
import { CardActions, Link } from '../../common';
import {
  Card,
  Image,
  IconPlay,
  Title,
  Date,
  RootContainer,
} from './PremiumCard.elements';
import { formattedDate, getFormattedDuration, getFormattedPrice } from '../../utils';
import { translate } from '../../i18n';
const { Meta } = Card;

const PremiumCard = ({
  identifier,
  entityType,
  image,
  title,
  duration,
  date,
  description,
  price,
  isSaved,
  favouriteIdentifier,
  link,
  expired = false,
  mode = HOME_MODE_LK,
  deleteFavouriteCallback = () => { },
}) => {
  return (
    <RootContainer>
      <Link
        to={`/details/${PREMIUM_LK.toLowerCase()}/${btoa(identifier)}?entityType=${entityType}`}
        mode={mode}
        type={PREMIUM_LK}
        prevented={expired}
      >
        <Card
          discoverMode={mode === DISCOVER_MODE_LK}
          cover={
            <>
              <Image
                alt="premiumimg"
                src={image}
              />
              <IconPlay />
            </>
          }
        >
          <Meta
            title={
              <>
                <Title>{title}</Title>
                <Date>{`${duration} | ${formattedDate(date)}`}</Date>
              </>
            }
            description={
              <>
                {`${description}... `}
                <b>See more</b>
              </>
            }
          />
        </Card>
        <CardActions
          entityType={entityType}
          entityIdentifier={identifier}
          type={PREMIUM_LK}
          label={translate('videoDetails.buyButtonLabel').replace('!@#$%', getFormattedPrice(price))}
          mode={mode}
          isSaved={isSaved}
          favouriteIdentifier={favouriteIdentifier}
          link={link}
          deleteFavouriteCallback={deleteFavouriteCallback}
        />
      </Link>
    </RootContainer>
  );
}

export default PremiumCard;