import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { translate } from '../../../i18n';
import {
  DesktopTabs,
  MobileTabs,
  SubTab,
  RootContainer,
} from './EditProfile.elements';
import ProfileTab from './ProfileTab';
import SettingsTab from './SettingsTab';

const EditProfile = () => {
  const { pathname } = useLocation();
  const [activeKey, setActiveKey] = useState(pathname === '/edit-profile/profile' ? '0' : '1');

  useEffect(() => {
    setActiveKey(pathname === '/edit-profile/profile' ? '0' : '1')
  }, [pathname]);

  const content = (
    <>
      <SubTab
        tab={
          <Link to='/edit-profile/profile'>
            {translate('editProfile.profile')}
          </Link>
        }
        key="0"
      >
        <ProfileTab />
      </SubTab>
      <SubTab
        tab={
          <Link to='/edit-profile/settings'>
            {translate('editProfile.settings')}
          </Link>
        }
        key="1"
      >
        <SettingsTab />
      </SubTab>
    </>
  );

  const DesktopTab = () => (
    <DesktopTabs activeKey={activeKey}>
      {content}
    </DesktopTabs>
  );

  return (
    <RootContainer>
      <DesktopTab />
    </RootContainer>
  );
}

export default EditProfile;