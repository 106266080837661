import styled from "styled-components";
import { Collapse as AntCollapse, } from "antd";
import { device } from "../../theme";
import { Spinner as GlobalSpinner, } from "../../theme/styles";

export const RootContainer = styled.div`
  padding: 30px 60px;
  min-height: calc(100vh - 190px);

  @media ${device.laptop} {
    padding: 30px 40px;
  }

  @media ${device.mobileL} {
    padding: 20px;
  }

  @media ${device.mobileM} {
    padding: 10px;
  }
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: ${(props) => props.theme.fontColor};

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const Title = styled.span`
  text-decoration-line: underline;
  cursor: pointer;
`;

export const Subtitle = styled.span``;

export const DetailsContainer = styled.div``;

export const ShowTitle = styled.div`
  padding: 20px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: ${(props) => props.theme.fontColor};

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const Collapse = styled(AntCollapse)`
  background-color: ${(props) => props.theme.grayBoxColor};
  border: 1px solid #bebfcf;
  border-bottom: 0;

  .ant-collapse-item {
    border-bottom: 1px solid #bebfcf;

    .ant-collapse-header {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: ${(props) => props.theme.fontColor};

      @media ${device.laptop} {
        font-size: 14px;
      }

      @media ${device.mobileL} {
        font-size: 12px;
      }

      @media ${device.mobileM} {
        padding-left: 5px !important;
      }
    }
  }

  .ant-collapse-content {
    background-color: ${(props) => props.theme.body};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.fontColor};

    >div {
      @media ${device.tablet} {
        padding: 5px !important;
      }
    }

    @media ${device.laptop} {
      font-size: 14px;
    }

    @media ${device.mobileL} {
      font-size: 12px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const TextWrapper = styled.div`
  padding-right: 20px;
  color: #fff;
`;

export const Text = styled.div`
  padding-bottom: 5px;
`;

export const Table = styled.div`
  width: 100%;

  div:last-child {
    border: none;
  }
`;

export const TRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  padding: 20px;
  text-align: left;
  border-bottom: 1px dashed #bebfcf;

  @media ${device.laptop} {
    padding: 10px;
 }
  
 @media ${device.mobileM} {
  padding: 5px;
 }
`;

export const TCell = styled.div`
  display: ${({ hide }) => (hide ? "none" : "block")};
  padding-bottom: 10px;
  text-align: ${({ right, center }) =>
    (right && "right") || (center && "center") || "left"};
`;

export const Loader = styled.div`
  text-align: center;
`;

export const Spinner = styled(GlobalSpinner)`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`;