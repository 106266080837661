import styled, { css } from "styled-components";
import { Badge, Card as AntCard } from "antd";
import { device } from "../../theme";

const cardHoverEffect = css`
background-image: linear-gradient(
        ${(props) => props.theme.primaryColor},
        ${(props) => props.theme.secondaryColor}
      ),
      linear-gradient(
        ${(props) => props.theme.primaryColor},
        ${(props) => props.theme.secondaryColor}
      );
    border-top: 2px solid ${(props) => props.theme.primaryColor};
    border-bottom: 3px solid ${(props) => props.theme.secondaryColor};
    background-size: 3px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
    padding: 0 3px;

    .ant-card-cover img {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
`;

export const Card = styled(AntCard)`
  background-color: #fff;
  border-radius: 5px;
  border: none;
  filter: ${({ theme, homeMode }) => (homeMode ? "none" : theme.boxShadow)};
  height: 100%;

  &:hover {
    ${cardHoverEffect}
  }

  .ant-card-cover {
    position: relative;
    aspect-ratio: 16 / 9;
    margin: 0 !important;

    img {
      border-radius: 5px;
    }
  }

  .ant-card-body {
    padding: 18px 15px;
    position: relative;

    @media ${device.laptop} {
      padding: 10px;
    }

    @media ${device.tablet} {
      padding: 10px 5px;
    }
  }

  .ant-card-meta {
    margin-bottom: 60px;
  }

  .ant-card-meta-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme.secondaryColor};
    text-transform: uppercase;
    white-space: normal !important;
    text-overflow: unset !important;

    @media ${device.laptop} {
      font-size: 13px;
      margin-bottom: 0 !important;
    }

    @media ${device.tablet} {
      font-size: 11px;
      margin-bottom: 0 !important;
    }
  }

  .ant-card-meta-description {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    padding-top: 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;


    @media ${device.laptop} {
      -webkit-line-clamp: 3;
      font-size: 16px;
      padding-top: 5px;
    }

    @media ${device.tablet} {
      -webkit-line-clamp: 2;
      font-size: 13px;
      padding-top: 0px;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;

export const RootContainer = styled.div`
position: relative;
height: 100%;

  &:hover {
    ${Card} {
      ${cardHoverEffect}
    }
  }
`;

export const LiveIconWrapper = styled.div`
position: absolute;
bottom: 0;
left: 0;
z-index: 20;
background: #201c21a6;
width: fit-content;
border-radius: 5px;
padding: 0 5px;
`;

export const LiveText = styled.span`
color: red;
font-size: 15px;
font-weight: bold;
letter-spacing: 1.5px;
padding-left: 10px;
`;

export const LiveIcon = styled(Badge)`
  .ant-badge-status-dot {
    width: 15px !important;
    height: 15px !important;
  }
  .ant-badge-status-text{
    display: none;
  }
`;