import React from 'react';
import {
  SectionTemplate,
  SkeletonCard,
} from '../../../common';
import {
  DETAILS_MODE_LK,
  LIVESTREAM_LK,
  PREMIUM_LK,
} from '../../../constants';

const Skeleton = ({
  type,
  title,
  mode,
}) => {

  const items = () => {
    switch (type) {
      case PREMIUM_LK:
        return 2;
      case LIVESTREAM_LK:
        return 3;
      default:
        return 4;
    }
  }

  return (
    <SectionTemplate
      loading='true'
      filled={type === LIVESTREAM_LK && mode !== DETAILS_MODE_LK}
      title={title}
      items={items()}
      type={type}
    >
      <SkeletonCard type={type} />
      <SkeletonCard type={type} />
      <SkeletonCard type={type} />
      <SkeletonCard type={type} />
    </SectionTemplate>
  );
}

export default Skeleton;