/**
 * Constants
 */
const DEV = 'DEV';
const STAGING = 'STAGING';
const TESTING = 'TESTING';
const PRODUCTION = 'PRODUCTION';

const devBaseURL = 'https://dev-api.showlike.live';
const testingBaseURL = 'http://stg-api.showlike.live';
const stagingBaseURL = 'http://stg-api.showlike.live';
const productionBaseURL = 'https://api.showlike.live';

const devBaseAwsURL = '';
const testingBaseAwsURL = '';
const stagingBaseAwsURL = '';
const productionBaseAwsURL = '';

const devBasePath = '/api';
const testingBasePath = '/api';
const stagingBasePath = '/api';
const productionBasePath = '/api';

const devHashSignKey = 'ioefoefnewf!2ek3jfe@cjn-h';
const testingHashSignKey = 'ioefoefnewf!2ek3jfe@cjn-h';
const stagingHashSignKey = 'ioefoefnewf!2ek3jfe@cjn-h';
const productionHashSignKey = 'ioefoefnewf!2ek3jfe@cjn-h';

const devSecretAccessKey = 'fWTIVp2VeCoLlS3JcuW';
const testingSecretAccessKey = 'fWTIVp2VeCoLlS3JcuW';
const stagingSecretAccessKey = 'fWTIVp2VeCoLlS3JcuW';
const productionSecretAccessKey = 'fWTIVp2VeCoLlS3JcuW';

const devAccessKeyId = 'AKIAIDTWXTBE';
const testingAccessKeyId = 'AKIAIDTWXTBE';
const stagingAccessKeyId = 'AKIAIDTWXTBE';
const productionAccessKeyId = 'AKIAIDTWXTBE';

const devAwsRegion = 'ap-southeast-1';
const testingAwsRegion = 'ap-southeast-1';
const stagingAwsRegion = 'ap-southeast-1';
const productionAwsRegion = 'ap-southeast-1';

const devAwsAccessKeyId = 'AKIAWTFLZYZWVJWBDGB7';
const testingAwsAccessKeyId = 'AKIAWTFLZYZWVJWBDGB7';
const stagingAwsAccessKeyId = 'AKIAWTFLZYZWVJWBDGB7';
const productionAwsAccessKeyId = 'AKIAWTFLZYZWVJWBDGB7';

const devAwsSecretAccessKey = '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';
const testingAwsSecretAccessKey = 'eWgpE/AnNH8Z78RpPpb6xqofs2wfjVvGF+8URNtF';
const stagingAwsSecretAccessKey = 'eWgpE/AnNH8Z78RpPpb6xqofs2wfjVvGF+8URNtF';
const productionAwsSecretAccessKey = 'eWgpE/AnNH8Z78RpPpb6xqofs2wfjVvGF+8URNtF';

const devAwsBucket = 'showlike-media-staging-s3';
const testingAwsBucket = 'showlike-media-staging-s3';
const stagingAwsBucket = 'showlike-media-staging-s3';
const productionAwsBucket = 'showlike-media-staging-s3';

const devApiTimeOverAllowance = 50 * 1000; // 50 second timeout
const testingApiTimeOverAllowance = 50 * 1000;
const stagingApiTimeOverAllowance = 50 * 1000;
const productionApiTimeOverAllowance = 50 * 1000;

const devFacebookAppId = '412184737404077';
const testingFacebookAppId = '412184737404077';
const stagingFacebookAppId = '1598930190641320';
const productionFacebookAppId = '412184737404077';

const devGoogleClientId =
  '103531820475-6pusmtr6e8k4pe2vuq1keehk2974crmn.apps.googleusercontent.com';
const testingGoogleClientId =
  '103531820475-6pusmtr6e8k4pe2vuq1keehk2974crmn.apps.googleusercontent.com';
const stagingGoogleClientId =
  '103531820475-6pusmtr6e8k4pe2vuq1keehk2974crmn.apps.googleusercontent.com';
const productionGoogleClientId =
  '103531820475-6pusmtr6e8k4pe2vuq1keehk2974crmn.apps.googleusercontent.com';

// Change this value to test different environment
export const ENVIRONMENT = PRODUCTION;
export const VERSION = '01.0';

export const getAccessKey = () => {
  switch (ENVIRONMENT) {
    case PRODUCTION:
      return productionAwsAccessKeyId;
    case DEV:
      return devAwsAccessKeyId;
    case TESTING:
      return testingAwsAccessKeyId;
    default:
      return stagingAwsAccessKeyId;
  }
};

export const getBuildConfig = () => {
  const commonParameters = {
    host: 'OV Myanmar',
    awsXAmzCredential: `${getAccessKey()}/!@#$%/ap-southeast-1/s3/aws4_request`,
    awsXAmzAlgorithm: 'AWS4-HMAC-SHA256',
  };

  switch (ENVIRONMENT) {
    case PRODUCTION:
      return {
        ...commonParameters,
        baseUrl: productionBaseURL,
        baseAwsUrl: productionBaseAwsURL,
        basePath: productionBasePath,
        hashSignKey: productionHashSignKey,
        accessKeyId: productionAccessKeyId,
        secretAccesskey: productionSecretAccessKey,
        awsRegion: productionAwsRegion,
        awsSecretAccessKey: productionAwsSecretAccessKey,
        awsBucket: productionAwsBucket,
        apiTimeOverAllowance: productionApiTimeOverAllowance,
        facebookAppId: productionFacebookAppId,
        googleClientId: productionGoogleClientId,
      };
    case DEV:
      return {
        ...commonParameters,
        baseUrl: devBaseURL,
        baseAwsUrl: devBaseAwsURL,
        basePath: devBasePath,
        hashSignKey: devHashSignKey,
        accessKeyId: devAccessKeyId,
        secretAccesskey: devSecretAccessKey,
        awsRegion: devAwsRegion,
        awsSecretAccessKey: devAwsSecretAccessKey,
        awsBucket: devAwsBucket,
        apiTimeOverAllowance: devApiTimeOverAllowance,
        facebookAppId: devFacebookAppId,
        googleClientId: devGoogleClientId,
      };
    case STAGING:
      return {
        ...commonParameters,
        baseUrl: stagingBaseURL,
        baseAwsUrl: stagingBaseAwsURL,
        basePath: stagingBasePath,
        hashSignKey: stagingHashSignKey,
        accessKeyId: stagingAccessKeyId,
        secretAccesskey: stagingSecretAccessKey,
        awsRegion: stagingAwsRegion,
        awsSecretAccessKey: stagingAwsSecretAccessKey,
        awsBucket: stagingAwsBucket,
        apiTimeOverAllowance: stagingApiTimeOverAllowance,
        facebookAppId: stagingFacebookAppId,
        googleClientId: stagingGoogleClientId,
      };
    case TESTING:
      return {
        ...commonParameters,
        baseUrl: testingBaseURL,
        baseAwsUrl: testingBaseAwsURL,
        basePath: testingBasePath,
        hashSignKey: testingHashSignKey,
        accessKeyId: testingAccessKeyId,
        secretAccesskey: testingSecretAccessKey,
        awsRegion: testingAwsRegion,
        awsSecretAccessKey: testingAwsSecretAccessKey,
        awsBucket: testingAwsBucket,
        apiTimeOverAllowance: testingApiTimeOverAllowance,
        facebookAppId: testingFacebookAppId,
        googleClientId: testingGoogleClientId,
      };
    default:
      throw new Error('Unsupported Environment');
  }
};
