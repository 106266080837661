import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Col,
  message,
  Row,
} from 'antd';
import { api, Status } from '../../../api';
import {
  EmptyCard,
  PosterCard,
} from '../../../common';
import {
  LIMITS,
  POSTER_SK,
  UNSAVE_SK,
} from '../../../constants';
import _ from 'lodash';
import { translate } from '../../../i18n';
import { ListContainer, Loader, } from './WatchListTab.elements';
import { ListColumn, ListItem, Spinner } from '../../../theme';

const Posters = ({
  active,
  showName,
}) => {
  const status = useSelector(({ app }) => app.status);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [hasMore, setHasMore] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    !active && resetState();
  }, [active]);

  useEffect(() => {
    status === Status.SUCCESS && active && fetchPostersList();
  }, [status, active]);

  useEffect(() => {
    page !== 1 && fetchPostersList(page);
  }, [page]);

  useEffect(() => {
    setIsEmpty(list.length === 0);
    setList(prevState => {
      return _.filter(prevState, item => !item.isDeleted);
    });
  }, [list]);

  const fetchPostersList = (page = 1) => {
    setApiStatus(Status.LOADING);
    api
      .fetchFavouriteList({
        entityType: POSTER_SK,
        page,
      })
      .then(({ userResponse: newList }) => {
        setApiStatus(Status.SUCCESS);
        setList([...list, ...newList]);
        setHasMore(newList.length >= LIMITS.ITEMS_PER_PAGE);
        setIsEmpty(page === 1 && newList.length === 0);
      })
      .catch(() => {
        setApiStatus(Status.ERROR);
      });
  }

  const deleteFavouriteCallback = (favouriteIdentifier) => {
    showMessage();
    let _list = _.clone(list);
    _list.map(item => {
      if (item.favouriteIdentifier === favouriteIdentifier) {
        item.isDeleted = true;
      }
    })
    setList(_list);
  }

  const showMessage = () => {
    message.success({
      content: translate('userProfile.unsavedSuccessMessage')
    });
  }

  const resetState = () => {
    setPage(1);
    setList([]);
    setApiStatus(Status.DEFAULT);
    setHasMore(true);
    setIsEmpty(false);
  }

  const loader = (
    <Loader>
      <Spinner />
    </Loader>
  );

  const endMessage = (
    isEmpty && <EmptyCard watchList />
  );

  const next = () => {
    if (apiStatus !== Status.DEFAULT &&
      apiStatus !== Status.LOADING
    ) {
      setPage(prevState => prevState + 1);
    }
  }

  const renderItem = (item) => {
    if (item.isDeleted) {
      return null;
    }
    return (
      <PosterCard
        entityType={item.entityType}
        posterIdentifier={item.entityIdentifier}
        isSaved={item.isSaved === UNSAVE_SK}
        favouriteIdentifier={item.favouriteIdentifier}
        image={item.portraitImagePath.originalUrl}
        deleteFavouriteCallback={deleteFavouriteCallback}
      />
    )
  }

  return (
    <InfiniteScroll
      dataLength={list.length}
      next={next}
      hasMore={hasMore}
      loader={loader}
      endMessage={endMessage}
    >
      <ListContainer>
        {list.map((item, key) => (
          <ListColumn key={key}>
            <ListItem>
              {renderItem(item)}
            </ListItem>
          </ListColumn>
        ))}
      </ListContainer>
    </InfiniteScroll>
  );
}

export default Posters;