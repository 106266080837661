import styled from "styled-components";
import { Form, Row, Col, } from "antd";
import { device } from "../../../theme";
import { GlobalButton, GlobalInputBox } from "../../../theme/styles";

export const Container = styled(Row)`
  background-color: #fff;
  min-height: 100vh;
  color: #000;
`;

export const ForgetWrapper = styled(Col)`
  margin-top: 100px;
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  text-align: center;
  color: ${props => props.theme.primaryColor};

  @media ${device.laptop} {
    font-size: 40px;
  }
  
  @media ${device.mobileL} {
    font-size: 25px;
  }

  @media ${device.mobileS} {
    font-size: 20px;
  }
`;

export const TextInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
  padding: 0px 120px;

  @media ${device.laptop}{
    font-size: 16px;
    padding: 0px 20px;
  }

  @media ${device.mobileL}{
    font-size: 14px;
  }

  @media ${device.mobileS}{
    font-size: 12px;
    padding: 0px;
  }
`;

export const FormContainer = styled(Form)`
  padding: 30px 20px;
`;

export const FormRow = styled(Row)``;

export const ForgetEmailItem = styled(Form.Item)`
  display: ${({ isEmail }) => (isEmail ? "block" : "none")};
  margin-bottom: 20px;
`;

export const ForgetPhoneItem = styled(Form.Item)`
  display: ${({ isEmail }) => (!isEmail ? "block" : "none")};
  margin-bottom: 20px;
`;

export const ForgetInputBox = styled(GlobalInputBox)``;

export const ForgetPhoneBox = styled.div``;

export const ForgetButton = styled(GlobalButton)`
width: 100%;
`;

