import styled from "styled-components";
import { device } from "../../theme";
import { GlobalButton } from "../../theme/styles";

export const SocailImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
export const SocialLoginButton = styled(GlobalButton)`
width: 100%;
`;

export const SocialButton = styled(GlobalButton)`
  width: 100%;
`;

export const ImgSection = styled.div``;

export const LabelSection = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;
