import styled from 'styled-components';
import {
  ContainerTwoTone,
} from '@ant-design/icons';
import { GlobalButton } from '../../theme/styles';
import { device } from '../../theme';

export const Container = styled.div`
margin-top: ${props => props.watchList ? '-20px' : '20px'};
padding-bottom: 60px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
`;

export const TicketIcon = styled(ContainerTwoTone).attrs({
  twoToneColor: '#BEBFCF',
})`
display: ${({ watchList }) => watchList ? 'none' : 'block'};
  svg {
    width: 80px;
    height: 80px;

    @media ${device.tablet} {
      width: 50px;
      height: 50px;
    }
  }
`;

export const WatchListIcon = styled.div`
width: 80px;
height: 80px;
align-items: center;
justify-content: center;
display: ${({ watchList }) => watchList ? 'inline-flex' : 'none'};
  
  span {
    position: absolute;
  }
  .anticon-heart svg {
    width: 80px;
    height: 80px;
    color: #BEBFCF;

    @media ${device.tablet} {
      width: 50px;
      height: 50px;
    }
  }
  .anticon-question svg {
    width: 30px;
    height: 30px;
    color: #BEBFCF;

    @media ${device.tablet} {
      width: 15px;
      height: 15px;
    }
  }

@media ${device.tablet} {
  width: 50px;
  height: 50px;
}
`;

export const TextOne = styled.div`
padding-top: 30px;
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 45px;
text-align: center;
color: ${props => props.theme.fontColor};

@media ${device.tablet} {
  padding-top: 5px;
  font-size: 16px;
}
`;

export const TextTwo = styled.div`
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
text-align: center;
color: ${props => props.theme.fontColor};

@media ${device.tablet} {
  font-size: 12px;
}
`;

export const Button = styled(GlobalButton)`
min-width: 40%;
margin-top: 30px;
font-style: normal;
font-weight: 600;
font-size: 16px;
text-align: center;
color: #FFFFFF;

@media ${device.tablet} {
  margin-top: 20px;
  font-size: 14px;
}
`;